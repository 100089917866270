import { z } from "zod";

import { i18n } from "@lib/i18n";
import { globalLayoutRoute } from "@routes";
import { allowAdminAndUser, allowCompany, allowGroup } from "@routes/authorization";
import { createRoute, redirect } from "@tanstack/react-router";

import JobDescriptions from "./index";

const JobDescriptionsSearchSchema = z.object({
    jdid: z.string().optional(),
});
type JobDescriptionsSearch = z.infer<typeof JobDescriptionsSearchSchema>;
export const jobDescriptionsRoute = createRoute({
    getParentRoute: () => globalLayoutRoute,
    path: `job-descriptions`,
    component: JobDescriptions,
    validateSearch: (search: JobDescriptionsSearch) => {
        return JobDescriptionsSearchSchema.parse(search);
    },
    beforeLoad: async () => {
        const allowedAdminAndUser = await allowAdminAndUser();
        if (!(allowCompany() || allowGroup()) && allowedAdminAndUser) {
            throw redirect({ to: `/` });
        }

        return { getTitle: () => i18n.doc_title_job_descriptions() };
    },
});
