import { connect } from "react-redux";
import { toast } from "saphir";
import { ModalsService } from "side-ui";

import { i18n } from "@lib/i18n";
import { router } from "@routes";

import * as backend from "../../../lib/api";
import trackEvent from "../../../lib/trackers";
import DuplicateModal from "../components/DuplicateModal";

const mapStateToProps = (state, ownProps) => ({
    taskName: ownProps.task.name,
    taskJobtypeTranslatedName: ownProps.task.jobTitle,
});

const mapDispatchToProps = (dispatch, ownProps) => {
    const {
        task: { status, type, id },
        match,
    } = ownProps;

    return {
        duplicateTask: ({ taskName, attendanceConfigType }) => {
            trackEvent({
                name: `taskOptionButton`,
                params: {
                    optionClick: `duplicateTask`,
                    page: !match || !match.params.status ? `all` : status,
                    taskStatus: status,
                },
            });
            backend
                .duplicateTask({
                    type,
                    name: taskName,
                    attendanceConfigType,
                    taskId: id,
                })
                .then((res) => res.json())
                .then((res) => {
                    if (!res.id) {
                        toast.warning(i18n.errors_generic());
                        throw new Error(`Task not duplicated`);
                    }
                    router.navigate({
                        to: `/taskPosting/$taskId/motive`,
                        params: {
                            taskId: res.id,
                        },
                    });
                });

            ModalsService.closeModal(`DUPLICATE_TASK`);
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(DuplicateModal);
