import { useRef, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { Dialog, toast } from "saphir";
import { Button, ComboBox } from "sui";
import { z } from "zod";

import { zodResolver } from "@hookform/resolvers/zod";
import { createLocation } from "@lib/api";
import { i18n } from "@lib/i18n";
import { usePatchTask } from "@lib/mutations/usePatchTask";
import { queries } from "@lib/queries";
import { addressComponentsAdapter } from "@routes/JobDescriptions/utils";
import { useMutation, useQuery, useQueryClient, useSuspenseQuery } from "@tanstack/react-query";

interface Props {
    taskId: string;
    trigger: React.ReactNode;
    tracker: () => void;
}

export function EditTaskAddressDialog(props: Props) {
    const [open, setOpen] = useState(false);

    return (
        <Dialog.Root open={open} onOpenChange={setOpen}>
            <Dialog.Trigger asChild>{props.trigger}</Dialog.Trigger>
            <Dialog.Content>
                <EditTaskAddressDialogContent {...props} setOpen={setOpen} />
            </Dialog.Content>
        </Dialog.Root>
    );
}

function EditTaskAddressDialogContent(props: Props & { setOpen: (value: boolean) => void }) {
    const { data: task } = useSuspenseQuery(queries.task.detail(props.taskId));

    const [searchValue, setSearchValue] = useState(task.location?.address ?? "");
    const placesRef = useRef<HTMLDivElement>(null);

    const { data: googlePlacesPredictions } = useQuery(
        queries.googlePlacesPredictions.list(searchValue),
    );

    const googlePlacesPredictionsOptions =
        googlePlacesPredictions?.map((prediction) => ({
            value: prediction.place_id,
            label: prediction.description,
        })) ?? [];

    const schema = z.object({
        address: z
            .object({
                value: z.string(),
                label: z.string(),
            })
            .nullable(),
    });

    type Inputs = z.infer<typeof schema>;

    const {
        control,
        handleSubmit,
        formState: { isDirty },
        reset,
    } = useForm<Inputs>({
        mode: "onBlur",
        resolver: zodResolver(schema),
        defaultValues: {
            address: null,
        },
    });

    const queryClient = useQueryClient();

    const { patchTask } = usePatchTask();
    const { mutate: createLocationMutation, isPending } = useMutation({
        mutationFn: createLocation,
        onSuccess: (data) => {
            if (!data) return;
            patchTask({ id: props.taskId, location: data });
            props.tracker();
            reset({
                address: {
                    value: data.id,
                    label: data.address,
                },
            });
            props.setOpen(false);
            toast.success(i18n.task_edit_success_toast());
        },
        onSettled: () => {
            queryClient.invalidateQueries({ queryKey: ["task"] });
        },
    });

    const onSubmit: SubmitHandler<Inputs> = async (data) => {
        if (!placesRef.current) return;
        const PlacesService = new window.google.maps.places.PlacesService(placesRef.current);
        const placeDetails = await new Promise<google.maps.places.PlaceResult>(
            (resolve, reject) => {
                if (!data.address?.value) return;
                PlacesService.getDetails({ placeId: data.address.value }, (result, status) => {
                    if (status === window.google.maps.places.PlacesServiceStatus.OK && result) {
                        resolve(result);
                    } else {
                        reject(status);
                    }
                });
            },
        );

        const addressData = addressComponentsAdapter(placeDetails.address_components) as {
            streetNumber: string;
            streetName: string;
            postalCode: string;
            city: string;
            region: string;
            department: string;
            country: string;
        };
        if (!data.address?.label || !placeDetails.geometry?.location) return;
        createLocationMutation({
            organisationId: props.taskId,
            location: {
                ...addressData,
                address: data.address?.label,
                lat: placeDetails.geometry.location.lat(),
                lng: placeDetails.geometry.location.lng(),
                favorite: false,
            },
        });
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)} className='contents'>
            {/* required for google places api 🤷‍♂️ */}
            <div ref={placesRef} />
            <Dialog.ScrollArea>
                <Dialog.Header>
                    <Dialog.Title>{i18n.task_edit_location_workplace_title()}</Dialog.Title>
                    <Dialog.Description>
                        {i18n.location_form_workplace_subtitle()}
                    </Dialog.Description>
                </Dialog.Header>
                <Dialog.Main>
                    <Controller
                        name='address'
                        control={control}
                        render={({ field }) => {
                            return (
                                <ComboBox
                                    placeholder='Search for an address'
                                    aria-label='Search for an address'
                                    size='small'
                                    inputValue={searchValue}
                                    onInputChange={setSearchValue}
                                    options={googlePlacesPredictionsOptions}
                                    selection={field.value}
                                    onChange={(option) => {
                                        field.onChange(option);
                                    }}
                                />
                            );
                        }}
                    />
                </Dialog.Main>
            </Dialog.ScrollArea>
            <Dialog.Footer className='justify-end'>
                <div className='flex gap-2'>
                    <Dialog.Close asChild>
                        <Button type='button' shape='invisible' intention='secondary'>
                            {i18n.action_cancel()}
                        </Button>
                    </Dialog.Close>
                    <Button loading={isPending} disabled={!isDirty}>
                        {i18n.save()}
                    </Button>
                </div>
            </Dialog.Footer>
        </form>
    );
}
