import { Suspense, useState } from "react";
import { Euro } from "lucide-react";
import { SubmitHandler, useForm } from "react-hook-form";
import { Dialog, Input, TextField, toast, VisuallyHidden } from "saphir";
import { Button, Loader } from "sui";
import { z } from "zod";

import { zodResolver } from "@hookform/resolvers/zod";
import { i18n } from "@lib/i18n";
import { usePatchTask } from "@lib/mutations/usePatchTask";
import { queries } from "@lib/queries";
import { useQueryClient, useSuspenseQuery } from "@tanstack/react-query";

interface Props {
    taskId: string;
    trigger: React.ReactNode;
    tracker: () => void;
}

export function EditTaskSalaryDialog(props: Props) {
    const [open, setOpen] = useState(false);
    const queryClient = useQueryClient();

    return (
        <Dialog.Root open={open} onOpenChange={setOpen}>
            <Dialog.Trigger
                asChild
                onMouseEnter={() => {
                    // prefetch legalValues data
                    queryClient.ensureQueryData(queries.legalValues.list());
                }}
            >
                {props.trigger}
            </Dialog.Trigger>
            <Dialog.Content>
                <Suspense
                    fallback={
                        <div className='flex w-full items-center justify-center p-4 text-blue-500'>
                            <VisuallyHidden>
                                <Dialog.Title>
                                    {i18n.job_descriptions_creation_salary_input_label()}
                                </Dialog.Title>
                            </VisuallyHidden>
                            <Loader />
                        </div>
                    }
                >
                    <EditTaskSalaryDialogContent {...props} setOpen={setOpen} />
                </Suspense>
            </Dialog.Content>
        </Dialog.Root>
    );
}

function EditTaskSalaryDialogContent(props: Props & { setOpen: (value: boolean) => void }) {
    const { data: task } = useSuspenseQuery(queries.task.detail(props.taskId));
    const { data: legalValues } = useSuspenseQuery(queries.legalValues.list());

    const isLogas = Boolean(localStorage.getItem("side_team_logasId"));

    const schema = z.object({
        // we keep the hourlyRate as a string since it's the default type coming from text input and it makes decimal places validation easier
        hourlyRate: z.string().superRefine((val, ctx) => {
            // check that the value is not bellow the legalValues.minimumSalary
            if (Number(val) < legalValues.minimumSalary) {
                ctx.addIssue({
                    code: z.ZodIssueCode.too_small,
                    minimum: legalValues.minimumSalary,
                    type: "number",
                    inclusive: true,
                    message: i18n.job_descriptions_creation_salary_input_error_underflow(),
                });
            }

            // if the user is no logas, check that the value is not set bellow its current value
            if (task.hourlyRate && !isLogas && Number(val) < task.hourlyRate) {
                ctx.addIssue({
                    code: z.ZodIssueCode.too_small,
                    minimum: task.hourlyRate,
                    type: "number",
                    inclusive: true,
                    message: i18n.task_edit_salary_too_low_error(),
                });
            }

            // check that the value has maximum 2 decimal places
            const normalizedValue = val.replace(",", ".");

            const numberValue = parseFloat(normalizedValue);
            if (isNaN(numberValue)) {
                ctx.addIssue({
                    code: z.ZodIssueCode.custom,
                    message: i18n.task_edit_salary_invalid_number_error(),
                });
            }

            const decimalPart = normalizedValue.split(".")[1];
            if (decimalPart && decimalPart.length > 2) {
                ctx.addIssue({
                    code: z.ZodIssueCode.custom,
                    message: i18n.task_edit_salary_invalid_decimal_error(),
                });
            }
        }),
    });

    type Inputs = z.infer<typeof schema>;

    const {
        register,
        handleSubmit,
        formState: { isDirty, errors, isValid },
        reset,
    } = useForm<Inputs>({
        mode: "onChange",
        resolver: zodResolver(schema),
        defaultValues: {
            hourlyRate: String(task.hourlyRate) ?? legalValues.minimumSalary,
        },
    });

    const queryClient = useQueryClient();

    const { patchTask, isPending } = usePatchTask({
        onSuccess: (data) => {
            queryClient.setQueryData(queries.task.detail(props.taskId).queryKey, data);
            props.tracker();
            reset({
                hourlyRate: String(data?.hourlyRate),
            });
            props.setOpen(false);
            toast.success(i18n.task_edit_success_toast());
        },
        onSettled: () => {
            queryClient.invalidateQueries({ queryKey: ["task"] });
        },
    });

    const onSubmit: SubmitHandler<Inputs> = async (data) => {
        patchTask({
            id: props.taskId,
            hourlyRate: Number(data.hourlyRate),
        });
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)} className='contents'>
            <Dialog.ScrollArea>
                <Dialog.Header>
                    <Dialog.Title>
                        {i18n.job_descriptions_creation_salary_input_label()}
                    </Dialog.Title>
                    <Dialog.Description>
                        {i18n.job_descriptions_creation_salary_subtitle()}
                    </Dialog.Description>
                </Dialog.Header>
                <Dialog.Main>
                    <Input.Root>
                        <Input.Element>
                            <TextField.Root type='number' step={0.01} {...register("hourlyRate")}>
                                <TextField.Slot>
                                    <Euro />
                                </TextField.Slot>
                            </TextField.Root>
                        </Input.Element>
                        <Input.ErrorMessage>{errors.hourlyRate?.message}</Input.ErrorMessage>
                    </Input.Root>
                </Dialog.Main>
            </Dialog.ScrollArea>
            <Dialog.Footer className='justify-end'>
                <div className='flex gap-2'>
                    <Dialog.Close asChild>
                        <Button type='button' shape='invisible' intention='secondary'>
                            {i18n.action_cancel()}
                        </Button>
                    </Dialog.Close>
                    <Button loading={isPending} disabled={!isDirty || !isValid}>
                        {i18n.save()}
                    </Button>
                </div>
            </Dialog.Footer>
        </form>
    );
}
