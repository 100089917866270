import { useEffect } from "react";
import clsx from "clsx";
import { Textarea } from "side-ui";

import { useFormInput } from "@lib/hooks/form";
import { i18n } from "@lib/i18n";
import { useTimer } from "@routes/JobDescriptions/hooks/useTimer";

import styles from "./DressCodeForm.module.css";

export function DressCodeForm({ dressCode, setDressCode }) {
    const { formInput: formDressCode, handleChange: setFormDressCode } = useFormInput(
        dressCode,
        () => null,
    );

    useEffect(() => {
        setFormDressCode(dressCode);
    }, [dressCode]);

    useTimer(
        {
            timing: 800,
            callback: () => setDressCode(formDressCode.value),
        },
        [formDressCode.value],
    );

    return (
        <div className={clsx(styles.dressCode, "dresscode")}>
            <Textarea
                id='dresscode'
                label={i18n.job_descriptions_creation_conditions_equipments_dresscode_title()}
                placeholder={i18n.job_descriptions_creation_conditions_equipments_dresscode_placeholder()}
                height={120}
                value={formDressCode.value}
                onChange={(value) => setFormDressCode(value)}
                small={false}
                required={true}
            />
        </div>
    );
}
