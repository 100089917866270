import fetch from "isomorphic-fetch";
import { z } from "zod";

import { handleError } from "@lib/utils/error";

import { withAuthHeaders } from "./apiHelpers";
import { apiConf } from "./index";

const getCompanyLegalInfoResponseSchema = z.object({
    name: z.string(),
    businessName: z.string().optional(),
    siret: z.string().optional(),
    apeCode: z.string().optional(),
});

export type GetCompanyLegalInfoResponse = z.infer<typeof getCompanyLegalInfoResponseSchema>;
export async function getCompanyLegalInfo(
    organisationId: string,
): Promise<GetCompanyLegalInfoResponse> {
    try {
        const resp = await fetch(
            withAuthHeaders(
                `${apiConf.BACK_URL}/organisations/${organisationId}/legal-informations`,
            ),
        );
        if (resp.status >= 400) {
            handleError(resp.error, resp.status);
            return Promise.reject(new Error("GetCompanyLegalInfo"));
        }

        return getCompanyLegalInfoResponseSchema.parse(await resp.json());
    } catch (e) {
        handleError(e, 500);
        return Promise.reject(new Error("GetCompanyLegalInfo"));
    }
}
