import { useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { Dialog, Input, RadioGroup, toast } from "saphir";
import { Button } from "sui";
import { z } from "zod";

import { zodResolver } from "@hookform/resolvers/zod";
import { ExecutiveStatusSchema } from "@lib/api";
import { i18n, i18nDK } from "@lib/i18n";
import { usePatchTask } from "@lib/mutations/usePatchTask";
import { queries } from "@lib/queries";
import { useQueryClient, useSuspenseQuery } from "@tanstack/react-query";

interface Props {
    taskId: string;
    trigger: React.ReactNode;
    tracker: () => void;
}

export function EditTaskExecutiveStatusDialog(props: Props) {
    const [open, setOpen] = useState(false);

    return (
        <Dialog.Root open={open} onOpenChange={setOpen}>
            <Dialog.Trigger asChild>{props.trigger}</Dialog.Trigger>
            <Dialog.Content>
                <EditTaskExecutiveStatusDialogContent {...props} setOpen={setOpen} />
            </Dialog.Content>
        </Dialog.Root>
    );
}

function EditTaskExecutiveStatusDialogContent(props: Props & { setOpen: (open: boolean) => void }) {
    const { data: task } = useSuspenseQuery(queries.task.detail(props.taskId));

    const schema = z.object({
        executiveStatus: ExecutiveStatusSchema,
    });

    type Inputs = z.infer<typeof schema>;

    const {
        control,
        handleSubmit,
        formState: { isDirty },
        reset,
    } = useForm<Inputs>({
        mode: "onBlur",
        resolver: zodResolver(schema),
        defaultValues: {
            executiveStatus: task.executiveStatus ?? "",
        },
    });

    const queryClient = useQueryClient();

    const { patchTask, isPending } = usePatchTask({
        onSuccess: (data) => {
            queryClient.setQueryData(queries.task.detail(props.taskId).queryKey, data);
            props.tracker();
            reset({
                executiveStatus: data?.executiveStatus,
            });
            props.setOpen(false);
            toast.success(i18n.task_edit_success_toast());
        },
        onSettled: () => {
            queryClient.invalidateQueries({ queryKey: ["task"] });
        },
    });

    const onSubmit: SubmitHandler<Inputs> = async (data) => {
        patchTask({
            id: props.taskId,
            executiveStatus: data.executiveStatus,
        });
    };
    return (
        <form onSubmit={handleSubmit(onSubmit)} className='contents'>
            <Dialog.ScrollArea>
                <Dialog.Header>
                    <Dialog.Title>{i18n.job_descriptions_creation_status_title()}</Dialog.Title>
                    <Dialog.Description>
                        {i18n.job_descriptions_creation_status_subtitle()}
                    </Dialog.Description>
                </Dialog.Header>
                <Dialog.Main>
                    <Controller
                        control={control}
                        name='executiveStatus'
                        render={({ field }) => {
                            return (
                                <RadioGroup.Root
                                    onValueChange={(value) => {
                                        field.onChange(value);
                                    }}
                                    value={field.value}
                                >
                                    {ExecutiveStatusSchema.options.map((option) => {
                                        if (!option) return null;
                                        return (
                                            <Input.Root
                                                key={option}
                                                className='group flex flex-col rounded border border-gray-200 hover:border-blue-500'
                                            >
                                                <Input.Label className='flex w-full flex-row items-center gap-4 p-4 !typography-body-m-semibold'>
                                                    <Input.Element>
                                                        <RadioGroup.Item value={option} />
                                                    </Input.Element>
                                                    {i18nDK(`job_descriptions_status_${option}`)}
                                                </Input.Label>
                                            </Input.Root>
                                        );
                                    })}
                                </RadioGroup.Root>
                            );
                        }}
                    />
                </Dialog.Main>
            </Dialog.ScrollArea>
            <Dialog.Footer className='justify-end'>
                <div className='flex gap-2'>
                    <Dialog.Close asChild>
                        <Button type='button' shape='invisible' intention='secondary'>
                            {i18n.action_cancel()}
                        </Button>
                    </Dialog.Close>
                    <Button loading={isPending} disabled={!isDirty}>
                        {i18n.save()}
                    </Button>
                </div>
            </Dialog.Footer>
        </form>
    );
}
