import { SummaryTableRow } from "side-ui";

import { i18n, i18nDK } from "@lib/i18n";

import styles from "./JobDescriptionSummary.module.css";

export function JobDescriptionSummary({ jobDescription, companyManagers, editSection }) {
    const jobDescriptionName = `${jobDescription?.job?.jobTitle?.name}${
        jobDescription?.suffix ? ` - ${jobDescription?.suffix}` : ""
    }`;
    const jobDescriptionsJobTitle = jobDescription?.job?.jobTitle?.name;
    const jobDescriptionSubtasks = jobDescription?.job?.subtasks
        ?.map((subtask) => subtask.name)
        .join(", ");
    const jobDescriptionContext = jobDescription?.workConditions?.context;
    const jobDescriptionSafetyEquipements = jobDescription?.workConditions?.safetyEquipmentList
        ?.map((safetyEquipment) => {
            if (safetyEquipment?.custom) return safetyEquipment.name;
            return i18nDK(safetyEquipment.name);
        })
        .join(", ");
    const jobDescriptionDevices = jobDescription?.workConditions?.devices
        ?.map((device) => {
            if (device?.custom) return device.name;
            return i18nDK(device.name);
        })
        .join(", ");
    const jobDescriptionDresscode = jobDescription?.workConditions?.dressCode;
    const jobDescriptionRisks = (jobDescription?.workConditions?.risks ||
        jobDescription?.workConditions?.aboutRisks) && (
        <div>
            <p>
                {jobDescription?.workConditions?.risks?.map((risk) => i18nDK(risk.name)).join(", ")}
            </p>
            <p>{jobDescription?.workConditions?.aboutRisks}</p>
        </div>
    );
    const jobDescriptionLanguages = jobDescription?.skills?.languages
        ?.map((language) => i18nDK(language.ISOCode))
        .join(", ");
    const jobDescriptionTools = jobDescription?.skills?.tools
        ?.map((tool) => (tool.custom ? tool.name : i18nDK(tool.name)))
        .join(", ");
    const jobDescriptionLicences = jobDescription?.skills?.licences
        ?.map((licence) => i18nDK(licence.name))
        .join(", ");
    const jobDescriptionExperiences = jobDescription?.skills?.experiences;
    const jobDescriptionAddress = jobDescription?.location?.address;
    const jobDescriptionManager = (
        <div className={styles.manager}>
            <p>
                {companyManagers.find(({ value }) => value === jobDescription.managerId)?.label ||
                    i18n.manager_form_choice_later()}
            </p>
        </div>
    );
    const jobDescriptionHourlyRate = (
        <div className={styles.salary}>
            <p className={styles.label}>{i18n.job_descriptions_creation_salary_raw()}</p>
            <p className={styles.value}>{jobDescription?.hourlyRate}€</p>
        </div>
    );

    const jobDescriptionSummaryTable = [
        {
            label: i18n.job_descriptions_summary_name_title(),
            content: jobDescriptionName || "-",
            actionDemo: () => editSection("name"),
            actionIcon: "Pen",
        },
        {
            label: i18n.job_descriptions_summary_jobTitle_title(),
            content: jobDescriptionsJobTitle || "-",
            actionDemo: () => editSection("jobTitle"),
            actionIcon: "Pen",
        },
        {
            label: i18n.job_descriptions_summary_subtasks_title(),
            content: jobDescriptionSubtasks || "-",
            actionDemo: () => editSection("subtasks"),
            actionIcon: "Pen",
        },
        {
            label: i18n.job_descriptions_summary_context_title(),
            content: jobDescriptionContext || "-",
            actionDemo: () => editSection("context"),
            actionIcon: "Pen",
        },
        {
            label: i18n.job_descriptions_summary_safetyEquipment_title(),
            content: jobDescriptionSafetyEquipements || "-",
            actionDemo: () => editSection("safetyEquipment"),
            actionIcon: "Pen",
        },
        {
            label: i18n.job_descriptions_summary_devices_title(),
            content: jobDescriptionDevices || "-",
            actionDemo: () => editSection("devices"),
            actionIcon: "Pen",
        },
        {
            label: i18n.job_descriptions_summary_dresscode_title(),
            content: jobDescriptionDresscode || "-",
            actionDemo: () => editSection("dresscode"),
            actionIcon: "Pen",
        },
        {
            label: i18n.job_descriptions_summary_risks_title(),
            content: jobDescriptionRisks || "-",
            actionDemo: () => editSection("risks"),
            actionIcon: "Pen",
        },
        {
            label: i18n.job_descriptions_summary_languages_title(),
            content: jobDescriptionLanguages || "-",
            actionDemo: () => editSection("languages"),
            actionIcon: "Pen",
        },
        {
            label: i18n.job_descriptions_summary_tools_title(),
            content: jobDescriptionTools || "-",
            actionDemo: () => editSection("tools"),
            actionIcon: "Pen",
        },
        {
            label: i18n.job_descriptions_summary_licences_title(),
            content: jobDescriptionLicences || "-",
            actionDemo: () => editSection("licences"),
            actionIcon: "Pen",
        },
        {
            label: i18n.job_descriptions_summary_experiences_title(),
            content: jobDescriptionExperiences || "-",
            actionDemo: () => editSection("experiences"),
            actionIcon: "Pen",
        },
        {
            label: i18n.job_descriptions_summary_location_title(),
            content: jobDescriptionAddress || "-",
            actionDemo: () => editSection("location"),
            actionIcon: "Pen",
        },
        {
            label: i18n.job_descriptions_summary_manager_title(),
            content: jobDescriptionManager || "-",
            actionDemo: () => editSection("manager"),
            actionIcon: "Pen",
        },
        {
            label: i18n.job_descriptions_summary_salary_title(),
            content: jobDescriptionHourlyRate || "-",
            actionDemo: () => editSection("salary"),
            actionIcon: "Pen",
        },
        {
            label: i18n.job_descriptions_summary_status_title(),
            content: jobDescription.executiveStatus
                ? i18nDK(`job_descriptions_status_${jobDescription.executiveStatus}`)
                : "-",
            actionDemo: () => editSection("status"),
            actionIcon: "Pen",
        },
    ];

    return (
        <div className={styles.summary}>
            {jobDescriptionSummaryTable.map(
                ({ label, content, actionDemo, actionIcon }) =>
                    content &&
                    label && (
                        <SummaryTableRow
                            key={label}
                            label={label}
                            content={content}
                            action={actionDemo}
                            actionIcon={actionIcon}
                        />
                    ),
            )}
        </div>
    );
}
