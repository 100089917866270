import { Loader } from "sui";
import { z } from "zod";

import { i18n } from "@lib/i18n";
import { globalLayoutRoute } from "@routes";
import { allowAdminAndUser, allowCompany } from "@routes/authorization";
import { createRoute, redirect } from "@tanstack/react-router";

import { DetailsStep } from "./DetailsStep/DetailsStep";
import { FlexiblePlanningStep } from "./FlexiblePlanningStep/FlexiblePlanningStep";
import { MotiveStep } from "./MotiveStep/MotiveStep";
import { PlanningTypeStep } from "./PlanningTypeStep/PlanningTypeStep";
import { ShiftsStep } from "./ShiftsStep/ShiftsStep";
import { Summary } from "./Summary/Summary";
import { WorkersStep } from "./WorkersStep/WorkersStep";
import TaskForm from "./index";

import styles from "./TaskForm.module.css";

export const taskFormRoute = createRoute({
    getParentRoute: () => globalLayoutRoute,
    path: `taskPosting/$taskId`,
    component: TaskForm,
    beforeLoad: async () => {
        const allowedAdminAndUser = await allowAdminAndUser();
        if (!(allowCompany() && allowedAdminAndUser)) {
            throw redirect({ to: `/` });
        }

        return { getTitle: () => i18n.doc_title_posting() };
    },
});

export const motiveStepRoute = createRoute({
    getParentRoute: () => taskFormRoute,
    path: `motive`,
    component: MotiveStep,
    pendingComponent: () => (
        <div className={styles.loadingLayout}>
            <Loader className={styles.loader} />
        </div>
    ),
});

export const planningTypeStepRoute = createRoute({
    getParentRoute: () => taskFormRoute,
    path: `planning-type`,
    component: PlanningTypeStep,
});

export const flexiblePlanningStepRoute = createRoute({
    getParentRoute: () => taskFormRoute,
    path: `flexible-planning`,
    component: FlexiblePlanningStep,
});

const shiftStepSearchSchema = z
    .object({
        create: z.boolean().optional(),
    })
    .partial();
type ShiftStepSearch = z.infer<typeof shiftStepSearchSchema>;

export const shiftsStepRoute = createRoute({
    getParentRoute: () => taskFormRoute,
    path: `shifts`,
    component: ShiftsStep,
    validateSearch: (search: ShiftStepSearch) => shiftStepSearchSchema.parse(search),
});

export const detailsStepRoute = createRoute({
    getParentRoute: () => taskFormRoute,
    path: `details`,
    component: DetailsStep,
});

export const workersStepRoute = createRoute({
    getParentRoute: () => taskFormRoute,
    path: `workers`,
    component: WorkersStep,
});

export const summaryRoute = createRoute({
    getParentRoute: () => taskFormRoute,
    path: `summary`,
    component: Summary,
});
