import { memo } from "react";
import { Check } from "side-ui";

import { i18n } from "@lib/i18n";

const TimesheetsTableHeader = ({ attendancesTotalDuration, selectAll, allSelected }) => (
    <header className='timesheets-table__header'>
        <div className='timesheets-table__header__container timesheets-table__wrapper'>
            <span className='timesheets-table__header__title timesheets-table__header__title--date'>
                Date
            </span>

            <span className='timesheets-table__header__title timesheets-table__header__title--mission'>
                {i18n.ts_table_head_mission()}
            </span>

            <span className='timesheets-table__header__title timesheets-table__header__title--status'>
                {i18n.ts_table_head_status()}
            </span>

            <span className='timesheets-table__header__title timesheets-table__header__title--sider'>
                Sider
            </span>

            <span className='timesheets-table__header__title timesheets-table__header__title--slot'>
                {i18n.ts_table_head_start_end()}
            </span>

            <span className='timesheets-table__header__title timesheets-table__header__title--breaks'>
                {i18n.ts_table_head_sider_breaks()}
            </span>

            <span className='timesheets-table__header__title timesheets-table__header__title--duration'>
                {i18n.ts_table_head_duration()}({attendancesTotalDuration})
            </span>

            <span className='timesheets-table__header__title timesheets-table__header__title--checkbox'>
                <Check
                    checked={allSelected}
                    onChange={selectAll}
                    label=''
                    inputId='timesheet-table__header__check-all'
                    name='check-all'
                />
            </span>
        </div>
    </header>
);

export default memo(TimesheetsTableHeader);
