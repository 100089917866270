import { useEffect, useState } from "react";
import { SectionHeader } from "side-ui";
import { ChoiceBox, Radio } from "sui";

import * as backend from "@lib/api";
import { i18n } from "@lib/i18n";

import { SalaryForm } from "./SalaryForm/SalaryForm";

import styles from "./SalaryStep.module.css";

export const SalaryStep = ({ disableSubmit, setDataToUpdate, jobDescription }) => {
    const [salaryStepState, setSalaryStepState] = useState({
        hourlyRate: jobDescription?.hourlyRate ? jobDescription.hourlyRate : null,
        executiveStatus: jobDescription?.executiveStatus ? jobDescription.executiveStatus : null,
    });
    const [minimumHourlyWage, setMinimumHourlyWage] = useState<number | null>(null);

    useEffect(() => {
        setDataToUpdate(salaryStepState);
    }, [salaryStepState]);

    useEffect(() => {
        if (salaryStepState.executiveStatus === null) {
            disableSubmit(true);
        } else {
            disableSubmit(false);
        }
    }, [salaryStepState]);

    useEffect(() => {
        backend.getLegalValues().then((lvs) => setMinimumHourlyWage(lvs.minimumSalary));
    }, []);

    const options = [
        {
            label: i18n.job_descriptions_status_executive(),
            value: "executive",
        },
        {
            label: i18n.job_descriptions_status_ETAM(),
            value: "ETAM",
        },
        {
            label: i18n.job_descriptions_status_worker(),
            value: "worker",
        },
    ];

    return (
        <div className={styles.salary}>
            <div className={styles.wrapper}>
                <SectionHeader
                    title={i18n.job_descriptions_creation_salary_title()}
                    subtitle={i18n.job_descriptions_creation_salary_subtitle()}
                    closeAction={null}
                    icon='EuroSign'
                />
                {!!minimumHourlyWage && (
                    <SalaryForm
                        disableSubmit={disableSubmit}
                        minimumHourlyWage={minimumHourlyWage}
                        hourlyRate={salaryStepState.hourlyRate}
                        setHourlyRate={(hourlyRate) =>
                            setSalaryStepState((prevState) => ({
                                ...prevState,
                                hourlyRate,
                            }))
                        }
                    />
                )}
            </div>
            <div>
                <SectionHeader
                    title={i18n.job_descriptions_creation_status_title()}
                    subtitle={i18n.job_descriptions_creation_status_subtitle()}
                    closeAction={null}
                    icon='UserTeam'
                />
                <div className={styles.status}>
                    {options.map((option) => {
                        return (
                            <ChoiceBox
                                key={option.value}
                                label={option.label}
                                selectionControl={
                                    <Radio
                                        name='status'
                                        value={option.value}
                                        checked={salaryStepState.executiveStatus === option.value}
                                        onChange={(event) => {
                                            setSalaryStepState((prev) => {
                                                return {
                                                    ...prev,
                                                    executiveStatus: event.target.value,
                                                };
                                            });
                                        }}
                                    />
                                }
                            />
                        );
                    })}
                </div>
            </div>
        </div>
    );
};
