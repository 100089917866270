import { i18n } from "@lib/i18n";

import Invoice from "./Invoice";

const InvoicesList = (props) => {
    const { invoices } = props;
    const sortedInvoices =
        invoices &&
        invoices.length &&
        invoices.sort((invoice1, invoice2) => {
            const date1 = invoice1.weekStartDate;
            const date2 = invoice2.weekStartDate;
            if (date1 > date2) return -1;
            if (date1 < date2) return 1;
            return 0;
        });

    return (
        <div className='invoices-list'>
            <div className='row invoices-list__headers m-hidden'>
                <div className='col-xs-6 col-sm-4 invoices-list__col'>{i18n.invoices_date()}</div>
                <div className='col-xs-6 col-sm-3 invoices-list__col'>{i18n.invoices_number()}</div>
                <div className='col-sm-1 invoices-list__col'>{i18n.invoices_type()}</div>
                <div className='col-sm-2 invoices-list__col align-right'>
                    {i18n.invoices_amount()}
                </div>
                <div className='col-sm-2 col-sm-offset-1 invoices-list__col align-center'>
                    {i18n.invoices_status()}
                </div>
                <div className='col-sm-1 invoices-list__col align-right' />
            </div>
            {/* This div is here to allow us to have a first and last child */}
            <div>
                {sortedInvoices &&
                    sortedInvoices.length &&
                    sortedInvoices.map((invoice) => (
                        <Invoice key={invoice.referenceNumber} {...invoice} />
                    ))}
            </div>
        </div>
    );
};

export default InvoicesList;
