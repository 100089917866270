import fetch from "isomorphic-fetch";
import { z } from "zod";

import { handleError } from "@lib/utils/error";
import { debug } from "@side.co/client-debug";

import { withAuthHeaders } from "./apiHelpers";
import { EquipmentSchema } from "./getJobDescriptions";
import { TaskLocationSchema, TaskSchema, ToolSchema } from "./getTask";
import { apiConf } from "./index";

// used for preselection
const ApplicantSchema = z.object({
    id: z.string(),
    update: z.object({
        preSelectionStatus: z.enum(["sent", "approved", "refused"]).optional(),
        visible: z.boolean().optional(),
        comment: z.string().optional(),
        refusalReason: z.string().optional(),
        cvLink: z.string().optional(),
    }),
});

const updateTaskSchema = TaskSchema.extend({
    subtasks: z.array(
        z.object({
            // id is optional when patching, backend generates one for custom subtasks
            id: z.string().optional(),
            name: z.string(),
        }),
    ),
    // id is optional when patching, backend generates one for custom equipments
    equipments: z.array(
        EquipmentSchema.extend({
            id: z.string().optional(),
        }),
    ),
    // id is optional when patching, backend generates one for custom tools
    tools: z.array(
        ToolSchema.extend({
            id: z.string().optional(),
        }),
    ),
    applicant: ApplicantSchema.optional(),
    location: TaskLocationSchema.extend({
        motorized: z.boolean().optional(),
        remote: z.boolean().optional(),
        title: z.string().nullish(),
        description: z.string().optional(),
    }),
    // only used for notification, backend does NOT update database with this
    addressDetails: z.string().optional(),
    managerId: z.string().optional(),
    languageIds: z.array(z.string()).optional(),
    licenceIds: z.array(z.string()).optional(),
    riskIds: z.array(z.string()).optional(),
    requestedSiderIds: z.array(z.string()).optional(),
});

type UpdateTask = z.infer<typeof updateTaskSchema>;

export type UpdateTaskResponse = Awaited<ReturnType<typeof updateTask>>;

export async function updateTask(task: Partial<UpdateTask> & { managerId?: string }) {
    debug.addBreadcrumb({
        message: `Update existing task`,
        category: `action`,
        data: task,
    });

    try {
        const resp = await fetch(
            withAuthHeaders(`${apiConf.BACK_URL}/tasks/${task.id}`, {
                method: `PATCH`,
                body: JSON.stringify(task),
            }),
        );
        if (resp.status >= 400) {
            handleError(resp.error, resp.status);
            return Promise.reject(new Error("updateTask"));
        }

        const json = await resp.json();

        return TaskSchema.parse(json);
    } catch (e) {
        debug.catch(e);
        handleError(e, 500);
        return Promise.reject(new Error("updateTask"));
    }
}
