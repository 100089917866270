import { useState } from "react";
import { ModalSimple, RadioButtons } from "side-ui";

import { i18n, i18nDK } from "@lib/i18n";

import styles from "./SafetyEquipmentListForm.module.css";

export function EquipmentProviderModal({ equipment, setEquipment, hideModal }) {
    const [selectedEquipment, setSelectedEquipment] = useState(null);

    function handleEquipementSelection({ target }) {
        setSelectedEquipment({
            id: equipment.id,
            value: target.value,
            name: equipment.name,
            group: equipment.group,
            providedBy: target.value,
        });
    }

    return (
        <ModalSimple
            action={() => setEquipment(selectedEquipment)}
            validateLabel={i18n.add()}
            cancelLabel={i18n.cancel()}
            title={i18nDK(equipment?.name)}
            hideModal={hideModal}
            disableButton={!selectedEquipment?.value}
        >
            <p className={styles.provider}>
                {i18n.job_descriptions_creation_conditions_equipments_security_modal_subtitle()}
            </p>

            <RadioButtons
                label={i18n.job_descriptions_creation_conditions_equipments_security_modal_label()}
                value={{ [selectedEquipment?.value]: true }}
                options={[
                    { value: "sider", label: i18n.no() },
                    { value: "company", label: i18n.yes() },
                ]}
                onChange={handleEquipementSelection}
            />
        </ModalSimple>
    );
}
