import { Button, IconButton } from "side-ui";

import { i18n } from "@lib/i18n";

const EditCell = ({ setDisplayPanel }) => (
    <span className='timesheets-table__cell timesheets-table__cell--action'>
        <Button
            color='white'
            iconAfter='PenXL'
            action={() => setDisplayPanel(true)}
            customClass='timesheets-table__cell--action--withlabel'
        >
            {i18n.ts_update()}
        </Button>
        <IconButton color='grey' action={() => setDisplayPanel(true)} icon='PenXL' />
    </span>
);

export default EditCell;
