import { useEffect, useRef, useState } from "react";
import {
    eachMonthOfInterval,
    eachWeekOfInterval,
    endOfDay,
    endOfMonth,
    endOfWeek,
    endOfYear,
    format,
    getWeek,
    getYear,
    parseISO,
    startOfDay,
    startOfMonth,
    startOfWeek,
    subYears,
} from "date-fns";
import { fr } from "date-fns/locale";
import { Check, Filter, IconCalendar, RawDatePicker, SearchableDropdown } from "side-ui";
import { Tag } from "sui";

import { queryClient } from "@App";
import {
    attendanceModes,
    attendanceStatus,
    attendanceTypes,
    searchBy,
} from "@lib/api/getAttendances";
import { i18n, i18nDK } from "@lib/i18n";
import { queries } from "@lib/queries";
import { timesheetsRoute } from "@routes/Timesheets/route";
import { useQuery } from "@tanstack/react-query";

import trackEvent from "../../../../lib/trackers";
import { filterColors, setStatusLabel } from "../../utils";
import { formatWeek, setLocale } from "../../utils/dates";
import {
    initCheckboxFilter,
    initDateFilters,
    lookForManager,
    searchFilterOptions,
} from "../../utils/filters";

import CompanySearch from "./CompanySearch/CompanySearch";
import TimesheetsFilter from "./TimesheetsFilter/TimesheetsFilter";

import "./TimeSheetsFilterbar.scss";

const TimeSheetsFilterbar = ({
    updateFilters, // appends params to the URL and update Timesheets filters
    companyId,
    setPage,
    logAs,
}) => {
    const { current: locale } = useRef({ string: "fr", ref: fr });
    const { current: initialDate } = useRef(new Date());
    const filters = timesheetsRoute.useSearch();

    const startDate = (filters?.startDate && parseISO(filters?.startDate)) as Date | undefined;
    const endDate = filters?.endDate && parseISO(filters?.endDate);

    const { data: companyInfos } = useQuery(queries.company.detail());
    const companyName = companyInfos?.organisation.name;
    const companyMembers = companyInfos?.members || [];

    useEffect(() => {
        const storedLocale = localStorage.getItem("side_team_locale");

        locale.string = storedLocale || "fr";
        locale.ref = setLocale(storedLocale);
    }, []);

    const [statusFilter, setStatusFilter] = useState(
        (filters?.status && filters?.status.filter((f) => attendanceStatus.includes(f))) || [],
    );

    const [typeFilter, setTypeFilter] = useState({
        // SUI Filter component concatenates id with 'Checkbox'
        values: (filters?.type && filters?.type.map((f) => `${f}Checkbox`)) || [],
        active: (filters?.type && filters?.type.filter((f) => attendanceTypes.includes(f))) || [],
    });
    // mode is needed to select a filter option on init
    const [dateFilter, setDateFilter] = useState(
        filters.mode
            ? {
                  objectID: attendanceModes.includes(filters.mode) ? filters.mode : "all",
                  label: attendanceModes.includes(filters.mode)
                      ? i18nDK(`ts_filters_${filters.mode}`)
                      : i18n.ts_filters_all(),
              }
            : {
                  objectID: "all",
                  label: i18n.ts_filters_all(),
              },
    );
    const [selectedDate, setSelectedDate] = useState(startDate ?? initialDate);
    const [monthFilter, setMonthFilter] = useState(
        filters?.startDate
            ? {
                  objectID:
                      startDate &&
                      `${format(startDate, "MM", { weekStartsOn: 1 })}-${getYear(startDate)}`,
                  label:
                      startDate &&
                      `${format(startDate, "MMMM", {
                          locale: locale.ref,
                          weekStartsOn: 1,
                      })} ${getYear(startDate)}`,
                  date: startDate && startOfMonth(startDate),
              }
            : {
                  objectID: `${format(initialDate, "MM", { weekStartsOn: 1 })}-${getYear(
                      initialDate,
                  )}`,
                  label: `${format(initialDate, "MMMM", {
                      locale: locale.ref,
                      weekStartsOn: 1,
                  })} ${getYear(initialDate)}`,
                  date: startOfMonth(initialDate),
              },
    );
    const [weekFilter, setWeekFilter] = useState({
        objectID: "",
        label: "",
        subLabel: "",
        startDate: filters?.startDate || startOfWeek(new Date(), { weekStartsOn: 1 }),
        endDate: filters?.endDate || endOfWeek(new Date(), { weekStartsOn: 1 }),
    });
    const [searchFilter, setSearchFilter] = useState(
        filters?.searchBy && searchBy.includes(filters?.searchBy)
            ? {
                  objectID: filters?.searchBy,
                  label: filters?.searchBy,
              }
            : {
                  objectID: "sider",
                  label: "Sider",
              },
    );

    // if no sider[manager]Id do not set the name
    const [searchTerm, setSearchTerm] = useState(
        (filters?.managerId && filters?.managerName && decodeURI(filters?.managerName)) ||
            (filters?.siderId && filters?.siderName && decodeURI(filters?.siderName)) ||
            "",
    );
    const [searchFormState, setSearchFormState] = useState({
        touched: false,
    });

    // we need locale to be set before initializing week state
    useEffect(() => {
        setWeekFilter(
            filters.startDate && filters.endDate
                ? {
                      objectID: startDate
                          ? `${getWeek(startDate, { weekStartsOn: 1 })}-${getYear(startDate)}`
                          : "",
                      label:
                          startDate && endDate
                              ? `${locale.string === "fr" ? "Semaine" : "Week"} ${getWeek(
                                    startDate,
                                    {
                                        weekStartsOn: 1,
                                    },
                                )} ${getYear(endDate)}`
                              : "",
                      subLabel: startDate ? `${formatWeek(startDate)}` : "",
                      startDate: startDate ? startOfWeek(startDate, { weekStartsOn: 1 }) : "",
                      endDate: endDate ? endOfWeek(endDate, { weekStartsOn: 1 }) : "",
                  }
                : {
                      objectID: `${getWeek(initialDate, { weekStartsOn: 1 })}-${getYear(
                          initialDate,
                      )}`,
                      label: `${locale.string === "fr" ? "Semaine" : "Week"} ${getWeek(
                          initialDate,
                          { weekStartsOn: 1 },
                      )} ${getYear(initialDate)}`,
                      subLabel: `${formatWeek(initialDate)}`,
                      startDate: startOfWeek(initialDate, { weekStartsOn: 1 }),
                      endDate: endOfWeek(initialDate, { weekStartsOn: 1 }),
                  },
        );
    }, [locale, initialDate]);

    useEffect(() => {
        setMonthFilter(
            filters?.startDate
                ? {
                      objectID:
                          startDate &&
                          `${format(startDate, "MM", { weekStartsOn: 1 })}-${getYear(startDate)}`,
                      label:
                          startDate &&
                          `${format(startDate, "MMMM", {
                              locale: locale.ref,
                              weekStartsOn: 1,
                          })} ${getYear(startDate)}`,
                      date: startDate && startOfMonth(startDate),
                  }
                : {
                      objectID: `${format(initialDate, "MM", { weekStartsOn: 1 })}-${getYear(
                          initialDate,
                      )}`,
                      label: `${format(initialDate, "MMMM", {
                          locale: locale.ref,
                          weekStartsOn: 1,
                      })} ${getYear(initialDate)}`,
                      date: startOfMonth(initialDate),
                  },
        );
    }, [locale]);

    // resetting URL filters when in all mode
    useEffect(() => {
        if (dateFilter.objectID === "all") {
            // cleanup URL params
            updateFilters({ startDate: null, endDate: null, mode: null });
        }
    }, [dateFilter]);

    useEffect(() => {
        if (searchFilter.objectID === "sider") {
            // SUI Searchbar cleans the state itself we need this to
            // remove siderId/managerId from URL params
            if (searchTerm) {
                // user is typing fetch siders
                queryClient.invalidateQueries(queries.company.companySiders(searchTerm));
            } else {
                if (searchFormState.touched) {
                    // URL cleanup and refetch
                    updateFilters({
                        siderId: null,
                        managerId: null,
                        siderName: null,
                        managerName: null,
                    });
                }
            }
        } else {
            if (!searchTerm && searchFormState.touched) {
                // URL cleanup and refetch
                updateFilters({
                    siderId: null,
                    siderName: null,
                    managerId: null,
                    managerName: null,
                });
            }
        }
    }, [searchTerm, searchFormState.touched]);

    const handleStatusFilterChange = (event) => {
        const { name } = event.target;

        if (statusFilter.includes(name)) {
            return setStatusFilter(statusFilter.filter((status) => status !== name));
        }

        return setStatusFilter(statusFilter.concat(name));
    };

    const handleTypeFilterChange = (event) => {
        const { id, name } = event.target;
        // note: Filter SUI components concatenates id with 'Checkbox'.
        // To get a pure value for params we need to get the name attribute
        // and pass it to another array

        if (typeFilter.values.includes(id)) {
            setTypeFilter((prevState) => ({
                values: prevState.values.filter((filter) => filter !== id),
                active: prevState.active.filter((filter) => filter !== name),
            }));

            trackEvent({
                name: "timesheet - type filter",
                params: {
                    organisationId: companyId,
                    organisationName: companyName,
                    logAs,
                },
            });

            return;
        }

        setTypeFilter((prevState) => ({
            values: prevState.values.concat(id),
            active: prevState.active.concat(name),
        }));

        trackEvent({
            name: "timesheet - type filter",
            params: {
                organisationId: companyId,
                organisationName: companyName,
                logAs,
            },
        });
    };

    /* eslint-disable */
    const handleDateFilterDisplay = () => {
        switch (dateFilter.objectID) {
            case "day": {
                return (
                    <TimesheetsFilter
                        label={
                            selectedDate &&
                            format(
                                selectedDate,
                                locale.string === "fr" ? "dd/MM/yyyy" : "MM/dd/yyyy",
                                { locale: locale.ref, weekStartsOn: 1 },
                            )
                        }
                        className='timesheets__filters__type timesheets__filter timesheets__filter__day'
                        icon={<IconCalendar customClass='timesheets-filter__icon' />}
                        childrenAsFunction={true}
                    >
                        {({ setIsOpen }) => (
                            <>
                                <RawDatePicker
                                    onChange={(date) => {
                                        setSelectedDate(date);
                                        setIsOpen(false);

                                        setPage(1);
                                        updateFilters({
                                            startDate: startOfDay(date),
                                            endDate: endOfDay(date),
                                            pageNumber: 1,
                                        });
                                    }}
                                    selectedDate={selectedDate}
                                    label=''
                                    onBlur={() => null}
                                    onFocus={() => null}
                                    inline={true}
                                    locale={locale.ref}
                                    id='default-datepicker'
                                    isOpen={true}
                                    minDate={new Date("2017-01-01")}
                                />
                            </>
                        )}
                    </TimesheetsFilter>
                );
            }

            case "week": {
                const weekRange = eachWeekOfInterval(
                    {
                        start: subYears(initialDate, 1),
                        end: endOfYear(initialDate),
                    },
                    { weekStartsOn: 1 },
                );

                return (
                    <div className='timesheets__filter'>
                        <SearchableDropdown
                            id='week-filter'
                            options={weekRange.map((date, index) => ({
                                objectID: `${getWeek(date, { weekStartsOn: 1 })}-${getYear(
                                    startOfWeek(date, { weekStartsOn: 1 }),
                                )}`,
                                label: `${locale.string === "fr" ? "Semaine" : "Week"} ${getWeek(
                                    date,
                                    {
                                        weekStartsOn: 1,
                                        firstWeekContainsDate: 4, //https://date-fns.org/v3.2.0/docs/getWeek#types/FirstWeekContainsDate/
                                    },
                                )} ${getYear(startOfWeek(date, { weekStartsOn: 1 }))}`,
                                subLabel: formatWeek(date, locale.string),
                                // handling timezone offset (daylight saving)
                                startDate: startOfWeek(date, { weekStartsOn: 1 }),
                                endDate: endOfWeek(date, { weekStartsOn: 1 }),
                            }))}
                            onOptionClick={(weekOption) => {
                                setWeekFilter(weekOption);

                                setPage(1);
                                updateFilters({
                                    startDate: weekOption.startDate,
                                    endDate: weekOption.endDate,
                                    pageNumber: 1,
                                });
                            }}
                            selectedOption={{
                                objectID: weekFilter?.objectID,
                                label: weekFilter?.label,
                            }}
                            withTooltip={false}
                            className='timesheets__filter__week timesheets__filter'
                        />
                    </div>
                );
            }

            case "month": {
                const monthsRange = eachMonthOfInterval({
                    start: subYears(initialDate, 1),
                    end: initialDate,
                });

                return (
                    <div className='timesheets__filter'>
                        <SearchableDropdown
                            id='month-filter'
                            options={monthsRange.map((date, index) => ({
                                objectID: `${format(date, "MM", {
                                    locale: locale.ref,
                                    weekStartsOn: 1,
                                })}-${getYear(date)}`,
                                label: `${format(date, "MMMM", {
                                    locale: locale.ref,
                                    weekStartsOn: 1,
                                })}
                    ${getYear(date) !== getYear(initialDate) ? getYear(date) : ""}`,
                                date,
                            }))}
                            onOptionClick={(monthOption) => {
                                setMonthFilter(monthOption);

                                setPage(1);
                                updateFilters({
                                    startDate: startOfMonth(monthOption.date),
                                    endDate: endOfMonth(monthOption.date),
                                    pageNumber: 1,
                                });
                            }}
                            selectedOption={{
                                objectID: monthFilter?.objectID,
                                label: monthFilter?.label,
                            }}
                            withTooltip={false}
                            className='timesheets__filter__month timesheets__filter'
                        />
                    </div>
                );
            }
        }
        /* eslint-enable */
    };

    const handleSearchTermChange = ({ target }) => {
        setSearchTerm(target.value);
        setSearchFormState({ touched: true });
    };

    const trackSearchItemSelection = () => {
        const trackerName =
            searchFilter.objectID === "sider"
                ? "timesheet - sider filter"
                : "timesheet - task manager filter";

        trackEvent({
            name: trackerName,
            params: {
                organisationId: companyId || "missing",
                organisationName: companyName,
                logAs,
            },
        });
    };

    const handleSelectSearchItem = (siderOrManagerId, name) => {
        setSearchFilter(searchFilter);

        // clean the URL param by setting the other value to null
        // see updateFilters function ../
        const cleanURLParams = searchFilter.objectID === "sider" ? "managerId" : "siderId";

        // pagination back to first page when selecting a Sider
        setPage(1);
        updateFilters({
            searchBy: searchFilter.objectID,
            pageNumber: 1,
            [cleanURLParams]: null,
            // siderId or managerId
            [`${searchFilter.objectID}Id`]: siderOrManagerId,
            // sider/managerName
            [`${searchFilter.objectID}Name`]: name ? encodeURI(name) : name,
        });

        if (name) {
            setSearchTerm(name);
        }

        trackSearchItemSelection();
    };

    return (
        <section className='timesheets__filters'>
            <div className='timesheets__filters__wrapper timesheets-table__wrapper'>
                <section className='timesheets__filters--left'>
                    <div className='timesheets__filter'>
                        <TimesheetsFilter
                            label={i18n.ts_filters_status()}
                            confirmButton={true}
                            confirmCallback={() => {
                                setPage(1);
                                updateFilters({ status: statusFilter, pageNumber: 1 });

                                trackEvent({
                                    name: "timesheet - status filter",
                                    params: {
                                        organisationId: companyId,
                                        organisationName: companyName,
                                        // ["att.waiting", "att.disputed"] to => waiting/disputed
                                        status:
                                            statusFilter.length &&
                                            statusFilter.join("/").replace(/att./g, ""),
                                        logAs,
                                    },
                                });
                            }}
                            className='timesheets__filters__status'
                            activeFiltersCounter={filters.status && filters.status.length}
                        >
                            <ul>
                                {attendanceStatus.map((status) => {
                                    return (
                                        <li className='timesheets__filters__checkbox' key={status}>
                                            <Check
                                                checked={statusFilter.includes(status)}
                                                onChange={handleStatusFilterChange}
                                                id={status}
                                                name={status}
                                            >
                                                <Tag color={filterColors[status]}>
                                                    {setStatusLabel(status)}
                                                </Tag>
                                            </Check>
                                        </li>
                                    );
                                })}
                            </ul>
                        </TimesheetsFilter>
                    </div>

                    <TimesheetsFilter
                        label={i18n.ts_filters_type()}
                        confirmButton={true}
                        confirmCallback={() => {
                            updateFilters({ type: typeFilter.active, pageNumber: 1 });

                            setPage(1);
                        }}
                        className='timesheets__filters__type timesheets__filter'
                        activeFiltersCounter={typeFilter.active.length}
                    >
                        {initCheckboxFilter().map(({ id, label, description }) => (
                            <Filter
                                withCheckbox={true}
                                id={id}
                                label={label}
                                key={id}
                                description={description}
                                checked={typeFilter.values.includes(`${id}Checkbox`)}
                                onChange={handleTypeFilterChange}
                            />
                        ))}
                    </TimesheetsFilter>
                </section>

                <section
                    className={`timesheets__filters--center timesheets__filters__period timesheets__filters__period--${dateFilter.objectID} `}
                >
                    <div className='timesheets__filter'>
                        <SearchableDropdown
                            id='date-filter'
                            options={initDateFilters()}
                            onOptionClick={(dateFilterOption) => {
                                setDateFilter(dateFilterOption);

                                // all is the default behavior no need to set it
                                if (dateFilterOption.objectID !== "all") {
                                    setPage(1);
                                    updateFilters({
                                        mode: dateFilterOption?.objectID,
                                        startDate: dateFilterOption?.startDate,
                                        endDate: dateFilterOption?.endDate,
                                        pageNumber: 1,
                                    });
                                }

                                trackEvent({
                                    name: "timesheet - date filter",
                                    params: {
                                        organisationId: companyId,
                                        organisationName: companyName,
                                        range: dateFilterOption?.objectID,
                                        logAs,
                                    },
                                });
                            }}
                            selectedOption={{
                                objectID: dateFilter?.objectID,
                                label: dateFilter?.label,
                            }}
                            withTooltip={false}
                            className='timesheets__filter__date'
                        />
                    </div>

                    {handleDateFilterDisplay()}
                </section>

                <section className='timesheets__filters--right'>
                    <CompanySearch
                        options={searchFilterOptions}
                        selectedOption={{
                            objectID: searchFilter.objectID,
                            label: searchFilter.label,
                        }}
                        searchTerm={searchTerm}
                        handleSearchTermChange={handleSearchTermChange}
                        searchPlaceholder={i18nDK(`ts_filters_search_${searchFilter.objectID}`)}
                        companyMembers={lookForManager(companyMembers, searchTerm)}
                        touched={searchFormState.touched}
                        selectItem={handleSelectSearchItem}
                        onOptionClick={(searchOption) => {
                            setSearchFilter(searchOption);
                            setSearchTerm("");
                        }}
                    />
                </section>
            </div>
        </section>
    );
};

export default TimeSheetsFilterbar;
