import { useMemo, useRef, useState } from "react";
import clsx from "clsx";
import { ArrowForward, Button, fonts, Tag } from "sui";

import { i18n, i18nDK } from "@lib/i18n";
import { queries } from "@lib/queries";
import trackEvent from "@lib/trackers";
import { parseStringWithFontStyle } from "@lib/utils/parseStringWithFontStyle";
import { useQuery } from "@tanstack/react-query";

import { HorizonModal } from "../HorizonModal/HorizonModal";

import acquisitionImg from "./assets/acquisitionImg.png";
import poolImg from "./assets/poolImg.png";

import styles from "./Horizon.module.css";

export const Horizon = () => {
    const modalRef = useRef<HTMLDialogElement>(null);
    const content = useMemo(getContent, []);
    const [resetModal, setResetModal] = useState(0);
    const { data: company } = useQuery(queries.company.detail());

    return (
        <>
            <div className={styles.horizon}>
                <div className={styles.wrapper}>
                    <div className={styles.header}>
                        <h1 className={clsx(styles.title, fonts.sans32Medium)}>
                            <span>{i18n.horizon_title()}</span>{" "}
                            <Tag className={styles.tag}>{i18n.label_new()}</Tag>
                        </h1>
                        <p className={styles.description}>{i18n.horizon_subtitle()}</p>
                    </div>
                    <div className={styles.sections}>
                        <section className={styles.section}>
                            <h2 className={clsx(fonts.sans20Medium, styles.title)}>
                                {i18n.horizon_differentiate_title()}
                            </h2>
                            <div className={styles.differenciate}>
                                {content.differenciate.map((item) => (
                                    <div key={item.title} className={styles.block}>
                                        <img src={item.img} />
                                        <div className={styles.text}>
                                            <h3 className={clsx(fonts.sans24Medium, styles.title)}>
                                                {item.title}
                                            </h3>
                                            disdhsqkjb
                                            {item.arguments.map((argument, index) => (
                                                <p key={index} className={styles.argument}>
                                                    {argument}
                                                </p>
                                            ))}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </section>
                        <section className={styles.reassurance}>
                            {content.reassurance.map((reassurance, index) => (
                                <div key={index}>
                                    <p>{reassurance}</p>
                                </div>
                            ))}
                        </section>
                        <section className={styles.section}>
                            <h2 className={clsx(fonts.sans20Medium, styles.title)}>
                                {i18n.horizon_steps_title()}
                            </h2>
                            <div className={styles.steps}>
                                {content.steps.map((step, index) => (
                                    <div key={index} className={styles.step}>
                                        <div className={fonts.sans18Medium}>{index + 1}</div>
                                        <p>{step}</p>
                                    </div>
                                ))}
                            </div>
                        </section>
                    </div>
                </div>
            </div>
            <footer className={styles.footer}>
                <Button
                    icon={<ArrowForward />}
                    onClick={() => {
                        modalRef.current?.showModal();

                        trackEvent({
                            name: `bbhorizon - user clicks to display form`,
                            params: {
                                logAs: !!localStorage.getItem("side_team_logas_email"),
                                companyId: company?.organisation.id,
                                companyName: company?.organisation.name,
                                network: !!company?.parent,
                                industry: company?.organisation.industry || "",
                                networkName: company?.parent,
                            },
                        });
                    }}
                >
                    {i18n.label_continue()}
                </Button>
            </footer>
            <HorizonModal
                ref={modalRef}
                key={resetModal}
                reset={() => setResetModal((prev) => prev + 1)}
            />
        </>
    );
};

function getTranslationWithIndex(key, amount) {
    return new Array(amount)
        .fill(null)
        .map((_, index) => parseStringWithFontStyle(i18nDK(`${key}_${index}`)));
}
function getContent() {
    return {
        differenciate: ["acquisition", "pool"].map((type) => ({
            title: i18nDK(`horizon_${type}_title`),
            img: type === "acquisition" ? acquisitionImg : poolImg,
            arguments: getTranslationWithIndex(`horizon_${type}_argument`, 3),
        })),
        steps: getTranslationWithIndex("horizon_step", 5),
        reassurance: getTranslationWithIndex("horizon_reassurance", 4),
    };
}
