import moment from "moment";
import { toast } from "saphir";

import { i18n } from "@lib/i18n";
import { router } from "@routes";
import { debug } from "@side.co/client-debug";

import * as backend from "../lib/api";
import checkResForError from "../lib/utils/checkResForError";
import { handleError } from "../lib/utils/error";
import isAuth from "../lib/utils/isAuth";

import normalizeContracts from "./normalizers/contracts";
import type { Action, ContractsBatch, State } from "./types/contracts";
import type { Dispatch, GetState } from "./types";

//------------------------------------
// Constants
//------------------------------------

export const SET_CONTRACTS_LOADING_STATUS = `SET_CONTRACTS_LOADING_STATUS` as const;
export const EMPTY_CONTRACTS = `EMPTY_CONTRACTS` as const;
export const LOAD_CONTRACTS = `LOAD_CONTRACTS` as const;
export const SET_CONTRACTS_BATCH_DISPUTE_DATE = `SET_CONTRACTS_BATCH_DISPUTE_DATE` as const;
export const SET_CONTRACTS_BATCH_SIGNATURE_DATE = `SET_CONTRACTS_BATCH_SIGNATURE_DATE` as const;
export const UPDATE_CONTRACTS_BATCHES_TOTAL = `UPDATE_CONTRACTS_BATCHES_TOTAL` as const;
export const UPDATE_CURRENT_CONTRACTS_TAB = `UPDATE_CURRENT_CONTRACTS_TAB` as const;
export const UPDATE_IDS_BY_PAGE = `UPDATE_IDS_BY_PAGE` as const;
export const UPDATE_CURRENT_CONTRACTS_PAGE_NUMBER = `UPDATE_CURRENT_CONTRACTS_PAGE_NUMBER` as const;
export const UPDATE_PAGE_COUNT = `UPDATE_PAGE_COUNT` as const;
export const HAS_CONTRACTS_BATCHES = `HAS_CONTRACTS_BATCHES` as const;
export const GET_NUMBER_CONTRACTS_BATCHES_TO_SIGN = `GET_NUMBER_CONTRACTS_BATCHES_TO_SIGN` as const;
export const GET_TOTAL_CONTRACTS_BATCHES_TO_SIGN = `GET_TOTAL_CONTRACTS_BATCHES_TO_SIGN` as const;
export const SET_SIGNATURE_ERROR = `SET_SIGNATURE_ERROR` as const;

//------------------------------------
// Actions
//------------------------------------

export const pendingRequest = (state: boolean): Action => ({
    type: SET_CONTRACTS_LOADING_STATUS,
    state,
});

export const emptyContracts = (): Action => ({
    type: EMPTY_CONTRACTS,
});

export const loadContracts = (contractsBatches: { [key: string]: ContractsBatch }): Action => ({
    type: LOAD_CONTRACTS,
    contractsBatches,
});

export const SetContractsBatchDispute = (date: string): Action => ({
    type: SET_CONTRACTS_BATCH_DISPUTE_DATE,
    date,
});

export const SetContractsBatchSignature = (date?: string): Action => ({
    type: SET_CONTRACTS_BATCH_SIGNATURE_DATE,
    date,
});

export const hasContractsBatches = (bool: boolean): Action => ({
    type: HAS_CONTRACTS_BATCHES,
    bool,
});

export const updateCurrentTab = (newTabName: "toSign" | "signed"): Action => ({
    type: UPDATE_CURRENT_CONTRACTS_TAB,
    newTabName,
});

export const updateIdsByPage = (pageNumber: number, documentsIds: string[]): Action => ({
    type: UPDATE_IDS_BY_PAGE,
    pageNumber,
    documentsIds,
});

export const updateCurrentPageNumber = (pageNumber: number): Action => ({
    type: UPDATE_CURRENT_CONTRACTS_PAGE_NUMBER,
    pageNumber,
});

export const updatePageCount = (count: number): Action => ({
    type: UPDATE_PAGE_COUNT,
    count,
});

export const updateContractsBatchesTotal = (count: number): Action => ({
    type: UPDATE_CONTRACTS_BATCHES_TOTAL,
    count,
});

export const getNumberOfContractsBatchesToSign = (count: number): Action => ({
    type: GET_NUMBER_CONTRACTS_BATCHES_TO_SIGN,
    count,
});

export const getTotalOfContractsBatchesToSign = (count: number): Action => ({
    type: GET_TOTAL_CONTRACTS_BATCHES_TO_SIGN,
    count,
});

export const setSignatureError = (state: boolean): Action => ({
    type: SET_SIGNATURE_ERROR,
    state,
});

//------------------------------------
// Specialized Action Creator
//------------------------------------

export const getContractsBatches = (pageNumber: number, fetchAll = false) =>
    async function (dispatch: Dispatch, getState: GetState) {
        if (!isAuth()) {
            debug.warn(`User doesn't have auth info in localStorage`);
            if (router.history.location.pathname !== "/signin") {
                router.navigate({ to: "/signin" });
            }
            return;
        }
        const {
            contracts: { pagination, currentTab },
        } = getState();

        const currentPagination = pagination[currentTab] || { limit: 0 };

        // Start fecthing contrats
        const organisationId = localStorage.getItem(`side_team_activeOrganisationId`);
        if (!organisationId) {
            return;
        }
        dispatch(pendingRequest(true));
        let res;
        try {
            res = await backend.getContractsBatches({
                signed: currentTab === `signed`,
                offset: currentPagination.limit * (pageNumber - 1),
                limit: currentPagination.limit,
                organisationId,
                fetchAll,
            });
        } catch (e) {
            debug.catch(e);
            handleError(e.toString());
            dispatch(pendingRequest(false));
        }
        if (res) {
            if (checkResForError(res)) {
                dispatch(pendingRequest(false));
                try {
                    const text = await res.text();
                    handleError(text);
                } catch (e) {
                    debug.catch(e);
                    handleError(e.toString());
                }

                return;
            }
            const json = await res.json();
            const normalizedData: any = normalizeContracts(json.documents);

            dispatch(loadContracts(normalizedData.contractsBatches));

            const pageCount = json.limit ? Math.ceil(json.total / json.limit) : 0;
            dispatch(updatePageCount(pageCount));
            dispatch(updateContractsBatchesTotal(json.total));
            dispatch(updateCurrentPageNumber(pageNumber));
            dispatch(updateIdsByPage(pageNumber, Object.keys(normalizedData.contractsBatches)));
            dispatch(pendingRequest(false));
        }
    };

export const countContractsBatches = () =>
    async function (dispatch: Dispatch) {
        let res;
        try {
            res = await backend.getNumberOfContractsBatches();
        } catch (e) {
            debug.catch(e);
            handleError(e.toString());
        }
        if (res) {
            if (checkResForError(res)) {
                try {
                    const text = await res.text();
                    handleError(text);
                } catch (e) {
                    debug.catch(e);
                    handleError(e.toString());
                }

                return;
            }
            const json = await res.json();
            dispatch(getNumberOfContractsBatchesToSign(json.count));
        }
    };

export const regenerateDocuments = (documentIds: string[]) =>
    async function () {
        let res;
        try {
            res = await backend.regenerateDocuments({
                documentIds,
            });
        } catch (e) {
            debug.catch(e);
            handleError(e.toString());
        }
        if (res) {
            if (checkResForError(res)) {
                try {
                    const text = await res.text();
                    handleError(text);
                } catch (e) {
                    debug.catch(e);
                    handleError(e.toString());
                }

                return;
            }

            toast.success(i18n.contract_signature_regeneration_success());
        }
    };

export const signContracts = (documentId: string) =>
    async function () {
        const organisationId = localStorage.getItem(`side_team_activeOrganisationId`);

        if (!organisationId) {
            return;
        }

        backend
            .putSignature({
                documentId,
                organisationId,
            })
            .then((res) => {
                if (res) {
                    if (checkResForError(res)) {
                        debug.catch(i18n.contract_signature_confirmation_block_error());
                        handleError("contract_signature_confirmation_block_error");
                        return;
                    }

                    toast.success(i18n.contract_signature_confirmation_toast());
                }
            })
            .catch((err) => {
                debug.catch(err);
                handleError("contract_signature_confirmation_block_error");
            });
    };

//------------------------------------
// Reducer
//------------------------------------

const initialState = {
    isLoading: false,
    currentTab: `toSign`,
    hasContractsBatches: false,
    toSignCount: 0,
    contractsBatches: {},
    pagination: {
        toSign: {
            pageCount: 1,
            currentPageNumber: 1,
            pages: {},
            limit: 20,
            contractsBatchesTotal: 0,
        },
        signed: {
            pageCount: 1,
            currentPageNumber: 1,
            pages: {},
            limit: 20,
            contractsBatchesTotal: 0,
        },
    },
    signError: false,
};

const contractsReducer = (state: State = initialState, action: Action) => {
    switch (action.type) {
        case SET_CONTRACTS_LOADING_STATUS:
            return {
                ...state,
                isLoading: action.state,
            };
        case EMPTY_CONTRACTS:
            return {
                ...state,
                pagination: { ...initialState.pagination },
                contractsBatches: {},
            };
        case LOAD_CONTRACTS:
            return {
                ...state,
                contractsBatches: {
                    ...state.contractsBatches,
                    ...action.contractsBatches,
                },
            };
        case SET_CONTRACTS_BATCH_DISPUTE_DATE:
            return {
                ...state,
                disputeDate: action.date
                    ? moment(action.date).format(`YYYY-MM-DD`)
                    : moment().format(`YYYY-MM-DD`),
            };
        case SET_CONTRACTS_BATCH_SIGNATURE_DATE:
            return {
                ...state,
                signatureDate: action.date && moment(action.date).format(`YYYY-MM-DD`),
            };
        case HAS_CONTRACTS_BATCHES:
            return {
                ...state,
                hasTimesheets: action.bool,
            };

        case UPDATE_IDS_BY_PAGE:
            return {
                ...state,
                pagination: {
                    ...state.pagination,
                    [state.currentTab]: {
                        ...state.pagination[state.currentTab],
                        pages: {
                            ...state.pagination[state.currentTab].pages,
                            [action.pageNumber]: action.documentsIds,
                        },
                    },
                },
            };
        case UPDATE_CURRENT_CONTRACTS_PAGE_NUMBER:
            return {
                ...state,
                pagination: {
                    ...state.pagination,
                    [state.currentTab]: {
                        ...state.pagination[state.currentTab],
                        currentPageNumber: action.pageNumber,
                    },
                },
            };
        case UPDATE_PAGE_COUNT:
            return {
                ...state,
                pagination: {
                    ...state.pagination,
                    [state.currentTab]: {
                        ...state.pagination[state.currentTab],
                        pageCount: action.count,
                    },
                },
            };
        case UPDATE_CURRENT_CONTRACTS_TAB:
            return {
                ...state,
                currentTab: action.newTabName,
            };
        case UPDATE_CONTRACTS_BATCHES_TOTAL:
            return {
                ...state,
                pagination: {
                    ...state.pagination,
                    [state.currentTab]: {
                        ...state.pagination[state.currentTab],
                        contractsBatchesTotal: action.count,
                    },
                },
            };
        case GET_NUMBER_CONTRACTS_BATCHES_TO_SIGN:
            return { ...state, toSignCount: action.count };
        case SET_SIGNATURE_ERROR:
            return { ...state, signError: action.state };
        default:
            return state;
    }
};

export default contractsReducer;
