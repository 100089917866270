import { useEffect, useState } from "react";

import { PostAttendancesParams } from "@lib/api/postAttendances";

export const requiredColumns = ["siderId", "startDate", "endDate", "breakDuration"];

export function useCsvToJson() {
    const [file, setFile] = useState<File[]>();
    const [json, setJson] = useState<PostAttendancesParams["attendances"]>([]);

    function reset() {
        setFile([]);
    }

    function processCSV(str: string): PostAttendancesParams["attendances"] {
        const separator = (() => {
            const commaCount = (str.match(/,/g) || []).length;
            const semiColonCount = (str.match(/;/g) || []).length;
            return commaCount > semiColonCount ? "," : ";";
        })();
        const headers = str
            .slice(0, str.indexOf("\n"))
            .split(separator)
            .map((h) => h.trim());

        const rows = str.slice(str.indexOf("\n") + 1).split("\n");

        const shifts = rows.map((row) => {
            const values: (string | number)[] = row.split(separator);

            const shift = headers.reduce((obj, header: string, headerIndex) => {
                if (!requiredColumns.includes(header)) return obj;
                obj[header] = (values[headerIndex] as string)?.trim().replace("\r", "");
                return obj;
            }, {});
            return shift;
        });

        return shifts as PostAttendancesParams["attendances"];
    }

    useEffect(() => {
        if (file?.length && file[0]) {
            const csv = file[0];
            const reader = new FileReader();

            reader.onload = function (e) {
                const text = e.target?.result;
                if (typeof text !== "string") return;
                setJson(processCSV(text));
            };

            reader.readAsText(csv);
        }
        if (file?.length && !file[0]) {
            setFile([]);
        }
        if (!file?.length) setJson([]);
    }, [file]);

    return {
        file,
        setFile,
        reset,
        json,
        setJson,
    };
}
