export function parseStringWithFontStyle(text: string) {
    const parts = text.split(/(<b>|<\/b>|<i>|<\/i>)/);
    let isBold = false;
    let isItalic = false;

    return parts
        .map((part, index) => {
            if (part === "<b>") {
                isBold = true;
                return null; // Ignore the <b> tag, we'll handle it with <strong>
            } else if (part === "</b>") {
                isBold = false;
                return null; // Ignore the </b> tag, we'll handle it with </strong>
            } else if (part === "<i>") {
                isItalic = true;
                return null; // Ignore the <i> tag, we'll handle it with <em>
            } else if (part === "</i>") {
                isItalic = false;
                return null; // Ignore the </i> tag, we'll handle it with </em>
            } else {
                if (isBold) {
                    return (
                        <strong key={index} className='font-bold'>
                            {part}
                        </strong>
                    );
                } else if (isItalic) {
                    return (
                        <em key={index} className='font-italic'>
                            {part}
                        </em>
                    );
                }
                return part;
            }
        })
        .filter((part) => part !== null); // Remove null values
}
