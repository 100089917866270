import { useEffect, useRef, useState } from "react";
import clsx from "clsx";
import { differenceInMinutes, set } from "date-fns";
import { atom, useAtomValue, useSetAtom } from "jotai";
import {
    Add,
    ArrowBack,
    ArrowForward,
    Button,
    Delete,
    Information,
    Loader,
    useConfirmDialog,
} from "sui";

import { queryClient } from "@App";
import { postFlexiblePlanning, PostFlexiblePlanningParams } from "@lib/api/postFlexiblePlanning";
import { useLocation } from "@lib/hooks/useLocation";
import useOnClickOutside from "@lib/hooks/useOnClickOutside";
import { i18n } from "@lib/i18n";
import { usePatchTask } from "@lib/mutations/usePatchTask";
import { queries } from "@lib/queries";
import trackEvent from "@lib/trackers";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useMatchRoute, useNavigate } from "@tanstack/react-router";
import { useFlag } from "@unleash/proxy-client-react";

import Quotation from "./Quotation/Quotation";
import { modalShiftsStateAtom } from "./ShiftsStep/ShiftsStep";
import { shiftsToDeleteAtom } from "./ShiftsStep/ShiftsTable/ShiftsTable";
import { useShiftAPI } from "./ShiftsStep/useShiftAPI";
import { taskFormRoute } from "./route";

import styles from "./TaskFormFooter.module.css";
export const orderDataToSendAtom = atom({});
export const disabledSubmitAtom = atom(false);

export function TaskFormFooter() {
    const isFlexiblePlanningEnabled = useFlag("flexible-planning");
    const setModalShistsStateAtom = useSetAtom(modalShiftsStateAtom);
    const shiftsToDeleteAtomValue = useAtomValue(shiftsToDeleteAtom);
    const matchRoute = useMatchRoute();
    const { taskId } = taskFormRoute.useParams();
    const { data: task, isLoading: isTaskLoading } = useQuery(queries.task.detail(taskId));
    const { data: quotation, refetch: refetchQuotation } = useQuery({
        ...queries.quotation.detail(taskId),
        enabled: !!task?.shifts?.length,
    });
    const quotationTotal = quotation?.price;

    const orderDataToSend = useAtomValue(orderDataToSendAtom);
    const disabledSubmit = useAtomValue(disabledSubmitAtom);

    const location = useLocation();
    const step = location.pathname.split("/")[3];
    const navigate = useNavigate();

    const [priceEstimateTooltipVisible, setPriceEstimateTooltipVisible] = useState(false);
    const priceEstimateRef = useRef(null);
    useOnClickOutside(priceEstimateRef, () => setPriceEstimateTooltipVisible(false));

    const confirm = useConfirmDialog();

    const { removeShifts } = useShiftAPI();

    useEffect(() => {
        if (task?.shifts?.length) {
            refetchQuotation();
        } else {
            queryClient.removeQueries(queries.quotation.detail(taskId));
        }
    }, [task?.shifts]);

    const { patchTask: updateTask, isPending: isUpdateTaskPending } = usePatchTask({
        onSuccess: goToNextStep,
    });
    const { mutate: createPlanning, isPending: isCreatePlanningPending } = useMutation({
        mutationFn: postFlexiblePlanning,
        onSuccess: () => {
            goToNextStep();
        },
        onSettled: () => {
            queryClient.removeQueries(queries.quotation.detail(taskId));
            queryClient.invalidateQueries(queries.task.detail(taskId));
        },
    });
    const isPending = isUpdateTaskPending || isCreatePlanningPending;

    const isFlexiblePlanningPage = !!matchRoute({ to: "/taskPosting/$taskId/flexible-planning" });

    function handleValidation() {
        if (isFlexiblePlanningPage) {
            trackEvent({
                name: "flexibleplanning - flexible planning details",
                params: formatFlexiblePlanningDataToSend(orderDataToSend as any),
            });
            createPlanning({
                taskId,
                config: orderDataToSend as PostFlexiblePlanningParams["config"],
            });
        } else {
            updateTask({
                id: taskId,
                ...orderDataToSend,
            });
        }
    }

    function goToNextStep() {
        switch (step) {
            case "motive":
                navigate({
                    to: (() => {
                        if (
                            !isFlexiblePlanningEnabled ||
                            (task?.flexiblePlanning === false && task?.shifts?.length)
                        ) {
                            return `/taskPosting/$taskId/shifts`;
                        }
                        if (task?.flexiblePlanning === true && task?.flexiblePlanningId) {
                            return `/taskPosting/$taskId/flexible-planning`;
                        }
                        return `/taskPosting/$taskId/planning-type`;
                    })(),
                    params: { taskId },
                });
                break;
            case "shifts":
            case "flexible-planning":
                navigate({
                    to: `/taskPosting/$taskId/details`,
                    params: { taskId },
                });
                break;
            case "details":
                navigate({
                    to: `/taskPosting/$taskId/workers`,
                    params: { taskId },
                });
                break;
            case "workers":
                navigate({
                    to: `/taskPosting/$taskId/summary`,
                    params: { taskId },
                });
                break;
            default:
                navigate({
                    to: `/taskPosting/$taskId/motive`,
                    params: { taskId },
                });
                break;
        }
    }

    function goToPreviousStep() {
        switch (step) {
            case "summary":
                navigate({
                    to: `/taskPosting/$taskId/workers`,
                    params: { taskId },
                });
                break;
            case "workers":
                navigate({
                    to: `/taskPosting/$taskId/details`,
                    params: { taskId },
                });
                break;
            case "details":
                navigate({
                    to: (() => {
                        if (!isFlexiblePlanningEnabled || task?.flexiblePlanning === false) {
                            return `/taskPosting/$taskId/shifts`;
                        }
                        if (task?.flexiblePlanning === true) {
                            return `/taskPosting/$taskId/flexible-planning`;
                        }
                    })(),
                    params: { taskId },
                });
                break;
            case "shifts":
            case "flexible-planning":
                navigate({
                    to: `/taskPosting/$taskId/motive`,
                    params: { taskId },
                });
                break;
            default:
                navigate({ to: `/job-descriptions` });
                break;
        }
    }
    const isShiftPage = !!matchRoute({ to: "/taskPosting/$taskId/shifts" });

    if (isShiftPage && task?.shifts?.length === 0) return null;

    return (
        <footer className={styles.footer}>
            {shiftsToDeleteAtomValue.length > 0 ? (
                <div className={styles.flex}>
                    <Button
                        shape='outlined'
                        intention='danger'
                        onClick={() =>
                            confirm({
                                title: i18n.task_shifts_dialog_title(),
                                message: i18n.task_shifts_dialog_message(),
                            }).then(() => removeShifts({ taskId, ids: shiftsToDeleteAtomValue }))
                        }
                        icon={<Delete />}
                        iconDisposition='left'
                    >
                        {i18n.task_shifts_footer_delete_button()}
                    </Button>
                </div>
            ) : (
                <>
                    <div className={styles.flex}>
                        <Button
                            shape='outlined'
                            intention='secondary'
                            onClick={goToPreviousStep}
                            icon={<ArrowBack />}
                            iconDisposition='left'
                        >
                            {i18n.back()}
                        </Button>
                        <div className={styles.shiftsEstimationAction}>
                            {(task?.shifts?.length ?? 0) > 0 &&
                                quotation !== null &&
                                !isFlexiblePlanningPage && (
                                    <div ref={priceEstimateRef} className={styles.priceEstimate}>
                                        {priceEstimateTooltipVisible && (
                                            <div className={styles.tooltip}>
                                                <Quotation
                                                    quotation={quotation}
                                                    withTaxes={false}
                                                />
                                            </div>
                                        )}
                                        <div
                                            className={clsx(
                                                styles.priceEstimateLabel,
                                                "typography-body-m-semibold",
                                            )}
                                        >
                                            Estimation Totale HT
                                        </div>
                                        <div
                                            className={clsx(
                                                styles.priceEstimateAmount,
                                                "typography-heading-s-semibold",
                                            )}
                                        >
                                            {quotationTotal ? (
                                                parseFloat(quotationTotal).toFixed(2) + "€"
                                            ) : (
                                                <Loader />
                                            )}
                                        </div>
                                        <Button
                                            onClick={() =>
                                                setPriceEstimateTooltipVisible(
                                                    !priceEstimateTooltipVisible,
                                                )
                                            }
                                            icon={<Information />}
                                            shape='outlined'
                                            intention='secondary'
                                        />
                                    </div>
                                )}
                            {isShiftPage && (
                                <Button
                                    onClick={() =>
                                        setModalShistsStateAtom({
                                            open: true,
                                            mode: "creation",
                                            shift: null,
                                        })
                                    }
                                    icon={<Add />}
                                    iconDisposition='left'
                                    shape='outlined'
                                    intention='secondary'
                                >
                                    {i18n.task_shifts_add()}
                                </Button>
                            )}
                        </div>
                    </div>
                    <Button
                        disabled={disabledSubmit}
                        loading={isTaskLoading || isPending}
                        onClick={() => handleValidation()}
                        icon={<ArrowForward />}
                    >
                        {i18n.label_continue()}
                    </Button>
                </>
            )}
        </footer>
    );
}

function formatFlexiblePlanningDataToSend(data) {
    return {
        startDate: data.startDate.toISOString(),
        endDate: data.endDate.toISOString(),
        slots: data.siders,
        hourlyVolume: Number(data.weeklyVolume),
        amplitudeStart: data.amplitudeStart,
        amplitudeEnd: data.amplitudeEnd,
        amplitudeTotalDurationInMinutes: differenceInMinutes(
            set(new Date(), {
                hours: Number(data.amplitudeStart.split(":")[0]),
                minutes: Number(data.amplitudeStart.split(":")[1]),
            }),
            set(new Date(), {
                hours: Number(data.amplitudeEnd.split(":")[0]),
                minutes: Number(data.amplitudeEnd.split(":")[1]),
            }),
        ),
        includesWeekDays: !!data.include.weekDays,
        includesHolidays: !!data.include.holidays,
        includesSaturday: !!data.include.saturday,
        includesSunday: !!data.include.sunday,
    };
}
