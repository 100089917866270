import { useEffect, useState } from "react";
import clsx from "clsx";
import { SectionHeader } from "side-ui";

import { i18n } from "@lib/i18n";
import { normalizeJobDescription } from "@lib/queries/normalizeJobDescriptions";

import { useTimer } from "../../hooks/useTimer";

import { LanguagesForm } from "./LanguagesForm/LanguagesForm";
import { LicencesForm } from "./LicencesForm/LicencesForm";
import { ToolsForm } from "./ToolsForm/ToolsForm";
import { ExperiencesForm } from "./ExperiencesForm";

import styles from "./SkillsStep.module.css";

type Props = {
    setDataToUpdate: React.Dispatch<React.SetStateAction<any>>;
    jobDescription: ReturnType<typeof normalizeJobDescription>;
    disableSubmit: (disable: boolean) => void;
};
export const normalizeLanguage = ({ id, ISOCode, level }) => {
    if (!id) {
        return {};
    }

    return {
        id,
        name: ISOCode,
        label: level,
        value: level,
    };
};

export function SkillsStep({ setDataToUpdate, jobDescription, disableSubmit }: Props) {
    const [skillsState, setSkillsState] = useState({
        languages:
            jobDescription?.skills?.languages?.map((language) => normalizeLanguage(language)) || [],
        tools: jobDescription?.skills?.tools || [],
        licences: jobDescription?.skills?.licences || [],
        experiences: jobDescription?.skills?.experiences || "",
    });

    const { languages, tools, licences, experiences } = skillsState;

    useEffect(() => {
        disableSubmit(false);
    }, []);

    function submitSkillsStepData() {
        setDataToUpdate({
            languageIds: languages?.map((language) => language.id),
            ...(tools && { tools }),
            licenceIds: licences?.map((licence) => licence.id),
            ...(experiences && { experiences }),
        });
    }

    // upper store will be updated every 100ms after user finished interacting
    useTimer(
        {
            timing: 100,
            callback: () => submitSkillsStepData(),
        },
        [skillsState],
    );

    return (
        <div className={clsx(styles.skills)}>
            <div className={styles.header}>
                <SectionHeader
                    title={i18n.job_descriptions_creation_skills_languages_tools_title()}
                    subtitle={i18n.job_descriptions_creation_skills_languages_tools_subtitle()}
                    icon='Bolt'
                />

                <LanguagesForm
                    selectedLanguages={skillsState.languages}
                    setSelectedLanguages={(selectedLanguages) =>
                        setSkillsState((prevState) => ({
                            ...prevState,
                            languages: selectedLanguages.map((language) => language),
                        }))
                    }
                />
                <ToolsForm
                    currentTools={skillsState.tools}
                    submitTools={(selectedTools = []) =>
                        setSkillsState((prevState) => ({
                            ...prevState,
                            tools: selectedTools.map(({ id, name, custom, level, levels }) => ({
                                // do not pass any id if custom tool
                                id: !custom ? id : undefined,
                                name,
                                level,
                                levels,
                            })),
                        }))
                    }
                />
            </div>

            <div className={styles.wrapper}>
                <SectionHeader
                    title={i18n.job_descriptions_creation_skills_licence_title()}
                    subtitle={i18n.job_descriptions_creation_skills_licence_subtitle()}
                    icon='DocumentLines'
                />
                <LicencesForm
                    currentLicences={skillsState.licences}
                    submitLicences={(selectedLicences) =>
                        setSkillsState((prevState) => ({
                            ...prevState,
                            licences: selectedLicences.map((licence) => licence),
                        }))
                    }
                />
            </div>

            <div className={styles.wrapper}>
                <SectionHeader
                    title={i18n.job_descriptions_creation_skills_experience_title()}
                    subtitle={i18n.job_descriptions_creation_skills_experience_subtitle()}
                    icon='DocumentLines'
                />
                <ExperiencesForm
                    experiences={skillsState.experiences}
                    setExperiences={(inputedExperiences) =>
                        setSkillsState((prevState) => ({
                            ...prevState,
                            experiences: inputedExperiences,
                        }))
                    }
                />
            </div>
        </div>
    );
}
