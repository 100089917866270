import { InputText } from "side-ui";

import { i18n } from "@lib/i18n";

import styles from "./JobDescriptionName.module.css";

export function JobDescriptionName({ prefix, suffix, setSuffix }) {
    return (
        <div className={styles.rename}>
            <form>
                <div className={styles.field}>
                    <p className={styles.prefix}>{`${prefix} -`}</p>
                    <InputText
                        name='renameJobDescription'
                        id='rename_job-description'
                        placeholder={i18n.job_descriptions_rename_field_placeholder()}
                        smallInput={true}
                        onChange={(event) => setSuffix(event.target.value)}
                        value={suffix}
                    />
                </div>
            </form>
            <p className={styles.tip}>{i18n.job_descriptions_rename_tip()}</p>
        </div>
    );
}
