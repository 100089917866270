import { useState } from "react";
import clsx from "clsx";
import { ModalSimple, Select } from "side-ui";
import { IconCross } from "side-ui";

import { i18n, i18nDK } from "@lib/i18n";

import styles from "../../WorkConditionsAndSkillsModals.module.css";
export function EditLanguages({ languages, hideModal, selectedLanguages, submitLanguages }) {
    const [touched, setTouched] = useState(false);
    // cache updates should user click on the cancel button
    // only submits when actually validated
    const [updatedLanguages, setUpdatedLanguages] = useState(selectedLanguages);

    function handleLanguageLevelEdit(id, selectedItem) {
        const editedLanguages = updatedLanguages.map((language) => {
            if (language.id === id) {
                return {
                    ...language,
                    value: selectedItem,
                    label: selectedItem,
                    // passing level id
                    id: languages[language.name]?.levels?.find((l) => l.value === selectedItem)?.id,
                };
            }

            return language;
        });

        setUpdatedLanguages(editedLanguages);
        setTouched(true);
    }

    function filterLanguage(id) {
        return setUpdatedLanguages((prevState) =>
            prevState.filter((language) => language.id !== id),
        );
    }

    return (
        <ModalSimple
            hideModal={hideModal}
            title={i18n.job_descriptions_creation_skills_languages_modal_title()}
            subtitle={i18n.job_descriptions_creation_skills_languages_modal_subtitle()}
            cancelLabel={i18n.cancel()}
            validateLabel={i18n.save()}
            disableButton={!touched}
            action={() => {
                submitLanguages(updatedLanguages);
                hideModal();
            }}
            customClass={styles.modal}
        >
            {!updatedLanguages.length ? (
                <p>{i18n.job_descriptions_creation_skills_languages_edit_disclaimer()}</p>
            ) : null}

            {updatedLanguages.map((selectedLanguage) => (
                <div key={selectedLanguage.id} className={clsx(styles.edit, styles.languages)}>
                    <div className={styles.title}>
                        {i18nDK(selectedLanguage.name)}

                        <button
                            type='button'
                            title={i18n.label_delete()}
                            onClick={() => {
                                filterLanguage(selectedLanguage.id);
                                setTouched(true);
                            }}
                        >
                            <IconCross customClass={styles.icon} />
                        </button>
                    </div>

                    <div className={styles.inputWrapper}>
                        <Select
                            id='language-edit'
                            name='language-edit'
                            options={
                                languages[selectedLanguage.name]?.levels.map((level) => ({
                                    id: level.id,
                                    label: i18nDK(level.label),
                                    value: level.value,
                                    name: level.name,
                                })) || []
                            }
                            chevronIcon={true}
                            onChange={(_, __, selectedItem) =>
                                handleLanguageLevelEdit(selectedLanguage.id, selectedItem)
                            }
                            selectedItem={selectedLanguage.value && i18nDK(selectedLanguage.value)}
                        />
                    </div>
                </div>
            ))}
        </ModalSimple>
    );
}
