import { BlockFeedback, InputText, ModalSimple, ModalsService, RadioButtons } from "side-ui";

import { useFormInput } from "@lib/hooks/form";
import { i18n } from "@lib/i18n";

import styles from "./SafetyEquipmentListForm.module.css";

export function CustomSafetyEquipmentModal({ submitEquipment }) {
    const { formInput: customEquipmentName, handleChange: setCustomEquipmentName } = useFormInput(
        "",
        () => null,
    );

    const { formInput: customEquipmentProvider, handleChange: setCustomEquipmentProvider } =
        useFormInput("", () => null);

    function handleTextChange(event) {
        const { value } = event.target;

        if (value?.length > 50) {
            return;
        }

        setCustomEquipmentName(value);
    }

    function handleRadioButtonChange(event) {
        const { value } = event.target;

        setCustomEquipmentProvider(value);
    }

    const customId = `custom_equipment_${Date.now()}`;

    return (
        <ModalSimple
            title={i18n.job_descriptions_creation_conditions_equipments_security_add_custom_button()}
            validateLabel={i18n.add()}
            cancelLabel={i18n.cancel()}
            hideModal={() => ModalsService.closeModal(`ADD_CUSTOM_SAFETY_EQUIPMENT`)}
            disableButton={
                customEquipmentName?.value?.length < 2 ||
                !customEquipmentProvider.dirty ||
                !customEquipmentName.dirty
            }
            action={() => {
                submitEquipment({
                    group: "Safety",
                    name: customEquipmentName?.value,
                    custom: true,
                    providedBy: customEquipmentProvider?.value,
                    // necessary to discriminate when equipment is not yet persisted
                    // (id is set by the backend)
                    issuedByFrontEnd: true,
                    customId, // necessary to handle deletion in EditSafetyEquipment
                });
                ModalsService.closeModal(`ADD_CUSTOM_SAFETY_EQUIPMENT`);
            }}
        >
            <div className={styles.custom}>
                <BlockFeedback
                    withIcon={false}
                    type='neutral'
                    content={i18n.job_descriptions_creation_conditions_equipments_security_custom_modal_tip()}
                />
                <InputText
                    label={i18n.job_descriptions_creation_conditions_equipments_security_custom_modal_input_label()}
                    name='customEquipment'
                    id='customEquipment'
                    value={customEquipmentName?.value}
                    placeholder={i18n.task_edit_custom_equipment_name_placeholder()}
                    smallInput={true}
                    onChange={handleTextChange}
                />

                <RadioButtons
                    label={i18n.job_descriptions_creation_conditions_equipments_security_modal_label()}
                    value={{ [customEquipmentProvider?.value]: true }}
                    options={[
                        {
                            value: "sider",
                            label: i18n.job_descriptions_creation_conditions_equipment_provider_sider(),
                            name: "side",
                        },
                        {
                            value: "company",
                            label: i18n.job_descriptions_creation_conditions_equipment_provider_company(),
                            name: "company",
                        },
                    ]}
                    onChange={handleRadioButtonChange}
                />
            </div>
        </ModalSimple>
    );
}
