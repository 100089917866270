import { useEffect, useState } from "react";
import { useSetAtom } from "jotai";
import { SectionHeader } from "side-ui";
import { Select, TextArea } from "sui";

import { useCompanyManagers } from "@lib/hooks/useCompanyManagers";
import { useLocation } from "@lib/hooks/useLocation";
import { i18n } from "@lib/i18n";
import { usePatchTask } from "@lib/mutations/usePatchTask";
import { queries } from "@lib/queries";
import { taskFormRoute } from "@routes/TaskPosting/route";
import { useQuery } from "@tanstack/react-query";

import LocationForm from "../../../components/LocationForm/LocationForm";
import * as backend from "../../../lib/api";
import { disabledSubmitAtom, orderDataToSendAtom } from "../TaskFormFooter";

import "./DetailsStep.scss";

export function DetailsStep() {
    const setOrderDataToSend = useSetAtom(orderDataToSendAtom);
    const setDisabledSubmit = useSetAtom(disabledSubmitAtom);
    const { patchTask: updateTask } = usePatchTask();

    const { taskId } = taskFormRoute.useParams();
    const { data: task } = useQuery(queries.task.detail(taskId));

    const [remoteLocation, setRemoteLocation] = useState<backend.CreateLocation | null>(null);
    const [orderLocation, setOrderLocation] = useState(task?.location ?? null);
    const [state, setState] = useState({
        remote: task?.location?.remote || false,
        motorized: task?.location?.motorized || false,
        managerId: task?.manager?.id || "",
        missionInformation: task?.missionInformation || "",
        address: task?.location?.address || "",
    });

    const companyManagers = useCompanyManagers();

    useEffect(() => {
        if (task?.id) {
            const isManagerExist = companyManagers.some(({ id }) => id === task?.manager?.id);
            if (!isManagerExist) {
                setState((prevState) => ({
                    ...prevState,
                    managerId: "",
                }));
            }
        }
    }, [companyManagers.length, task?.id]);

    // enabled scrolling to a specific element, cf. Summary
    const { hash } = useLocation();
    useEffect(() => {
        if (hash) {
            const element = document.querySelector(hash);

            if (element) {
                element.scrollIntoView();
            }
        }
    }, []);

    useEffect(() => {
        setDisabledSubmit(!state.address || !state.managerId);
    }, [state.address, state.managerId]);

    // create the new location if it doesn't exist in order props
    const createNewLocation = async (addressLocation) => {
        try {
            const res = await backend.createLocation({
                organisationId: localStorage.getItem(`side_team_activeOrganisationId`) ?? "",
                location: addressLocation,
            });
            if (res) setRemoteLocation(res);
        } catch (e) {
            return e;
        }

        return null;
    };

    // component mounts
    //    -> if location is favorite or not created yet, duplicate / create it
    //    -> create a new location
    useEffect(() => {
        if (orderLocation?.favorite === true || (!!orderLocation && !orderLocation.id)) {
            createNewLocation({
                ...orderLocation,
                favorite: false,
            });
        }
    }, [orderLocation?.address]);

    // when remoteLocation's reference updates...
    useEffect(() => {
        if (remoteLocation) {
            // ...sync parent's state with new location data + state...
            setOrderDataToSend((prevState) => ({
                ...prevState,
                location: { ...remoteLocation, ...state },
            }));
            // ...and finally PATCH the order with the same data.
            updateTask({ id: taskId, location: { title: null, ...remoteLocation, ...state } });
            setOrderLocation({ title: null, ...remoteLocation, ...state });
        }
    }, [remoteLocation?.address]);

    // update parent's state necessary to update the task
    useEffect(() => {
        setOrderDataToSend(() => ({
            location: {
                ...orderLocation,
                remote: state.remote,
                motorized: state.motorized,
            },
            managerId: state.managerId,
            missionInformation: state.missionInformation,
        }));
    }, [state.remote, state.motorized, state.managerId, state.missionInformation]);

    return (
        <div className='order-form__details-step'>
            <div className='order-form__details-step__content'>
                <SectionHeader
                    title={i18n.location_form_workplace_title()}
                    subtitle={i18n.location_form_workplace_subtitle()}
                    closeAction={null}
                    icon='Location'
                />
                <LocationForm
                    mode=''
                    location={{
                        ...orderLocation,
                        remote: state.remote,
                        vehicle: state.motorized,
                    }}
                    setLocationState={(locationData) => {
                        // API is not consistent, LocationForm is used both for task and job desc, for job desc key is called "vehicle" while for task it's "motorized"
                        locationData.motorized = locationData.vehicle;
                        delete locationData.vehicle;

                        setOrderLocation((prevState) => ({
                            ...prevState,
                            ...locationData,
                        }));

                        setState((prevState) => ({
                            ...prevState,
                            ...locationData,
                        }));
                    }}
                />
                <SectionHeader
                    title={i18n.manager_form_title()}
                    subtitle={i18n.manager_form_subtitle()}
                    closeAction={null}
                    icon='UserTeam'
                />
                <div className='manager-form' id='manager'>
                    <Select
                        label='Manager'
                        placeholder={i18n.manager_form_label()}
                        selection={companyManagers.find(({ id }) => id === state.managerId) ?? null}
                        options={companyManagers}
                        onChange={(selectedItem) =>
                            setState((prevState) => ({
                                ...prevState,
                                managerId:
                                    companyManagers.find(
                                        ({ value }) => value === selectedItem?.value,
                                    )?.id ?? "",
                            }))
                        }
                    />
                </div>
                <SectionHeader
                    title={i18n.order_details_title()}
                    subtitle={i18n.order_details_subtitle()}
                    icon='Warning'
                />
                <div
                    className='order-form__details-step__content__textarea__wrapper'
                    id='additional-informations'
                >
                    <TextArea
                        rows={4}
                        label={i18n.order_details_label()}
                        placeholder={i18n.order_details_placeholder()}
                        value={state.missionInformation}
                        onChange={(event) =>
                            setState((prevState) => ({
                                ...prevState,
                                missionInformation: event.target.value,
                            }))
                        }
                        required={false}
                    />
                </div>
            </div>
        </div>
    );
}
