import { useState } from "react";
import { SectionHeader } from "side-ui";

import LocationForm from "@components/LocationForm/LocationForm";
import ManagerForm from "@components/ManagerForm/ManagerForm";
import { i18n } from "@lib/i18n";

import { useTimer } from "../../hooks/useTimer";

import styles from "./LocationStep.module.css";

export const LocationStep = ({ currentJobDescription, setDataToUpdate, companyManagers }) => {
    const [locationStepState, setLocationStepState] = useState({
        location: currentJobDescription?.location ? currentJobDescription?.location : {},
        ...(currentJobDescription?.managerId && { managerId: currentJobDescription?.managerId }),
    });

    useTimer(
        {
            timing: 100,
            callback: () => setDataToUpdate(locationStepState),
        },
        [locationStepState],
    );

    return (
        <div className={styles.location}>
            <SectionHeader
                title={i18n.location_form_workplace_title()}
                subtitle={i18n.location_form_workplace_subtitle()}
                closeAction={null}
                icon='Location'
            />
            <LocationForm
                mode='jobDescription'
                location={locationStepState.location}
                setLocationState={(locationData) =>
                    setLocationStepState((prevState) => ({
                        ...prevState,
                        location: {
                            ...prevState.location,
                            ...locationData,
                        },
                    }))
                }
            />
            <SectionHeader
                title={i18n.manager_form_title()}
                subtitle={i18n.manager_form_subtitle()}
                closeAction={null}
                icon='UserTeam'
            />
            <ManagerForm
                managerId={locationStepState.managerId}
                companyManagers={companyManagers}
                setManager={(managerId) =>
                    setLocationStepState((prevState) => ({
                        ...prevState,
                        managerId,
                    }))
                }
            />
        </div>
    );
};
