import fetch from "isomorphic-fetch";

import { handleError } from "@lib/utils/error";

import { withAuthHeaders } from "./apiHelpers";
import { apiConf } from "./index";

type UpdateCompanyLegalInfoParams = {
    organisationId: string;
    data: { name?: string; businessName?: string; siret?: string; apeCode?: string };
};
export async function updateCompanyLegalInfo(params: UpdateCompanyLegalInfoParams): Promise<void> {
    try {
        const resp = await fetch(
            withAuthHeaders(
                `${apiConf.BACK_URL}/organisations/${params.organisationId}/legal-informations`,
                {
                    method: `PATCH`,
                    body: JSON.stringify(params.data),
                },
            ),
        );

        if (resp.status >= 400) {
            handleError(await resp.json(), resp.status);
            return Promise.reject(new Error("UpdateCompanyLegalInfo"));
        }
        return;
    } catch (e) {
        handleError(e, 500);
        return Promise.reject(new Error("UpdateCompanyLegalInfo"));
    }
}
