import { lazy, Suspense, useEffect } from "react";
import { ConfirmDialogProvider } from "sui";

import { i18n } from "@lib/i18n";
import { queries } from "@lib/queries";
import { debug } from "@side.co/client-debug";
import { type QueryClient, useQuery, useQueryClient } from "@tanstack/react-query";
import {
    createRootRouteWithContext,
    createRoute,
    createRouter,
    Outlet,
    redirect,
    RouterProvider,
    ScrollRestoration,
    useMatches,
} from "@tanstack/react-router";
import { useUnleashContext } from "@unleash/proxy-client-react";

import OrganisationLayout from "../layouts/OrganisationLayout";

import { activateAccountRoute } from "./ActivateAccount/route";
import {
    contractsBatchesArchivesRoute,
    contractsIndexRoute,
    contractsRoute,
    contractsSignatureRoute,
} from "./Contracts/route";
import { homeRoute } from "./Home/route";
import { horizonRoute } from "./Horizon/route";
import { invoicesRoute } from "./Invoices/route";
import { jobDescriptionsRoute } from "./JobDescriptions/route";
import { logAsRoute } from "./LogAs/route";
import { planningRoute } from "./Planning/route";
import { preselectionRoute } from "./Preselection/route";
import {
    legalInformationRoute,
    paymentInformationRoute,
    profileRoute,
    remunerationInformationRoute,
    settingsIndexRoute,
    settingsRoute,
    teamSettingsRoute,
} from "./Settings/route";
import { signInRoute } from "./SignIn/route";
import { signUpRoute } from "./SignUp/route";
import {
    detailsStepRoute,
    flexiblePlanningStepRoute,
    motiveStepRoute,
    planningTypeStepRoute,
    shiftsStepRoute,
    summaryRoute,
    taskFormRoute,
    workersStepRoute,
} from "./TaskPosting/route";
import {
    tasksListHomeRoute,
    tasksListIndexRoute,
    tasksListStatusRoute,
    tasksRoute,
    tasksRouteLayout,
    taskViewRoute,
} from "./TasksList/route";
import { timesheetsRoute } from "./Timesheets/route";
import { userProfileRoute } from "./UserProfile/route";
import { getUser } from "./authorization";
import NotFound from "./NotFound";

const TanStackRouterDevtools =
    process.env.NODE_ENV === "production"
        ? () => null // Render nothing in production
        : lazy(() =>
              // Lazy load in development
              import("@tanstack/router-devtools").then((res) => ({
                  default: res.TanStackRouterDevtools,
              })),
          );

interface RootContext {
    getTitle?: () => string;
    queryClient: QueryClient;
}

export const rootRoute = createRootRouteWithContext<RootContext>()({
    component: () => {
        const matches = useMatches();

        const { data: user } = useQuery(queries.user.detail());

        useEffect(() => {
            function getFirstAvailableTitle() {
                const baseTitle = "Side";

                for (let i = matches.length - 1; i >= 0; i--) {
                    const match = matches[i];
                    if (match.context && "getTitle" in match.context) {
                        try {
                            const title = match.context.getTitle?.();
                            if (title) {
                                document.title = `${baseTitle} · ${title}`;
                                return;
                            }
                        } catch (error) {
                            if (import.meta.env.DEV) {
                                console.warn("Error getting title", match.pathname, error);
                            }
                        }
                    } else if (i === matches.length - 1 && import.meta.env.DEV) {
                        // Warning in dev so we don't forget to add a title
                        console.warn("no getTitle", match.pathname, match);
                    }
                }

                // If no title is found, use the default title
                document.title = baseTitle;
            }

            void getFirstAvailableTitle();
        }, [matches]);

        // unleash context
        const updateContext = useUnleashContext();
        const userId = localStorage.getItem("Meteor.userId") ?? "";
        const organisationId = localStorage.getItem("side_team_activeOrganisationId") ?? "";
        const networkId = localStorage.getItem("side_team_groupId") ?? "";
        // unleash expects a string
        const logAs =
            user?.isInsider && localStorage.getItem("side_team_logasId") ? "true" : "false";

        useEffect(() => {
            updateContext({ userId, properties: { organisationId, networkId, logAs } });
        }, [userId, organisationId, networkId, logAs]);

        return (
            <>
                <Outlet />
                <ScrollRestoration />
                <Suspense fallback={null}>
                    <TanStackRouterDevtools />
                </Suspense>
            </>
        );
    },
    notFoundComponent: () => <NotFound />,
});

export const globalLayoutRoute = createRoute({
    getParentRoute: () => rootRoute,
    id: "globalLayout",
    beforeLoad: async () => {
        const user = await getUser();
        if (!user) {
            throw redirect({ to: `/signin` });
        }
    },
    component: () => (
        <ConfirmDialogProvider
            confirmButtonLabel={i18n.action_confirm()}
            cancelButtonLabel={i18n.action_cancel()}
        >
            <OrganisationLayout>
                <Outlet />
            </OrganisationLayout>
        </ConfirmDialogProvider>
    ),
});
const defaultRoute = createRoute({
    getParentRoute: () => rootRoute,
    path: `/`,
    beforeLoad: async () => {
        const user = await getUser();
        const orgaIsCompany = localStorage.getItem("side_team_activeOrganisationId") !== null;
        const orgaIsGroup = localStorage.getItem("side_team_groupId") !== null;

        if (user?.isInsider) {
            throw redirect({ to: `/side-admin` });
        }
        if (user && orgaIsCompany) {
            throw redirect({ to: `/planning` });
        }
        if (user && orgaIsGroup) {
            throw redirect({ to: `/home` });
        }

        throw redirect({ to: `/signin` });
    },
});

const routeTree = rootRoute.addChildren([
    globalLayoutRoute,
    defaultRoute,
    signInRoute,
    signUpRoute,
    activateAccountRoute,
    homeRoute,
    logAsRoute,
    preselectionRoute,
    tasksRoute.addChildren([
        tasksRouteLayout.addChildren([
            tasksListIndexRoute,
            tasksListHomeRoute,
            tasksListStatusRoute,
        ]),
        taskViewRoute,
    ]),
    planningRoute,
    contractsRoute.addChildren([contractsIndexRoute, contractsBatchesArchivesRoute]),
    contractsSignatureRoute,
    timesheetsRoute,
    invoicesRoute,
    userProfileRoute,
    settingsRoute.addChildren([
        settingsIndexRoute,
        legalInformationRoute,
        paymentInformationRoute,
        remunerationInformationRoute,
        profileRoute,
        teamSettingsRoute,
    ]),
    taskFormRoute.addChildren([
        motiveStepRoute,
        planningTypeStepRoute,
        flexiblePlanningStepRoute,
        shiftsStepRoute,
        detailsStepRoute,
        workersStepRoute,
        summaryRoute,
    ]),
    jobDescriptionsRoute,
    horizonRoute,
]);

export const router = createRouter({
    routeTree,
    defaultPreload: "intent",
    // Since we're using React Query, we don't want loader calls to ever be stale
    // This will ensure that the loader is always called when the route is preloaded or visited
    defaultPreloadStaleTime: 0,
    defaultOnCatch: (error) => {
        debug.catch(error);
    },
    context: {
        queryClient: undefined!, // This will be set after we wrap the app in a QueryClientProvider
    },
});

declare module "@tanstack/react-router" {
    interface Register {
        router: typeof router;
    }
}

export function AppRouter() {
    const queryClient = useQueryClient();
    return <RouterProvider router={router} context={{ queryClient }} />;
}
