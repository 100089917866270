import fetch from "isomorphic-fetch";
import { z } from "zod";

import { handleError } from "@lib/utils/error";
import { debug } from "@side.co/client-debug";

import { withAuthHeaders } from "./apiHelpers";
import { apiConf } from "./index";

const GetFlexiblePlanningResponseSchema = z.object({
    id: z.string(),
    startDate: z.string().transform((val) => new Date(val)),
    endDate: z.string().transform((val) => new Date(val)),
    weeklyVolume: z.number().transform((val) => val.toString()),
    siders: z.number(),
    amplitudeStart: z.string(),
    amplitudeEnd: z.string(),
    include: z
        .object({
            holidays: z.boolean(),
            weekDays: z.boolean(),
            saturday: z.boolean(),
            sunday: z.boolean(),
        })
        .partial(),
});
export type GetFlexiblePlanningResponse = z.infer<typeof GetFlexiblePlanningResponseSchema>;

const message = "Get FlexiblePlanning";
export async function getFlexiblePlanning(taskId: string): Promise<GetFlexiblePlanningResponse> {
    debug.addBreadcrumb({
        message,
        category: `action`,
        data: { taskId },
    });

    try {
        const resp = await fetch(
            withAuthHeaders(`${apiConf.BACK_URL}/tasks/${taskId}/flexible-planning`, {
                method: `GET`,
            }),
        );

        if (resp.status >= 400) {
            return Promise.reject(new Error(message));
        }

        return GetFlexiblePlanningResponseSchema.parse(await resp.json());
    } catch (e) {
        handleError(e, 500);
        return Promise.reject(e);
    }
}
