import { useEffect } from "react";

import { i18nDK } from "@lib/i18n";

import BlankTab from "../../../components/BlankTab";
import { tasksListStatusRoute } from "../route";

import TasksListTable from "./TaskListTable";

import "./TasksListStatus.scss";

function TasksListStatus({ items, pagination, getTasks, limit, managerIdFilter }) {
    const { status } = tasksListStatusRoute.useParams();
    const { page } = tasksListStatusRoute.useSearch();

    const match = tasksListStatusRoute.useMatch();

    useEffect(() => {
        getTasks(status, page, limit);
    }, [page, managerIdFilter, status]);

    const hasFetchedItems = (!pagination || !pagination.isFetching) && (!items || !items.length);
    const isLoading = pagination && pagination.isFetching;

    if (!isLoading && hasFetchedItems) {
        return (
            <BlankTab
                title={i18nDK(`empty_placeholder_${status}_title`)}
                subtitle={i18nDK(`empty_placeholder_${status}_subtitle`)}
            />
        );
    }

    return (
        <div className='tasks-list'>
            <TasksListTable
                status={status}
                items={items}
                pagination={pagination}
                isLoading={isLoading}
                hasPagination={true}
                match={match}
            />
        </div>
    );
}

export default TasksListStatus;
