import fetch from "isomorphic-fetch";

import { handleError } from "@lib/utils/error";
import { debug } from "@side.co/client-debug";

import { withAuthHeaders } from "./apiHelpers";
import { apiConf } from "./index";

type GetCompanyPaymentInfoParams = {
    organisationId: string;
};
export type GetCompanyPaymentInfoResponse = {
    id: string;
    companyId: string;
    billingAddress: BillingAddress;
    accountingInfo: AccountingInfo;
    paymentMethod: PaymentMethod;
    paymentDelay: number;
    clientSecret: string;
    wantOrderForm: boolean;
};

type PaymentMethod = {
    type: string;
    last4: string;
};

type AccountingInfo = {
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
};

type BillingAddress = {
    street: string;
    postalCode: string;
    city: string;
    country: string;
};
export async function getCompanyPaymentInfo(
    params: GetCompanyPaymentInfoParams,
): Promise<GetCompanyPaymentInfoResponse> {
    debug.addBreadcrumb({
        message: `Get payment informations`,
        category: `action`,
        data: {
            organisationId: params.organisationId,
        },
    });

    try {
        const resp = await fetch(
            withAuthHeaders(
                `${apiConf.BACK_URL}/organisations/${params.organisationId}/payment-informations`,
            ),
        );
        if (resp.status >= 400) {
            handleError(resp.error, resp.status);
            return Promise.reject(new Error("Get payment informations"));
        }

        return resp.json();
    } catch (e) {
        handleError(e, 500);
        return Promise.reject(new Error("Get payment informations"));
    }
}
