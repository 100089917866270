import { useEffect, useRef, useState } from "react";

import { i18n } from "@lib/i18n";

const CompanySidersSearchResults = ({ companySiders, searchTerm, selectItem, touched }) => {
    const searchRef = useRef<HTMLDivElement>(null);
    const [isOpen, setIsOpen] = useState(companySiders.length > 0 && searchTerm && touched);

    const handleClickOutside = (event) => {
        const { target } = event;

        if (!searchRef.current) {
            return;
        }
        if (!searchRef.current.contains(target)) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        if (companySiders.length) {
            setIsOpen(true);
        }
    }, [companySiders]);

    useEffect(() => {
        document.addEventListener("click", handleClickOutside);

        return () => {
            document.removeEventListener("click", handleClickOutside);
        };
    });

    return isOpen ? (
        <div className='timesheets__filters__search__results__wrapper' ref={searchRef}>
            <ul className='timesheets__filters__search__results'>
                {companySiders.length ? (
                    companySiders.map(
                        ({
                            id,
                            pictureURL = "https://s3.eu-west-3.amazonaws.com/in.side-assets/placeholder-sider.png",
                            firstName,
                            lastName,
                            email,
                        }) => (
                            <li className='timesheets__filters__search__results__item' key={id}>
                                <button
                                    type='button'
                                    onClick={() => {
                                        selectItem(id, `${firstName} ${lastName}`);
                                        setIsOpen(false);
                                    }}
                                    className='timesheets__filters__search__results__item__button'
                                >
                                    <img
                                        src={pictureURL}
                                        className='timesheets__filters__search__results__item__sider__image'
                                        alt='Sider avatar'
                                        loading='lazy'
                                    />

                                    <span className='timesheets__filters__search__results__item__sider'>
                                        <span
                                            className='timesheets__filters__search__results__item__sider__name'
                                            title={`${firstName} ${lastName}`}
                                        >
                                            {firstName}
                                            &nbsp;
                                            {lastName}
                                        </span>

                                        <span className='timesheets__filters__search__results__item__sider__email'>
                                            {email}
                                        </span>
                                    </span>
                                </button>
                            </li>
                        ),
                    )
                ) : (
                    <li className='timesheets__filters__search__results__item timesheets__filters__search__results__item--blank'>
                        {i18n.ts_filters_search_item_empty()}
                    </li>
                )}
            </ul>
        </div>
    ) : null;
};

export default CompanySidersSearchResults;
