import { useEffect } from "react";
import { format } from "date-fns";
import { enUS, fr } from "date-fns/locale";
import moment from "moment";
import { Loader, TabItem, TabMenu } from "side-ui";

import { i18n } from "@lib/i18n";
import { router } from "@routes";
import { Link } from "@tanstack/react-router";

import BlankTab from "../../../components/BlankTab";
import Pagination from "../../../components/Pagination";
import { invoicesRoute } from "../route";

import InvoicesList from "./InvoicesList";

import "./Invoices.scss";

function Invoices({
    monthsInPage,
    invoicesByMonth,
    pagination,
    startDates,
    isLoading,
    getInvoices,
}) {
    const { location } = router.history;
    const search = invoicesRoute.useSearch();

    useEffect(() => {
        // fetch the date for the URL,
        // and find the corresponding page number
        let endDate;
        if (search?.date && moment(search?.date).isValid()) {
            endDate = moment(search?.date).toDate();
        } else if (search?.date) {
            router.navigate({ to: `/invoices` });
        }

        // if there's no date in the URL, take the current day
        if (!endDate) {
            endDate = new Date();
        }
        const startDate = moment(endDate).subtract(6, "months").format("YYYY-MM");
        getInvoices(moment(endDate).format(`YYYY-MM`), startDate);
    }, []);

    useEffect(() => {
        // compare page number via the url

        if (search.date && startDates.indexOf(search.date) !== -1) {
            getInvoices(search.date, moment(search.date).subtract(6, "months").format("YYYY-MM"));
        } else if (search.date && startDates && startDates.length) {
            router.navigate({
                to: `/invoices`,
                search: {
                    date: startDates[0],
                },
            });
        }
    }, [search.date, startDates]);

    const items = [
        <TabLink
            key='invoices'
            to='/invoices'
            label={i18n.top_menu_filter_all_invoices()}
            activeOnlyWhenExact={true}
        />,
    ];
    return (
        <div className='invoices'>
            <div className='invoices__header'>
                <TabMenu items={items} />
            </div>
            {isLoading ? (
                <div className='loader--centered'>
                    <Loader />
                </div>
            ) : (
                <div className='invoices__content'>
                    <div className='invoices__content__wrapper'>
                        {monthsInPage && monthsInPage.length ? (
                            monthsInPage.map((month) => (
                                <div key={month} className='invoices__month'>
                                    <h1 className='mb-5 typography-heading-xl-semibold'>
                                        {format(new Date(month), "MMMM yyyy", {
                                            locale: i18n.locale === "fr" ? fr : enUS,
                                        })}
                                    </h1>
                                    {invoicesByMonth[month] && invoicesByMonth[month].length ? (
                                        <div>
                                            <InvoicesList invoices={invoicesByMonth[month]} />
                                        </div>
                                    ) : (
                                        <div className='typography-body-m-regular'>
                                            {i18n.no_invoice_month()}
                                        </div>
                                    )}
                                </div>
                            ))
                        ) : (
                            <BlankTab title={i18n.no_invoice_company()} />
                        )}
                        {monthsInPage && !!monthsInPage.length && (
                            <Pagination
                                location={location}
                                pageCount={pagination.count}
                                path='/invoices'
                                page='invoices'
                                urlParam='date'
                                urlParamValues={startDates}
                            />
                        )}
                    </div>
                </div>
            )}
        </div>
    );
}

const TabLink = ({ label, to, activeOnlyWhenExact }) => (
    <TabItem active={true}>
        <Link
            to={to}
            activeOptions={{ exact: activeOnlyWhenExact }}
            dangerouslySetInnerHTML={{ __html: label }}
        />
    </TabItem>
);

export default Invoices;
