import { z } from "zod";

import { i18n } from "@lib/i18n";
import { globalLayoutRoute } from "@routes";
import { allowCompany } from "@routes/authorization";
import { createRoute, redirect } from "@tanstack/react-router";

import Planning from "./index";

const planningSearchSchema = z
    .object({
        week: z.number(),
        year: z.number(),
    })
    .partial();
type PlanningSearch = z.infer<typeof planningSearchSchema>;
export const planningRoute = createRoute({
    getParentRoute: () => globalLayoutRoute,
    path: `planning`,
    validateSearch: (search: PlanningSearch) => planningSearchSchema.parse(search),
    component: Planning,
    beforeLoad: () => {
        if (!allowCompany()) {
            throw redirect({ to: `/` });
        }

        return { getTitle: () => i18n.doc_title_planning() };
    },
});
