import { useState } from "react";
import {
    IconCard,
    IconCompany,
    IconDollarSign,
    IconIDCard,
    IconUserTeam,
    TabItem,
    TabMenu,
} from "side-ui";

import { useLocation } from "@lib/hooks/useLocation";
import { i18n } from "@lib/i18n";
import { Link, Outlet } from "@tanstack/react-router";

import "./Settings.scss";

function Settings() {
    const { pathname } = useLocation();
    const [active, setActive] = useState(pathname);

    const allowCompany = localStorage.getItem("side_team_activeOrganisationId") !== null;

    const items = [
        ...(allowCompany
            ? [
                  <TabLink
                      key='settingsLegal'
                      to='/settings/legal'
                      label={i18n.settings_nav_legal_info()}
                      icon={<IconIDCard />}
                      activeOnlyWhenExact={true}
                      isActive={active === `/settings/legal`}
                      onClick={setActive}
                  />,
                  <TabLink
                      key='settingsPayment'
                      to='/settings/payment'
                      label={i18n.settings_nav_payment_info()}
                      icon={<IconCard />}
                      activeOnlyWhenExact={true}
                      isActive={active === `/settings/payment`}
                      onClick={setActive}
                  />,
                  <TabLink
                      key='settingsRemuneration'
                      to='/settings/remuneration'
                      label={i18n.settings_nav_payment_info_remuneration()}
                      icon={<IconDollarSign />}
                      activeOnlyWhenExact={true}
                      isActive={active === `/settings/remuneration`}
                      onClick={setActive}
                  />,
                  <TabLink
                      key='settingsCompanyProfile'
                      to='/settings/profile'
                      label={i18n.settings_nav_company_info()}
                      icon={<IconCompany />}
                      activeOnlyWhenExact={true}
                      isActive={active === `/settings/profile`}
                      onClick={setActive}
                  />,
              ]
            : []),
        <TabLink
            key='settingsTeam'
            to='/settings/team'
            label={i18n.header_dropdown_team()}
            icon={<IconUserTeam />}
            activeOnlyWhenExact={true}
            isActive={active === `/settings/team`}
            onClick={setActive}
        />,
    ];

    return (
        <div className='settings'>
            <div className='settings__header'>
                <TabMenu items={items} />
            </div>
            <div className='settings__container'>
                <div className='settings__inner'>
                    <div className='settings__content'>
                        <Outlet />
                    </div>
                </div>
            </div>
        </div>
    );
}

const TabLink = ({ label, to, isActive, onClick, icon, activeOnlyWhenExact }) => (
    <TabItem active={isActive}>
        <Link to={to} activeOptions={{ exact: activeOnlyWhenExact }} onClick={() => onClick(to)}>
            {icon}
            {label}
        </Link>
    </TabItem>
);

export default Settings;
