import * as React from "react";
import { ModalSimple, ModalsService } from "side-ui";

import { i18n } from "@lib/i18n";

export const showUnsavedChangesModal = (handleSubmit, cancelAction) => {
    ModalsService.openModal({
        id: "UNSAVED_CHANGES_MODAL",
        content: (
            <UnsavedChangesModal
                hideModal={() => {
                    ModalsService.closeModal("UNSAVED_CHANGES_MODAL");
                    cancelAction();
                }}
                saveChanges={() => {
                    ModalsService.closeModal("UNSAVED_CHANGES_MODAL");
                    handleSubmit();
                }}
            />
        ),
    });
};

const UnsavedChangesModal = (props) => {
    const { saveChanges, hideModal } = props;
    return (
        <ModalSimple
            action={saveChanges}
            validateLabel={i18n.settings_warning_save_dialog_save()}
            hideModal={hideModal}
            cancelLabel={i18n.settings_warning_save_dialog_discard()}
            title={i18n.settings_warning_save_dialog_title()}
        >
            {i18n.settings_warning_save_dialog_content()}
        </ModalSimple>
    );
};

export default UnsavedChangesModal;
