import { useEffect, useRef, useState } from "react";
import { IconArrow2Down } from "side-ui";

import { i18n } from "@lib/i18n";

import "./TimesheetsFilter.scss";

type TimesheetsFilterProps = {
    label: string;
    children:
        | React.ReactNode
        | ((args: { setIsOpen: (isOpen: boolean) => void }) => React.ReactNode);
    // optional props
    activeFiltersCounter?: number;
    className?: string;
    deleteButton?: boolean;
    confirmButton?: boolean;
    confirmCallback?: () => void;
    deleteCallback?: () => void;
    childrenAsFunction?: boolean;
    triangle?: boolean;
    icon?: React.ReactNode;
};

const TimesheetsFilter = ({
    label,
    children,

    // optional props
    activeFiltersCounter,
    className = "",
    deleteButton,
    confirmButton,
    confirmCallback,
    deleteCallback,
    childrenAsFunction,
    triangle,
    icon,
}: TimesheetsFilterProps) => {
    const [isOpen, setIsOpen] = useState(false);
    const filterRef = useRef<HTMLDivElement>(null);

    const toggleDisplay = (event) => {
        event.preventDefault();

        setIsOpen(!isOpen);
    };

    const handleClickOutside = (event) => {
        const { target } = event;

        if (!filterRef.current) {
            return;
        }
        if (!filterRef.current.contains(target)) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener("click", handleClickOutside);

        return () => {
            document.removeEventListener("click", handleClickOutside);
        };
    });

    const filterIsSet = activeFiltersCounter && activeFiltersCounter > 0;

    return (
        <section ref={filterRef} className={`timesheets-filter ${className}`}>
            <button
                type='button'
                className={`sui-btn timesheets-filter__button sui-btn timesheets-filter__button${isOpen ? "--active" : ""} ${filterIsSet ? "sui-btn timesheets-filter__button--filter-set" : ""} `}
                onClick={toggleDisplay}
            >
                <span>{label}</span>
                {filterIsSet ? (
                    <span className='timesheets-filter__counter'>
                        &nbsp;({activeFiltersCounter})
                    </span>
                ) : null}

                {!icon && triangle && <IconArrow2Down customClass='timesheets-filter__triangle' />}
                {icon}
            </button>

            <div
                className={`timesheets-filter__children timesheets-filter__children--${isOpen ? "open" : "closed"} ${className} `}
            >
                {isOpen ? (
                    <>
                        {childrenAsFunction && typeof children === "function"
                            ? (children as React.FC)({ setIsOpen })
                            : (children as React.ReactNode)}

                        <div className='timesheets-filter__button__wrapper'>
                            {
                                deleteButton ? (
                                    <button
                                        className='sui-btn sui-btn--transparent-red'
                                        onClick={() => {
                                            if (deleteCallback) {
                                                deleteCallback();
                                            }
                                        }}
                                    >
                                        {/* {i18n.action_erase()} */}
                                    </button>
                                ) : (
                                    <div />
                                ) // hack float right (space-between) when only confirmButton is present
                            }
                            {confirmButton && (
                                <button
                                    className='sui-btn sui-btn--blue'
                                    onClick={(event) => {
                                        toggleDisplay(event);

                                        if (confirmCallback) {
                                            confirmCallback();
                                        }
                                    }}
                                >
                                    {i18n.save()}
                                </button>
                            )}
                        </div>
                    </>
                ) : null}
            </div>
        </section>
    );
};

export default TimesheetsFilter;
