import { i18n } from "@lib/i18n";
import { rootRoute } from "@routes";
import { createRoute } from "@tanstack/react-router";

import ActivateAccount from "./ActivateAccount";

export const activateAccountRoute = createRoute({
    getParentRoute: () => rootRoute,
    path: `invitation`,
    beforeLoad: () => ({ getTitle: () => i18n.activate_account_title() }),
    component: ActivateAccount,
});
