import { Loader } from "sui";

import { i18n } from "@lib/i18n";
import { globalLayoutRoute } from "@routes";
import { allowAdminAndUser, allowCompany, allowGroup } from "@routes/authorization";
import { createRoute, redirect } from "@tanstack/react-router";

import { LegalInformation } from "./components/LegalInformation";
import RemunerationInformation from "./containers/RemunerationInformation";
import Profile from "./Profile/Profile";
import Team from "./Team/Team";
import Settings from "./index";
import { PaymentInformation } from "./PaymentInformation";

export const settingsRoute = createRoute({
    getParentRoute: () => globalLayoutRoute,
    path: `settings`,
    component: Settings,
    beforeLoad: async () => {
        const allowedAdminAndUser = await allowAdminAndUser();
        if (!(allowCompany() || allowGroup()) && allowedAdminAndUser) {
            throw redirect({ to: `/` });
        }

        return { getTitle: () => i18n.doc_title_settings() };
    },
});
export const settingsIndexRoute = createRoute({
    getParentRoute: () => settingsRoute,
    path: `/`,
    beforeLoad: () => {
        throw redirect({ to: `/settings/legal` });
    },
});

export const legalInformationRoute = createRoute({
    getParentRoute: () => settingsRoute,
    path: `legal`,
    component: LegalInformation,
});
export const paymentInformationRoute = createRoute({
    getParentRoute: () => settingsRoute,
    path: `payment`,
    component: PaymentInformation,
    pendingComponent: () => (
        <div className='flex justify-center text-[var(--sui-blue-500)]'>
            <Loader />
        </div>
    ),
});
export const remunerationInformationRoute = createRoute({
    getParentRoute: () => settingsRoute,
    path: `remuneration`,
    component: RemunerationInformation,
});
export const profileRoute = createRoute({
    getParentRoute: () => settingsRoute,
    path: `profile`,
    component: Profile,
});
export const teamSettingsRoute = createRoute({
    getParentRoute: () => settingsRoute,
    path: `team`,
    component: Team,
});
