import { useEffect } from "react";
import { BlockFeedback, InputText, ModalSimple, ModalsService } from "side-ui";

import { useFormInput } from "@lib/hooks/form";
import { i18n } from "@lib/i18n";

export function CustomSubtaskModal({ submitSubtask, searchSubtaskValue }) {
    const { formInput: customSubtask, handleChange: setCustomSubtask } = useFormInput(
        "",
        () => null,
    );

    function handleTextChange(event) {
        const { value } = event.target;

        if (value?.length > 50) {
            return;
        }

        setCustomSubtask(value);
    }

    useEffect(() => {
        handleTextChange({
            target: {
                value: searchSubtaskValue,
            },
        });
    }, [searchSubtaskValue]);

    return (
        <ModalSimple
            title={i18n.job_descriptions_creation_job_subtasks_custom_modal_title()}
            validateLabel={i18n.add()}
            cancelLabel={i18n.cancel()}
            hideModal={() => ModalsService.closeModal(`ADD_CUSTOM_SUBTASK`)}
            disableButton={customSubtask?.value?.length < 2 || !customSubtask.dirty}
            action={() => {
                submitSubtask({
                    name: customSubtask?.value,
                    custom: true,
                    id: `custom_subtask_${Date.now()}`,
                });
                ModalsService.closeModal(`ADD_CUSTOM_SUBTASK`);
            }}
        >
            <div className='job-description__form__subtasks__custom'>
                <BlockFeedback
                    withIcon={false}
                    type='neutral'
                    content={i18n.job_descriptions_creation_job_subtasks_custom_modal_subtitle()}
                />
                <InputText
                    label={i18n.job_descriptions_creation_job_subtasks_custom_modal_label()}
                    name='customSubtask'
                    id='customSubtask'
                    value={customSubtask?.value}
                    placeholder={i18n.job_descriptions_creation_job_subtasks_custom_modal_placeholder()}
                    smallInput={true}
                    onChange={handleTextChange}
                />
            </div>
        </ModalSimple>
    );
}
