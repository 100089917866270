import moment from "moment";

import addMomentCustom from "../moment-custom";

export default (locale) => {
    const currentLocale = locale || localStorage.getItem(`side_team_locale`) || `fr`;
    // i18n.missingTranslation = function () { return undefined; };
    addMomentCustom();
    moment.locale(currentLocale);
};
