import { globalLayoutRoute } from "@routes";
import { createRoute } from "@tanstack/react-router";

import { UserProfile } from "./UserProfile";

export const userProfileRoute = createRoute({
    getParentRoute: () => globalLayoutRoute,
    path: `me/profile`,
    component: UserProfile,
});
