import { useEffect, useState } from "react";
import { InputText } from "side-ui";

import { i18n } from "@lib/i18n";
import { normalizeCurrency } from "@lib/utils/inputsNormalizers";

import { useTimer } from "../../../hooks/useTimer";

import styles from "./SalaryForm.module.css";

export function SalaryForm({ hourlyRate, disableSubmit, setHourlyRate, minimumHourlyWage }) {
    const [hourlyWage, setHourlyWage] = useState(
        normalizeCurrency(`${hourlyRate || minimumHourlyWage}`),
    );
    const [validity, setFormValidity] = useState<{ valid: boolean; rangeUnderflow: boolean }>();

    useEffect(() => {
        disableSubmit(!parseFloat(hourlyWage) || parseFloat(hourlyWage) < minimumHourlyWage);
    }, [hourlyWage]);

    useTimer(
        {
            timing: 100,
            callback: () => {
                if (parseFloat(hourlyWage)) {
                    setHourlyRate(parseFloat(hourlyWage));
                }
            },
        },
        [hourlyWage],
    );

    const displayInputError = () => {
        // no errors, return
        if (!validity || validity?.valid) return null;

        return validity?.rangeUnderflow
            ? i18n.job_descriptions_creation_salary_input_error_underflow()
            : i18n.job_descriptions_creation_salary_input_error();
    };

    return (
        <div className={styles.salary}>
            <div className={styles.input}>
                <InputText
                    label={i18n.job_descriptions_creation_salary_input_label()}
                    name='salary'
                    id='salary'
                    type='number'
                    smallInput={true}
                    placeholder={i18n.job_descriptions_creation_salary_input_placeholder({
                        minimumHourlyRate: minimumHourlyWage,
                    })}
                    min={minimumHourlyWage}
                    step='0.01'
                    onChange={({ target }) => {
                        // format input to not exceed 2 decimal places
                        // eslint-disable-next-line no-param-reassign
                        if (target.value.split(/([.,])/)[2]?.length > 2)
                            target.value = target.value.slice(0, -1);
                        setHourlyWage(normalizeCurrency(target.value));
                        // using native element validation
                        setFormValidity(target.validity);
                        disableSubmit(target.checkValidity() === false);
                    }}
                    value={hourlyWage}
                    onBlur={({ target }) =>
                        disableSubmit(target.checkValidity() === false || !target.value)
                    }
                    error={displayInputError()}
                />
                {hourlyWage && (
                    <span
                        className={styles.unit}
                        style={{
                            left: hourlyWage < 100 ? "58px" : "66px",
                        }}
                    >
                        €
                    </span>
                )}
            </div>
        </div>
    );
}
