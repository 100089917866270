import { Checkbox, ChoiceBox } from "sui";

import { i18n } from "@lib/i18n";

import AddressForm from "./AddressForm/AddressForm";

import "./LocationForm.scss";

const LocationForm = ({ location, setLocationState, mode }) => (
    <div className='location-form'>
        <div className='location-form__wrapper'>
            <AddressForm
                location={location}
                submitAddress={(address) => setLocationState({ ...address })}
            />

            <div className='location-form__checkboxContainer'>
                <ChoiceBox
                    label={i18n.location_form_workplace_option1()}
                    hierarchy={mode === "jobDescription" ? "secondary" : "primary"}
                    selectionControl={
                        <Checkbox
                            checked={location?.remote}
                            onChange={({ currentTarget }) => {
                                setLocationState({ ...location, remote: currentTarget.checked });
                            }}
                        />
                    }
                />
                <ChoiceBox
                    label={i18n.location_form_workplace_option2()}
                    hierarchy={mode === "jobDescription" ? "secondary" : "primary"}
                    selectionControl={
                        <Checkbox
                            checked={location?.vehicle}
                            onChange={({ currentTarget }) => {
                                setLocationState({ ...location, vehicle: currentTarget.checked });
                            }}
                        />
                    }
                />
            </div>
        </div>
    </div>
);
export default LocationForm;
