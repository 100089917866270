import { useState } from "react";
import clsx from "clsx";
import { SubmitHandler, useForm } from "react-hook-form";
import { Dialog, TextField, toast } from "saphir";
import { Button, fonts, Information, Label } from "sui";
import { z } from "zod";

import { zodResolver } from "@hookform/resolvers/zod";
import { i18n } from "@lib/i18n";
import { usePatchTask } from "@lib/mutations/usePatchTask";
import { queries } from "@lib/queries";
import { useQueryClient, useSuspenseQuery } from "@tanstack/react-query";

import taskPreliminaryDetailsModalStyles from "@routes/JobDescriptions/components/TaskPreliminaryDetailsModal/TaskPreliminaryDetailsModal.module.css";

interface Props {
    taskId: string;
    trigger: React.ReactNode;
    tracker: () => void;
}

export function EditTaskSuffixDialog(props: Props) {
    const [open, setOpen] = useState(false);

    return (
        <Dialog.Root open={open} onOpenChange={setOpen}>
            <Dialog.Trigger asChild>{props.trigger}</Dialog.Trigger>
            <Dialog.Content>
                <EditTaskSuffixDialogContent {...props} setOpen={setOpen} />
            </Dialog.Content>
        </Dialog.Root>
    );
}

function EditTaskSuffixDialogContent(props: Props & { setOpen: (value: boolean) => void }) {
    const { data: task } = useSuspenseQuery(queries.task.detail(props.taskId));

    const schema = z.object({
        taskSuffix: z.string().optional(),
    });

    type Inputs = z.infer<typeof schema>;

    const {
        register,
        handleSubmit,
        formState: { isDirty },
        watch,
        reset,
    } = useForm<Inputs>({
        mode: "onBlur",
        resolver: zodResolver(schema),
        defaultValues: {
            taskSuffix: (() => {
                const baseName = task.jobTitle;
                const name = task.name;
                const separator = " - ";

                const suffix = name.replace(baseName!, "").replace(separator, "") ?? "";

                return suffix;
            })(),
        },
    });

    const queryClient = useQueryClient();

    const { patchTask, isPending } = usePatchTask({
        onSuccess: (data) => {
            queryClient.setQueryData(queries.task.detail(props.taskId).queryKey, data);
            props.tracker();
            reset({
                taskSuffix: data?.name.replace(task.jobTitle!, "").replace(" - ", ""),
            });
            props.setOpen(false);
            toast.success(i18n.task_edit_success_toast());
        },
        onSettled: () => {
            queryClient.invalidateQueries({ queryKey: ["task"] });
        },
    });

    const onSubmit: SubmitHandler<Inputs> = async (data) => {
        patchTask({
            id: props.taskId,
            name: `${task.jobTitle} - ${data.taskSuffix?.trim()}`,
        });
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)} className='contents'>
            <Dialog.ScrollArea>
                <Dialog.Header>
                    <Dialog.Title>{i18n.task_edit_suffix_title()}</Dialog.Title>
                    <Dialog.Description>{i18n.task_edit_suffix_desc()}</Dialog.Description>
                </Dialog.Header>
                <Dialog.Main>
                    <Label
                        className={taskPreliminaryDetailsModalStyles.label}
                        icon={
                            <Information className={taskPreliminaryDetailsModalStyles.optional} />
                        }
                        tooltip={i18n.order_preliminary_details_taskname_tip()}
                    >
                        {i18n.order_preliminary_details_taskname_label()}{" "}
                        <span
                            className={clsx(
                                taskPreliminaryDetailsModalStyles.optional,
                                fonts.sans18Regular,
                            )}
                        >
                            ({i18n.order_preliminary_details_taskname_optional()})
                        </span>
                    </Label>
                    <TextField.Root
                        className={taskPreliminaryDetailsModalStyles.textField}
                        aria-label={i18n.order_preliminary_details_taskname_label()}
                        placeholder={i18n.order_preliminary_details_taskname_placeholder()}
                        {...register("taskSuffix")}
                    />
                    <p className='mt-2 text-gray-700 typography-body-m-regular'>{`${task.jobTitle} - ${watch("taskSuffix")}`}</p>
                </Dialog.Main>
            </Dialog.ScrollArea>
            <Dialog.Footer className='justify-end'>
                <div className='flex gap-2'>
                    <Dialog.Close asChild>
                        <Button type='button' shape='invisible' intention='secondary'>
                            {i18n.action_cancel()}
                        </Button>
                    </Dialog.Close>
                    <Button loading={isPending} disabled={!isDirty}>
                        {i18n.save()}
                    </Button>
                </div>
            </Dialog.Footer>
        </form>
    );
}
