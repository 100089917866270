import { forwardRef, useCallback, useReducer } from "react";
import { format } from "date-fns";
import { enUS, fr } from "date-fns/locale";
import { produce } from "immer";
import { Button, DatePicker, Modal, ModalContent, ModalFooter, ModalHeader, TextField } from "sui";

import type { Recruitment } from "@lib/api";
import { postCompanyMotive } from "@lib/api/postCompanyMotive";
import { i18n } from "@lib/i18n";
import { queries } from "@lib/queries";
import trackEvent from "@lib/trackers";
import { useMutation, useQueryClient, useSuspenseQuery } from "@tanstack/react-query";

import { RECRUITMENT } from "./MotiveStep";

import styles from "./motiveModals.module.css";

type RecruitmentWithId = Omit<Recruitment, "date"> & {
    id: ReturnType<typeof crypto.randomUUID>;
    date?: Date;
};
type RecruitmentReducerAction = {
    type: "remove" | "update";
    id?: ReturnType<typeof crypto.randomUUID>;
    payload?: Partial<Recruitment>;
};

type Props = {
    setSelectedJustification: React.Dispatch<
        React.SetStateAction<{
            label: string;
            value: string;
        } | null>
    >;
};

export const RecruitmentModal = forwardRef<HTMLDialogElement, Props>(function RecruitmentModal(
    { setSelectedJustification },
    ref,
) {
    const { data: company } = useSuspenseQuery(queries.company.detail());
    const [recruitments, dispatch] = useReducer(
        (state: RecruitmentWithId[], action: RecruitmentReducerAction) =>
            produce(state, (draft) => {
                switch (action.type) {
                    case "remove":
                        draft.splice(
                            draft.findIndex((recruitment) => recruitment.id === action.id),
                            1,
                        );
                        break;
                    case "update":
                        const recruitmentIndex = draft.findIndex(
                            (recruitment) => recruitment.id === action.id,
                        );
                        if (recruitmentIndex !== -1 && action.payload) {
                            draft[recruitmentIndex] = {
                                ...draft[recruitmentIndex],
                                ...action.payload,
                            };
                        }
                        break;
                }
            }),
        [{ id: crypto.randomUUID(), name: "", position: "", date: undefined }],
    );

    const updateRecruitment = useCallback(
        (id: RecruitmentReducerAction["id"], payload: RecruitmentReducerAction["payload"]) => {
            dispatch({ type: "update", id, payload });
        },
        [],
    );

    const queryClient = useQueryClient();

    const { mutate: postCompanyMotiveMutation, isPending } = useMutation({
        mutationFn: postCompanyMotive,
        onSuccess: (res, req) => {
            setSelectedJustification({
                label: req.justification,
                value: res.id,
            });
            trackEvent({
                name: `companymotives - created justification`,
                params: {
                    orgId: company.organisation.id,
                    reason: RECRUITMENT,
                },
            });
            (ref as React.MutableRefObject<HTMLDialogElement | null>).current?.close();
        },
        onSettled: () => {
            queryClient.invalidateQueries(queries.company.companyMotives());
        },
    });

    function hasEmptyValues(itemsArray) {
        return Boolean(
            itemsArray.find((arr) =>
                Object.values(arr).some((item) => {
                    return item === "" || item === undefined || item === null;
                }),
            ),
        );
    }

    return (
        <Modal
            ref={ref}
            onSubmit={() => {
                postCompanyMotiveMutation({
                    reason: RECRUITMENT,
                    justification: `${recruitments[0].name} - ${format(recruitments[0].date!, "P", {
                        locale: localStorage.getItem("side_team_locale") === "fr" ? fr : enUS,
                    })}`,
                    recruitments: recruitments.map((recruitment) => ({
                        name: recruitment.name,
                        position: recruitment.position,
                        date: recruitment.date!.toISOString(),
                    })),
                });
                (ref as React.MutableRefObject<HTMLDialogElement | null>).current?.close();
            }}
            onClose={() => {
                updateRecruitment(recruitments[0].id, {
                    name: "",
                    position: "",
                    date: undefined,
                });
            }}
        >
            <div className={styles.scrollContainer}>
                <ModalHeader
                    title={i18n.motive_recruitment_title()}
                    description={
                        <p>
                            {i18n.motive_recruitment_subtitle()}
                            &nbsp;
                            <a
                                href={i18n.motive_recruitment_explaination_link()}
                                target='_blank'
                                rel='noopener noreferrer external'
                                className={styles.link}
                            >
                                {i18n.motive_recruitment_explaination_link_label()}
                            </a>
                        </p>
                    }
                />
                <ModalContent>
                    <div className={styles.multipleEntriesShelf}>
                        {recruitments.map((recruitment) => {
                            return (
                                <div
                                    className={styles.multipleEntriesContainer}
                                    key={recruitment.id}
                                >
                                    <div className={styles.flex}>
                                        <TextField
                                            label={i18n.motive_recruitment_name_label()}
                                            placeholder={i18n.motive_recruitment_name_placeholder()}
                                            value={recruitment.name}
                                            onChange={(e) => {
                                                updateRecruitment(recruitment.id, {
                                                    name: e.target.value,
                                                });
                                            }}
                                            size='small'
                                            className={styles.requiredField}
                                            required
                                            autoComplete='off'
                                        />
                                        <div className={styles.requiredField}>
                                            <DatePicker
                                                label={i18n.motive_recruitment_date_label()}
                                                selected={recruitment.date || new Date()}
                                                onSelect={(date) => {
                                                    updateRecruitment(recruitment.id, {
                                                        date,
                                                    });
                                                }}
                                                size='small'
                                                portal={false}
                                                required
                                            />
                                        </div>
                                    </div>
                                    <TextField
                                        label={i18n.motive_recruitment_position_label()}
                                        placeholder={i18n.motive_recruitment_position_placeholder()}
                                        value={recruitment.position}
                                        onChange={(e) => {
                                            updateRecruitment(recruitment.id, {
                                                position: e.target.value,
                                            });
                                        }}
                                        size='small'
                                        className={styles.requiredField}
                                        required
                                        autoComplete='off'
                                    />
                                </div>
                            );
                        })}
                    </div>
                    <div className={styles.spacer} />
                </ModalContent>
            </div>
            <ModalFooter
                mainButton={
                    <Button loading={isPending} disabled={hasEmptyValues(recruitments)}>
                        {i18n.order_justifications_create_action()}
                    </Button>
                }
                cancelButtonLabel={i18n.cancel()}
            />
        </Modal>
    );
});
