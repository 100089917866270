import fetch from "isomorphic-fetch";
import { z } from "zod";

import { handleError } from "@lib/utils/error";
import { debug } from "@side.co/client-debug";

import { withAuthHeaders } from "./apiHelpers";
import { apiConf } from "./index";

export const SiderIdSchema = z.string();
export const DateSchema = z.string().datetime();
export const BreakDurationSchema = z.number().int().positive();
export const ImportAttendancesSchema = z.object({
    attendances: z.array(
        z.object({
            siderId: SiderIdSchema,
            startDate: DateSchema,
            endDate: DateSchema,
            breakDuration: BreakDurationSchema,
        }),
    ),
    partial: z.boolean().optional().catch(false),
});
export const ImportAttendancesErrorSchema = z.object({
    key: z.string(),
    metadata: z.object({
        fileAttDuplicate: z.string().optional(),
        dbAttDuplicate: z.string().optional(),
        shiftsNotFound: z.string().optional(),
    }),
});
export type PostAttendancesParams = z.infer<typeof ImportAttendancesSchema>;
export type PostAttendancesError = z.infer<typeof ImportAttendancesErrorSchema>;

const message = "Post attendances";
export async function postAttendances(
    params: PostAttendancesParams,
): Promise<void | PostAttendancesError> {
    debug.addBreadcrumb({
        message,
        category: `action`,
        data: params,
    });

    try {
        const resp = await fetch(
            withAuthHeaders(`${apiConf.BACK_URL}/attendances`, {
                method: `POST`,
                body: JSON.stringify(params),
            }),
        );

        if (resp.status >= 400) {
            const error = await resp.json();
            if (error?.key === "errors.attendance.internal_error") {
                return Promise.reject(error);
            }
            return Promise.reject(new Error(message));
        }

        return Promise.resolve();
    } catch (e) {
        handleError(e, 500);
        return Promise.reject(e);
    }
}
