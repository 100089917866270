import { useEffect, useState } from "react";
import { InputPhoneNumber } from "side-ui";
import { Button, Modal, ModalContent, ModalFooter, ModalHeader, TextField } from "sui";

import { i18n } from "@lib/i18n";
import { useMutation } from "@tanstack/react-query";

import * as backend from "../../../lib/api";
import { useFormInput } from "../../../lib/hooks/form";

import "./AddProfileModal.scss";

type AddProfileModalProps = {
    submitNewProfile: (phoneNumber: string) => void;
    modalRef: React.RefObject<HTMLDialogElement | null>;
};

export function AddProfileModal({ submitNewProfile, modalRef }: AddProfileModalProps) {
    const { formInput: firstName, handleChange: setFirstName } = useFormInput("", (value) =>
        !value ? i18n.error_field_required() : null,
    );
    const { formInput: lastName, handleChange: setLastName } = useFormInput("", (value) =>
        !value ? i18n.error_field_required() : null,
    );

    const [phoneBlur, setPhoneBlur] = useState(false);
    const [formattedPhoneNumber, setFormattedPhoneNumber] = useState("");
    const [isValidPhoneNumber, setIsValidPhoneNumber] = useState(null);
    const { formInput: phoneNumber, handleChange: setPhoneNumber } = useFormInput("", (value) =>
        !value || value.length === 0 ? i18n.error_field_required() : null,
    );
    const [phoneNumberError, setPhoneNumberError] = useState<string | null>(null);

    const { mutate: addProfile, isPending } = useMutation({
        mutationFn: (args: Parameters<typeof backend.addSiderProfile>[0]) =>
            backend.addSiderProfile(args),
        onSuccess: () => {
            modalRef.current?.close();
            submitNewProfile(formattedPhoneNumber);
        },
    });

    useEffect(() => {
        if (!phoneNumber.touched || !phoneBlur) {
            setPhoneNumberError(null);
            return;
        }

        if (phoneNumber.error) {
            setPhoneNumberError(phoneNumber.error);
            return;
        }

        if (!isValidPhoneNumber) {
            setPhoneNumberError(i18n.error_phone_number_invalid());
            return;
        }

        setPhoneNumberError(null);
    }, [phoneNumber.touched, phoneBlur, isValidPhoneNumber]);

    return (
        <Modal
            ref={modalRef}
            onSubmit={() => {
                addProfile({
                    phoneNumber: formattedPhoneNumber,
                    firstName: firstName.value,
                    lastName: lastName.value,
                });
            }}
        >
            <ModalHeader
                title={i18n.order_workers_gestion_profile_add_modal_title()}
                description={i18n.order_workers_gestion_profile_add_modal_subtitle()}
            />
            <ModalContent>
                <div className='order-form__workers-step__add-profile'>
                    <div className='order-form__workers-step__add-profile__row'>
                        <TextField
                            label={i18n.order_workers_gestion_profile_add_modal_firstname_label()}
                            name='firstname'
                            key='firstname'
                            id='firstname'
                            value={firstName?.value || ""}
                            error={(firstName.dirty && firstName.error) || ""}
                            placeholder={i18n.order_workers_gestion_profile_add_modal_firstname_placeholder()}
                            onChange={(e) => setFirstName(e.target.value)}
                        />
                        <TextField
                            label={i18n.order_workers_gestion_profile_add_modal_lastname_label()}
                            name='lastname'
                            key='lastname'
                            id='lastname'
                            value={lastName?.value || ""}
                            error={(lastName.dirty && lastName.error) || ""}
                            placeholder={i18n.order_workers_gestion_profile_add_modal_lastname_placeholder()}
                            onChange={(e) => setLastName(e.target.value)}
                        />
                    </div>
                    <div className='order-form__workers-step__add-profile__row' key='phonenumber'>
                        <InputPhoneNumber
                            id='new-profile-phone'
                            name='phone'
                            label={i18n.order_workers_gestion_profile_add_modal_phone_label()}
                            placeholder='(0)6 07 08 09 10'
                            error={phoneNumberError}
                            onChange={(e) => setPhoneNumber(e.target.value)}
                            value={phoneNumber.value || ""}
                            setFormattedOutput={setFormattedPhoneNumber}
                            setIsValid={setIsValidPhoneNumber}
                            onBlur={() => setPhoneBlur(true)}
                            type='tel'
                            autoComplete='tel'
                        />
                        <div />
                    </div>
                </div>
            </ModalContent>
            <ModalFooter
                mainButton={
                    <Button
                        disabled={
                            !!firstName.error ||
                            !!lastName.error ||
                            !!phoneNumber.error ||
                            isPending ||
                            !isValidPhoneNumber
                        }
                        loading={isPending}
                    >
                        {i18n.confirm()}
                    </Button>
                }
                cancelButtonLabel={i18n.cancel()}
            />
        </Modal>
    );
}
