import { Suspense, useState } from "react";
import { isEqual } from "lodash";
import { ArrowLeft, Plus } from "lucide-react";
import { Controller, FormProvider, SubmitHandler, useForm, useFormContext } from "react-hook-form";
import { Checkbox, Dialog, Input, TextField, toast, VisuallyHidden } from "saphir";
import { Button, Loader, Select } from "sui";
import { z } from "zod";

import { zodResolver } from "@hookform/resolvers/zod";
import { ToolSchema as TaskToolSchema } from "@lib/api/getTask";
import { i18n, i18nDK } from "@lib/i18n";
import { usePatchTask } from "@lib/mutations/usePatchTask";
import { queries } from "@lib/queries";
import { normalizeTools } from "@lib/queries/normalizeJobDescriptions";
import { useQueryClient, useSuspenseQuery } from "@tanstack/react-query";

interface Props {
    taskId: string;
    trigger: React.ReactNode;
    tracker: () => void;
}

const steps = ["initial", "addCustomTool"] as const;
type Steps = (typeof steps)[number];

const ToolSchema = TaskToolSchema.extend({
    issuedByFrontEnd: z.boolean().optional(),
    levels: z
        .array(
            z.object({
                id: z.string(),
                label: z.string(),
                value: z.enum(["beginner", "intermediate", "advanced", "expert"]),
            }),
        )
        .optional(),
});

type Tool = z.infer<typeof ToolSchema>;

const schema = z.object({
    tools: z.array(ToolSchema),
});

type Inputs = z.infer<typeof schema>;

export function EditTaskToolsDialog(props: Props) {
    const [open, setOpen] = useState(false);
    const queryClient = useQueryClient();

    return (
        <Dialog.Root open={open} onOpenChange={setOpen}>
            <Dialog.Trigger
                asChild
                onMouseEnter={() => {
                    // prefetch jobDescriptionsOptions data
                    queryClient.ensureQueryData(queries.jobDescriptionOptions.list());
                }}
            >
                {props.trigger}
            </Dialog.Trigger>
            <Dialog.Content>
                <Suspense
                    fallback={
                        <div className='flex w-full items-center justify-center p-4 text-blue-500'>
                            <VisuallyHidden>
                                <Dialog.Title>
                                    {i18n.job_descriptions_summary_tools_title()}
                                </Dialog.Title>
                            </VisuallyHidden>
                            <Loader />
                        </div>
                    }
                >
                    <EditTaskToolsDialogContent {...props} setOpen={setOpen} />
                </Suspense>
            </Dialog.Content>
        </Dialog.Root>
    );
}

function EditTaskToolsDialogContent(props: Props & { setOpen: (open: boolean) => void }) {
    const { data: task } = useSuspenseQuery(queries.task.detail(props.taskId));
    const { data: jobDescriptionOptions } = useSuspenseQuery(queries.jobDescriptionOptions.list());
    const [step, setStep] = useState<Steps>("initial");

    const toolsOptionsFromBackend = jobDescriptionOptions.tools ?? [];
    const toolsOptionsFromTask = task.tools ?? [];

    const [toolOptions, setToolOptions] = useState(() => {
        const mergedEquipmentOptions = [
            // we want task tools to be on top of the list
            ...toolsOptionsFromTask,
            ...toolsOptionsFromBackend,
        ];

        // remove duplicates by ids
        return Object.values(
            normalizeTools(
                mergedEquipmentOptions.filter(
                    (tool, index, self) => index === self.findIndex((eq) => eq.id === tool.id),
                ),
            ) as Tool[],
        );
    });

    const form = useForm<Inputs>({
        mode: "onChange",
        resolver: zodResolver(schema),
        defaultValues: {
            tools: toolsOptionsFromTask,
        },
    });

    return (
        <FormProvider {...form}>
            {step === "initial" ? (
                <InitialStep
                    setOpen={props.setOpen}
                    setStep={setStep}
                    taskId={props.taskId}
                    tracker={props.tracker}
                    toolOptions={toolOptions}
                />
            ) : step === "addCustomTool" ? (
                <AddCustomToolStep setStep={setStep} setEquipmentOptions={setToolOptions} />
            ) : null}
        </FormProvider>
    );
}

interface InitialStepProps {
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    setStep: React.Dispatch<React.SetStateAction<"initial" | "addCustomTool">>;
    tracker: () => void;
    taskId: string;
    toolOptions: Tool[];
}

function InitialStep(props: InitialStepProps) {
    const queryClient = useQueryClient();

    const {
        control,
        handleSubmit,
        formState: { defaultValues, isValid },
        reset,
        watch,
    } = useFormContext<Inputs>();

    const isDirty = !isEqual(
        watch("tools").sort((a, b) => (a?.id && b?.id ? a.id.localeCompare(b.id) : 0)),
        defaultValues?.tools?.sort((a, b) => (a?.id && b?.id ? a.id.localeCompare(b.id) : 0)),
    );

    const { patchTask, isPending } = usePatchTask({
        onSuccess: (data) => {
            queryClient.setQueryData(queries.task.detail(props.taskId).queryKey, data);
            props.tracker();

            reset({
                tools: data?.tools ?? [],
            });

            props.setOpen(false);
            toast.success(i18n.task_edit_success_toast());
        },
        onSettled: () => {
            queryClient.invalidateQueries({ queryKey: ["task"] });
        },
    });

    const onSubmit: SubmitHandler<Inputs> = async (data) => {
        //remove ids from tools that are issued by frontend
        const toolsToSubmit = data.tools.map((tool) => {
            if (tool.issuedByFrontEnd) {
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                const { id, ...rest } = tool;
                return rest;
            }
            return tool;
        });
        patchTask({ id: props.taskId, tools: toolsToSubmit });
    };

    const { tools } = watch();

    return (
        <form onSubmit={handleSubmit(onSubmit)} className='contents'>
            <Dialog.ScrollArea>
                <Dialog.Header>
                    <Dialog.Title>{i18n.job_descriptions_summary_tools_title()}</Dialog.Title>
                    <Dialog.Description>
                        {i18n.job_descriptions_creation_skills_tools_subtitle()}
                    </Dialog.Description>
                </Dialog.Header>
                <Dialog.Main className='flex flex-col gap-2'>
                    {props.toolOptions.map((toolOption) => {
                        const checked = tools.some((tool) => tool.name === toolOption.name);
                        const levelOptions = toolOption.levels
                            ?.map((level) => ({
                                id: level.id,
                                label: i18nDK(level.label),
                                value: level.value,
                            }))
                            .sort((a, b) => {
                                const order = ["beginner", "intermediate", "advanced", "expert"];
                                return order.indexOf(a.value) - order.indexOf(b.value);
                            });

                        const excelFeedback = i18n
                            .job_descriptions_creation_skills_tools_modal_excel_feedback()
                            .split("- ")
                            .filter((e) => e);

                        return (
                            <div
                                key={toolOption.id}
                                className='group flex flex-col rounded border border-gray-200 hover:border-blue-500'
                            >
                                <Input.Root>
                                    <Input.Label className='flex w-full flex-row items-center gap-4 p-4 !typography-body-m-semibold'>
                                        <Controller
                                            control={control}
                                            name='tools'
                                            render={({ field }) => {
                                                return (
                                                    <Input.Element>
                                                        <Checkbox
                                                            className='group-hover:border-blue-500'
                                                            checked={checked}
                                                            onCheckedChange={(checked) => {
                                                                if (checked) {
                                                                    field.onChange([
                                                                        ...tools,
                                                                        {
                                                                            ...toolOption,
                                                                            ...(toolOption.name ===
                                                                            "TO_EXCEL"
                                                                                ? {
                                                                                      level: "intermediate",
                                                                                  }
                                                                                : {}),
                                                                        },
                                                                    ]);
                                                                } else {
                                                                    field.onChange(
                                                                        tools.filter(
                                                                            (tool) =>
                                                                                tool.name !==
                                                                                toolOption.name,
                                                                        ),
                                                                    );
                                                                }
                                                            }}
                                                        />
                                                    </Input.Element>
                                                );
                                            }}
                                        />
                                        {i18nDK(toolOption.name)}
                                    </Input.Label>
                                </Input.Root>
                                {levelOptions && checked ? (
                                    <div className='p-4 pt-0'>
                                        <Controller
                                            control={control}
                                            name='tools'
                                            render={({ field }) => {
                                                return (
                                                    <Select
                                                        size='small'
                                                        placeholder={i18n.task_edit_custom_equipment_providedBy_placeholder()}
                                                        aria-label={i18n.job_descriptions_creation_conditions_equipments_security_modal_label()}
                                                        options={levelOptions}
                                                        selection={
                                                            levelOptions.find(
                                                                (option) =>
                                                                    option.value ===
                                                                    tools.find(
                                                                        (tool) =>
                                                                            tool.name ===
                                                                            toolOption.name,
                                                                    )?.level,
                                                            ) ?? null
                                                        }
                                                        onChange={(option) => {
                                                            if (!option) return;
                                                            field.onChange(
                                                                tools.map((tool) => {
                                                                    if (
                                                                        tool.name ===
                                                                        toolOption.name
                                                                    ) {
                                                                        return {
                                                                            ...tool,
                                                                            level: option.value,
                                                                            id: option.id,
                                                                        };
                                                                    }
                                                                    return tool;
                                                                }),
                                                            );
                                                        }}
                                                    />
                                                );
                                            }}
                                        />
                                    </div>
                                ) : null}
                                {toolOption.name === "TO_EXCEL" && checked ? (
                                    <div className='p-4 pt-0 text-gray-600 typography-body-m-regular'>
                                        <ul className='list-disc pl-4'>
                                            {excelFeedback.map((feedback) => (
                                                <li key={feedback}>
                                                    <span>{`${feedback.split(": ")[0]}: `}</span>
                                                    {feedback.split(": ")[1]}
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                ) : null}
                            </div>
                        );
                    })}
                </Dialog.Main>
            </Dialog.ScrollArea>
            <Dialog.Footer className='justify-between'>
                <Button
                    type='button'
                    shape='outlined'
                    icon={<Plus />}
                    iconDisposition='left'
                    onClick={() => {
                        props.setStep("addCustomTool");
                    }}
                >
                    {i18n.job_descriptions_creation_job_subtasks_custom_add_button()}
                </Button>
                <div className='flex gap-2'>
                    <Dialog.Close asChild>
                        <Button type='button' shape='invisible' intention='secondary'>
                            {i18n.action_cancel()}
                        </Button>
                    </Dialog.Close>
                    <Button loading={isPending} disabled={!isDirty || !isValid}>
                        {i18n.save()}
                    </Button>
                </div>
            </Dialog.Footer>
        </form>
    );
}

interface addCustomToolStepProps {
    setStep: React.Dispatch<React.SetStateAction<"initial" | "addCustomTool">>;
    setEquipmentOptions: React.Dispatch<React.SetStateAction<Tool[]>>;
}

function AddCustomToolStep(props: addCustomToolStepProps) {
    const addCustomToolFormSchema = z.object({
        name: z.string().max(50),
    });
    type addCustomToolInputs = z.infer<typeof addCustomToolFormSchema>;

    const {
        register,
        handleSubmit,
        formState: { isDirty, isValid },
        reset,
    } = useForm<addCustomToolInputs>({
        mode: "onChange",
        resolver: zodResolver(addCustomToolFormSchema),
        defaultValues: {
            name: "",
        },
    });

    // initial form
    const { setValue: setInitialFormValue, watch: initialFormWatch } = useFormContext<Inputs>();

    const onSubmit: SubmitHandler<addCustomToolInputs> = async (data) => {
        const newTool = {
            id: crypto.randomUUID(),
            issuedByFrontEnd: true,
            custom: true,
            ...data,
        } as const;
        props.setEquipmentOptions((prev) => {
            return [newTool, ...prev];
        });
        setInitialFormValue("tools", [newTool, ...initialFormWatch("tools")]);
        reset();
        props.setStep("initial");
    };

    return (
        <form className='contents' onSubmit={handleSubmit(onSubmit)}>
            <Dialog.Header>
                <Dialog.Title className='flex items-center gap-2'>
                    <Button
                        shape='outlined'
                        intention='secondary'
                        onClick={() => props.setStep("initial")}
                        iconDisposition='icon-only'
                        icon={<ArrowLeft />}
                    />
                    {i18n.task_edit_custom_equipment_title()}
                </Dialog.Title>
                <Dialog.Description>
                    {i18n.job_descriptions_creation_skills_tools_custom_modal_tip()}
                </Dialog.Description>
            </Dialog.Header>
            <Dialog.Main className='flex flex-col gap-4'>
                <Input.Root>
                    <Input.Label>{i18n.task_edit_tools_custom_label()}</Input.Label>
                    <Input.Element>
                        <TextField.Root
                            placeholder={i18n.job_descriptions_creation_skills_tools_custom_modal_placeholder()}
                            required
                            autoComplete='off'
                            maxLength={50}
                            {...register("name")}
                        />
                    </Input.Element>
                </Input.Root>
            </Dialog.Main>
            <Dialog.Footer className='justify-end'>
                <div className='flex gap-2'>
                    <Dialog.Close asChild>
                        <Button type='button' shape='invisible' intention='secondary'>
                            {i18n.action_cancel()}
                        </Button>
                    </Dialog.Close>
                    <Button disabled={!isDirty || !isValid}>{i18n.add()}</Button>
                </div>
            </Dialog.Footer>
        </form>
    );
}
