import { useState } from "react";
import {
    differenceInCalendarDays,
    formatISO,
    getDate,
    getHours,
    getMinutes,
    isEqual,
    isSameDay,
    isWithinInterval,
    set,
    startOfDay,
} from "date-fns";
import { fr } from "date-fns/locale";
import { ModalSimple, ModalsService, RawDatePicker as DatePicker, Tip } from "side-ui";

import { i18n } from "@lib/i18n";

import { getConsecutiveDates, sortByDateASC } from "../../utils";

import "./ShiftDuplicationModal.scss";

const ShiftDuplicationModal = ({ shift, holidays, handleShiftDuplication }) => {
    // add currentShift's date to the selection BUT convert it to the startOfDay
    // to be able to compare it with other selected dates
    const [dates, setDates] = useState(
        shift.startDate ? [startOfDay(new Date(shift.startDate))] : [],
    );

    // add date to the array or filter it if already selected
    const handleDateSelection = (selectedDate) => {
        if (dates.find((d) => isEqual(d, selectedDate))) {
            setDates((prevState) => prevState.filter((d) => !isEqual(d, selectedDate)));
            return;
        }
        setDates((prevState) => prevState.concat(selectedDate));
    };

    const handleSave = () => {
        if (dates?.length) {
            const updatedDates = dates
                .filter((d) => !isEqual(d, startOfDay(new Date(shift.startDate))))
                .map((selectedDate) => ({
                    ...shift,
                    // set date of selectedDate with hours and minutes of the shift
                    // being "duplicated"
                    startDate: formatISO(
                        set(selectedDate, {
                            hours: getHours(shift.startDate),
                            minutes: getMinutes(shift.startDate),
                        }),
                    ),
                    endDate: formatISO(
                        set(selectedDate, {
                            hours: getHours(shift.endDate),
                            minutes: getMinutes(shift.endDate),
                            // below handles case when start/end spans accross 2 days
                            // will either add 1 to the date or add 0 if no differenceInCalendarDays
                            date:
                                getDate(selectedDate) +
                                differenceInCalendarDays(shift.endDate, shift.startDate),
                        }),
                    ),
                }));

            handleShiftDuplication(updatedDates);
        }
        ModalsService.closeModal(`SHIFTS_DUPLICATION`);
    };

    const renderSelectedDates = () => {
        if (!dates?.length) return [];
        return {
            "react-datepicker--multiple-selection": dates,
        };
    };

    const computedHolidays = holidays?.map((holiday) => ({
        startDate: startOfDay(new Date(holiday.startDate)),
        solidarity: holiday.solidarity,
    }));

    let holidaySelected = false;
    if (dates?.length) {
        holidaySelected = dates.some(
            // check in holiday
            // but ensure it's not "jour de solidarité"
            (d) =>
                computedHolidays
                    .filter(({ solidarity }) => !solidarity)
                    .find((holiday) => isEqual(holiday.startDate, d)),
        );
    }

    const sortedDates = sortByDateASC(dates);
    // Check for holidays
    const interval = { start: sortedDates[0], end: sortedDates[sortedDates.length - 1] };
    // Holidays in period
    const holidaysInPeriod = computedHolidays.filter(
        (holiday) => isWithinInterval(new Date(holiday.startDate), interval) && !holiday.solidarity,
    );
    // Check if there is no shift for holidaysInPeriod
    const noShiftForHolidaysInPeriodCheck = !dates.some((date) =>
        holidaysInPeriod.find((holiday) => isSameDay(new Date(date), new Date(holiday.startDate))),
    );

    return (
        <ModalSimple
            action={handleSave}
            withCloseButton={true}
            disableButton={!dates?.length}
            cancelFunction={() => ModalsService.closeModal(`SHIFTS_DUPLICATION`)}
            cancelLabel={i18n.cancel()}
            validateLabel={i18n.save()}
            title={i18n.duplicate()}
            subtitle={i18n.shifts_duplicate_modal_subtitle()}
            hideModal={() => ModalsService.closeModal(`SHIFTS_DUPLICATION`)}
        >
            <div className='shifts-duplication'>
                <DatePicker
                    label=''
                    id='duplication'
                    key='duplication'
                    minDate={new Date()}
                    selected={null}
                    onChange={(selectedDate) => {
                        handleDateSelection(selectedDate);
                    }}
                    locale={fr}
                    multipleSelect={true}
                    inline={true}
                    calendarClassName='datepicker'
                    disabledKeyboardNavigation={true}
                    highlightDates={[
                        {
                            "react-datepicker--holidays": computedHolidays.map(
                                ({ startDate }) => startDate,
                            ),
                        },
                    ].concat(renderSelectedDates() as any)}
                    excludeDates={[new Date(shift?.startDate)]}
                />
            </div>

            {holidaysInPeriod.length > 0 && noShiftForHolidaysInPeriodCheck && (
                <div className='shifts-duplication__tip-container'>
                    <Tip
                        isWarning={true}
                        content={i18n.order_summary_shifts_warning_holiday_interval()}
                    />
                </div>
            )}

            {holidaySelected && (
                <div className='shifts-duplication__tip-container'>
                    <Tip isWarning={true} content={i18n.shifts_warning_public_holiday_selected()} />
                </div>
            )}

            {/* check if dates are consecutives to display warning */}
            {getConsecutiveDates(sortByDateASC(dates)).find((dateArr) => dateArr.length > 6) ? (
                <div className='shifts-duplication__tip-container'>
                    <Tip isWarning={true} content={i18n.shifts_warning_7_consecutive()} />
                </div>
            ) : null}
        </ModalSimple>
    );
};

export default ShiftDuplicationModal;
