import { useMemo, useRef } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { toast } from "saphir";
import { ArrowForward, Button, LockClosed, TextField, Tip, User as UserIcon } from "sui";
import { z } from "zod";

import { queryClient } from "@App";
import { zodResolver } from "@hookform/resolvers/zod";
import { patchProfile } from "@lib/api";
import { i18nDK } from "@lib/i18n";
import { i18n } from "@lib/i18n";
import { queries } from "@lib/queries";
import { User } from "@store/types/user";
import { useMutation, useSuspenseQuery } from "@tanstack/react-query";

import SaveBar from "../Settings/components/partials/SaveBar";

import { PasswordModal } from "./PasswordModal";

export function UserProfile() {
    const { data: user } = useSuspenseQuery(queries.user.detail());
    const paswwordModalRef = useRef<HTMLDialogElement>(null);
    const cantEditPasswordMessage = localStorage.getItem("side_team_logasId")
        ? i18n.warning_is_log_as()
        : localStorage.getItem("google_login") === "true"
          ? i18n.warning_is_google_login()
          : "";

    return (
        <>
            <div className='flex h-full w-full justify-center'>
                <div className='w-full max-w-[560px] self-center rounded bg-white pt-10 text-[var(--sui-grey-900)]'>
                    <h1 className='mb-6 px-10 text-[40px] typography-heading-xl-semibold'>
                        {i18n.personal_informations()}
                    </h1>
                    <div className='mb-10 px-10'>
                        <h2 className='flex gap-4 typography-heading-m-semibold'>
                            <div className='mb-4 flex h-8 w-8 items-center justify-center rounded-full bg-blue-50 text-blue-700'>
                                <UserIcon />
                            </div>
                            {i18n.personal_informations_general_information_title()}
                        </h2>
                        <p className='mb-6 text-lg leading-6 text-gray-400'>
                            {i18n.personal_informations_general_information_description()}
                        </p>
                        <UserProfileForm user={user} />
                    </div>
                    <div className='mb-10 border-t border-gray-100 px-10 pt-10'>
                        <h2 className='flex gap-4 typography-heading-m-semibold'>
                            <div className='mb-6 flex h-8 w-8 items-center justify-center rounded-full bg-blue-50 text-blue-700'>
                                <LockClosed />
                            </div>
                            {i18n.personal_informations_password_title()}
                        </h2>
                        {cantEditPasswordMessage && (
                            <Tip className='mb-4' intention='error'>
                                {cantEditPasswordMessage}
                            </Tip>
                        )}
                        <Button
                            disabled={!!cantEditPasswordMessage}
                            iconDisposition='right'
                            icon={<ArrowForward />}
                            onClick={() => paswwordModalRef.current?.showModal()}
                        >
                            {i18n.personal_informations_password_button()}
                        </Button>
                    </div>
                </div>
            </div>
            {i18nDK("personal_informations_error_incorrect_password_length")}
            <PasswordModal ref={paswwordModalRef} user={user} />
        </>
    );
}

function UserProfileForm({ user }: { user: User }) {
    const schema = useMemo(() => {
        return z.object({
            firstName: z.string().min(1, { message: i18n.error_field_is_required() }),
            lastName: z.string().min(1, { message: i18n.error_field_is_required() }),
            email: z.string().min(1, { message: i18n.error_field_is_required() }).email(),
            phone: z
                .string()
                .min(1, { message: i18n.error_field_is_required() })
                .regex(/[0-9 ]{6,15}/, {
                    message: i18n.personal_informations_error_invalid_phone(),
                }),
        });
    }, []);
    type Inputs = z.infer<typeof schema>;

    const {
        register,
        handleSubmit,
        reset,
        formState: { isDirty, errors },
    } = useForm<Inputs>({
        mode: "onBlur",
        resolver: zodResolver(schema),
        defaultValues: {
            firstName: user.firstName || "",
            lastName: user.lastName || "",
            email: user.email || "",
            phone: user.phoneNumber || "",
        },
    });

    const { mutate: updateProfile, isPending } = useMutation({
        mutationFn: patchProfile,
        onSuccess: (data, variables) => {
            toast.success(i18n.personal_informations_profile_saved());
            reset({
                firstName: variables.firstName,
                lastName: variables.lastName,
                email: variables.email,
                phone: variables.phone,
            });
        },
        onSettled: () => {
            queryClient.invalidateQueries(queries.user.detail());
        },
    });

    const onSubmit: SubmitHandler<Inputs> = async (data) => {
        updateProfile(data);
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className='flex flex-col gap-4'>
                <div className='flex gap-3'>
                    <TextField
                        {...register("firstName")}
                        className='flex-grow'
                        label={i18n.personal_informations_firstname()}
                        placeholder={i18n.personal_informations_firstname_placeholder()}
                        size='small'
                        error={errors.firstName?.message}
                    />
                    <TextField
                        {...register("lastName")}
                        className='flex-grow'
                        label={i18n.personal_informations_lastname()}
                        placeholder={i18n.personal_informations_lastname_placeholder()}
                        size='small'
                        error={errors.lastName?.message}
                    />
                </div>
                <div className='flex gap-3'>
                    <TextField
                        {...register("email")}
                        className='flex-grow'
                        label={i18n.personal_informations_email()}
                        placeholder={i18n.personal_informations_email_placeholder()}
                        size='small'
                        error={errors.email?.message}
                    />
                    <TextField
                        {...register("phone")}
                        className='flex-grow'
                        label={i18n.personal_informations_phone()}
                        placeholder={i18n.personal_informations_phone_placeholder()}
                        size='small'
                        error={errors.phone?.message}
                    />
                </div>
            </div>
            {/* SAVE BAR */}
            {isDirty ? (
                <SaveBar
                    resetAction={null}
                    saveAction={handleSubmit(onSubmit)}
                    canSave={!(errors.firstName || errors.lastName || errors.email || errors.phone)}
                    loading={isPending}
                />
            ) : null}
        </form>
    );
}
