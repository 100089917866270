import { useState } from "react";
import { AlertCircle, Search } from "lucide-react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { Checkbox, Dialog, Input, TextField, toast } from "saphir";
import { Button, Select } from "sui";
import { z } from "zod";

import { zodResolver } from "@hookform/resolvers/zod";
import { LanguageSchema } from "@lib/api";
import { i18n, i18nDK } from "@lib/i18n";
import { usePatchTask } from "@lib/mutations/usePatchTask";
import { queries } from "@lib/queries";
import { normalizeLanguages, sortLanguagesByLevel } from "@lib/queries/normalizeJobDescriptions";
import { useQueryClient, useSuspenseQuery } from "@tanstack/react-query";

interface Props {
    taskId: string;
    trigger: React.ReactNode;
    tracker: () => void;
}

const commonLanguages = ["fre", "eng", "ger", "spa", "ara", "ita", "por"];

export function EditTaskLanguagesDialog(props: Props) {
    const [open, setOpen] = useState(false);

    return (
        <Dialog.Root open={open} onOpenChange={setOpen}>
            <Dialog.Trigger asChild>{props.trigger}</Dialog.Trigger>
            <Dialog.Content>
                <EditTaskLanguagesDialogContent {...props} setOpen={setOpen} />
            </Dialog.Content>
        </Dialog.Root>
    );
}

function EditTaskLanguagesDialogContent(props: Props & { setOpen: (open: boolean) => void }) {
    const { data: task } = useSuspenseQuery(queries.task.detail(props.taskId));
    const { data: jobDescriptionLanguagesOptions = {} } = useSuspenseQuery({
        ...queries.jobDescriptionOptions.list(),
        select: (data) => normalizeLanguages(data.languages),
    });

    const [search, setSearch] = useState("");

    const languageOptions = Object.values(jobDescriptionLanguagesOptions)
        .sort((a, b) => {
            // Check if each language option exists in the task's languages
            const aIsTaskLanguage = task.languages?.some(
                (language) => language.ISOCode === a.label,
            );
            const bIsTaskLanguage = task.languages?.some(
                (language) => language.ISOCode === b.label,
            );

            // Check if each language option is in the list of common languages
            const aIsCommonLanguage = commonLanguages.includes(a.label);
            const bIsCommonLanguage = commonLanguages.includes(b.label);

            // Case 1: Both `a` and `b` are task languages
            if (aIsTaskLanguage && bIsTaskLanguage) {
                // If both are also common languages, sort them by their priority order in `commonLanguages`
                if (aIsCommonLanguage && bIsCommonLanguage) {
                    return commonLanguages.indexOf(a.label) - commonLanguages.indexOf(b.label);
                }
                // If they are not both common, sort alphabetically by their labels
                return a.label.localeCompare(b.label);
            }

            // Case 2: Only `a` is a task language, so it has higher priority and comes first
            if (aIsTaskLanguage) {
                return -1;
            }

            // Case 3: Only `b` is a task language, so it has higher priority and comes first
            if (bIsTaskLanguage) {
                return 1;
            }

            // Case 4: Both `a` and `b` are common languages but not task languages
            if (aIsCommonLanguage && bIsCommonLanguage) {
                // Sort them by their priority order in `commonLanguages`
                return commonLanguages.indexOf(a.label) - commonLanguages.indexOf(b.label);
            }

            // Case 5: Only `a` is a common language, so it comes first
            if (aIsCommonLanguage) {
                return -1;
            }

            // Case 6: Only `b` is a common language, so it comes first
            if (bIsCommonLanguage) {
                return 1;
            }

            // Case 7: Neither `a` nor `b` are task or common languages, so sort them alphabetically
            return a.label.localeCompare(b.label);
        })
        .filter((language) => i18nDK(language.label).toLowerCase().includes(search.toLowerCase()));

    const schema = z.object({
        languages: z.array(LanguageSchema),
    });

    type Inputs = z.infer<typeof schema>;

    const {
        control,
        handleSubmit,
        formState: { isDirty, isValid },
        reset,
        watch,
    } = useForm<Inputs>({
        mode: "onBlur",
        resolver: zodResolver(schema),
        defaultValues: {
            languages: task?.languages ?? [],
        },
    });

    const queryClient = useQueryClient();

    const { patchTask, isPending } = usePatchTask({
        onSuccess: (data) => {
            queryClient.setQueryData(queries.task.detail(props.taskId).queryKey, data);
            props.tracker();
            reset({
                languages: data?.languages ?? [],
            });
            props.setOpen(false);
            toast.success(i18n.task_edit_success_toast());
        },
        onSettled: () => {
            queryClient.invalidateQueries({ queryKey: ["task"] });
        },
    });

    const onSubmit: SubmitHandler<Inputs> = async (data) => {
        patchTask({
            id: props.taskId,
            languageIds: data.languages.map((language) => language.id),
        });
    };

    const { languages } = watch();

    return (
        <form onSubmit={handleSubmit(onSubmit)} className='contents'>
            <Dialog.ScrollArea>
                <Dialog.Header>
                    <Dialog.Title>
                        {i18n.job_descriptions_creation_skills_languages_modal_title()}
                    </Dialog.Title>
                    <Dialog.Description>
                        {i18n.job_descriptions_creation_skills_languages_subtitles()}
                    </Dialog.Description>
                </Dialog.Header>
                <Dialog.Main className='flex flex-col gap-6'>
                    <TextField.Root
                        placeholder={i18n.search()}
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                        autoComplete='off'
                    >
                        <TextField.Slot>
                            <Search />
                        </TextField.Slot>
                    </TextField.Root>
                    <div className='flex flex-col gap-2'>
                        {languageOptions.length === 0 ? (
                            <div className='rounded border border-gray-50 p-6'>
                                <div className='flex flex-col items-center justify-center gap-2'>
                                    <div className='grid h-10 w-10 place-items-center rounded-full bg-blue-50'>
                                        <AlertCircle className='h-5 w-5 text-blue-500' />
                                    </div>
                                    <div className='text-center text-gray-700 typography-body-m-regular'>
                                        <p>{i18n.no_result()}</p>
                                        <p>{i18n.task_edit_languages_no_result()}</p>
                                    </div>
                                </div>
                            </div>
                        ) : null}
                        {languageOptions.map((languageOption) => {
                            const checked = languageOption.levels.some((level) =>
                                languages.some((language) => language.id === level.id),
                            );

                            const levelsOptions = sortLanguagesByLevel(languageOption.levels).map(
                                (level) => ({
                                    id: level.id,
                                    label: i18nDK(level.label),
                                    value: level.value,
                                    name: level.name,
                                }),
                            );

                            return (
                                <div
                                    key={languageOption.id}
                                    className='group flex flex-col rounded border border-gray-200 hover:border-blue-500'
                                >
                                    <Input.Root>
                                        <Input.Label className='flex w-full flex-row items-center gap-4 p-4 !typography-body-m-semibold'>
                                            <Controller
                                                control={control}
                                                name='languages'
                                                render={({ field }) => {
                                                    return (
                                                        <Input.Element>
                                                            <Checkbox
                                                                className='group-hover:border-blue-500'
                                                                checked={checked}
                                                                onCheckedChange={(checked) => {
                                                                    if (checked) {
                                                                        const newLanguage =
                                                                            languageOption.levels.find(
                                                                                (level) =>
                                                                                    level.value ===
                                                                                    "intermediate",
                                                                            );
                                                                        if (newLanguage) {
                                                                            field.onChange([
                                                                                ...field.value,
                                                                                {
                                                                                    ISOCode:
                                                                                        newLanguage.label,
                                                                                    id: newLanguage.id,
                                                                                    level: newLanguage.value,
                                                                                },
                                                                            ]);
                                                                        }
                                                                    } else {
                                                                        field.onChange(
                                                                            field.value.filter(
                                                                                (language) =>
                                                                                    !languageOption.levels.some(
                                                                                        (level) =>
                                                                                            level.id ===
                                                                                            language.id,
                                                                                    ),
                                                                            ),
                                                                        );
                                                                    }
                                                                }}
                                                            />
                                                        </Input.Element>
                                                    );
                                                }}
                                            />
                                            {i18nDK(languageOption.label)}
                                        </Input.Label>
                                    </Input.Root>
                                    {checked ? (
                                        <div className='p-4 pt-0'>
                                            <Controller
                                                control={control}
                                                name='languages'
                                                render={({ field }) => {
                                                    return (
                                                        <Select
                                                            size='small'
                                                            placeholder={i18n.task_edit_custom_equipment_providedBy_placeholder()}
                                                            aria-label={i18n.job_descriptions_creation_conditions_equipments_security_modal_label()}
                                                            options={levelsOptions}
                                                            selection={levelsOptions.find((level) =>
                                                                field.value.some(
                                                                    (language) =>
                                                                        language.id === level.id,
                                                                ),
                                                            )}
                                                            onChange={(option) => {
                                                                if (!option) return;
                                                                const newLanguage =
                                                                    languageOption.levels.find(
                                                                        (level) =>
                                                                            level.id === option.id,
                                                                    );
                                                                if (!newLanguage) return;
                                                                // Only update if option is valid
                                                                field.onChange([
                                                                    ...field.value.filter(
                                                                        (language) =>
                                                                            !languageOption.levels.some(
                                                                                (level) =>
                                                                                    level.id ===
                                                                                    language.id,
                                                                            ),
                                                                    ),
                                                                    {
                                                                        ISOCode: newLanguage.label,
                                                                        id: newLanguage.id,
                                                                        level: newLanguage.value,
                                                                    },
                                                                ]);
                                                            }}
                                                        />
                                                    );
                                                }}
                                            />
                                        </div>
                                    ) : null}
                                </div>
                            );
                        })}
                    </div>
                </Dialog.Main>
            </Dialog.ScrollArea>
            <Dialog.Footer className='justify-end'>
                <div className='flex gap-2'>
                    <Dialog.Close asChild>
                        <Button type='button' shape='invisible' intention='secondary'>
                            {i18n.action_cancel()}
                        </Button>
                    </Dialog.Close>
                    <Button loading={isPending} disabled={!isDirty || !isValid}>
                        {i18n.save()}
                    </Button>
                </div>
            </Dialog.Footer>
        </form>
    );
}
