import { useEffect, useRef, useState } from "react";

import { i18n } from "@lib/i18n";

const CompanyMembersSearchResults = ({ selectItem, companyMembers, searchTerm, touched }) => {
    const searchRef = useRef<HTMLDivElement>(null);
    const [isOpen, setIsOpen] = useState(Boolean(searchTerm) && touched);

    const handleClickOutside = (event) => {
        const { target } = event;

        if (!searchRef.current) {
            return;
        }
        if (!searchRef.current.contains(target)) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        if (companyMembers.length && searchTerm.length) {
            setIsOpen(true);
        }
    }, [companyMembers, searchTerm]);

    useEffect(() => {
        document.addEventListener("click", handleClickOutside);

        return () => {
            document.removeEventListener("click", handleClickOutside);
        };
    });

    return isOpen ? (
        <div className='timesheets__filters__search__results__wrapper' ref={searchRef}>
            <ul className='timesheets__filters__search__results'>
                {companyMembers && companyMembers.length > 0 ? (
                    companyMembers.map(({ _id, firstName, lastName }) => (
                        <li className='timesheets__filters__search__results__item' key={_id}>
                            <button
                                type='button'
                                onClick={() => {
                                    selectItem(_id, `${firstName} ${lastName}`);
                                    setIsOpen(false);
                                }}
                                className='timesheets__filters__search__results__item__button'
                            >
                                <span className='timesheets__filters__search__results__item__member'>
                                    <span
                                        className='timesheets__filters__search__results__item__member__name'
                                        title={`${firstName} ${lastName}`}
                                    >
                                        {firstName}
                                        &nbsp;
                                        {lastName}
                                    </span>
                                </span>
                            </button>
                        </li>
                    ))
                ) : (
                    <li // eslint-disable-line
                        className='timesheets__filters__search__results__item timesheets__filters__search__results__item--blank'
                    >
                        {i18n.ts_filters_search_item_empty()}
                    </li>
                )}
            </ul>
        </div>
    ) : null;
};

export default CompanyMembersSearchResults;
