import { useState } from "react";
import { BlockFeedback, InputText, ModalSimple, ModalsService } from "side-ui";

import { i18n } from "@lib/i18n";
import { queries } from "@lib/queries";
import trackEvent from "@lib/trackers";
import { useQuery } from "@tanstack/react-query";

import "./DuplicateModal.scss";

function DuplicateModal({ taskJobtypeTranslatedName, duplicateTask, taskName }) {
    const { data: company } = useQuery(queries.company.detail());
    const [error, setError] = useState();
    const [value, setValue] = useState(
        // Split suffix from the Job Title
        taskName.includes("(F/H)")
            ? taskName.split("(F/H) - ")[1]
            : (taskName.split(" - ")[1] ?? ""),
    );

    function sendForm(event) {
        if (event.key === `Enter`) {
            event.preventDefault();
            submitForm();
        }
    }

    function submitForm() {
        const assembledTaskName = `${taskJobtypeTranslatedName} - ${value}`;
        trackEvent({
            name: `jobposting - added custom name`,
            params: {
                duplicated: true,
            },
        });

        trackEvent({
            name: `jobposting - started posting task`,
            params: {
                duplicated: true,
                translatedJobType: taskJobtypeTranslatedName,
                organisationId: localStorage.getItem(`side_team_activeOrganisationId`),
            },
        });

        duplicateTask({
            taskName: !value ? taskJobtypeTranslatedName : assembledTaskName,
            attendanceConfigType: company.organisation.attendanceConfigType,
        });
        setError(undefined);
    }

    return (
        <ModalSimple
            action={submitForm}
            validateLabel={i18n.duplicate()}
            cancelLabel={i18n.cancel()}
            title={i18n.modal_duplicate_title()}
            hideModal={() => ModalsService.closeModal(`DUPLICATE_TASK`)}
        >
            <p className='duplicate-modal__description'>{i18n.modal_duplicate_description()}</p>
            <div className='name-modal'>
                <BlockFeedback
                    type='neutral'
                    content={i18n.modal_rename_description()}
                    withIcon={false}
                />
                <form>
                    <p className='name-modal__label'>{i18n.modal_rename_field_label()}</p>
                    <div className='name-modal__field'>
                        <InputText
                            name='nameTask'
                            id='duplicate_task'
                            placeholder={i18n.modal_rename_field_placeholder()}
                            smallInput={true}
                            onKeyPress={sendForm}
                            error={error}
                            onChange={(event) => setValue(event.target.value)}
                            value={value}
                        />
                    </div>
                </form>
                <p className='name-modal__tip'>{i18n.modal_rename_field_tip()}</p>
            </div>
        </ModalSimple>
    );
}

export default DuplicateModal;
