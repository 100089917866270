import { ExpandableCheckboxPanel } from "side-ui";

import { i18n } from "@lib/i18n";

import { onFieldChange } from "./onFieldChange";

const ZTICondition = (props) => {
    const { field, form } = props;
    const { setFieldValue } = form;

    return (
        <div className='other-parameters__field'>
            <ExpandableCheckboxPanel
                checked={field.value.checked}
                label={i18n.settings_remuneration_indemnities_other_other_parameters_zti()}
                onChange={() => {
                    onFieldChange(
                        "zti",
                        "checked",
                        !field.value.checked,
                        form.values.otherParameters,
                        setFieldValue,
                    );
                }}
            />
        </div>
    );
};

export { ZTICondition };
