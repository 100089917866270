import { i18n } from "@lib/i18n";
import { clearLogas } from "@lib/utils/clearLogas";
import { rootRoute } from "@routes";
import { allowInsider } from "@routes/authorization";
import { createRoute, redirect } from "@tanstack/react-router";

import LogAs from "./index";

export const logAsRoute = createRoute({
    getParentRoute: () => rootRoute,
    path: `side-admin`,
    component: LogAs,
    beforeLoad: async () => {
        clearLogas();
        const allowed = await allowInsider();
        if (!allowed) {
            throw redirect({ to: `/` });
        }

        return { getTitle: () => i18n.doc_title_logas() };
    },
});
