import { i18n } from "@lib/i18n";
import { globalLayoutRoute } from "@routes";
import { createRoute } from "@tanstack/react-router";

import { Preselection } from "./Preselection";

export const preselectionRoute = createRoute({
    getParentRoute: () => globalLayoutRoute,
    path: `preselection/$taskId`,
    beforeLoad: () => ({ getTitle: () => i18n.doc_title_preselection() }),
    component: Preselection,
});
