import { useState } from "react";
import clsx from "clsx";
import { AlertCircle, Search } from "lucide-react";
import { Checkbox, Dialog, Input, Switch, Tabs, TextField, toast } from "saphir";
import { Button, Loader, Tip } from "sui";

import { i18n } from "@lib/i18n";
import { usePatchTask } from "@lib/mutations/usePatchTask";
import { queries } from "@lib/queries";
import { TABS, useWorkersStep, WorkerLabel } from "@routes/TaskPosting/WorkersStep/WorkersStep";
import { useQueryClient, useSuspenseQuery } from "@tanstack/react-query";

interface Props {
    taskId: string;
    trigger: React.ReactNode;
    tracker: () => void;
}

export function EditTaskWorkersDialog(props: Props) {
    const [open, setOpen] = useState(false);

    return (
        <Dialog.Root open={open} onOpenChange={setOpen}>
            <Dialog.Trigger asChild>{props.trigger}</Dialog.Trigger>
            <Dialog.Content>
                <EditTaskDressCodeDialogContent {...props} setOpen={setOpen} />
            </Dialog.Content>
        </Dialog.Root>
    );
}

function EditTaskDressCodeDialogContent(props: Props & { setOpen: (open: boolean) => void }) {
    const { data: task } = useSuspenseQuery(queries.task.detail(props.taskId));
    const [requestedSidersOnly, setRequestedSidersOnly] = useState(task.requestedSidersOnly);
    const {
        activeTab,
        handleChange,
        isChecked,
        isLoadingSiders,
        numberOfSlots,
        numberOfSelectedWorkers,
        organisationWorkers,
        searchedOrganisationWorkers,
        selectedWorkers,
        selectAllOrganisationSiders,
        selectAllSharedSiders,
        setActiveTab,
        setSelectedWorkers,
        searchInput,
        setSearchInput,
        sharedWorkers,
        tabsContent,
    } = useWorkersStep({ taskId: props.taskId });

    const queryClient = useQueryClient();

    const { patchTask, isPending } = usePatchTask({
        onSuccess: (data) => {
            queryClient.setQueryData(queries.task.detail(props.taskId).queryKey, data);
            props.tracker();
            props.setOpen(false);
            toast.success(i18n.task_edit_success_toast());
        },
        onSettled: () => {
            queryClient.invalidateQueries({ queryKey: ["task"] });
        },
    });

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        patchTask({
            id: props.taskId,
            requestedSiderIds: selectedWorkers
                .map((worker) => worker.id)
                .filter((id): id is string => id !== undefined),
            requestedSidersOnly,
        });
    };

    const sidersSelectionRemainingCount = numberOfSlots - numberOfSelectedWorkers;
    const gestionDisabled = task.type === "gestion" && sidersSelectionRemainingCount > 0;
    const requestedSidersOnlyDisabled = sidersSelectionRemainingCount > 0;

    return (
        <form onSubmit={handleSubmit} className='contents'>
            <Dialog.ScrollArea>
                <Tabs.Root value={activeTab} onValueChange={setActiveTab}>
                    <div className='sticky top-0 z-10 bg-white'>
                        <Dialog.Header>
                            <Dialog.Title>{i18n.order_summary_workers_title()}</Dialog.Title>
                            <Dialog.Description>{i18n.order_workers_subtitle()}</Dialog.Description>
                        </Dialog.Header>
                        <div className='flex flex-col gap-4 px-10'>
                            {requestedSidersOnly ? (
                                <Tip intention='warning'>
                                    {i18n.order_workers_wa_only_modal_warning()}
                                </Tip>
                            ) : null}
                            {gestionDisabled ? (
                                <Tip intention='warning'>
                                    {i18n.order_workers_gestion_amount({
                                        numberOfRemainingSlots: sidersSelectionRemainingCount,
                                    })}
                                </Tip>
                            ) : null}
                            <TextField.Root
                                aria-label={"search-input"}
                                value={searchInput.value}
                                onChange={(e) => setSearchInput(e.target.value)}
                                placeholder={i18n.order_workers_search_placeholder()}
                            >
                                <TextField.Slot>
                                    <Search />
                                </TextField.Slot>
                            </TextField.Root>
                            <div className='flex items-center gap-4'>
                                <div className='p-4'>
                                    <Checkbox
                                        checked={isChecked}
                                        onCheckedChange={
                                            activeTab === TABS.ORGANISATION_SIDERS
                                                ? selectAllOrganisationSiders
                                                : activeTab === TABS.SHARED_SIDERS
                                                  ? selectAllSharedSiders
                                                  : () => setSelectedWorkers([])
                                        }
                                    />
                                </div>
                                <div />
                                <Tabs.List>
                                    <Tabs.Trigger value={TABS.ORGANISATION_SIDERS}>
                                        {i18n.order_workers_list_orga_label({
                                            numberOfOrganisationWorkers:
                                                task?.type === "gestion"
                                                    ? searchedOrganisationWorkers?.length
                                                    : organisationWorkers?.length,
                                        })}
                                    </Tabs.Trigger>
                                    {sharedWorkers?.length ? (
                                        <Tabs.Trigger value={TABS.SHARED_SIDERS}>
                                            {i18n.order_workers_list_network_label({
                                                numberOfSharedWorkers: sharedWorkers?.length,
                                            })}
                                        </Tabs.Trigger>
                                    ) : null}
                                    <Tabs.Trigger value={TABS.SELECTED_SIDERS}>
                                        {i18n.order_workers_list_selected_label({
                                            numberOfSelectedWorkers,
                                        })}
                                    </Tabs.Trigger>
                                </Tabs.List>
                            </div>
                        </div>
                    </div>
                    <Dialog.Main>
                        <div>
                            {isLoadingSiders ? (
                                <div className='grid h-10 place-items-center text-blue-500'>
                                    <Loader />
                                </div>
                            ) : (
                                <>
                                    <div className='h-4' />
                                    {tabsContent.map(({ value, workers }) => (
                                        <Tabs.Content key={value} value={value}>
                                            <div className='flex flex-col gap-2'>
                                                {workers.length ? (
                                                    workers.map((worker) => {
                                                        return (
                                                            <label
                                                                key={worker.id}
                                                                className='flex w-full flex-row items-center gap-4 rounded bg-gray-20 p-4 !typography-body-m-semibold hover:bg-blue-50 hover:text-blue-500'
                                                            >
                                                                <Checkbox
                                                                    checked={selectedWorkers.some(
                                                                        (w) => w.id === worker.id,
                                                                    )}
                                                                    onCheckedChange={() =>
                                                                        handleChange(worker)
                                                                    }
                                                                />
                                                                <WorkerLabel worker={worker} />
                                                            </label>
                                                        );
                                                    })
                                                ) : (
                                                    <div className='rounded border border-gray-50 p-6'>
                                                        <div className='flex flex-col items-center justify-center gap-2'>
                                                            <div className='grid h-10 w-10 place-items-center rounded-full bg-blue-50'>
                                                                <AlertCircle className='h-5 w-5 text-blue-500' />
                                                            </div>
                                                            <div className='text-center text-gray-700 typography-body-m-regular'>
                                                                <p>{i18n.no_result()}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </Tabs.Content>
                                    ))}
                                </>
                            )}
                        </div>
                    </Dialog.Main>
                </Tabs.Root>
            </Dialog.ScrollArea>
            <Dialog.Footer
                className={clsx(
                    "items-center",
                    task.type === "gestion" && "justify-end",
                    task.type === "delegation" && "justify-between",
                )}
            >
                {task.type === "delegation" ? (
                    <Input.Root className='flex-row items-center gap-4'>
                        <Input.Element>
                            <Switch
                                checked={requestedSidersOnly}
                                onCheckedChange={setRequestedSidersOnly}
                                disabled={requestedSidersOnlyDisabled}
                            />
                        </Input.Element>
                        <Input.Label
                            className={clsx(
                                "my-[-4px] !typography-body-m-semibold",
                                requestedSidersOnlyDisabled &&
                                    "text-gray-200 hover:cursor-not-allowed",
                            )}
                        >
                            {i18n.task_edit_workers_wao_label()}
                            {requestedSidersOnlyDisabled ? (
                                <p className='typography-body-s-regular'>
                                    {i18n.task_edit_workers_wao_hint({
                                        numberOfRemainingSlots: sidersSelectionRemainingCount,
                                        plural: sidersSelectionRemainingCount > 1 ? "s" : "",
                                    })}
                                </p>
                            ) : null}
                        </Input.Label>
                    </Input.Root>
                ) : null}
                <div className='flex gap-2'>
                    <Dialog.Close asChild>
                        <Button type='button' shape='invisible' intention='secondary'>
                            {i18n.action_cancel()}
                        </Button>
                    </Dialog.Close>
                    <Button loading={isPending} disabled={gestionDisabled}>
                        {i18n.save()}
                    </Button>
                </div>
            </Dialog.Footer>
        </form>
    );
}
