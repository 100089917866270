import { useEffect } from "react";
import { CheckboxButton, Textarea } from "side-ui";
import { Select } from "side-ui";

import { getJobDescriptionOptions } from "@lib/api";
import { useFormInput } from "@lib/hooks/form";
import { i18n, i18nDK } from "@lib/i18n";
import { queries } from "@lib/queries";
import { useQuery } from "@tanstack/react-query";

import { useTimer } from "../../../hooks/useTimer";

import styles from "./RisksForm.module.css";

export function RisksForm({ aboutRisks = "", setAboutRisks, selectedRisks, setSelectedRisks }) {
    const { formInput: formAboutRisks, handleChange: setFormAboutRisks } = useFormInput(
        aboutRisks,
        () => null,
    );

    const { data: risks = [] } = useQuery({
        ...queries.jobDescriptionOptions.list(),
        select: (data) => {
            return (data?.risks?.reduce((acc, val) => {
                if (!acc[val.name]) {
                    acc[val.name] = [val];
                    return acc;
                }
                acc[val.name].push(val);
                return acc;
            }, {}) || {}) as Record<
                string,
                Awaited<ReturnType<typeof getJobDescriptionOptions>>["risks"][number][]
            >;
        },
    });

    useEffect(() => {
        setFormAboutRisks(aboutRisks);
    }, [aboutRisks]);

    useEffect(() => {
        setAboutRisks(formAboutRisks.value);
    }, [formAboutRisks.value]);

    // same for aboutRisks
    useTimer(
        {
            timing: 1200,
            callback: () => {
                setAboutRisks(formAboutRisks.value);
            },
        },
        [formAboutRisks.value],
    );

    function removeSelectedRisk(name) {
        const filteredRisks = Object.fromEntries(
            Object.entries(selectedRisks)?.filter(([key]) => key !== name),
        );
        setSelectedRisks(filteredRisks);
    }

    function handleCheckboxChange(name) {
        // already checked unset it
        if (Object.keys(selectedRisks)?.includes(name)) {
            return removeSelectedRisk(name);
        }

        return setSelectedRisks({
            ...selectedRisks,
            [name]: [{ name }],
        });
    }

    function handleOptionChange(selectedItem, groupName) {
        if (selectedRisks[groupName]?.includes(groupName)) {
            return setSelectedRisks({
                ...selectedRisks,
                [groupName]: [{ groupName }],
            });
        }

        return setSelectedRisks({
            ...selectedRisks,
            [groupName]: [selectedItem],
        });
    }

    return (
        <div className={styles.risks}>
            <p className={styles.label}>
                {i18n.job_descriptions_creation_conditions_equipments_risks_label1()}
            </p>
            {risks
                ? Object.entries(risks)?.map(([name, options], index) => (
                      <CheckboxButton
                          key={`${name}-${index}`} // eslint-disable-line react/no-array-index-key
                          id={name}
                          name={name}
                          label={i18nDK(name)}
                          checked={Object.keys(selectedRisks)?.includes(name)}
                          secondaryStyle={true}
                          onChange={() => {
                              if (options.length > 1) {
                                  return handleCheckboxChange(name);
                              }

                              // if not more than one choice
                              // either remove it...
                              if (Object.keys(selectedRisks)?.includes(name)) {
                                  return removeSelectedRisk(name);
                              }
                              // ...or add it to the state
                              return setSelectedRisks({
                                  ...selectedRisks,
                                  [name]: [options[0]],
                              });
                          }}
                          value={name}
                      >
                          {/* no need for displaying Select if options is of length 1 */}
                          {Object.keys(selectedRisks)?.includes(name) && options.length > 1 ? (
                              <Select
                                  id={`${name}-${index}`}
                                  name={`${name}-${index}`}
                                  placeholder={i18n.job_descriptions_creation_conditions_equipments_risks_options()}
                                  chevronIcon={true}
                                  onChange={(_, __, selectedItem) =>
                                      handleOptionChange(
                                          options.find(({ id }) => id === selectedItem),
                                          name,
                                      )
                                  }
                                  // provide a formatted copy for options
                                  options={options.map(({ id, value: label }) => ({
                                      id,
                                      value: id,
                                      label: label ? i18nDK(label) : "",
                                  }))}
                                  selectedItem={
                                      options.find(
                                          ({ id }) =>
                                              id === Object.assign({}, ...selectedRisks[name]).id,
                                      )?.value &&
                                      i18nDK(
                                          options.find(
                                              ({ id }) =>
                                                  id ===
                                                  Object.assign({}, ...selectedRisks[name]).id,
                                          )?.value ?? "",
                                      )
                                  }
                              />
                          ) : null}
                      </CheckboxButton>
                  ))
                : null}

            <div className={styles.about}>
                <Textarea
                    id='aboutrisks'
                    label={i18n.job_descriptions_creation_conditions_equipments_risks_label2()}
                    placeholder={i18n.job_descriptions_creation_conditions_equipments_risks_placeholder()}
                    height={120}
                    value={formAboutRisks.value}
                    onChange={setFormAboutRisks}
                    small={false}
                    required={true}
                />
            </div>
        </div>
    );
}
