import fetch from "isomorphic-fetch";

import { handleError } from "@lib/utils/error";

import { withAuthHeaders } from "./apiHelpers";
import { apiConf } from "./index";

type UpdateCompanyPaymentInfoParams = {
    organisationId: string;
    data: {
        wantOrderForm?: boolean;
        accountingInfo?: {
            firstName: string;
            lastName: string;
            email: string;
            phoneNumber: string;
        };
        tvaNumber?: string;
        billingAddress?: {
            street: string;
            postalCode: string;
            city: string;
            country: string;
        };
        paymentMethodId?: string;
    };
};

const message = "updateCompanyPaymentInfo";
export async function updateCompanyPaymentInfo(params: UpdateCompanyPaymentInfoParams) {
    try {
        const resp = await fetch(
            withAuthHeaders(
                `${apiConf.BACK_URL}/organisations/${params.organisationId}/payment-informations`,
                {
                    method: `PATCH`,
                    body: JSON.stringify(params.data),
                },
            ),
        );

        if (resp.status >= 400) {
            handleError(await resp.json(), resp.status);
            return Promise.reject(new Error(message));
        }
        return;
    } catch (e) {
        handleError(e, 500);
        return Promise.reject(new Error(message));
    }
}
