import { useEffect, useRef, useState } from "react";
import { toast } from "saphir";
import {
    Button,
    CheckboxButtons,
    InputRadioSwitch,
    ModalsService,
    SquaredButton,
    Textarea,
} from "side-ui";

import { queryClient } from "@App";
import { postFeedback } from "@lib/api";
import { putFeedback } from "@lib/api/putFeedback";
import { i18n } from "@lib/i18n";
import { queries } from "@lib/queries";
import trackEvent from "@lib/trackers";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useSearch } from "@tanstack/react-router";

import { TimesheetsSearch } from "../route";

import "./FeedbackForm.scss";

type FeedbackFormProps = {
    sider: any;
    task: any;
    wrapperName: string;
    workAgainValue: number;
    feedbackId: string;
    updateWorkAgainValue: (value: number) => void;
};

export default function FeedbackForm({
    sider,
    task,
    wrapperName,
    workAgainValue,
    feedbackId,
    updateWorkAgainValue,
}: FeedbackFormProps) {
    const workAgainQuestion = useRef<HTMLDivElement>(null);
    const detailQuestion = useRef<HTMLDivElement>(null);
    const commentQuestion = useRef<HTMLDivElement>(null);

    const stepRefs = [workAgainQuestion.current, detailQuestion.current, commentQuestion.current];

    // we use strict false here because the form is rendered in a modal which is in the globalLayout
    const filters: TimesheetsSearch = useSearch({ strict: false });

    const { data: attendanceData } = useQuery(queries.attendance.list(filters));
    const { data: fullFeedback } = useQuery(queries.feedback.detail(feedbackId));

    const feedbacks = attendanceData?.attendances.map((attendance) => attendance.feedback);
    const feedback = fullFeedback ?? feedbacks?.find((feedback) => feedback?.id === feedbackId);

    const [values, setValues] = useState({
        workAgain: workAgainValue,
        detail: {
            pro: workAgainValue === 1 ? 1 : (feedback && feedback.pro) || 0,
            perf: workAgainValue === 1 ? 1 : (feedback && feedback.perf) || 0,
        },
        comment: (feedback && feedback.comment) || undefined,
    });

    const [activeSteps, setActiveSteps] = useState({
        0: {
            active: true,
            hasValue: true,
            isRequired: true,
            error: undefined,
        },
        1: {
            active: false,
            hasValue: false,
            isRequired: true,
            error: undefined,
        },
        2: {
            active: false,
            hasValue: false,
            isRequired: false,
            error: undefined,
        },
    });

    const [currentStep, setCurrentStep] = useState(0);

    useEffect(() => {
        const isUpdateToNo =
            feedback && workAgainValue !== feedback.workAgain && workAgainValue === -1;

        if (typeof workAgainValue !== `undefined`) {
            setValues({
                ...values,
                workAgain: workAgainValue,
                detail: {
                    // if the value goes for yes to no, we need to set the values to 0
                    // if this is not an update, or one from no to yes,
                    // we take the value 1 if yes is selected
                    // or the current value if this is a "no" (or 0 as a default value)
                    pro: isUpdateToNo
                        ? 0
                        : workAgainValue === 1
                          ? 1
                          : (feedback && feedback.pro) || 0,
                    perf: isUpdateToNo
                        ? 0
                        : workAgainValue === 1
                          ? 1
                          : (feedback && feedback.perf) || 0,
                },
            });
            // if the current workAgain value is true,
            // we need to remove the 2nd step and jump to the last one
            setActiveSteps({
                ...activeSteps,
                1: {
                    ...activeSteps[1],
                    active: workAgainValue !== 1,
                    hasValue: !!(!isUpdateToNo && feedback && (feedback.perf || feedback.pro)),
                },
                2: {
                    ...activeSteps[2],
                    active: workAgainValue === 1,
                },
            });
            setCurrentStep(workAgainValue === 1 ? 2 : 1);
        }
    }, []);

    useEffect(() => {
        const handleScroll = () => {
            const wrapper = document.getElementById(wrapperName);
            const wrapperBody = wrapper && document.getElementsByClassName(`modal__body`)[0];

            if (!wrapperBody) return;
            const wrapperCoord = wrapperBody.getBoundingClientRect();
            const threshold = wrapperCoord.top + wrapperCoord.height / 2;

            // we take a look at each step's position
            // to determine which one is the current
            for (let i = 0; i < stepRefs.length; i++) {
                const step = stepRefs[i];

                // get the position of top of the element
                // find position of the middle of the wrapper (threshold)
                // if the first is < to the second,
                // then the element becomes the current step
                const stepTopPos = step && step.getBoundingClientRect().top;
                const stepBottomPos = step && step.getBoundingClientRect().bottom;

                if (
                    currentStep !== i &&
                    stepTopPos &&
                    stepTopPos < threshold &&
                    stepBottomPos &&
                    stepBottomPos > threshold
                ) {
                    setCurrentStep(i);
                    break;
                }
            }
        };
        // Add event listener for scrolling
        const wrapperBody = document.getElementsByClassName(`modal__body`)[0];
        wrapperBody.addEventListener(`wheel`, handleScroll);
        wrapperBody.addEventListener(`touchmove`, handleScroll);

        return () => {
            wrapperBody.removeEventListener(`wheel`, handleScroll);
            wrapperBody.removeEventListener(`touchmove`, handleScroll);
        };
    }, []);

    useEffect(() => {
        const isUpdateToNo =
            feedback && workAgainValue !== feedback.workAgain && workAgainValue === -1;

        setValues((prev) => ({
            ...prev,
            workAgain: workAgainValue,
            detail: {
                // same as in did mount
                pro: isUpdateToNo ? 0 : (feedback && feedback.pro) || 0,
                perf: isUpdateToNo ? 0 : (feedback && feedback.perf) || 0,
            },
            comment: (feedback && feedback.comment) || undefined,
        }));

        setActiveSteps((prev) => ({
            ...prev,
            1: {
                ...prev["1"],
                hasValue: Boolean(
                    !isUpdateToNo &&
                        ((feedback && feedback.pro !== 0) || (feedback && feedback.perf !== 0)),
                ),
            },
        }));
    }, [feedback]);

    useEffect(() => {
        scrollToStep(currentStep);
    }, [currentStep, activeSteps]);

    function scrollToStep(step) {
        if (stepRefs[step]) {
            stepRefs[step]?.scrollIntoView({
                behavior: "smooth",
                block: `start`,
            });
        }
        setCurrentStep(step);
    }

    function getActionLabel() {
        if (currentStep === 2 && (!values.comment || !values.comment.length)) {
            return (
                <span dangerouslySetInnerHTML={{ __html: i18n.feedback_form_send_no_comment() }} />
            );
        }
        if (currentStep === 2) {
            return i18n.send();
        }
        return i18n.label_continue();
    }

    function getAction() {
        if (currentStep === 2) {
            // submit
            handleSubmit();
        } else {
            // go to the next step
            goToNextStep();
        }
    }

    function goToNextStep() {
        if (currentStep + 1 >= Object.keys(activeSteps).length) return;

        // update the current step,
        // this will trigger the scroll in didUpdate
        if (currentStep === 0) {
            // if the current workAgain value is true, and the user clicks on "next"
            // we need to remove the 2nd step and jump to the last one
            setActiveSteps({
                ...activeSteps,
                1: {
                    ...activeSteps[1],
                    active: values.workAgain !== 1,
                },
                2: {
                    ...activeSteps[2],
                    active: values.workAgain === 1,
                },
            });
            setCurrentStep(values.workAgain === 1 ? 2 : 1);
        } else {
            setActiveSteps({
                ...activeSteps,
                [currentStep + 1]: {
                    ...activeSteps[currentStep + 1],
                    active: true,
                },
            });
            setCurrentStep(currentStep + 1);
        }
    }

    function goToPreviousStep() {
        const newStep = values.workAgain === 1 ? 0 : currentStep - 1;

        setCurrentStep(newStep);
        scrollToStep(newStep);
    }

    function handleWorkAgainChange(value) {
        const isValuePositive = value === `1`;

        // we directly go to the next step (without using the button)
        // so we update the state here directly
        // the update depends on the input value
        setValues((prev) => ({
            ...prev,
            workAgain: parseInt(value, 10),
            detail: {
                pro: isValuePositive ? 1 : 0,
                perf: isValuePositive ? 1 : 0,
            },
        }));

        setActiveSteps((prev) => ({
            ...prev,
            1: {
                ...prev[1],
                active: !isValuePositive,
                hasValue: false,
            },
            2: {
                ...prev[2],
                active: !!isValuePositive,
                hasValue: false,
            },
        }));

        setCurrentStep(isValuePositive ? 2 : 1);

        // we tell the parent component to update its state
        updateWorkAgainValue(parseInt(value, 10));
    }

    function handleDetailChange(field, value) {
        // we calculate if the field has a value or not
        // either the current value that just was selected is true
        // otherwise, we know it's false and we need to check the other value in the state
        const hasValue = !!(value || values.detail[field === `pro` ? `perf` : `pro`]);

        setValues((prev) => ({
            ...prev,
            detail: {
                ...prev.detail,
                [field]: value,
            },
        }));

        setActiveSteps((prev) => ({
            ...prev,
            1: {
                ...prev[1],
                hasValue,
            },
        }));
    }

    function handleCommentChange(value) {
        setValues((prev) => ({
            ...prev,
            comment: value,
        }));

        setActiveSteps((prev) => ({
            ...prev,
            2: {
                ...prev[2],
                hasValue: Boolean(values.comment && values.comment.length),
            },
        }));
    }

    function checkRequiredFields() {
        for (let i = 0; i < currentStep; i++) {
            const step = activeSteps[i];
            if (step.isRequired && step.active && !step.hasValue) {
                setActiveSteps((prev) => ({
                    ...prev,
                    [i]: {
                        ...prev[i],
                        error: i18n.error_field_is_required_checkbox(),
                    },
                }));
                scrollToStep(i);
                return true;
            }
        }

        return false;
    }

    const { mutate: saveFeedbackMutation, isPending } = useMutation({
        mutationFn: (args: { feedback; isEdit }) => {
            const { feedback, isEdit } = args;

            let dataToSend;
            if (isEdit) {
                dataToSend = {
                    feedbackId: feedback.id,
                    data: {
                        siderId: feedback.siderId,
                        taskId: feedback.taskId,
                        typeId: feedback.typeId,
                        organisationId: localStorage.getItem(`side_team_activeOrganisationId`),
                        managerId: feedback.managerId,
                        workAgain: feedback.workAgain,
                        pro: feedback.pro,
                        perf: feedback.perf,
                        comment: feedback.comment,
                    },
                };
                return putFeedback(dataToSend);
            } else {
                dataToSend = {
                    siderId: feedback.siderId,
                    organisationId: localStorage.getItem(`side_team_activeOrganisationId`),
                    data: {
                        taskId: feedback.taskId,
                        typeId: feedback.typeId,
                        managerId: feedback.managerId,
                        workAgain: feedback.workAgain,
                        pro: feedback.pro,
                        perf: feedback.perf,
                        comment: feedback.comment,
                    },
                };
                return postFeedback(dataToSend);
            }
        },
        onSuccess: (_, { feedback }) => {
            queryClient.invalidateQueries({ queryKey: ["attendance", "list"] });
            queryClient.invalidateQueries({ queryKey: ["feedback"] });
            ModalsService.closeModal(`FEEDBACK`);

            if (feedback?.workAgain === 1) {
                toast.info(i18n.feedback_toast_satisfied({ name: sider.firstName }));
            } else {
                toast.info(i18n.feedback_toast_not_satisfied({ name: sider.firstName }));
            }
        },
    });

    function handleSubmit() {
        if (checkRequiredFields()) return;

        const dataToSend = {
            id:
                (feedback && "_id" in feedback && feedback?._id) ||
                (feedback && "id" in feedback && feedback?.id),
            taskId: task.id,
            typeId: task.jobTypeId,
            managerId:
                localStorage.getItem(`side_team_logasId`) || localStorage.getItem(`Meteor.userId`),
            siderId: sider.id,
            workAgain: values.workAgain,
            pro: values.detail.pro,
            perf: values.detail.perf,
            comment: values.comment,
            sider: {
                firstName: sider?.firstName,
                lastName: sider?.lastName,
            },
        };

        function submitForm(feedback, isEdit) {
            const hasComment = feedback.comment && feedback.comment.length > 0;
            trackEvent({
                name: `feedback - completed rating`,
                params: {
                    edition: isEdit,
                    comment: hasComment,
                    commentLength: hasComment ? feedback.comment.length : undefined,
                    taskId: feedback.taskId,
                    siderId: feedback.siderId,
                },
            });
            saveFeedbackMutation({ feedback, isEdit });
        }

        submitForm(dataToSend, !!dataToSend.id);
    }

    const closeModalWithoutSaving = (sider, task) => {
        trackEvent({
            name: `feedback - stopped rating`,
            params: {
                taskId: task.id,
                siderId: sider.id,
            },
        });
        ModalsService.closeModal(`FEEDBACK`);
        toast.warning(i18n.feedback_toast_not_saved({ name: sider.firstName }));
    };

    const noNextStepActive =
        activeSteps[currentStep + 1] &&
        !activeSteps[currentStep + 1].active &&
        activeSteps[currentStep] &&
        !activeSteps[currentStep].hasValue &&
        activeSteps[currentStep].isRequired;
    return (
        <div className='feedback-form'>
            <div className='feedback-form__steps'>
                <div>
                    <div
                        ref={workAgainQuestion}
                        className={`feedback-form__question ${currentStep === 0 ? `active` : ``}`}
                    >
                        <h2 className='mb-4 typography-heading-xl-semibold'>
                            {i18n.feedback_form_general_question({
                                name: sider.firstName,
                            })}
                        </h2>
                        <InputRadioSwitch
                            id='test'
                            options={[
                                {
                                    value: 1,
                                    label: i18n.feedback_form_general_answer_1(),
                                    description: i18n.feedback_form_general_answer_1_notice(),
                                    icon: `SmileyHappy`,
                                    iconSize: 48,
                                },
                                {
                                    value: -1,
                                    label: i18n.feedback_form_general_answer_2(),
                                    description: i18n.feedback_form_general_answer_2_notice(),
                                    icon: `SmileySad`,
                                    iconSize: 48,
                                },
                            ]}
                            value={values.workAgain}
                            onChange={(value) => handleWorkAgainChange(value)}
                        />
                    </div>
                </div>
                <div ref={detailQuestion}>
                    {activeSteps[`1`].active && (
                        <div
                            className={`feedback-form__question ${
                                currentStep === 1 ? `active` : ``
                            }`}
                        >
                            <h2 className='mb-4 typography-heading-xl-semibold'>
                                {i18n.feedback_form_issue_question({
                                    name: sider.firstName,
                                })}
                            </h2>
                            <p className='text-big'>{i18n.feedback_form_issue_description()}</p>
                            {!!activeSteps[`1`].error && (
                                <div className='form-error'>{activeSteps[`1`].error}</div>
                            )}
                            <CheckboxButtons
                                options={[
                                    {
                                        name: `pro`,
                                        label: i18n.feedback_form_issue_choice_1(),
                                        description: i18n.feedback_form_issue_choice_1_notice(),
                                    },
                                    {
                                        name: `perf`,
                                        label: i18n.feedback_form_issue_choice_2(),
                                        description: i18n.feedback_form_issue_choice_2_notice(),
                                    },
                                ]}
                                value={values.detail}
                                onChange={(field, value) => {
                                    const newValue = value ? -1 : 0;
                                    handleDetailChange(field, newValue);
                                }}
                            />
                        </div>
                    )}
                </div>
                <div ref={commentQuestion}>
                    {activeSteps[`2`].active && (
                        <div
                            className={`feedback-form__question ${
                                currentStep === 2 ? `active` : ``
                            }`}
                        >
                            <h2 className='mb-4 typography-heading-xl-semibold'>
                                {i18n.feedback_form_comment_question({
                                    name: sider.firstName,
                                })}
                            </h2>
                            <Textarea
                                id='comment'
                                label={i18n.feedback_form_comment_question_label()}
                                placeholder={i18n.feedback_form_comment_question_placeholder()}
                                maxLength={260}
                                value={values.comment}
                                onChange={(value) => handleCommentChange(value)}
                                counterLabel={i18n.textarea_counter_label({
                                    s:
                                        values.comment && 260 - values.comment.length <= 1
                                            ? ``
                                            : `s`,
                                })}
                            />
                        </div>
                    )}
                </div>
            </div>
            <div className='feedback-form__actions'>
                <Button
                    action={() => closeModalWithoutSaving(sider, task)}
                    color='light-grey'
                    customClass='btn-cancel-mobile'
                >
                    {i18n.cancel()}
                </Button>
                {currentStep !== 0 && (
                    <SquaredButton
                        action={() => goToPreviousStep()}
                        color='light'
                        icon='ArrowUp'
                        customClass='btn-squared-up'
                    />
                )}
                <Button
                    action={() => getAction()}
                    color='blue'
                    disabled={isPending || noNextStepActive}
                >
                    {getActionLabel()}
                </Button>
            </div>
        </div>
    );
}
