import moment from "moment";
import { IconArrowRight, IconDownload } from "side-ui";
import { Tooltip, TooltipContent, TooltipTrigger } from "sui";

import { i18n, i18nDK } from "@lib/i18n";
import trackEvent from "@lib/trackers";

const Invoice = (props) => {
    const { sideInvoiceUrl, amount, weekStartDate, weekEndDate, type, paid, referenceNumber } =
        props;

    return (
        <div className='row invoices-list__row'>
            <div className='col-xs-14 col-sm-4 invoices-list__col invoices-list__col--date m-align-left'>
                <Tooltip>
                    <TooltipTrigger>
                        <p className='invoices-list__col--date--start'>
                            {moment(weekStartDate).format(`DD/MM/YYYY`)}
                        </p>
                    </TooltipTrigger>
                    <TooltipContent type='info'>
                        {i18n.week()} {moment(weekStartDate).format(`W`)}
                    </TooltipContent>
                </Tooltip>
                <div className='invoices-list__col--date--icon'>
                    <IconArrowRight />
                </div>
                <Tooltip>
                    <TooltipTrigger>
                        <p className='invoices-list__col--date--end'>
                            {moment(weekEndDate).format(`DD/MM/YYYY`)}
                        </p>
                    </TooltipTrigger>
                    <TooltipContent type='info'>
                        {i18n.week()} {moment(weekEndDate).format(`W`)}
                    </TooltipContent>
                </Tooltip>
            </div>
            <div className='col-xs-14 col-sm-3 invoices-list__col'>{referenceNumber}</div>
            <div className='col-xs-7 col-sm-1 invoices-list__col m-align-left'>
                <span className={`invoices-list__col__type invoices-list__col__type--${type}`}>
                    {type && i18nDK(`invoices_type_${type}`)}
                </span>
            </div>
            <div className='col-xs-7 col-sm-2 invoices-list__col align-right m-align-right'>
                <span className={`amount invoices-list__col__amount--${type}`}>
                    {amount.toFixed(2)}€
                </span>
            </div>
            <div className='col-xs-14 col-sm-2 col-sm-offset-1 invoices-list__col align-center m-align-center'>
                <span
                    className={`invoices-list__col__status invoices-list__col__status--${
                        paid === true ? "paid" : "unpaid"
                    }`}
                >
                    {i18nDK(`invoices_status_${paid === true ? "paid" : "unpaid"}`)}
                </span>
            </div>
            <div className='col-xs-14 col-sm-1 invoices-list__col align-right'>
                {sideInvoiceUrl ? (
                    <a
                        className='button-download'
                        href={sideInvoiceUrl}
                        download={true}
                        rel='noopener noreferrer'
                        onClick={() =>
                            trackEvent({
                                name: `invoices - single invoice download`,
                                params: {
                                    logas: !!localStorage.getItem(`side_team_logasId`),
                                },
                            })
                        }
                    >
                        <span className='text-download bold visible-sm'>
                            {i18n.invoices_download()}
                        </span>
                        <IconDownload />
                    </a>
                ) : (
                    <Tooltip
                        attachment='bottom center'
                        targetAttachment='top center'
                        anchor={
                            <a className='button-download button-download--disabled'>
                                <span className='text-download bold visible-sm'>
                                    {i18n.invoices_download()}
                                </span>
                                <IconDownload />
                            </a>
                        }
                    >
                        {i18n.invoices_download_error()}
                    </Tooltip>
                )}
            </div>
        </div>
    );
};

export default Invoice;
