import { Formik } from "formik";
import { InputNumber, ModalSimple, ModalsService, ToggleSwitch } from "side-ui";

import { i18n } from "@lib/i18n";

import { normalizeNumber } from "../../../../lib/utils/inputsNormalizers";

import HoursSeniorityIncrease from "./HoursSeniorityIncrease";
import { submitSundayHours, sundayHoursValidationSchema } from "./remunerationFormsValidation";

import "./IndemnityForm.scss";

const onFieldChange = (fieldName, value, prevValue, setFieldValue) => {
    let newValue = value;
    switch (fieldName) {
        case "zti.increase":
        case "increase":
            newValue = normalizeNumber(value, prevValue);
            break;
        default:
            break;
    }
    setFieldValue(fieldName, newValue);
};

const SundayHoursForm = (props) => {
    const { sundayHours, updateHourType } = props;

    const initialVal = {
        increase: `${sundayHours.increase}`,
        seniority: sundayHours.seniority
            ? {
                  checked: true,
                  daysThreshold: `${sundayHours.seniority.daysThreshold}`,
                  increase: `${sundayHours.seniority.increase}`,
              }
            : {
                  checked: false,
                  daysThreshold: "",
                  increase: "",
              },
        zti: sundayHours.ztiIncrease
            ? {
                  checked: true,
                  increase: `${sundayHours.ztiIncrease}`,
              }
            : {
                  checked: false,
                  increase: "",
              },
    };

    return (
        <Formik
            initialValues={initialVal}
            onSubmit={(values, actions) =>
                submitSundayHours(values, actions, initialVal, updateHourType)
            }
            validationSchema={sundayHoursValidationSchema()}
            validateOnChange={false}
            validateOnBlur={true}
            render={({ values, errors, touched, handleBlur, submitForm, setFieldValue }) => (
                <ModalSimple
                    action={submitForm}
                    validateLabel={i18n.settings_remuneration_indemnities_modal_save()}
                    cancelLabel={i18n.settings_remuneration_indemnities_modal_cancel()}
                    title={i18n.settings_remuneration_indemnities_sunday_hours_title()}
                    subtitle={i18n.settings_remuneration_indemnities_sunday_hours_subtitle()}
                    hideModal={() => ModalsService.closeModal(`sunday_hours`)}
                >
                    <div className='indemnity__section'>
                        <InputNumber
                            id='increase'
                            onChange={(e) =>
                                onFieldChange(
                                    "increase",
                                    e.target.value,
                                    values.increase,
                                    setFieldValue,
                                )
                            }
                            label={i18n.settings_remuneration_indemnities_sunday_hours_rate()}
                            placeholder={50}
                            unit='%'
                            onBlur={handleBlur}
                            smallInput={true}
                            value={values.increase}
                            error={touched.increase && errors.increase}
                            customClass='indemnity__small-input'
                        />
                    </div>
                    <div className='indemnity__section'>
                        <HoursSeniorityIncrease />
                    </div>
                    <div className='indemnity__section'>
                        <div className={values.zti.checked ? "indemnity__field" : ""}>
                            <ToggleSwitch
                                id='zti.checked'
                                checked={values.zti.checked}
                                label={i18n.settings_remuneration_indemnities_sunday_hours_zti()}
                                onChange={(e) =>
                                    onFieldChange(
                                        "zti.checked",
                                        e.target.checked,
                                        values.zti.checked,
                                        setFieldValue,
                                    )
                                }
                            />
                        </div>
                        {values.zti.checked && (
                            <div className='indemnity__field'>
                                <InputNumber
                                    id='zti.increase'
                                    onChange={(e) =>
                                        onFieldChange(
                                            "zti.increase",
                                            e.target.value,
                                            values.zti.increase,
                                            setFieldValue,
                                        )
                                    }
                                    label={i18n.settings_remuneration_indemnities_sunday_hours_zti()}
                                    placeholder={100}
                                    unit='%'
                                    onBlur={handleBlur}
                                    smallInput={true}
                                    value={values.zti.increase}
                                    error={
                                        touched.zti &&
                                        touched.zti.increase &&
                                        errors.zti &&
                                        errors.zti.increase
                                    }
                                    customClass='indemnity__small-input'
                                />
                            </div>
                        )}
                    </div>
                </ModalSimple>
            )}
        />
    );
};

export default SundayHoursForm;
