import fetch from "isomorphic-fetch";

import { debug } from "@side.co/client-debug";

import { handleError } from "../utils/error";
import sha256Hash from "../utils/sha256";

import { withAuthHeaders } from "./apiHelpers";
import { GetOrganisationUsersResponse } from "./getOrganisationUsers";
import { apiConf } from "./index";

export type PatchProfileReq = {
    firstName?: string;
    lastName?: string;
    email?: string;
    phone?: string;
    oldPassword?: string;
    password?: string;
    passwordConfirmation?: string;
};

export async function patchProfile(
    req: PatchProfileReq,
): Promise<GetOrganisationUsersResponse | null> {
    debug.addBreadcrumb({
        message: `PatchProfile`,
        category: `action`,
        data: req,
    });

    const body = {
        firstName: req.firstName,
        lastName: req.lastName,
        email: req.email,
        phone: req.phone,
        ...(req.oldPassword &&
            req.oldPassword !== "" && { oldPassword: sha256Hash(req.oldPassword) }),
        ...(req.password &&
            req.password !== "" &&
            req.password === req.passwordConfirmation && { password: sha256Hash(req.password) }),
    };

    try {
        const resp = await fetch(
            withAuthHeaders(`${apiConf.BACK_URL}/me/profile`, {
                method: `PATCH`,
                credentials: "omit",
                body: JSON.stringify(body),
            }),
        );

        if (resp.status >= 400) {
            handleError(await resp.json(), resp.status);
            return Promise.reject(new Error("PatchProfile"));
        }

        return resp.json();
    } catch (e) {
        handleError(e, 500);
    }

    return null;
}
