import { useState } from "react";
import device from "current-device";
import { id } from "date-fns/locale";
import { ModalSimple, ModalsService, Select } from "side-ui";

import { i18n } from "@lib/i18n";
import { queries } from "@lib/queries";
import { useQuery } from "@tanstack/react-query";

import trackEvent from "../../../lib/trackers";

import "./ManagerUpdateModal.scss";

const ManagerUpdateModal = ({ taskId, taskManagerId, updateTaskManager }) => {
    const { data: companyInfoData } = useQuery(queries.company.detail());
    const companyMembers = companyInfoData?.members || [];
    const currentManager = companyMembers?.find((member) => member._id === taskManagerId) || {};
    const [manager, setManager] = useState({
        id: currentManager?._id,
        label: currentManager ? `${currentManager.firstName} ${currentManager.lastName}` : "",
        value: currentManager?._id,
    });
    function onSubmit() {
        updateTaskManager(taskId, manager.id);

        trackEvent({
            name: `tasks - change manager of the task`,
            params: {
                organisationId: localStorage.getItem(`side_team_activeOrganisationId`),
                deviceType: device.type,
                currentManager: currentManager._id,
                newManager: manager.id,
                taskId,
            },
        });
    }

    return (
        <ModalSimple
            action={() => onSubmit()}
            validateLabel={i18n.confirm()}
            cancelLabel={i18n.cancel()}
            title={i18n.modal_update_manager_title()}
            hideModal={() => ModalsService.closeModal(`UPDATE_MANAGER`)}
            disableButton={!manager?.id}
        >
            <div className='manager-update-modal'>
                <Select
                    id='manager'
                    name='manager'
                    selectedItem={manager.label}
                    chevronIcon={true}
                    onChange={(_, __, selectedItem) => {
                        const { firstName, lastName } = companyMembers.find(
                            ({ _id }) => _id === selectedItem,
                        );
                        setManager({
                            id: selectedItem,
                            value: id,
                            label: `${firstName} ${lastName}`,
                        });
                    }}
                    options={companyMembers.map(({ _id, firstName, lastName }) => ({
                        id: _id,
                        value: _id,
                        label: `${firstName} ${lastName}`,
                    }))}
                />
            </div>
        </ModalSimple>
    );
};

export default ManagerUpdateModal;
