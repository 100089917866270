import { toast } from "saphir";

import { i18n } from "@lib/i18n";
import { debug } from "@side.co/client-debug";

import * as backend from "../lib/api";
import checkResForError from "../lib/utils/checkResForError";
import { handleError } from "../lib/utils/error";

import normalizePaymentInfo from "./normalizers/companyPaymentInfo";
import normalizeRemunerationInfo, {
    normalizeIndemnities,
} from "./normalizers/companyRemunerationInfo";
import { getCompanyRemunerationInfoData } from "./selectors/company";
import type {
    Action,
    BankHolidayHours,
    CompanyMember,
    CompanyUser,
    ExtraHours,
    HoursTypes,
    Indemnities,
    LegalInfo,
    LunchIndemnity,
    NightHours,
    OtherIndemnity,
    PaymentInfo,
    RemunerationInfo,
    State,
    SundayHours,
    TransportationIndemnity,
} from "./types/company";
import type { Dispatch, GetState } from "./types";

//------------------------------------
// Constants
//------------------------------------

export const SET_COMPANY_FEATURES = `SET_COMPANY_FEATURES` as const;
export const SET_COMPANY_MEMBERS = `SET_COMPANY_MEMBERS` as const;
export const SET_COMPANY_ORGANISATION_USERS = `SET_COMPANY_ORGANISATION_USERS` as const;
export const SET_GROUP_MEMBERS = `SET_GROUP_MEMBERS` as const;
export const SET_WORKLEGALSTATUS = `SET_WORKLEGALSTATUS` as const;
export const SET_GROUP_WORKLEGALSTATUS = `SET_GROUP_WORKLEGALSTATUS` as const;
export const SET_GROUP_NAME = `SET_GROUP_NAME` as const;
export const SET_GROUP_LOGO = `SET_GROUP_LOGO` as const;
export const SET_COMPANY_NAME = `SET_COMPANY_NAME` as const;
export const SET_COMPANY_LOGO = `SET_COMPANY_LOGO` as const;
export const SET_COMPANY_LEGAL_INFO = `SET_COMPANY_LEGAL_INFO` as const;
export const SET_LEGAL_INFO_LOADING_STATUS = `SET_LEGAL_INFO_LOADING_STATUS` as const;
export const SET_COMPANY_PAYMENT_INFO = `SET_COMPANY_PAYMENT_INFO` as const;
export const SET_PAYMENT_INFO_LOADING_STATUS = `SET_PAYMENT_INFO_LOADING_STATUS` as const;
export const SET_COMPANY_REMUNERATION_INFO = `SET_COMPANY_REMUNERATION_INFO` as const;
export const SET_REMUNERATION_INFO_LOADING_STATUS = `SET_REMUNERATION_INFO_LOADING_STATUS` as const;
export const MARK_INDEMNITIES_CONFIRMED = `MARK_INDEMNITIES_CONFIRMED` as const;
export const MARK_HOURS_TYPES_CONFIRMED = `MARK_HOURS_TYPES_CONFIRMED` as const;

//------------------------------------
// Actions
//------------------------------------

export const setCompanyFeatures = (features: Array<string>) => ({
    type: SET_COMPANY_FEATURES,
    features,
});

export const setCompanyMembers = (members: Array<CompanyMember>) => ({
    type: SET_COMPANY_MEMBERS,
    members,
});

export const setCompanyUsers = (payload: CompanyUser[]) => ({
    type: SET_COMPANY_ORGANISATION_USERS,
    payload,
});

export const setGroupMembers = (members: Array<CompanyMember>) => ({
    type: SET_GROUP_MEMBERS,
    members,
});

export const setWorkLegalStatus = (workLegalStatus: string) => ({
    type: SET_WORKLEGALSTATUS,
    workLegalStatus,
});

export const setGroupWorkLegalStatus = (workLegalStatus: string) => ({
    type: SET_GROUP_WORKLEGALSTATUS,
    workLegalStatus,
});

export const setGroupName = (name: string) => ({
    type: SET_GROUP_NAME,
    name,
});

export const setGroupLogo = (logoUrl: string) => ({
    type: SET_GROUP_LOGO,
    logoUrl,
});

export const setCompanyName = (name: string) => ({
    type: SET_COMPANY_NAME,
    name,
});

export const setCompanyLogo = (logoUrl: string) => ({
    type: SET_COMPANY_LOGO,
    logoUrl,
});

export const setCompanyLegalInfo = (data: LegalInfo) => ({
    type: SET_COMPANY_LEGAL_INFO,
    data,
});

export const pendingLegalInfoRequest = (isLoading: boolean): Action => ({
    type: SET_LEGAL_INFO_LOADING_STATUS,
    isLoading,
});

export const setCompanyPaymentInfo = (data: PaymentInfo) => ({
    type: SET_COMPANY_PAYMENT_INFO,
    data,
});

export const pendingPaymentInfoRequest = (isLoading: boolean): Action => ({
    type: SET_PAYMENT_INFO_LOADING_STATUS,
    isLoading,
});

export const setCompanyRemunerationInfo = (data: RemunerationInfo) => ({
    type: SET_COMPANY_REMUNERATION_INFO,
    data,
});

export const pendingRemunerationInfoRequest = (isLoading: boolean): Action => ({
    type: SET_REMUNERATION_INFO_LOADING_STATUS,
    isLoading,
});

export const markHoursTypesAsConfirmed = () => ({
    type: MARK_HOURS_TYPES_CONFIRMED,
});

export const markIndemnitiesAsConfirmed = () => ({
    type: MARK_INDEMNITIES_CONFIRMED,
});

export const getCompanyPaymentInfo = () => async (dispatch: Dispatch) => {
    const organisationId = localStorage.getItem(`side_team_activeOrganisationId`);
    if (!organisationId) {
        return;
    }
    dispatch(pendingPaymentInfoRequest(true));
    let res;
    let json = {};
    try {
        res = await backend.getCompanyPaymentInfo({ organisationId });
        json = await res.json();
    } catch (e) {
        handleError(e.toString());
        dispatch(pendingPaymentInfoRequest(false));
    }
    const normalizedData = normalizePaymentInfo(json) as unknown as PaymentInfo;
    setTimeout(() => {
        dispatch(setCompanyPaymentInfo(normalizedData));
    }, 1000);
};

export const deleteIndemnity = (type: "transportation" | "lunch" | "other", id: string) =>
    async function (dispatch: Dispatch, getState: GetState) {
        const remunerationInfo: any = getCompanyRemunerationInfoData(getState());

        let indemnities: Indemnities = JSON.parse(JSON.stringify(remunerationInfo.indemnities));
        switch (type) {
            case "transportation":
                indemnities.transportation = indemnities.transportation.filter(
                    (ind) => ind.id !== id,
                );
                break;
            case "lunch":
                indemnities.lunch = indemnities.lunch.filter((ind) => ind.id !== id);
                break;
            case "other":
                indemnities.other = indemnities.other.filter((ind) => ind.id !== id);
                break;
            default:
                return;
        }

        const organisationId = localStorage.getItem(`side_team_activeOrganisationId`);
        if (!organisationId) {
            return;
        }
        try {
            const res = await backend.updateCompanyIndemnities({
                organisationId,
                data: indemnities,
            });
            if (res) {
                if (checkResForError(res)) {
                    try {
                        const text = await res.text();
                        handleError(text);
                    } catch (e) {
                        debug.catch(e);
                        handleError(e.toString());
                    }

                    return;
                }

                const json = await res.json();
                indemnities = normalizeIndemnities(json);
                dispatch(
                    setCompanyRemunerationInfo({
                        ...remunerationInfo,
                        indemnities: {
                            ...indemnities,
                            valid: remunerationInfo.indemnities.valid,
                        },
                    }),
                );

                toast.success(i18n.settings_submit_success());
            }
        } catch (e) {
            debug.catch(e);
            handleError(e.toString());
        }
    };

export const updateIndemnity = (data: {
    lunch: LunchIndemnity;
    transportation: TransportationIndemnity;
    other: OtherIndemnity;
}) =>
    async function (dispatch: Dispatch, getState: GetState) {
        const remunerationInfo: any = getCompanyRemunerationInfoData(getState());

        let indemnities: Indemnities = JSON.parse(JSON.stringify(remunerationInfo.indemnities));
        // Adding the new indemnity to the store object.
        if (data.lunch) {
            if (data.lunch.id && data.lunch.id !== "") {
                indemnities.lunch = indemnities.lunch.map((val) => {
                    if (val.id === data.lunch.id) {
                        return data.lunch;
                    }

                    return val;
                });
            } else {
                indemnities.lunch = [...(indemnities.lunch || []), data.lunch];
            }
        } else if (data.transportation) {
            if (data.transportation.id && data.transportation.id !== "") {
                indemnities.transportation = indemnities.transportation.map((val) => {
                    if (val.id === data.transportation.id) {
                        return data.transportation;
                    }

                    return val;
                });
            } else {
                indemnities.transportation = [
                    ...(indemnities.transportation || []),
                    data.transportation,
                ];
            }
        } else if (data.other) {
            if (data.other.id && data.other.id !== "") {
                indemnities.other = indemnities.other.map((val) => {
                    if (val.id === data.other.id) {
                        return data.other;
                    }

                    return val;
                });
            } else {
                indemnities.other = [...(indemnities.other || []), data.other];
            }
        }

        const organisationId = localStorage.getItem(`side_team_activeOrganisationId`);
        if (!organisationId) {
            return;
        }
        try {
            const res = await backend.updateCompanyIndemnities({
                organisationId,
                data: indemnities,
            });
            if (res) {
                if (checkResForError(res)) {
                    try {
                        const text = await res.text();
                        handleError(text);
                    } catch (e) {
                        debug.catch(e);
                        handleError(e.toString());
                    }

                    return;
                }

                const json = await res.json();
                indemnities = normalizeIndemnities(json);
                dispatch(
                    setCompanyRemunerationInfo({
                        ...remunerationInfo,
                        indemnities: {
                            ...indemnities,
                            valid: remunerationInfo.indemnities.valid,
                        },
                    }),
                );

                toast.success(i18n.settings_submit_success());
            }
        } catch (e) {
            debug.catch(e);
            handleError(e.toString());
        }
    };

export const updateHourType =
    (data: {
        extra?: ExtraHours;
        night?: NightHours;
        sundays?: SundayHours;
        bankHolidays?: BankHolidayHours;
    }) =>
    async (dispatch: Dispatch, getState: GetState) => {
        const remunerationInfo: any = getCompanyRemunerationInfoData(getState());

        let hoursTypes: HoursTypes = JSON.parse(JSON.stringify(remunerationInfo.hoursTypes));
        hoursTypes = {
            ...hoursTypes,
            ...data,
        };

        const organisationId = localStorage.getItem(`side_team_activeOrganisationId`);
        if (!organisationId) {
            return;
        }
        try {
            const res = await backend.updateCompanyHoursTypes({
                organisationId,
                data: hoursTypes,
            });
            if (res) {
                if (checkResForError(res)) {
                    try {
                        const text = await res.text();
                        handleError(text);
                    } catch (e) {
                        debug.catch(e);
                        handleError(e.toString());
                    }

                    return;
                }
            }
        } catch (e) {
            debug.catch(e);
            handleError(e.toString());
        }

        dispatch(setCompanyRemunerationInfo({ ...remunerationInfo, hoursTypes }));

        toast.success(i18n.settings_submit_success());
    };

export const getCompanyRemunerationInfo = () => async (dispatch: Dispatch) => {
    const organisationId = localStorage.getItem(`side_team_activeOrganisationId`);
    if (!organisationId) {
        return;
    }
    dispatch(pendingRemunerationInfoRequest(true));
    let res;
    let json = {};
    try {
        res = await backend.getCompanyRemunerationInfo({ organisationId });
        json = await res.json();
    } catch (e) {
        handleError(e.toString());
        dispatch(pendingRemunerationInfoRequest(false));
    }
    const normalizedData = normalizeRemunerationInfo(json) as RemunerationInfo;
    setTimeout(() => {
        dispatch(setCompanyRemunerationInfo(normalizedData));
    }, 1000);
};

export const confirmHoursTypes = () =>
    async function (dispatch: Dispatch) {
        const organisationId = localStorage.getItem(`side_team_activeOrganisationId`);
        if (!organisationId) {
            return;
        }
        try {
            const res = await backend.confirmHoursTypes({
                organisationId,
            });
            if (res && checkResForError(res)) {
                handleError(res.statusText);
                return;
            }
        } catch (e) {
            debug.catch(e);
            handleError(e.toString());
        }

        dispatch(markHoursTypesAsConfirmed());
    };

export const confirmIndemnities = () =>
    async function (dispatch: Dispatch) {
        const organisationId = localStorage.getItem(`side_team_activeOrganisationId`);
        if (!organisationId) {
            return;
        }
        try {
            const res = await backend.confirmIndemnities({
                organisationId,
            });
            if (res && checkResForError(res)) {
                handleError(res.statusText);
                return;
            }
        } catch (e) {
            debug.catch(e);
            handleError(e.toString());
        }

        dispatch(markIndemnitiesAsConfirmed());
    };

//------------------------------------
// Reducer
//------------------------------------

const initialState = {
    group: {
        members: [],
        workLegalStatus: "",
        name: "",
        logoUrl: "",
        children: "",
    },
    features: [],
    members: [],
    workLegalStatus: "",
    name: "",
    logoUrl: "",
    legalInfo: {},
    paymentInfo: {},
    remunerationInfo: {},
    organisation: {},
};

const companyReducer = (state: State = initialState, action: Action): State => {
    switch (action.type) {
        case SET_COMPANY_FEATURES:
            return { ...state, features: action.features };
        case SET_COMPANY_MEMBERS:
            return { ...state, members: action.members };
        case SET_WORKLEGALSTATUS:
            return { ...state, workLegalStatus: action.workLegalStatus };
        case SET_GROUP_MEMBERS:
            return { ...state, group: { ...state.group, members: action.members } };
        case SET_GROUP_WORKLEGALSTATUS:
            return {
                ...state,
                group: { ...state.group, workLegalStatus: action.workLegalStatus },
            };
        case SET_GROUP_NAME:
            return { ...state, group: { ...state.group, name: action.name } };
        case SET_GROUP_LOGO:
            return { ...state, group: { ...state.group, logoUrl: action.logoUrl } };
        case SET_COMPANY_NAME:
            return { ...state, name: action.name };
        case SET_COMPANY_LOGO:
            return { ...state, logoUrl: action.logoUrl };
        case SET_COMPANY_LEGAL_INFO:
            return { ...state, legalInfo: { ...action.data, isLoading: false } };
        case SET_LEGAL_INFO_LOADING_STATUS:
            return {
                ...state,
                legalInfo: {
                    ...state.legalInfo,
                    isLoading: action.isLoading,
                },
            };
        case SET_COMPANY_PAYMENT_INFO:
            return { ...state, paymentInfo: { ...action.data, isLoading: false } };
        case SET_PAYMENT_INFO_LOADING_STATUS:
            return {
                ...state,
                paymentInfo: {
                    ...state.paymentInfo,
                    isLoading: action.isLoading,
                },
            };
        case SET_COMPANY_REMUNERATION_INFO:
            return { ...state, remunerationInfo: { ...action.data, isLoading: false } };
        case SET_REMUNERATION_INFO_LOADING_STATUS:
            return {
                ...state,
                remunerationInfo: {
                    ...state.remunerationInfo,
                    isLoading: action.isLoading,
                },
            };
        case MARK_HOURS_TYPES_CONFIRMED:
            return {
                ...state,
                remunerationInfo: {
                    ...state.remunerationInfo,
                    hoursTypesConfirmation: {
                        date: new Date(),
                        userId: localStorage.getItem(`Meteor.userId`) || "",
                    },
                },
            };
        case MARK_INDEMNITIES_CONFIRMED:
            return {
                ...state,
                remunerationInfo: {
                    ...state.remunerationInfo,
                    indemnitiesConfirmation: {
                        date: new Date(),
                        userId: localStorage.getItem(`Meteor.userId`) || "",
                    },
                },
            };
        case SET_COMPANY_ORGANISATION_USERS:
            return {
                ...state,
                users: action.payload,
            };

        default:
            return state;
    }
};

export default companyReducer;
