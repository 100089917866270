// Utilities file mainly for populating filter options
import {
    addHours,
    endOfDay,
    endOfMonth,
    endOfWeek,
    isDate,
    startOfDay,
    startOfMonth,
    startOfWeek,
} from "date-fns";

import { i18n } from "@lib/i18n";

// i18n function won't fire if not called from within a function
export const initCheckboxFilter = () => [
    {
        id: "matching",
        label: i18n.ts_filters_type_hours_similar_label(),
        description: i18n.ts_filters_type_hours_similar_description(),
    },
    {
        id: "conflicting",
        label: i18n.ts_filters_type_hours_different_label(),
        description: i18n.ts_filters_type_hours_different_description(),
    },
];

// i18n function won't fire if not called from within a function
export const initDateFilters = () => [
    {
        objectID: "day",
        label: i18n.ts_filters_day(),
        startDate: startOfDay(new Date()),
        endDate: endOfDay(new Date()),
    },
    {
        objectID: "week",
        label: i18n.ts_filters_week(),
        startDate: startOfWeek(new Date()),
        endDate: endOfWeek(new Date()),
    },
    {
        objectID: "month",
        label: i18n.ts_filters_month(),
        startDate: startOfMonth(new Date()),
        endDate: endOfMonth(new Date()),
    },
    {
        objectID: "all",
        label: i18n.ts_filters_all(),
    },
];

export const searchFilterOptions = [
    {
        objectID: "sider",
        label: "Sider",
    },
    {
        objectID: "manager",
        label: "Task Manager",
    },
];

export function encodeUrlParams(filters) {
    const filtersResult = [] as string[];

    Object.keys(filters).forEach((key) => {
        const value = filters[key];

        // do not set undefined or null value and remove them from the URL
        // but add numeric falsy value
        if (value || Number.isInteger(value)) {
            if (isDate(value)) {
                // ensure that timezone offset doesn't end up being day - 1 (before midnight)
                // setting at OO:OO
                const updatedDate = addHours(value, Math.abs(value.getTimezoneOffset() / 60));

                filtersResult.push(`${key}=${updatedDate.toISOString()}`);
            } else {
                if (Array.isArray(value)) {
                    value.forEach((val) => {
                        filtersResult.push(`${key}=${val}`);
                    });
                } else {
                    filtersResult.push(`${key}=${value}`);
                }
            }
        }
    });

    return filtersResult.join("&");
}

/**
 * @param {string} searchString window.location.search string
 */
export function decodeURLParams(searchString) {
    const params = new URLSearchParams(searchString);
    const result = {};

    for (const param of params.entries()) {
        // eslint-disable-line
        const [key, value] = param;

        // if a date search key, convert it to a date
        if (key.includes("Date")) {
            const date = new Date(value);

            if (isDate(date)) {
                result[key] = startOfDay(date);
            }
        } else {
            if (isArrayOrObject(value)) {
                const valueParsed = JSON.parse(value);
                result[key] = valueParsed;
            } else {
                // otherwise it's a string
                result[key] = params.get(key);
            }
        }
    }

    return result;
}

/**
 * @param {string} searchTerm user entered search
 */
export function lookForManager(managers, searchTerm) {
    if (!managers || !managers.length) {
        return [];
    }

    return managers.filter(
        ({ _id, firstName, lastName }) =>
            firstName.toLowerCase().includes(searchTerm) ||
            lastName.toLowerCase().includes(searchTerm) ||
            _id === searchTerm,
    );
}

function isArrayOrObject(value) {
    try {
        JSON.parse(value);
        return true;
    } catch (e) {
        return false;
    }
}
