import { format } from "date-fns";
import {
    Button,
    IconButton,
    ModalSimple,
    ModalsService,
    SectionHeader,
    SummaryTableRow,
    Tag,
    Tip,
} from "side-ui";

import { i18n, i18nDK } from "@lib/i18n";
import { queries } from "@lib/queries";
import { getLocale } from "@lib/utils/getLocale";
import { customFormatDuration, planningDaysSorted } from "@routes/TaskPosting/Summary/Summary";
import { useQuery, useSuspenseQuery } from "@tanstack/react-query";
import { useNavigate, useRouter } from "@tanstack/react-router";
import { useFlag } from "@unleash/proxy-client-react";

import ShiftsTable from "../../../components/ShiftsTable";
import UsersList from "../../../components/UsersList";
import Quotation from "../../TaskPosting/Quotation/Quotation";
import DuplicateModal from "../containers/DuplicateModal";
import ManagerUpdateModal from "../containers/ManagerUpdateModal";
import RenameModal from "../containers/RenameModal";
import { taskViewRoute } from "../route";

import "./TaskView.scss";

const taskStatus = {
    DRAFT: "draft",
    NEW: "new",
    ONGOING: "ongoing",
    UPCOMING: "upcoming",
    CLOSED: "closed",
};

const tagColors = {
    [taskStatus.DRAFT]: "orange",
    [taskStatus.NEW]: "grey",
    [taskStatus.ONGOING]: "green",
    [taskStatus.UPCOMING]: "blue",
    [taskStatus.CLOSED]: "grey",
    [undefined]: "blue",
};

const TaskView = () => {
    const locale = getLocale();
    const { taskId } = taskViewRoute.useParams();
    const { data: task } = useSuspenseQuery(queries.task.detail(taskId));
    const { history } = useRouter();

    const navigateBack = () => history.back();
    const navigate = useNavigate();

    const { data: quotation } = useQuery(queries.quotation.detail(taskId));
    const { data: planning } = useQuery({
        ...queries.task.planning(taskId),
        queryKey: [...queries.task.planning(taskId).queryKey, task.flexiblePlanningId],
        queryFn: () => (task.flexiblePlanningId ? queries.task.planning(taskId).queryFn() : null),
    });
    const isFlexiblePlanningEnabled = useFlag("flexible-planning");

    if (!task) {
        return null;
    }

    const orderSections = [
        {
            label: i18n.order_summary_name_title(),
            content: task?.name || "-",
            action: () =>
                ModalsService.openModal({
                    id: "RENAME_TASK",
                    content: <RenameModal task={task} />,
                }),
        },
        {
            label: i18n.order_summary_manager_title(),
            content: task?.manager ? `${task.manager.firstName} ${task.manager.lastName}` : "-",
            action: () =>
                ModalsService.openModal({
                    id: "UPDATE_MANAGER",
                    content: <ManagerUpdateModal task={task} />,
                }),
        },
        {
            label: i18n.job_descriptions_jobtitle(),
            content: task?.jobTitle || "-",
        },
        {
            label: i18n.order_preliminary_details_type_label(),
            content: i18nDK(`order_preliminary_details_type_${task?.type}_label`) || "-",
        },

        {
            label: i18n.order_summary_address_title(),
            content: task?.location?.address || "-",
        },
        {
            label: i18n.order_summary_motive_title(),
            content: task?.motive
                ? i18nDK(
                      `order_motives_label_${task?.motive?.reason
                          .split(/(?=[A-Z])/)
                          .join("_")
                          .toLowerCase()}`,
                  )
                : "-",
        },
        // START FLEXIBLE PLANNING
        ...(isFlexiblePlanningEnabled && planning
            ? [
                  {
                      label: i18n.order_summary_planning_title(),
                      content: (
                          <div>
                              <p>
                                  {i18n.order_summary_planning_sentence_1({
                                      siders:
                                          planning.siders +
                                          (planning.siders > 1 ? " Siders" : " Sider"),
                                      weeklyVolume: planning.weeklyVolume,
                                  })}
                                  <br />
                                  {i18n.order_summary_planning_sentence_2({
                                      startDate: format(new Date(planning.startDate), "P", {
                                          locale,
                                      }),
                                      endDate: format(new Date(planning.endDate), "P", { locale }),
                                  })}
                                  <br />
                                  {`${planningDaysSorted
                                      .reduce((acc, value) => {
                                          if (planning.include[value]) {
                                              return [
                                                  ...acc,
                                                  i18nDK(
                                                      `order_summary_planning_sentence_${value}`,
                                                  ),
                                              ];
                                          }
                                          return acc;
                                      }, [])
                                      .map((day, index, self) => {
                                          if (index === self.length - 1) {
                                              return day;
                                          } else if (index === self.length - 2) {
                                              return `${day} ${i18n.order_summary_planning_sentence_and()} `;
                                          }
                                          return `${day}, `;
                                      })
                                      .join("")}${
                                      planning.include.holidays
                                          ? ` ${i18n.order_summary_planning_sentence_holidays()}`
                                          : ""
                                  }`}
                                  <br />
                                  {i18n.order_summary_planning_sentence_3({
                                      amplitudeStart: planning.amplitudeStart,
                                      amplitudeEnd: planning.amplitudeEnd,
                                  })}
                              </p>

                              <p className='mt-1 text-gray-300 typography-body-s-regular'>
                                  {(() => {
                                      const sentence = i18n.order_summary_planning_sentence_figures(
                                          {
                                              total: quotation?.assignments.reduce(
                                                  (acc, assignment) => {
                                                      return acc + Number(assignment.quantity);
                                                  },
                                                  0,
                                              ),
                                          },
                                      );
                                      return `${sentence} ${customFormatDuration(new Date(planning.startDate), new Date(planning.endDate))}`;
                                  })()}
                              </p>
                          </div>
                      ),
                      action: () =>
                          navigate({
                              to: "/taskPosting/$taskId/flexible-planning",
                              params: { taskId },
                          }),
                  },
              ]
            : [
                  {
                      label: isFlexiblePlanningEnabled
                          ? i18n.order_summary_planning_title()
                          : i18n.order_summary_shifts_title(),
                      content: <ShiftsTable shifts={task?.shifts || []} /> || "-",
                      layout: "block",
                  },
              ]),
        // END FLEXIBLE PLANNING
        {
            label: i18n.order_summary_workers_title(),
            content: task?.requestedSiders ? <UsersList users={task?.requestedSiders} /> : "-",
            contentBelow: task?.requestedSidersOnly ? (
                <Tip isWarning='true' content={i18n.order_summary_workers_wa_warning()} />
            ) : null,
        },
        {
            label: i18n.order_summary_info_title(),
            content: task?.missionInformation || "-",
        },
    ];

    function openQuotationModal() {
        ModalsService.openModal({
            id: `PRICE_ESTIMATION`,
            content: (
                <ModalSimple
                    title={
                        <SectionHeader title={i18n.order_summary_estimate_title()} icon='Trophy' />
                    }
                    hideModal={() => ModalsService.closeModal(`PRICE_ESTIMATION`)}
                    withCloseButton={true}
                >
                    <Quotation quotation={quotation} withTaxes={true} />
                </ModalSimple>
            ),
        });
    }

    return (
        <div className='task-view'>
            <header className='order-form__header task-view__header'>
                <div className='task-view__header__details'>
                    <IconButton icon='ArrowLeft' action={() => navigateBack()} color='white' />

                    <p className='order-form__details__name' title={task?.name}>
                        {task?.name}
                    </p>
                    <Tag
                        big={true}
                        color={tagColors[task?.progressStep]}
                        label={i18nDK(`task_status_${task?.progressStep}`)}
                    />
                </div>

                {task?.status !== taskStatus.DRAFT ? (
                    <Button
                        action={() => {
                            ModalsService.openModal({
                                id: `DUPLICATE_TASK`,
                                content: <DuplicateModal task={task} />,
                            });
                        }}
                        color='blue'
                    >
                        {i18n.duplicate_draft()}
                    </Button>
                ) : (
                    <Button
                        action={() =>
                            navigate({
                                to: `/taskPosting/$taskId/motive`,
                                params: { taskId },
                            })
                        }
                        color='blue'
                    >
                        {i18n.finish_draft()}
                    </Button>
                )}
            </header>

            <section className='task-view__content'>
                <section className='task-view__summary__wrapper'>
                    <div className='task-view__summary'>
                        <SectionHeader title={i18n.order_summary_title()} icon='Trophy' />

                        <div className='order-form__summary__order__items'>
                            {orderSections.map(
                                (section) =>
                                    section?.content &&
                                    section?.label && (
                                        <SummaryTableRow
                                            key={section.label}
                                            label={section.label}
                                            content={section.content}
                                            action={section?.action ?? section.action}
                                            actionIcon='Pen'
                                            layout={section.layout}
                                        />
                                    ),
                            )}
                        </div>
                    </div>
                </section>

                <section className='task-view__quotation'>
                    <SectionHeader title={i18n.order_summary_estimate_title()} icon='EuroSign' />

                    <div className='task-view__price-estimate'>
                        <div className='task-view__price-estimate__label'>
                            {i18n.order_summary_estimate_total_without_taxes()}
                        </div>
                        <div className='task-view__price-estimate__amount'>
                            {quotation?.price ? `${parseFloat(quotation.price).toFixed(2)}€` : "-"}
                        </div>
                    </div>
                    <div className='task-view__price-estimate-tax'>
                        <div className='task-view__price-estimate-tax__label'>
                            {i18n.order_summary_estimate_total_with_taxes()}
                        </div>
                        <div className='task-view__price-estimate-tax__amount'>
                            {quotation?.price
                                ? `${(parseFloat(quotation.price) * 1.2).toFixed(2)}€`
                                : "-"}
                        </div>
                    </div>

                    <button
                        className='task-view__confirmation__price__button'
                        onClick={() => openQuotationModal()}
                        type='button'
                        disabled={!task?.shifts}
                    >
                        {i18n.order_summary_estimate_details()}
                    </button>
                </section>
            </section>
        </div>
    );
};

export default TaskView;
