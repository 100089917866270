import { i18n } from "@lib/i18n";
import { rootRoute } from "@routes";
import { createRoute } from "@tanstack/react-router";

import SignUp from "./SignUp";

export const signUpRoute = createRoute({
    getParentRoute: () => rootRoute,
    path: `signup`,
    component: SignUp,
    beforeLoad: () => ({ getTitle: () => i18n.sign_up_title() }),
});
