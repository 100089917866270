import { ArrowLeft } from "lucide-react";
import { SubmitHandler, useForm, useFormContext } from "react-hook-form";
import { Dialog, Input, TextField } from "saphir";
import { Button } from "sui";
import { z } from "zod";

import { zodResolver } from "@hookform/resolvers/zod";
import { postCompanyMotive } from "@lib/api/postCompanyMotive";
import { i18n } from "@lib/i18n";
import { queries } from "@lib/queries";
import trackEvent from "@lib/trackers";
import { ACTIVITY_INCREASE } from "@routes/TaskPosting/MotiveStep/MotiveStep";
import { useMutation, useQueryClient, useSuspenseQuery } from "@tanstack/react-query";

import { StepContentProps } from "./EditTaskMotiveDialog";

export function ActivityIncreaseStep(props: StepContentProps) {
    const { data: company } = useSuspenseQuery(queries.company.detail());
    const schema = z.object({
        justification: z.string().min(1, i18n.error_field_is_required()),
    });

    // this is the form to set the motive and justification
    const { setValue } = useFormContext();

    type Inputs = z.infer<typeof schema>;

    const {
        register,
        handleSubmit,
        formState: { errors, isValid },
        reset,
    } = useForm<Inputs>({
        mode: "onChange",
        resolver: zodResolver(schema),
        defaultValues: {
            justification: "",
        },
    });

    const queryClient = useQueryClient();

    const { mutate: postCompanyMotiveMutation, isPending } = useMutation({
        mutationFn: postCompanyMotive,
        onSuccess: (data) => {
            setValue(
                "selectedJustification",
                {
                    label: data.justification,
                    value: data.id,
                },
                { shouldDirty: true, shouldValidate: true },
            );
            reset({
                justification: "",
            });
            trackEvent({
                name: `companymotives - created justification`,
                params: {
                    orgId: company.organisation.id,
                    reason: ACTIVITY_INCREASE,
                },
            });
            props.setStep("initial");
        },
        onSettled: () => {
            queryClient.invalidateQueries(queries.company.companyMotives());
        },
    });

    const onSubmit: SubmitHandler<Inputs> = async (data) => {
        postCompanyMotiveMutation({
            reason: ACTIVITY_INCREASE,
            justification: data.justification.trim(),
        });
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Dialog.ScrollArea>
                <Dialog.Header>
                    <Dialog.Title className='flex items-center gap-2'>
                        <Button
                            shape='outlined'
                            intention='secondary'
                            onClick={() => props.setStep("initial")}
                            iconDisposition='icon-only'
                            icon={<ArrowLeft />}
                        />
                        {i18n.motive_activity_increase_title()}
                    </Dialog.Title>
                    <Dialog.Description>
                        <>
                            {i18n.motive_activity_increase_subtitle()}
                            &nbsp;
                            <a
                                href={i18n.motive_activity_increase_explaination_link()}
                                target='_blank'
                                rel='noopener noreferrer external'
                                className='inline font-medium underline'
                            >
                                {i18n.motive_activity_increase_explaination_link_label()}
                            </a>
                        </>
                    </Dialog.Description>
                </Dialog.Header>
                <Dialog.Main>
                    <Input.Root>
                        <Input.Label>{i18n.motive_activity_increase_label()}</Input.Label>
                        <Input.Element>
                            <TextField.Root
                                placeholder={i18n.motive_activity_increase_placeholder()}
                                {...register("justification")}
                                required
                            />
                        </Input.Element>
                        <Input.ErrorMessage>{errors.justification?.message}</Input.ErrorMessage>
                    </Input.Root>
                </Dialog.Main>
            </Dialog.ScrollArea>
            <Dialog.Footer className='justify-end'>
                <div className='flex gap-2'>
                    <Dialog.Close asChild>
                        <Button type='button' shape='invisible' intention='secondary'>
                            {i18n.action_cancel()}
                        </Button>
                    </Dialog.Close>
                    <Button loading={isPending} disabled={!isValid}>
                        {i18n.order_justifications_create_action()}
                    </Button>
                </div>
            </Dialog.Footer>
        </form>
    );
}
