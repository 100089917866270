import { useRef, useState } from "react";
import { RadioButtons, Select } from "side-ui";

import { i18n } from "@lib/i18n";

import "./ManagerForm.scss";

const ManagerForm = ({ managerId, setManager, companyManagers }) => {
    const [state, setState] = useState({
        choice: managerId ? "now" : "later",
        id: managerId,
    });

    // using a ref to only hide the "select later" option if there was no managerId passed, not if it was changed
    const managerSelectRef = useRef(managerId);

    function handleCheckboxChange({ currentTarget }, key) {
        return setState((prevState) => ({
            ...prevState,
            [key]: currentTarget.value,
        }));
    }

    function handleManagerSelection(_, __, selectedItem) {
        setState((prevState) => ({
            ...prevState,
            id: selectedItem,
        }));

        setManager(selectedItem);
    }

    const radioOptions = [
        {
            name: "manager",
            value: "now",
            label: i18n.manager_form_choice_now(),
            children:
                state.choice === "now" ? (
                    <Select
                        id='manager-choice'
                        name='choice'
                        label={i18n.manager_form_label()}
                        placeholder={i18n.manager_form_label()}
                        options={companyManagers}
                        chevronIcon={true}
                        onChange={handleManagerSelection}
                        selectedItem={
                            companyManagers.find(({ value }) => value === state.id)?.label || null
                        }
                    />
                ) : null,
        },
        {
            name: "manager",
            label: i18n.manager_form_choice_later(),
            value: "later",
            onChange: (e) => handleCheckboxChange(e, "choice"),
        },
    ];

    return (
        <div className='manager-form' id='manager'>
            <div className='manager-form__wrapper manager-form--padded'>
                <p className='input-label'>{i18n.manager_form_title()}</p>

                <RadioButtons
                    options={!managerSelectRef.current ? radioOptions : radioOptions.slice(0, 1)}
                    value={{
                        now: state.choice === "now",
                        later: state.choice === "later",
                    }}
                    onChange={(e) => {
                        // user toggled back to false
                        // update as well
                        if (state.choice === "now") {
                            setManager(managerId);
                        }

                        handleCheckboxChange(e, "choice");
                    }}
                />
            </div>
        </div>
    );
};

export default ManagerForm;
