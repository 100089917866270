import { useEffect, useRef } from "react";

export function useEffectSkipMount(callback: () => void, dependencies: any[]) {
    const hasMounted = useRef(false);

    const callbackRef = useRef<() => void>(() => {});
    callbackRef.current = callback;

    useEffect(() => {
        if (!hasMounted.current) {
            return;
        }

        callbackRef.current?.();
    }, dependencies);

    useEffect(() => {
        hasMounted.current = true;

        return () => {
            hasMounted.current = false;
        };
    }, []);
}
