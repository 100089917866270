import { toast } from "saphir";

import { i18n, i18nDK } from "@lib/i18n";
import { router } from "@routes";
import { debug } from "@side.co/client-debug";

import { clearLogas } from "./clearLogas";

// eslint-disable-next-line arrow-body-style
export const handleErrorAsString = (err) => {
    if (!err || !err.key) {
        return i18n.errors_generic();
    }
    return i18nDK(err.key.replace(/\./g, "_"), {
        ...(err.metadata ? err.metadata : {}),
    });
};

export const handleError = (err, statusCode = 500) => {
    console.error(err);
    debug.catch(err);
    if (statusCode === 401) {
        toast.error(handleErrorAsString(err));
        clearLogas();
        router.navigate({ to: `/` });
        return;
    }

    if (statusCode === 403) {
        toast.error(handleErrorAsString(err));
        return;
    }

    if (statusCode <= 400) {
        toast.error(handleErrorAsString(err));
        return;
    }

    toast.error(handleErrorAsString(err));
};
