import { forwardRef, useCallback, useReducer } from "react";
import { produce } from "immer";
import { Button, Modal, ModalContent, ModalFooter, ModalHeader, TextField } from "sui";

import type { Replacement } from "@lib/api";
import { postCompanyMotive } from "@lib/api/postCompanyMotive";
import { i18n } from "@lib/i18n";
import { queries } from "@lib/queries";
import trackEvent from "@lib/trackers";
import { useMutation, useQueryClient, useSuspenseQuery } from "@tanstack/react-query";

import { REPLACEMENT } from "./MotiveStep";

import styles from "./motiveModals.module.css";

type ReplacementWithId = Replacement & { id: ReturnType<typeof crypto.randomUUID> };
type ReplacementReducerAction = {
    type: "remove" | "update";
    id?: ReturnType<typeof crypto.randomUUID>;
    payload?: Partial<Replacement>;
};

type Props = {
    setSelectedJustification: React.Dispatch<
        React.SetStateAction<{
            label: string;
            value: string;
        } | null>
    >;
};

export const ReplacementModal = forwardRef<HTMLDialogElement, Props>(function ReplacementModal(
    { setSelectedJustification },
    ref,
) {
    const { data: company } = useSuspenseQuery(queries.company.detail());
    const [replacements, dispatch] = useReducer(
        (state: ReplacementWithId[], action: ReplacementReducerAction) =>
            produce(state, (draft) => {
                switch (action.type) {
                    case "remove":
                        draft.splice(
                            draft.findIndex((replacement) => replacement.id === action.id),
                            1,
                        );
                        break;
                    case "update":
                        const replacementIndex = draft.findIndex(
                            (replacement) => replacement.id === action.id,
                        );
                        if (replacementIndex !== -1 && action.payload) {
                            draft[replacementIndex] = {
                                ...draft[replacementIndex],
                                ...action.payload,
                            };
                        }
                        break;
                }
            }),
        [{ id: crypto.randomUUID(), name: "", position: "", justification: "" }],
    );

    const updateReplacement = useCallback(
        (id: ReplacementReducerAction["id"], payload: ReplacementReducerAction["payload"]) => {
            dispatch({ type: "update", id, payload });
        },
        [],
    );

    const queryClient = useQueryClient();

    const { mutate: postCompanyMotiveMutation, isPending } = useMutation({
        mutationFn: postCompanyMotive,
        onSuccess: (res, req) => {
            setSelectedJustification({
                label: req.justification,
                value: res.id,
            });
            trackEvent({
                name: `companymotives - created justification`,
                params: {
                    orgId: company.organisation.id,
                    reason: REPLACEMENT,
                },
            });
            (ref as React.MutableRefObject<HTMLDialogElement | null>).current?.close();
        },
        onSettled: () => {
            queryClient.invalidateQueries(queries.company.companyMotives());
        },
    });

    function hasEmptyValues(itemsArray) {
        return Boolean(
            itemsArray.find((arr) =>
                Object.values(arr).some((item) => {
                    return (
                        (typeof item === "string" && item.trim() === "") ||
                        item === undefined ||
                        item === null
                    );
                }),
            ),
        );
    }

    return (
        <Modal
            ref={ref}
            onSubmit={() => {
                postCompanyMotiveMutation({
                    reason: REPLACEMENT,
                    justification: `${replacements[0].name} - ${replacements[0].justification}`,
                    replacements: replacements.map((replacement) => ({
                        name: replacement.name,
                        position: replacement.position,
                        justification: replacement.justification.trim(),
                    })),
                });
            }}
            onClose={() => {
                updateReplacement(replacements[0].id, {
                    name: "",
                    position: "",
                    justification: "",
                });
            }}
        >
            <div className={styles.scrollContainer}>
                <ModalHeader
                    title={i18n.motive_replacement_title()}
                    description={
                        <p>
                            {i18n.motive_replacement_subtitle()}
                            &nbsp;
                            <a
                                href={i18n.motive_replacement_explaination_link()}
                                target='_blank'
                                rel='noopener noreferrer external'
                                className={styles.link}
                            >
                                {i18n.motive_replacement_explaination_link_label()}
                            </a>
                        </p>
                    }
                />
                <ModalContent>
                    <div className={styles.multipleEntriesShelf}>
                        {replacements.map((replacement) => {
                            return (
                                <div
                                    className={styles.multipleEntriesContainer}
                                    key={replacement.id}
                                >
                                    <div className={styles.flex}>
                                        <TextField
                                            className={styles.requiredField}
                                            label={i18n.motive_replacement_name_label()}
                                            placeholder={i18n.motive_replacement_name_placeholder()}
                                            value={replacement.name}
                                            onChange={(e) => {
                                                updateReplacement(replacement.id, {
                                                    name: e.target.value,
                                                });
                                            }}
                                            size='small'
                                            required
                                            autoComplete='off'
                                        />
                                        <TextField
                                            className={styles.requiredField}
                                            label={i18n.motive_replacement_reason_label()}
                                            placeholder={i18n.motive_replacement_reason_placeholder()}
                                            value={replacement.justification}
                                            onChange={(e) => {
                                                updateReplacement(replacement.id, {
                                                    justification: e.target.value,
                                                });
                                            }}
                                            size='small'
                                            required
                                            autoComplete='off'
                                        />
                                    </div>
                                    <TextField
                                        className={styles.requiredField}
                                        label={i18n.motive_replacement_position_label()}
                                        placeholder={i18n.motive_replacement_position_placeholder()}
                                        value={replacement.position}
                                        onChange={(e) => {
                                            updateReplacement(replacement.id, {
                                                position: e.target.value,
                                            });
                                        }}
                                        size='small'
                                        required
                                        autoComplete='off'
                                    />
                                </div>
                            );
                        })}
                    </div>
                    <div className={styles.spacer} />
                </ModalContent>
            </div>
            <ModalFooter
                mainButton={
                    <Button loading={isPending} disabled={hasEmptyValues(replacements)}>
                        {i18n.order_justifications_create_action()}
                    </Button>
                }
                cancelButtonLabel={i18n.cancel()}
            />
        </Modal>
    );
});
