import * as yup from "yup";

import { i18n } from "@lib/i18n";

export const otherParametersSchema = () => ({
    seniority: yup.object().shape({
        checked: yup.boolean(),
        daysThreshold: yup.number().when("checked", {
            is: true,
            then: yup
                .number()
                .moreThan(
                    0,
                    i18n.settings_remuneration_indemnities_other_parameters_seniority_error_more_than_0(),
                )
                .required(i18n.error_field_is_required()),
            otherwise: yup.number(),
        }),
    }),
    hoursPerDay: yup.object().shape({
        checked: yup.boolean(),
        threshold: yup.number().when("checked", {
            is: true,
            then: yup
                .number()
                .moreThan(
                    0,
                    i18n.settings_remuneration_indemnities_other_parameters_hours_per_day_error(),
                )
                .lessThan(
                    13,
                    i18n.settings_remuneration_indemnities_other_parameters_hours_per_day_error(),
                )
                .required(i18n.error_field_is_required()),
            otherwise: yup.number(),
        }),
    }),
});

export const clearOtherParametersValues = (values) => ({
    ...(values.seniority.checked
        ? {
              seniority: { daysThreshold: parseInt(values.seniority.daysThreshold, 10) },
          }
        : {}),
    ...(values.hoursPerDay.checked
        ? {
              hoursPerDay: { threshold: parseInt(values.hoursPerDay.threshold, 10) },
          }
        : {}),
    ...(values.zti.checked ? { zti: { isApplicable: true } } : {}),
    ...(values.taskType.checked ? { taskType: { jobTypes: values.taskType.jobTypes } } : {}),
});
