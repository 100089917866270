import { useEffect, useState } from "react";
import { atom, useAtom } from "jotai";
import moment from "moment";
import { VerticalNavigation } from "side-ui";

import { queryClient } from "@App";
import * as backend from "@lib/api";
import { checkGoogleAuthentication } from "@lib/api/checkGoogleAuthentication";
import { useLocation } from "@lib/hooks/useLocation";
import { i18n } from "@lib/i18n";
import { queries } from "@lib/queries";
import changeLocale from "@lib/utils/changeLocale";
import { debug } from "@side.co/client-debug";
import { useQuery } from "@tanstack/react-query";
import { useNavigate } from "@tanstack/react-router";

import { MenuMainItem } from "./MenuMainItem";

export const navigationModeAtom = atom("expanded");

const Navigation = (props) => {
    const { countContractsBatchesToSign } = props;

    const location = useLocation();
    const navigate = useNavigate();

    const { data: user } = useQuery(queries.user.detail());
    const { data: attendancesToValidate } = useQuery(queries.attendance.toValidateCount());

    const [navigationMode, setNavigationMode] = useAtom(navigationModeAtom);
    const [active, setActive] = useState(location.pathname);
    const isShrinked = navigationMode === "shrinked";
    const [error, setError] = useState(null);

    type Children = {
        objectID: string;
        sublabel: string;
        label: string;
        pictureUrl: string;
    };

    const [children, setChildren] = useState<Children[]>([]);

    const normalizeChildren = (data: {
        Organisations: {
            id: string;
            address: string;
            name: string;
            pictureURL: string;
        }[];
    }) => {
        let normalizedChildren: Children[] = [];
        if (data?.Organisations.length > 0) {
            normalizedChildren = data.Organisations.map((child) => ({
                objectID: child.id,
                sublabel: child.address,
                label: child.name,
                pictureUrl: child.pictureURL,
            }));
        }
        return normalizedChildren;
    };

    async function getChildren(searchFieldValue) {
        let res;
        try {
            res = await backend.searchOrganisation({
                q: searchFieldValue,
            });
        } catch (e) {
            setError(e.toString());
        }
        if (res) {
            if (res.status >= 400) {
                debug.log(`API call error: ${res.statusText}`, {
                    level: `warning`,
                    extra: {
                        res: JSON.stringify(res),
                    },
                });
                setError(res.statusText);
                return;
            }

            const json = await res.json();
            const normalizedData = normalizeChildren(json);
            if (json) {
                setChildren(normalizedData);
            }
        }
    }

    useEffect(() => {
        if (orgaIsCompany) {
            countContractsBatchesToSign();
        }

        if (orgaIsCompany && orgaIsGroup) {
            getChildren("");
        }

        if (localStorage.getItem("google_login")) {
            checkGoogleAuthentication();
        }
    }, []);

    const { contracts } = props;

    const { data: company } = useQuery(queries.company.detail());

    const orgaIsGroup = localStorage.getItem(`side_team_groupId`) !== null;
    const orgaIsCompany = localStorage.getItem(`side_team_activeOrganisationId`) !== null;
    const groupId = localStorage.getItem("side_team_groupId");
    const isInsider = !!localStorage.getItem(`side_team_logas_email`);

    const goBackHome = () =>
        orgaIsGroup
            ? (localStorage.removeItem(`side_team_activeOrganisationId`),
              queryClient.invalidateQueries(queries.company.detail()),
              navigate({ to: "/home" }))
            : navigate({ to: `/tasks` });

    // list of actions to display in the header
    const headerActions = [
        {
            icon: "House",
            label: i18n.header_backhome(),
            action: () => goBackHome(),
            show: () => orgaIsCompany === true && orgaIsGroup === true,
        },
    ].filter((item) => !item.show || item.show());

    // list of actions in the footer dropdown
    const footerActions = [
        {
            label: i18n.header_dropdown_personal_settings(),
            action: () => {
                navigate({ to: `/me/profile` });
            },
            icon: "Information",
            customClass: `--with_border-bottom`,
        },
        {
            label: i18n.header_dropdown_help(),
            action: () => window.open(`https://support.side.co/b2b/fr`),
            icon: "Book",
        },
        {
            label: i18n.header_dropdown_language(),
            action: () => changeLocale(moment.locale() === "en" ? "fr" : "en"),
            icon: moment.locale() === "en" ? "FlagFR" : "FlagUK",
        },
        {
            label: i18n.header_dropdown_logout(),
            action: () => {
                queryClient.removeQueries();
                if (isInsider) {
                    localStorage.removeItem("side_team_activeOrganisationId");
                    window.location.assign("/side-admin");
                } else {
                    window.localStorage.clear();
                    window.location.assign("/signin");
                }
            },
            icon: "Power",
            customClass: `--color-red`,
        },
    ];

    const groupMobileItems = [
        {
            key: "/settings/team",
            label: i18n.header_team(),
            icon: "UserTeam",
            show: () => orgaIsGroup === true && !orgaIsCompany,
        },
    ]
        .filter((item) => !item.show || item.show())
        .map((item) => {
            return {
                ...item,
                action: () => {
                    setActive(item.key);
                    navigate({ to: item.key });
                },
                isActive: active.includes(item.key),
            };
        });

    const companyNavItems = [
        {
            key: "/home",
            label: i18n.header_search_company(),
            icon: "CompanySearch",
            show: () => orgaIsGroup === true && !orgaIsCompany,
        },
        {
            key: "/settings/team",
            label: i18n.header_team(),
            icon: "UserTeam",
            show: () => orgaIsGroup === true && !orgaIsCompany,
        },
        {
            key: "/planning",
            label: i18n.header_planning(),
            icon: "Calendar",
            show: () => orgaIsCompany === true,
        },
        {
            key: "/tasks",
            label: i18n.header_tasks(),
            icon: "Briefcase",
            show: () => orgaIsCompany === true,
        },
        {
            key: "/job-descriptions",
            label: i18n.header_job_descriptions(),
            icon: "Bolt",
            show: () => user?.role !== "collaborator",
        },
        {
            key: "/contracts",
            label: i18n.header_contracts(),
            icon: "DocumentLines",
            notifications: contracts.toSignCount,
            show: () => user && ["admin", "user"].includes(user?.role) && orgaIsCompany === true,
        },
        {
            key: "/timesheets",
            label: i18n.header_timesheets(),
            icon: "ClockXL",
            notifications: attendancesToValidate?.count,
            show: () => orgaIsCompany === true,
        },
        {
            key: "/invoices",
            label: i18n.header_invoices(),
            icon: "EuroSign",
            show: () => user && ["admin", "user"].includes(user?.role) && orgaIsCompany === true,
        },
        {
            key: "/settings",
            label: i18n.header_settings(),
            icon: "Cog",
            show: () => user && ["admin", "user"].includes(user?.role) && orgaIsCompany === true,
        },
    ]
        .filter((item) => !item.show || item.show())
        .map((item) => {
            return {
                ...item,
                action: () => {
                    setActive(item.key);
                    navigate({ to: item.key });
                },
                isActive: active.includes(item.key),
            };
        });

    const connectToChild = (option) => {
        localStorage.setItem(`side_team_activeOrganisationId`, option.objectID);

        // We reload the current page so all the Redux actions are dispatched again
        queryClient.invalidateQueries(queries.company.detail());
        window.location.reload();
    };

    return (
        <div>
            <VerticalNavigation
                headerDropdownId={groupId && `switch_${groupId}`}
                headerDropdownOnChange={(value) => getChildren(value)}
                headerDropdownOnOptionClick={(option) => connectToChild(option)}
                headerDropdownSelectedOption={
                    orgaIsGroup &&
                    orgaIsCompany && {
                        objectID: localStorage.getItem(`side_team_activeOrganisationId`),
                        label: company?.organisation.name,
                    }
                }
                headerDropdownPlaceholder={i18n.header_switch_search_placeholder()}
                headerDropdownError={error}
                headerDropdownOptions={children}
                headerLogoOnClick={() => goBackHome()}
                headerActions={headerActions}
                menuItems={companyNavItems}
                menuMainItem={
                    user && ["admin", "user"].includes(user?.role) && orgaIsCompany === true ? (
                        <MenuMainItem isShrinked={isShrinked} />
                    ) : null
                }
                groupItems={groupMobileItems}
                user={user}
                footerActions={footerActions}
                group={
                    localStorage.getItem(`side_team_groupId`) &&
                    company &&
                    company?.parent?.length > 0
                        ? { name: company?.parent, logoUrl: company?.organisation.logoUrl }
                        : null
                }
                entity={
                    company && company?.organisation?.name?.length > 0
                        ? company?.organisation
                        : null
                }
                isShrinked={isShrinked}
                setIsShrinked={(bool) => {
                    setNavigationMode(bool ? "shrinked" : "expanded");
                }}
            />
        </div>
    );
};

export default Navigation;
