import { StrictMode } from "react";
import { Provider } from "react-redux";
import { Toaster } from "saphir";

import trackEvent from "@lib/trackers";
import { setLanguageTag } from "@paraglide/runtime";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { AppRouter } from "@routes";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { FlagProvider } from "@unleash/proxy-client-react";

import Error from "./components/Error";

import "@styles/tailwind.css";

import "saphir/props";
import "sui/props";

const config = {
    url: process.env.REACT_APP_UNLEASH_URL || import.meta.env.REACT_APP_UNLEASH_URL,
    clientKey:
        process.env.REACT_APP_UNLEASH_CLIENT_KEY || import.meta.env.REACT_APP_UNLEASH_CLIENT_KEY,
    refreshInterval: 15,
    appName: "team",
};
setLanguageTag((localStorage.getItem("side_team_locale") || "fr") as "en" | "fr");

export const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            staleTime: 1000 * 20,
            retry: 1,
        },
    },
});

trackEvent({
    name: "team - get user locale",
    params: { locale: localStorage.getItem("side_team_locale") || "fr" },
});

const App = ({ store, errorDisplay = false }) => (
    <StrictMode>
        <Provider store={store}>
            <FlagProvider config={config}>
                <GoogleOAuthProvider clientId='54503600174-07prhmecenv6bic8im520a7uq1a9b8sb.apps.googleusercontent.com'>
                    <QueryClientProvider client={queryClient}>
                        <div className='app'>
                            {errorDisplay && <Error />}
                            <AppRouter />
                            <Toaster />
                        </div>
                        <ReactQueryDevtools initialIsOpen={false} />
                    </QueryClientProvider>
                </GoogleOAuthProvider>
            </FlagProvider>
        </Provider>
    </StrictMode>
);

export default App;
