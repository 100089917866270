import { Loader } from "sui";
import { z } from "zod";

import { i18n } from "@lib/i18n";
import { queries } from "@lib/queries";
import { globalLayoutRoute } from "@routes";
import { allowCompany } from "@routes/authorization";
import { createRoute, Outlet, redirect } from "@tanstack/react-router";
import { useFlag } from "@unleash/proxy-client-react";

import TaskView from "./components/TaskView";
import TaskViewOld from "./components/TaskViewOld";
import TasksListHome from "./containers/TasksListHome";
import TasksListStatus from "./containers/TasksListStatus";
import TasksList from "./index";

export const tasksRoute = createRoute({
    getParentRoute: () => globalLayoutRoute,
    path: `tasks`,
    component: Outlet,
    beforeLoad: () => {
        if (!allowCompany()) {
            throw redirect({ to: `/` });
        }

        return { getTitle: () => i18n.doc_title_tasks() };
    },
});
export const tasksRouteLayout = createRoute({
    id: `tasksLayout`,
    getParentRoute: () => tasksRoute,
    component: TasksList,
});
export const tasksListIndexRoute = createRoute({
    getParentRoute: () => tasksRouteLayout,
    path: `/`,
    beforeLoad: () => {
        throw redirect({ to: `/tasks/all` });
    },
});
export const tasksListHomeRoute = createRoute({
    getParentRoute: () => tasksRouteLayout,
    path: `all`,
    component: TasksListHome,
});
const tasksListStatusSearchSchema = z
    .object({
        page: z.number().catch(1),
    })
    .partial();

type TaskListStatusSearch = z.infer<typeof tasksListStatusSearchSchema>;
export const tasksListStatusRoute = createRoute({
    getParentRoute: () => tasksRouteLayout,
    path: `$status`,
    validateSearch: (search: TaskListStatusSearch) => tasksListStatusSearchSchema.parse(search),
    component: TasksListStatus,
});

const taskViewRouteSearchSchema = z.object({
    origin: z.string().optional(),
});
export const taskViewRoute = createRoute({
    getParentRoute: () => tasksRoute,
    path: `view/$taskId`,
    validateSearch: taskViewRouteSearchSchema,
    loader: async (opts) => {
        // Don't render the route at all until the user is loaded because feature flag depends on user data
        await opts.context.queryClient.ensureQueryData(queries.user.detail());
    },
    component: () => {
        //@FEATURE_FLAG:task-modification
        const isTaskModificationFlagEnabled = useFlag("task-modification");

        if (isTaskModificationFlagEnabled) {
            return <TaskView />;
        }

        return <TaskViewOld />;
    },
    beforeLoad: () => {
        if (!allowCompany()) {
            throw redirect({ to: `/` });
        }
    },
    pendingComponent: () => (
        <div className='grid h-full w-full place-items-center text-blue-500'>
            <Loader />
        </div>
    ),
});
