import { format } from "date-fns";
import { ArrowLeft } from "lucide-react";
import { Controller, SubmitHandler, useForm, useFormContext } from "react-hook-form";
import { Dialog, Input, TextField } from "saphir";
import { Button, DatePicker } from "sui";
import { z } from "zod";

import { zodResolver } from "@hookform/resolvers/zod";
import { postCompanyMotive } from "@lib/api/postCompanyMotive";
import { i18n } from "@lib/i18n";
import { queries } from "@lib/queries";
import trackEvent from "@lib/trackers";
import { getLocale } from "@lib/utils/getLocale";
import { RECRUITMENT } from "@routes/TaskPosting/MotiveStep/MotiveStep";
import { useMutation, useQueryClient, useSuspenseQuery } from "@tanstack/react-query";

import { StepContentProps } from "./EditTaskMotiveDialog";

export function RecruitmentStep(props: StepContentProps) {
    const { data: company } = useSuspenseQuery(queries.company.detail());
    const schema = z.object({
        name: z.string().min(1, i18n.error_field_is_required()),
        date: z.date(),
        position: z.string().min(1, i18n.error_field_is_required()),
    });

    // this is the form to set the motive and justification
    const { setValue } = useFormContext();

    type Inputs = z.infer<typeof schema>;

    const {
        control,
        handleSubmit,
        formState: { errors, isValid },
        reset,
    } = useForm<Inputs>({
        mode: "onChange",
        resolver: zodResolver(schema),
        defaultValues: {
            name: "",
            date: new Date(),
            position: "",
        },
    });

    const queryClient = useQueryClient();

    const { mutate: postCompanyMotiveMutation, isPending } = useMutation({
        mutationFn: postCompanyMotive,
        onSuccess: (data) => {
            setValue(
                "selectedJustification",
                {
                    label: data.justification,
                    value: data.id,
                },
                { shouldDirty: true, shouldValidate: true },
            );
            reset({
                name: "",
                date: undefined,
                position: "",
            });
            trackEvent({
                name: `companymotives - created justification`,
                params: {
                    orgId: company.organisation.id,
                    reason: RECRUITMENT,
                },
            });
            props.setStep("initial");
        },
        onSettled: () => {
            queryClient.invalidateQueries(queries.company.companyMotives());
        },
    });

    const onSubmit: SubmitHandler<Inputs> = async (data) => {
        postCompanyMotiveMutation({
            reason: RECRUITMENT,
            justification: `${data.name} - ${format(data.date!, "P", {
                locale: getLocale(),
            })}`,
            recruitments: [{ ...data, date: data.date.toISOString() }],
        });
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Dialog.ScrollArea>
                <Dialog.Header>
                    <Dialog.Title className='flex items-center gap-2'>
                        <Button
                            shape='outlined'
                            intention='secondary'
                            onClick={() => props.setStep("initial")}
                            iconDisposition='icon-only'
                            icon={<ArrowLeft />}
                        />
                        {i18n.motive_recruitment_title()}
                    </Dialog.Title>
                    <Dialog.Description>
                        <>
                            {i18n.motive_recruitment_subtitle()}
                            &nbsp;
                            <a
                                href={i18n.motive_recruitment_explaination_link()}
                                target='_blank'
                                rel='noopener noreferrer external'
                                className='inline font-medium underline'
                            >
                                {i18n.motive_recruitment_explaination_link_label()}
                            </a>
                        </>
                    </Dialog.Description>
                </Dialog.Header>
                <Dialog.Main className='flex flex-col gap-2'>
                    <div className='grid grid-cols-2 gap-2'>
                        <Input.Root>
                            <Input.Label>{i18n.motive_recruitment_name_label()}</Input.Label>
                            <Controller
                                control={control}
                                name='name'
                                render={({ field }) => {
                                    return (
                                        <Input.Element>
                                            <TextField.Root
                                                placeholder={i18n.motive_recruitment_name_placeholder()}
                                                required
                                                value={field.value}
                                                onChange={field.onChange}
                                            />
                                        </Input.Element>
                                    );
                                }}
                            />
                            <Input.ErrorMessage>{errors.name?.message}</Input.ErrorMessage>
                        </Input.Root>
                        <Controller
                            control={control}
                            name='date'
                            render={({ field }) => (
                                <Input.Root>
                                    <Input.Label>
                                        {i18n.motive_recruitment_date_label()}
                                    </Input.Label>
                                    <Input.Element>
                                        <DatePicker
                                            selected={field.value || new Date()}
                                            onSelect={(date) => {
                                                field.onChange(date);
                                            }}
                                            size='small'
                                            portal={false}
                                            required
                                        />
                                    </Input.Element>
                                    <Input.ErrorMessage>{errors.date?.message}</Input.ErrorMessage>
                                </Input.Root>
                            )}
                        />
                    </div>
                    <Input.Root>
                        <Input.Label>{i18n.motive_recruitment_position_label()}</Input.Label>
                        <Controller
                            control={control}
                            name='position'
                            render={({ field }) => (
                                <Input.Element>
                                    <TextField.Root
                                        placeholder={i18n.motive_recruitment_position_placeholder()}
                                        required
                                        value={field.value}
                                        onChange={field.onChange}
                                    />
                                </Input.Element>
                            )}
                        />
                        <Input.ErrorMessage>{errors.position?.message}</Input.ErrorMessage>
                    </Input.Root>
                </Dialog.Main>
            </Dialog.ScrollArea>
            <Dialog.Footer className='justify-end'>
                <div className='flex gap-2'>
                    <Dialog.Close asChild>
                        <Button type='button' shape='invisible' intention='secondary'>
                            {i18n.action_cancel()}
                        </Button>
                    </Dialog.Close>
                    <Button loading={isPending} disabled={!isValid}>
                        {i18n.order_justifications_create_action()}
                    </Button>
                </div>
            </Dialog.Footer>
        </form>
    );
}
