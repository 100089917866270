import { Component } from "react";
import { Field } from "formik";
import { ToggleSwitch } from "side-ui";

import { i18n } from "@lib/i18n";

import { HoursPerDayCondition } from "./HoursPerDayCondition";
import { SeniorityCondition } from "./SeniorityCondition";
import { TaskTypeCondition } from "./TaskTypeCondition";
import { ZTICondition } from "./ZTICondition";

import "./OtherParameters.scss";

export const shouldToggleBeChecked = (otherParameters) => {
    let otherParametersToggled = false;
    if (
        otherParameters &&
        ((otherParameters.seniority || {}).checked ||
            (otherParameters.hoursPerDay || {}).checked ||
            (otherParameters.zti || {}).checked ||
            (otherParameters.taskType || {}).checked)
    ) {
        otherParametersToggled = true;
    }

    return otherParametersToggled;
};

export const initialOtherParametersValue = {
    seniority: {
        checked: false,
        daysThreshold: "30",
    },
    hoursPerDay: {
        checked: false,
        threshold: "4",
    },
    zti: {
        checked: false,
    },
    taskType: {
        checked: false,
        jobTypes: [],
    },
};

export const initialOtherParametersFromValue = (otherParameters) => {
    if (!otherParameters) {
        return initialOtherParametersValue;
    }

    return {
        seniority: otherParameters.seniority
            ? {
                  checked: true,
                  daysThreshold: otherParameters.seniority.daysThreshold,
              }
            : {
                  checked: false,
                  daysThreshold: "30",
              },
        hoursPerDay: otherParameters.hoursPerDay
            ? {
                  checked: true,
                  threshold: otherParameters.hoursPerDay.threshold,
              }
            : {
                  checked: false,
                  threshold: "4",
              },
        zti: {
            checked: !!otherParameters.zti && otherParameters.zti.isApplicable,
        },
        taskType: otherParameters.taskType
            ? {
                  checked: true,
                  jobTypes: otherParameters.taskType.jobTypes,
              }
            : {
                  checked: false,
                  jobTypes: [],
              },
    };
};

export class OtherParameters extends Component {
    constructor(props) {
        super(props);
        const { toggled } = props;

        this.state = {
            toggled,
        };
    }

    toggle() {
        const { toggled } = this.state;

        this.setState({
            toggled: !toggled,
        });
    }

    render() {
        const { toggled } = this.state;

        return (
            <div className='other-parameters__wrapper'>
                <ToggleSwitch
                    id='other-parameters-toggle'
                    label={i18n.settings_remuneration_indemnities_other_other_parameters_switch()}
                    onChange={() => this.toggle()}
                    checked={toggled}
                />
                {toggled && (
                    <div className='other-parameters__list'>
                        <Field name='otherParameters.seniority' component={SeniorityCondition} />
                        <Field
                            name='otherParameters.hoursPerDay'
                            component={HoursPerDayCondition}
                        />
                        <Field name='otherParameters.zti' component={ZTICondition} />
                        <Field name='otherParameters.taskType' component={TaskTypeCondition} />
                    </div>
                )}
            </div>
        );
    }
}
