import { Suspense, useState } from "react";
import { isEqual } from "lodash";
import { ArrowLeft, Plus } from "lucide-react";
import { Controller, FormProvider, SubmitHandler, useForm, useFormContext } from "react-hook-form";
import {
    Checkbox,
    Dialog,
    Input,
    Label,
    RadioGroup,
    TextField,
    toast,
    VisuallyHidden,
} from "saphir";
import { Button, Loader, Select } from "sui";
import { z } from "zod";

import { zodResolver } from "@hookform/resolvers/zod";
import { EquipmentSchema as JobDescriptionEquipmentSchema } from "@lib/api/getJobDescriptions";
import { i18n, i18nDK } from "@lib/i18n";
import { usePatchTask } from "@lib/mutations/usePatchTask";
import { queries } from "@lib/queries";
import { useQueryClient, useSuspenseQuery } from "@tanstack/react-query";

interface Props {
    taskId: string;
    trigger: React.ReactNode;
    tracker: () => void;
}

const steps = ["initial", "addCustomEquipment"] as const;
type Steps = (typeof steps)[number];

const EquipmentSchema = JobDescriptionEquipmentSchema.extend({
    issuedByFrontEnd: z.boolean().optional(),
});

type Equipment = z.infer<typeof EquipmentSchema>;

const schema = z.object({
    equipments: z.array(EquipmentSchema),
});

type Inputs = z.infer<typeof schema>;

export function EditTaskEquipmentsSafetyDialog(props: Props) {
    const [open, setOpen] = useState(false);
    const queryClient = useQueryClient();

    return (
        <Dialog.Root open={open} onOpenChange={setOpen}>
            <Dialog.Trigger
                asChild
                onMouseEnter={() => {
                    // prefetch jobDescriptionsOptions data
                    queryClient.ensureQueryData(queries.jobDescriptionOptions.list());
                }}
            >
                {props.trigger}
            </Dialog.Trigger>
            <Dialog.Content>
                <Suspense
                    fallback={
                        <div className='flex w-full items-center justify-center p-4 text-blue-500'>
                            <VisuallyHidden>
                                <Dialog.Title>
                                    {i18n.job_descriptions_creation_conditions_equipments_security_title()}
                                </Dialog.Title>
                            </VisuallyHidden>
                            <Loader />
                        </div>
                    }
                >
                    <EditTaskEquipmentsSafetyDialogContent {...props} setOpen={setOpen} />
                </Suspense>
            </Dialog.Content>
        </Dialog.Root>
    );
}

function EditTaskEquipmentsSafetyDialogContent(
    props: Props & { setOpen: (open: boolean) => void },
) {
    const { data: task } = useSuspenseQuery(queries.task.detail(props.taskId));
    const { data: jobDescriptionOptions } = useSuspenseQuery(queries.jobDescriptionOptions.list());
    const [step, setStep] = useState<Steps>("initial");

    const safetyEquipmentOptionsFromBackend = jobDescriptionOptions.equipmentList.filter(
        (equipment) => equipment.group === "Safety",
    );
    // custom equipments are at the task level
    const safetyEquipmentOptionsFromTask =
        task.equipments?.filter((equipment) => equipment.group === "Safety") ?? [];

    const [equipmentOptions, setEquipmentOptions] = useState(() => {
        const mergedEquipmentOptions = [
            // we want task equipments to be on top of the list
            ...safetyEquipmentOptionsFromTask,
            ...safetyEquipmentOptionsFromBackend,
        ];
        // remove duplicates by ids
        return mergedEquipmentOptions.filter(
            (equipment, index, self) => index === self.findIndex((eq) => eq.id === equipment.id),
        );
    });

    const form = useForm<Inputs>({
        mode: "onChange",
        resolver: zodResolver(schema),
        defaultValues: {
            equipments: safetyEquipmentOptionsFromTask,
        },
    });

    return (
        <FormProvider {...form}>
            {step === "initial" ? (
                <InitialStep
                    setOpen={props.setOpen}
                    setStep={setStep}
                    taskId={props.taskId}
                    tracker={props.tracker}
                    equipmentOptions={equipmentOptions}
                />
            ) : step === "addCustomEquipment" ? (
                <AddCustomEquipmentStep
                    setStep={setStep}
                    setEquipmentOptions={setEquipmentOptions}
                />
            ) : null}
        </FormProvider>
    );
}

interface InitialStepProps {
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    setStep: React.Dispatch<React.SetStateAction<"initial" | "addCustomEquipment">>;
    tracker: () => void;
    taskId: string;
    equipmentOptions: Omit<Equipment, "providedBy">[];
}

function InitialStep(props: InitialStepProps) {
    const { data: task } = useSuspenseQuery(queries.task.detail(props.taskId));
    const queryClient = useQueryClient();

    const {
        control,
        handleSubmit,
        formState: { defaultValues, isValid },
        reset,
        watch,
    } = useFormContext<Inputs>();

    const isDirty = !isEqual(
        watch("equipments").sort((a, b) => (a?.id && b?.id ? a.id.localeCompare(b.id) : 0)),
        defaultValues?.equipments?.sort((a, b) => (a?.id && b?.id ? a.id.localeCompare(b.id) : 0)),
    );

    const { patchTask, isPending } = usePatchTask({
        onSuccess: (data) => {
            queryClient.setQueryData(queries.task.detail(props.taskId).queryKey, data);
            props.tracker();

            reset({
                equipments:
                    data?.equipments?.filter((equipment) => equipment.group === "Safety") ?? [],
            });

            props.setOpen(false);
            toast.success(i18n.task_edit_success_toast());
        },
        onSettled: () => {
            queryClient.invalidateQueries({ queryKey: ["task"] });
        },
    });

    const onSubmit: SubmitHandler<Inputs> = async (data) => {
        //remove ids from equipments that are issued by frontend
        const equipmentsToSubmit = data.equipments.map((equipment) => {
            if (equipment.issuedByFrontEnd) {
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                const { id, ...rest } = equipment;
                return rest;
            }
            return equipment;
        });
        const otherEquipmentsFromTask =
            task.equipments?.filter((equipment) => equipment.group !== "Safety") ?? [];
        patchTask({
            id: props.taskId,
            equipments: [...otherEquipmentsFromTask, ...equipmentsToSubmit],
        });
    };

    const { equipments } = watch();

    const providedByOptions = [
        {
            label: i18n.job_descriptions_creation_conditions_equipment_provider_company(),
            value: "company",
        },
        {
            label: i18n.job_descriptions_creation_conditions_equipment_provider_sider(),
            value: "sider",
        },
    ] as const;

    return (
        <form onSubmit={handleSubmit(onSubmit)} className='contents'>
            <Dialog.ScrollArea>
                <Dialog.Header>
                    <Dialog.Title>
                        {i18n.job_descriptions_creation_conditions_equipments_security_title()}
                    </Dialog.Title>
                    <Dialog.Description>
                        {i18n.job_descriptions_creation_conditions_equipments_subtitle()}
                    </Dialog.Description>
                </Dialog.Header>
                <Dialog.Main className='flex flex-col gap-2'>
                    {props.equipmentOptions.map((equipmentOption) => {
                        const checked = equipments.some(
                            (equipment) => equipment.id === equipmentOption.id,
                        );

                        return (
                            <div
                                key={equipmentOption.id}
                                className='group flex flex-col rounded border border-gray-200 hover:border-blue-500'
                            >
                                <Input.Root>
                                    <Input.Label className='flex w-full flex-row items-center gap-4 p-4 !typography-body-m-semibold'>
                                        <Controller
                                            control={control}
                                            name='equipments'
                                            render={({ field }) => {
                                                return (
                                                    <Input.Element>
                                                        <Checkbox
                                                            className='group-hover:border-blue-500'
                                                            checked={checked}
                                                            onCheckedChange={(checked) => {
                                                                if (checked) {
                                                                    field.onChange([
                                                                        ...equipments,
                                                                        equipmentOption,
                                                                    ]);
                                                                } else {
                                                                    field.onChange(
                                                                        equipments.filter(
                                                                            (equipment) =>
                                                                                equipment.id !==
                                                                                equipmentOption.id,
                                                                        ),
                                                                    );
                                                                }
                                                            }}
                                                        />
                                                    </Input.Element>
                                                );
                                            }}
                                        />
                                        {i18nDK(equipmentOption.name)}
                                    </Input.Label>
                                </Input.Root>
                                {checked ? (
                                    <div className='p-4 pt-0'>
                                        <Controller
                                            control={control}
                                            name='equipments'
                                            render={({ field }) => {
                                                return (
                                                    <Select
                                                        size='small'
                                                        placeholder={i18n.task_edit_custom_equipment_providedBy_placeholder()}
                                                        aria-label={i18n.job_descriptions_creation_conditions_equipments_security_modal_label()}
                                                        options={[...providedByOptions]}
                                                        selection={
                                                            providedByOptions.find(
                                                                (option) =>
                                                                    option.value ===
                                                                    equipments.find(
                                                                        (equipment) =>
                                                                            equipment.id ===
                                                                            equipmentOption.id,
                                                                    )?.providedBy,
                                                            ) ?? null
                                                        }
                                                        onChange={(option) => {
                                                            if (!option) return;
                                                            field.onChange(
                                                                equipments.map((equipment) =>
                                                                    equipment.id ===
                                                                    equipmentOption.id
                                                                        ? {
                                                                              ...equipment,
                                                                              providedBy:
                                                                                  option?.value,
                                                                          }
                                                                        : equipment,
                                                                ),
                                                            );
                                                        }}
                                                    />
                                                );
                                            }}
                                        />
                                    </div>
                                ) : null}
                            </div>
                        );
                    })}
                </Dialog.Main>
            </Dialog.ScrollArea>
            <Dialog.Footer className='justify-between'>
                <Button
                    type='button'
                    shape='outlined'
                    icon={<Plus />}
                    iconDisposition='left'
                    onClick={() => {
                        props.setStep("addCustomEquipment");
                    }}
                >
                    {i18n.job_descriptions_creation_job_subtasks_custom_add_button()}
                </Button>
                <div className='flex gap-2'>
                    <Dialog.Close asChild>
                        <Button type='button' shape='invisible' intention='secondary'>
                            {i18n.action_cancel()}
                        </Button>
                    </Dialog.Close>
                    <Button loading={isPending} disabled={!isDirty || !isValid}>
                        {i18n.save()}
                    </Button>
                </div>
            </Dialog.Footer>
        </form>
    );
}

interface AddCustomEquipmentStepProps {
    setStep: React.Dispatch<React.SetStateAction<"initial" | "addCustomEquipment">>;
    setEquipmentOptions: React.Dispatch<React.SetStateAction<Equipment[]>>;
}

function AddCustomEquipmentStep(props: AddCustomEquipmentStepProps) {
    const addCustomEquipmentFormSchema = z.object({
        name: z.string().max(50),
        providedBy: z.enum(["sider", "company"]),
    });
    type AddCustomEquipmentInputs = z.infer<typeof addCustomEquipmentFormSchema>;

    const {
        register,
        handleSubmit,
        formState: { isDirty, isValid },
        reset,
        control,
    } = useForm<AddCustomEquipmentInputs>({
        mode: "onChange",
        resolver: zodResolver(addCustomEquipmentFormSchema),
        defaultValues: {
            name: "",
            providedBy: undefined,
        },
    });

    // initial form
    const { setValue: setInitialFormValue, watch: initialFormWatch } = useFormContext<Inputs>();

    const onSubmit: SubmitHandler<AddCustomEquipmentInputs> = async (data) => {
        const newEquipment = {
            id: crypto.randomUUID(),
            issuedByFrontEnd: true,
            group: "Safety",
            custom: true,
            ...data,
        } as const;
        props.setEquipmentOptions((prev) => {
            return [newEquipment, ...prev];
        });
        setInitialFormValue("equipments", [newEquipment, ...initialFormWatch("equipments")]);
        reset();
        props.setStep("initial");
    };

    return (
        <form className='contents' onSubmit={handleSubmit(onSubmit)}>
            <Dialog.Header>
                <Dialog.Title className='flex items-center gap-2'>
                    <Button
                        shape='outlined'
                        intention='secondary'
                        onClick={() => props.setStep("initial")}
                        iconDisposition='icon-only'
                        icon={<ArrowLeft />}
                    />
                    {i18n.task_edit_custom_equipment_title()}
                </Dialog.Title>
                <Dialog.Description>{i18n.task_edit_custom_equipment_desc()}</Dialog.Description>
            </Dialog.Header>
            <Dialog.Main className='flex flex-col gap-4'>
                <Input.Root>
                    <Input.Label>
                        {i18n.job_descriptions_creation_conditions_equipments_security_custom_modal_input_label()}
                    </Input.Label>
                    <Input.Element>
                        <TextField.Root
                            placeholder={i18n.task_edit_custom_equipment_name_placeholder()}
                            required
                            autoComplete='off'
                            maxLength={50}
                            {...register("name")}
                        />
                    </Input.Element>
                </Input.Root>
                <Controller
                    control={control}
                    name='providedBy'
                    render={({ field }) => {
                        return (
                            <RadioGroup.Root
                                onValueChange={(value) => {
                                    field.onChange(value);
                                }}
                            >
                                <Label className='required'>
                                    {i18n.job_descriptions_creation_conditions_equipments_security_modal_label()}
                                </Label>
                                <Input.Root className='group flex flex-col rounded border border-gray-200 hover:border-blue-500'>
                                    <Input.Label className='flex w-full flex-row items-center gap-4 p-4 !typography-body-m-semibold'>
                                        <Input.Element>
                                            <RadioGroup.Item value='sider' />
                                        </Input.Element>
                                        {i18n.job_descriptions_creation_conditions_equipment_provider_sider()}
                                    </Input.Label>
                                </Input.Root>
                                <Input.Root className='group flex flex-col rounded border border-gray-200 hover:border-blue-500'>
                                    <Input.Label className='flex w-full flex-row items-center gap-4 p-4 !typography-body-m-semibold'>
                                        <Input.Element>
                                            <RadioGroup.Item value='company' />
                                        </Input.Element>
                                        {i18n.job_descriptions_creation_conditions_equipment_provider_company()}
                                    </Input.Label>
                                </Input.Root>
                            </RadioGroup.Root>
                        );
                    }}
                />
            </Dialog.Main>
            <Dialog.Footer className='justify-end'>
                <div className='flex gap-2'>
                    <Dialog.Close asChild>
                        <Button type='button' shape='invisible' intention='secondary'>
                            {i18n.action_cancel()}
                        </Button>
                    </Dialog.Close>
                    <Button disabled={!isDirty || !isValid}>{i18n.add()}</Button>
                </div>
            </Dialog.Footer>
        </form>
    );
}
