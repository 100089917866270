import { z } from "zod";

import { debug } from "@side.co/client-debug";

import { apiRequest } from "./apiHelpers/apiRequest";
import { apiConf } from "./index";

const getCompanyMotivesResSchema = z.object({
    minimumSalary: z.number(),
});

export type GetLegalValuesResponse = z.infer<typeof getCompanyMotivesResSchema>;
export async function getLegalValues() {
    debug.addBreadcrumb({
        message: `Get legal values`,
    });
    const url = `${apiConf.BACK_URL}/legal-values`;
    return apiRequest({
        url,
        responseSchema: getCompanyMotivesResSchema,
    });
}
