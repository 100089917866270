import moment from "moment";
import { IconDownload, IconEye, IconSignature } from "side-ui";
import { Tooltip, TooltipContent, TooltipTrigger } from "sui";

import { i18n } from "@lib/i18n";
import trackEvent from "@lib/trackers";
import { Link } from "@tanstack/react-router";

import "./ContractsBatchRow.scss";

const ContractsBatchRow = (props) => {
    const {
        taskName,
        taskId,
        managerName,
        contractsIds,
        status,
        startDate,
        documentId,
        signature,
        contestation,
    } = props;
    const getButtonIcon = (signatureStatus) => {
        switch (signatureStatus) {
            case "signed":
                return <IconDownload />;
            case "unsigned":
                return <IconSignature />;
            case "disputed":
                return <IconEye />;
            default:
                return <IconEye />;
        }
    };

    const getButtonLabel = (signatureStatus) => {
        switch (signatureStatus) {
            case "signed":
                return i18n.contracts_batch_row_status_signed_button_label();
            case "unsigned":
                return i18n.contracts_batch_row_status_tosign_button_label();
            case "disputed":
            default:
                return i18n.contracts_batch_row_status_contested_button_label();
        }
    };

    const getButtonTooltipContent = (signatureStatus) => {
        switch (signatureStatus) {
            case "signed":
                return i18n.contracts_batch_row_status_signed_button_label();
            case "unsigned":
                return i18n.contracts_batch_row_status_tosign_button_label();
            case "contested":
            default:
                return i18n.contracts_batch_row_status_contested_button_label();
        }
    };

    const getStatusLabel = (signatureStatus) => {
        switch (signatureStatus) {
            case "signed":
                return i18n.contracts_batch_row_status_signed();
            case "unsigned":
                return i18n.contracts_batch_row_status_tosign();
            case "contested":
                return i18n.contracts_batch_row_status_contested();
            default:
                return i18n.contracts_batch_row_status_tosign();
        }
    };

    const getStatusTooltipContent = (signatureStatus) => {
        const contestationDate = contestation && moment(contestation.date).format("DD/MM/YYYY");
        const signatureAuthorName = signature && signature.managerName;
        const signatureDate = signature && moment(signature.date).format("DD/MM/YYYY");
        const signatureTime = signature && moment(signature.date).format("HH:MM");
        const currentDate = moment().startOf("day");
        const givenDate = moment(startDate);
        const numberOfDays = currentDate.diff(givenDate, "days");

        switch (signatureStatus) {
            case "signed":
                return (
                    <div>
                        <p className='contract__row__col--status__tooltip--content'>
                            {i18n.contracts_batch_row_status_signed_tooltip_content({
                                signatureAuthorName,
                                signatureDate,
                                signatureTime,
                            })}
                        </p>
                    </div>
                );
            case "unsigned":
                return (
                    <div>
                        <p className='contract__row__col--status__tooltip--title--old'>
                            {i18n.contracts_batch_row_status_tosign_late_tooltip_title({
                                numberOfDays,
                            })}
                        </p>
                        <p className='contract__row__col--status__tooltip--content'>
                            {i18n.contracts_batch_row_status_tosign_late_tooltip_content()}
                        </p>
                    </div>
                );
            case "contested":
                return (
                    <div>
                        <p className='contract__row__col--status__tooltip--title--contested'>
                            {i18n.contracts_batch_row_status_disputed_tooltip_title({
                                contestationDate,
                            })}
                        </p>
                        <p className='contract__row__col--status__tooltip--content'>
                            {i18n.contracts_batch_row_status_disputed_tooltip_content()}
                        </p>
                    </div>
                );
            default:
                return i18n.contracts_list_status_label_tosign();
        }
    };

    return (
        <div className='row contract__row'>
            <div
                className={`contract__row__color-status contract__row__color-status--${status} ${
                    moment().endOf("day").isAfter(startDate) && status === "unsigned"
                        ? `contract__row__color-status--old`
                        : ""
                }`}
            />
            {taskName && (
                <div className='col-xs-10 col-sm-4 contract__row__col contract__row__col--taskname batch-taskname ellipsis'>
                    <Tooltip>
                        <TooltipTrigger>
                            <span>{taskName}</span>
                        </TooltipTrigger>
                        <TooltipContent type='info'>{taskName}</TooltipContent>
                    </Tooltip>
                </div>
            )}
            {status && (
                <div
                    className={`col-xs-4 contract__row__col contract__row__col--status contract__row__col--status--${status}align-center m-align-right visible-sm ${
                        moment().endOf("day").isAfter(startDate) && status === "unsigned"
                            ? `contract__row__col--status--old`
                            : ""
                    }`}
                >
                    <span>{getStatusLabel(status)}</span>
                </div>
            )}
            {managerName && (
                <div className='col-xs-14 col-sm-3 contract__row__col contract__row__col--manager capitalize'>
                    <span className='visible-sm'>{i18n.contracts_batch_row_mobile_manager()} </span>
                    {managerName}
                </div>
            )}
            {contractsIds && (
                <div className='col-xs-14 col-sm-2 contract__row__col contract__row__col--number align-center m-align-left'>
                    <span className='contract__row__col--number'>{contractsIds.length}</span>
                    <span className='visible-sm'> {i18n.contracts_batch_row_mobile_number()}</span>
                </div>
            )}
            {status && (
                <div
                    className={`col-xs-14 col-sm-2 contract__row__col contract__row__col--status contract__row__col--status--${status} align-center m-hidden ${
                        moment().endOf("day").isAfter(startDate) && status === "unsigned"
                            ? `contract__row__col--status--old`
                            : ""
                    }`}
                >
                    {(moment().endOf("day").isAfter(startDate) && status === "unsigned") ||
                    status !== "unsigned" ? (
                        <Tooltip>
                            <TooltipTrigger>
                                <span
                                    className={
                                        moment().endOf("day").isAfter(startDate) &&
                                        status === "unsigned"
                                            ? `contract__row__col--status--old`
                                            : ""
                                    }
                                >
                                    {getStatusLabel(status)}
                                </span>
                            </TooltipTrigger>
                            <TooltipContent type='info'>
                                {((moment().endOf("day").isAfter(startDate) &&
                                    status === "unsigned") ||
                                    status !== "unsigned") &&
                                    getStatusTooltipContent(status)}
                            </TooltipContent>
                        </Tooltip>
                    ) : (
                        <span
                            className={
                                moment().endOf("day").isAfter(startDate) && status === "unsigned"
                                    ? `contract__row__col--status--old`
                                    : ""
                            }
                        >
                            {getStatusLabel(status)}
                        </span>
                    )}
                </div>
            )}
            {startDate && (
                <div className='col-xs-14 col-sm-2 contract__row__col contract__row__col--date align-left m-align-left'>
                    <span className='visible-sm'>{i18n.contracts_batch_row_mobile_date()} </span>
                    {moment(startDate).format(`DD/MM/YYYY`)}
                </div>
            )}
            {((moment().endOf("day").isAfter(startDate) && status === "unsigned") ||
                status !== "unsigned") && (
                <div className='visible-sm status-details'>{getStatusTooltipContent(status)}</div>
            )}
            <div className='col-xs-14 col-sm-1 contract__row__col contract__row__col__action align-center m-align-left'>
                <Tooltip>
                    <TooltipTrigger>
                        {status === "signed" ? (
                            <a
                                className='contract__row__col__button'
                                href={signature && signature.url}
                                onClick={() => {
                                    trackEvent({
                                        name: `teamcontract - company downloaded contract`,
                                        params: {
                                            organisationId: localStorage.getItem(
                                                "side_team_activeOrganisationId",
                                            ),
                                            contractId: documentId,
                                            taskId,
                                            status,
                                        },
                                    });
                                }}
                                download
                            >
                                <span className='contract__row__col__button__text bold visible-sm'>
                                    {getButtonLabel(status)}
                                </span>
                                {getButtonIcon(status)}
                            </a>
                        ) : (
                            <Link
                                to={`/contracts/${documentId}/signature`}
                                className='contract__row__col__button'
                            >
                                <span className='contract__row__col__button__text bold visible-sm'>
                                    {getButtonLabel(status)}
                                </span>
                                {getButtonIcon(status)}
                            </Link>
                        )}
                    </TooltipTrigger>
                    <TooltipContent type='action'>{getButtonTooltipContent(status)}</TooltipContent>
                </Tooltip>
            </div>
        </div>
    );
};

export default ContractsBatchRow;
