import { useEffect, useState } from "react";
import { ModalsService, SimpleSelect, TabItem, TabMenu } from "side-ui";

import { i18n, i18nDK } from "@lib/i18n";
import { queries } from "@lib/queries";
import { debug } from "@side.co/client-debug";
import { useQuery } from "@tanstack/react-query";
import { Link, Outlet } from "@tanstack/react-router";

import trackEvent from "../../../lib/trackers";

import ConfirmationModal from "./ConfirmationModal";

import "./TasksList.scss";

const TasksList = ({
    getUnsignedContractsBatches,
    addFilter,
    statuses,
    managerIdFilter,
    getStatuses,
}) => {
    const { data: user } = useQuery(queries.user.detail());
    const { data: companyInfoData } = useQuery(queries.company.detail());
    const companyMembers = companyInfoData?.members || [];
    const [active, setActive] = useState(window.location.pathname);

    useEffect(() => {
        getUnsignedContractsBatches();
        getStatuses();

        if (window.location.hash === `#confirmation`) {
            ModalsService.openModal({
                id: `TASK_CREATION_CONFIRMATION`,
                content: <ConfirmationModal />,
            });
        }
    }, []);

    const filterByManager = (value) => {
        addFilter(`managerId`, value);

        debug.addBreadcrumb({
            message: `Filter tasks by manager`,
            category: `action`,
            data: {
                manager: value,
            },
        });
    };

    const items = [
        <TabLink
            key='all'
            to='/tasks/all'
            label={i18n.top_menu_filter_all()}
            activeOnlyWhenExact={true}
            onClick={setActive}
            isActive={active === `/tasks/all`}
            status={null}
        />,
    ];
    statuses
        .filter((st) => ["draft", "completed"].includes(st))
        .forEach((status) => {
            items.push(
                <TabLink
                    key={status}
                    to={`/tasks/${status}`}
                    label={i18nDK(`top_menu_filter_${status}`)}
                    status={status}
                    onClick={setActive}
                    isActive={active === `/tasks/${status}`}
                    activeOnlyWhenExact={true}
                />,
            );
        });

    let teamMembersOptions = [];
    if (companyMembers && companyMembers.length) {
        teamMembersOptions = companyMembers.map((member, i) => ({
            id: `member-${i}`,
            label: `${member.firstName} ${member.lastName}`,
            value: member._id,
        }));
    }

    return (
        <div className='tasks'>
            <div className='tasks__header'>
                <div className='tasks__header__container'>
                    <TabMenu items={items} />
                    {teamMembersOptions.length > 1 && user?.isAdmin && (
                        <SimpleSelect
                            id='team_members'
                            options={teamMembersOptions}
                            placeholder={i18n.top_menu_dropdown_default()}
                            customClass='tasks__header__filter'
                            changeValue={(value) => filterByManager(value)}
                            value={managerIdFilter}
                            smallInput={true}
                        />
                    )}
                </div>
            </div>
            <div className='tasks__container'>
                <Outlet />
            </div>
        </div>
    );
};

const TabLink = ({ label, to, onClick, activeOnlyWhenExact, status, isActive }) => (
    <TabItem active={isActive}>
        <Link
            to={to}
            activeOptions={{ exact: activeOnlyWhenExact }}
            onClick={() => {
                trackEvent({
                    name: `topMenuFilter`,
                    params: {
                        filter: status || `all`,
                    },
                });
                onClick(to);
            }}
            dangerouslySetInnerHTML={{ __html: label }}
        />
    </TabItem>
);

export default TasksList;
