import { Formik } from "formik";
import { BlockFeedback, InputNumber, ModalSimple, ModalsService, RadioButtons, Tip } from "side-ui";

import { i18n } from "@lib/i18n";

import { normalizeCurrency, normalizeNumber } from "../../../../lib/utils/inputsNormalizers";

import {
    initialOtherParametersFromValue,
    initialOtherParametersValue,
    OtherParameters,
    shouldToggleBeChecked,
} from "./otherParameters/OtherParameters";
import {
    indemnityLunchValidationSchema,
    submitIndemnityLunch,
} from "./remunerationFormsValidation";

const onFieldChange = (fieldName, value, prevValue, setFieldValue) => {
    let newValue = value;
    switch (fieldName) {
        case "amount":
        case "indemnityValue":
            newValue = normalizeCurrency(value, prevValue);
            break;
        case "coverRate":
            newValue = normalizeNumber(value);
            break;
        default:
            break;
    }
    setFieldValue(fieldName, newValue);
};

const IndemnityLunch = ({ indemnity, updateIndemnity, deleteIndemnity }) => {
    const initOtherParameters =
        indemnity && indemnity.otherParameters
            ? initialOtherParametersFromValue(indemnity.otherParameters)
            : initialOtherParametersValue;

    const otherParametersToggled = shouldToggleBeChecked(initOtherParameters);

    return (
        <Formik
            initialValues={{
                id: indemnity && indemnity.id,
                type: indemnity && indemnity.type,
                amount: indemnity && indemnity.amount,
                coverRate: indemnity && indemnity.coverRate,
                otherParameters: initOtherParameters,
            }}
            onSubmit={(values, actions) => submitIndemnityLunch(values, actions, updateIndemnity)}
            validationSchema={indemnityLunchValidationSchema}
            validateOnChange={false}
            render={({
                values,
                errors,
                touched,
                handleBlur,
                handleChange,
                handleSubmit,
                setFieldValue,
                submitForm,
            }) => {
                const companyAmount =
                    values.amount &&
                    values.coverRate &&
                    (parseFloat(values.amount) * (parseInt(values.coverRate, 10) / 100)).toFixed(2);

                return (
                    <ModalSimple
                        action={submitForm}
                        validateLabel={i18n.settings_remuneration_indemnities_modal_save()}
                        deleteLabel={
                            indemnity && indemnity.id
                                ? i18n.settings_remuneration_indemnities_modal_delete()
                                : ""
                        }
                        deleteAction={
                            indemnity && indemnity.id ? () => deleteIndemnity(indemnity.id) : null
                        }
                        cancelLabel={i18n.settings_remuneration_indemnities_modal_cancel()}
                        title={i18n.settings_remuneration_indemnities_add_option_lunch()}
                        subtitle={i18n.settings_remuneration_indemnities_lunch_subtitle()}
                        hideModal={() => ModalsService.closeModal(`LUNCH_INDEMNITY`)}
                    >
                        <div className='indemnity'>
                            <form onSubmit={handleSubmit}>
                                <div className='indemnity__section'>
                                    <div className='indemnity__field'>
                                        {touched.amount && errors.type && (
                                            <BlockFeedback content={errors.type} type='error' />
                                        )}
                                        <RadioButtons
                                            label={i18n.settings_payment_method_label()}
                                            options={[
                                                {
                                                    name: "type",
                                                    value: "ticket",
                                                    label: i18n.settings_remuneration_indemnities_lunch_tr_option(),
                                                },
                                                {
                                                    name: "type",
                                                    value: "indemnity",
                                                    label: i18n.settings_remuneration_indemnities_lunch_indeminity_option(),
                                                },
                                            ]}
                                            value={{
                                                ticket: false,
                                                indemnity: false,
                                                [values.type]: true,
                                            }}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        />
                                    </div>
                                    {values.type === `ticket` && (
                                        <div>
                                            <div className='indemnity__field'>
                                                <InputNumber
                                                    id='amount'
                                                    label={i18n.settings_remuneration_indemnities_lunch_tr_value_label()}
                                                    unit='€'
                                                    smallInput={true}
                                                    value={values.amount}
                                                    error={touched.amount && errors.amount}
                                                    onChange={(e) =>
                                                        onFieldChange(
                                                            "amount",
                                                            e.target.value,
                                                            values.amount,
                                                            setFieldValue,
                                                        )
                                                    }
                                                    onBlur={handleBlur}
                                                    customClass='indemnity__small-input'
                                                />
                                            </div>
                                            <div className='indemnity__field'>
                                                <InputNumber
                                                    id='coverRate'
                                                    label={i18n.settings_remuneration_indemnities_lunch_tr_rate()}
                                                    unit='%'
                                                    smallInput={true}
                                                    value={values.coverRate}
                                                    error={touched.coverRate && errors.coverRate}
                                                    onChange={(e) =>
                                                        onFieldChange(
                                                            "coverRate",
                                                            e.target.value,
                                                            values.coverRate,
                                                            setFieldValue,
                                                        )
                                                    }
                                                    onBlur={handleBlur}
                                                    hint={
                                                        (companyAmount &&
                                                            i18n.settings_remuneration_indemnities_lunch_equivalent_amount(
                                                                { amount: companyAmount },
                                                            )) ||
                                                        " "
                                                    }
                                                />
                                            </div>
                                            <Tip
                                                content={i18n.settings_remuneration_indemnities_lunch_tr_tip()}
                                                noMobile={true}
                                                isWarning={true}
                                            />
                                        </div>
                                    )}
                                    {values.type === `indemnity` && (
                                        <div>
                                            <InputNumber
                                                id='amount'
                                                label={i18n.settings_remuneration_indemnities_lunch_indemnity_label()}
                                                unit='€'
                                                smallInput={true}
                                                value={values.amount}
                                                error={touched.amount && errors.amount}
                                                onChange={(e) =>
                                                    onFieldChange(
                                                        "amount",
                                                        e.target.value,
                                                        values.amount,
                                                        setFieldValue,
                                                    )
                                                }
                                                onBlur={handleBlur}
                                                customClass='indemnity__small-input'
                                            />
                                        </div>
                                    )}
                                </div>
                                <div className='indemnity__section'>
                                    <OtherParameters toggled={otherParametersToggled} />
                                </div>
                            </form>
                        </div>
                    </ModalSimple>
                );
            }}
        />
    );
};

export default IndemnityLunch;
