import { useContext, useEffect, useReducer, useRef, useState } from "react";
import clsx from "clsx";
import { Button, CategorizedTable, Overlay, Panel, SectionHeader } from "side-ui";

import { i18n, i18nDK } from "@lib/i18n";

import { useTimer } from "../../hooks/useTimer";
import { JobDescriptionContext } from "../JobDescriptionContext";

import { ContextGoalsForm } from "./ContextGoalsForm/ContextGoalsForm";
import { DevicesForm } from "./DevicesForm/DevicesForm";
import { EditDevices } from "./DevicesForm/EditDevices";
import { DressCodeForm } from "./DressCodeForm/DressCodeForm";
import { RisksForm } from "./RisksForm/RisksForm";
import { EditSafetyEquipment } from "./SafetyEquipmentListForm/EditSafetyEquipment";
import { SafetyEquipmentListForm } from "./SafetyEquipmentListForm/SafetyEquipmentListForm";

import styles from "./WorkConditionsStep.module.css";

export function WorkConditionsStep({ jobDescription, setDataToUpdate, disableSubmit }) {
    const [workConditionsState, setWorkConditionsState] = useState({
        context: jobDescription?.workConditions?.context,
        devices: jobDescription?.workConditions?.devices ?? [],
        safetyEquipmentList: jobDescription?.workConditions?.safetyEquipmentList ?? [],
        risks: jobDescription?.workConditions?.risks ?? [],
        aboutRisks: jobDescription?.workConditions?.aboutRisks,
        dressCode: jobDescription?.workConditions?.dressCode,
    });
    const [modalState, dispatch] = useReducer(
        (state, action) => {
            switch (action.type) {
                case "toggle-safety":
                    return { ...state, safetyEquipment: !state.safetyEquipment };
                case "toggle-device":
                    return { ...state, device: !state.device };
                default:
                    return state;
            }
        },
        {
            safetyEquipment: false,
            device: false,
        },
    );
    const [safetyEquipmentListPanelVisibility, setSafetyEquipmentListPanelVisibility] =
        useState(false);
    const [devicesPanelVisibility, setDevicesPanelVisibility] = useState(false);
    const scrollingRef = useRef(null);

    const { devices, safetyEquipmentList, risks, aboutRisks, context, dressCode } =
        workConditionsState;

    function submitWorkConditionsStepData() {
        setDataToUpdate({
            equipment: devices.concat(safetyEquipmentList),
            riskIds: risks.reduce((acc, risk) => (risk.id ? [...acc, risk.id] : acc), []),
            workConditions: aboutRisks,
            purpose: context,
            dressCode,
        });
    }
    const { panelContentRef } = useContext(JobDescriptionContext);

    useEffect(() => {
        disableSubmit(false);
        if (panelContentRef?.current) {
            panelContentRef.current.scrollTop = 0;
        }
    }, []);

    // upper store will be updated every 100ms after user finished interacting
    useTimer(
        {
            timing: 100,
            callback: () => submitWorkConditionsStepData(),
        },
        [workConditionsState],
    );

    const safetyEquipmentSection = {
        label: i18n.job_descriptions_creation_conditions_equipments_security_title(),
        action: () => dispatch({ type: "toggle-safety" }),
        rows: safetyEquipmentList?.map((safetyEquipment) => ({
            key: safetyEquipment.name,
            label:
                safetyEquipment.custom === true
                    ? safetyEquipment.name
                    : i18nDK(safetyEquipment.name),
            value:
                [
                    {
                        value: "sider",
                        label: i18n.job_descriptions_creation_conditions_equipment_provider_sider(),
                    },
                    {
                        value: "company",
                        label: i18n.job_descriptions_creation_conditions_equipment_provider_company(),
                    },
                ].find((opt) => opt.value === safetyEquipment.providedBy)?.label || "",
        })),
    };

    const devicesSection = {
        label: i18n.job_descriptions_creation_conditions_equipments_other_title(),
        action: () => dispatch({ type: "toggle-device" }),
        rows: devices?.map((device) => ({
            key: device.name,
            label: device.custom === true ? device.name : i18nDK(device.name),
            value:
                [
                    {
                        value: "sider",
                        label: i18n.job_descriptions_creation_conditions_equipment_provider_sider(),
                    },
                    {
                        value: "company",
                        label: i18n.job_descriptions_creation_conditions_equipment_provider_company(),
                    },
                ].find((opt) => opt.value === device.providedBy)?.label || "",
        })),
    };

    return (
        <div className={styles.workConditions} ref={scrollingRef}>
            <SectionHeader
                title={i18n.job_descriptions_creation_conditions_context_title()}
                subtitle={i18n.job_descriptions_creation_conditions_context_subtitle()}
                icon='Glasses'
            />
            <div className={styles.context}>
                <ContextGoalsForm
                    context={context}
                    setContext={(inputedContext) =>
                        setWorkConditionsState((prevState) => ({
                            ...prevState,
                            context: inputedContext,
                        }))
                    }
                />
            </div>

            <div className={clsx(styles.equipment)}>
                <SectionHeader
                    title={i18n.job_descriptions_creation_conditions_equipments_title()}
                    subtitle={i18n.job_descriptions_creation_conditions_equipments_subtitle()}
                    icon='Tool'
                />
                <Overlay
                    isVisible={safetyEquipmentListPanelVisibility || devicesPanelVisibility}
                    toggleVisibility={() =>
                        safetyEquipmentListPanelVisibility
                            ? setSafetyEquipmentListPanelVisibility(false)
                            : setDevicesPanelVisibility(false)
                    }
                />
                <Panel
                    visible={safetyEquipmentListPanelVisibility}
                    toggleVisibility={() =>
                        setSafetyEquipmentListPanelVisibility(!safetyEquipmentListPanelVisibility)
                    }
                    closeAction={() => setSafetyEquipmentListPanelVisibility(false)}
                    withSmallHeader={true}
                    position='bottom'
                    title={i18n.job_descriptions_creation_conditions_equipments_security_title()}
                    subtitle={i18n.job_descriptions_creation_conditions_equipments_security_subtitle()}
                    headerIcon='Briefcase'
                    saveLabel={i18n.save()}
                    cancelLabel={i18n.cancel()}
                    onCancel={() => setSafetyEquipmentListPanelVisibility(false)}
                    onSave={() => setSafetyEquipmentListPanelVisibility(false)}
                >
                    {/* micro optimization: render according to state value */}
                    {safetyEquipmentListPanelVisibility ? (
                        <SafetyEquipmentListForm
                            selectedSafetyEquipmentList={safetyEquipmentList}
                            setSelectedSafetyEquipmentList={(selectedSafetyEquipmentList) =>
                                setWorkConditionsState((prevState) => ({
                                    ...prevState,
                                    safetyEquipmentList: selectedSafetyEquipmentList,
                                }))
                            }
                        />
                    ) : null}
                    {/* check how to deal with the state of selected equipments / compare with subtasks */}
                </Panel>
                <Panel
                    visible={devicesPanelVisibility}
                    toggleVisibility={() => setDevicesPanelVisibility(!devicesPanelVisibility)}
                    closeAction={() => setDevicesPanelVisibility(false)}
                    withSmallHeader={true}
                    position='bottom'
                    title={i18n.job_descriptions_creation_conditions_equipments_other_title()}
                    subtitle={i18n.job_descriptions_creation_conditions_equipments_other_subtitle()}
                    headerIcon='Briefcase'
                    saveLabel={i18n.save()}
                    onSave={() => {
                        setDevicesPanelVisibility(false);
                    }}
                    cancelLabel={i18n.cancel()}
                    onCancel={() => setDevicesPanelVisibility(false)}
                >
                    {/* micro optimization: render according to state value */}
                    {devicesPanelVisibility ? (
                        <DevicesForm
                            selectedDevices={devices}
                            setSelectedDevices={(selectedDevices) =>
                                setWorkConditionsState((prevState) => ({
                                    ...prevState,
                                    devices: selectedDevices,
                                }))
                            }
                        />
                    ) : null}
                    {/* check how to deal with the state of selected equipments / compare with subtasks */}
                </Panel>
                {/* Because of a design flaw, we had to use 2x the categorized
        table component and replicate the structure */}
                <div className={clsx(styles.content, "safetyEquipment")}>
                    {!!devices?.length || !!safetyEquipmentList?.length ? (
                        <CategorizedTable
                            labelHeader={i18n.job_descriptions_creation_conditions_equipment_table_header_label()}
                            valueHeader={i18n.job_descriptions_creation_conditions_equipment_table_header_value()}
                            sections={
                                safetyEquipmentList?.length > 0 ? [safetyEquipmentSection] : []
                            }
                        />
                    ) : null}
                    <div className={clsx(styles.action, styles.withBottom)}>
                        {!safetyEquipmentList?.length && (
                            <p className={styles.title}>
                                {i18n.job_descriptions_creation_conditions_equipments_security_title()}
                            </p>
                        )}

                        <Button
                            color='blue'
                            action={() => setSafetyEquipmentListPanelVisibility(true)}
                            iconBefore='Plus'
                        >
                            {i18n.job_descriptions_creation_conditions_equipments_security_add_button()}
                        </Button>
                    </div>

                    <div className={"devices"}>
                        <CategorizedTable sections={devices?.length > 0 ? [devicesSection] : []} />
                    </div>
                    <div className={styles.action}>
                        {!devices?.length && (
                            <p className={styles.title}>
                                {i18n.job_descriptions_creation_conditions_equipments_other_title()}
                            </p>
                        )}
                        <Button
                            color='blue'
                            action={() => setDevicesPanelVisibility(true)}
                            iconBefore='Plus'
                        >
                            {i18n.job_descriptions_creation_conditions_equipments_other_add_button()}
                        </Button>
                    </div>
                </div>

                <div className={clsx(styles.dresscode, "dresscode")}>
                    <DressCodeForm
                        dressCode={dressCode}
                        setDressCode={(InputedDressCode) =>
                            setWorkConditionsState((prevState) => ({
                                ...prevState,
                                dressCode: InputedDressCode,
                            }))
                        }
                    />
                </div>
            </div>
            <div className={clsx(styles.risks, "risks")}>
                <SectionHeader
                    title={i18n.job_descriptions_creation_conditions_equipments_risks_title()}
                    subtitle={i18n.job_descriptions_creation_conditions_equipments_risks_subtitle()}
                    icon='Umbrella'
                />
                <RisksForm
                    selectedRisks={risks.reduce((acc, val) => {
                        if (!acc[val.name]) {
                            acc[val.name] = [val];
                            return acc;
                        }
                        acc[val.name].push(val);
                        return acc;
                    }, {})}
                    aboutRisks={aboutRisks}
                    setAboutRisks={(InputedAboutRisks) =>
                        setWorkConditionsState((prevState) => ({
                            ...prevState,
                            aboutRisks: InputedAboutRisks,
                        }))
                    }
                    setSelectedRisks={(selectedRisks) => {
                        setWorkConditionsState((prevState) => ({
                            ...prevState,
                            risks: Object.values(selectedRisks).map((selectedRisk) =>
                                (selectedRisk as any[]).reduce((acc, curr) => {
                                    acc[curr] = curr;
                                    return acc;
                                }),
                            ),
                        }));
                    }}
                />
            </div>

            {modalState.safetyEquipment ? (
                <EditSafetyEquipment
                    selectedEquipments={safetyEquipmentList}
                    hideModal={() => dispatch({ type: "toggle-safety" })}
                    setEquipment={(selectedSafetyEquipmentList) => {
                        setWorkConditionsState((prevState) => ({
                            ...prevState,
                            safetyEquipmentList: selectedSafetyEquipmentList,
                        }));
                    }}
                />
            ) : null}

            {modalState.device ? (
                <EditDevices
                    selectedDevices={devices}
                    hideModal={() => dispatch({ type: "toggle-device" })}
                    setDevices={(selectedDevices) => {
                        setWorkConditionsState((prevState) => ({
                            ...prevState,
                            devices: selectedDevices,
                        }));
                    }}
                />
            ) : null}
        </div>
    );
}
