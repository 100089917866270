import fetch from "isomorphic-fetch";

import { handleError } from "@lib/utils/error";
import { debug } from "@side.co/client-debug";

import { withAuthHeaders } from "./apiHelpers";
import { apiConf } from "./index";

type ConfirmTaskParams = {
    taskId: string;
    sideNote: string;
};
export async function confirmTask(params: ConfirmTaskParams) {
    const message = `Confirm Task`;
    debug.addBreadcrumb({
        message,
        category: `action`,
        data: {
            taskId: params.taskId,
        },
    });

    try {
        const resp = await fetch(
            withAuthHeaders(`${apiConf.BACK_URL}/tasks/${params.taskId}/confirm`, {
                method: `POST`,
                body: JSON.stringify({
                    sideNote: params.sideNote,
                }),
            }),
        );

        if (resp.status >= 400) {
            handleError(await resp.json(), resp.status);
            return Promise.reject(new Error(message));
        }

        return await resp.json();
    } catch (e) {
        handleError(e, 500);
        return Promise.reject(new Error(message));
    }
}
