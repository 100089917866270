import { useEffect, useRef } from "react";
import clsx from "clsx";
import { atom, useAtom, useSetAtom } from "jotai";
import { CalendarDays } from "lucide-react";
import { Loader } from "side-ui";

import { Task } from "@lib/api";
import { i18n } from "@lib/i18n";
import { queries } from "@lib/queries";
import { useQuery } from "@tanstack/react-query";
import { useFlag } from "@unleash/proxy-client-react";

import { shiftsStepRoute, taskFormRoute } from "../route";
import { disabledSubmitAtom } from "../TaskFormFooter";

import { Blank } from "./Blank/Blank";
import { ShiftModal as ShiftModalOld } from "./ShiftModal/ShiftModal";
import { ShiftModal as ShiftModalEditFlag } from "./ShiftModal/ShiftModalEditFlag";
import { ShiftsTable } from "./ShiftsTable/ShiftsTable";

import styles from "./ShiftsStep.module.css";

type ModalState = {
    open: boolean;
    mode: "creation" | "deletion" | "edition";
    shift: null | Task["shifts"][number];
};
export const modalShiftsStateAtom = atom<ModalState>({
    open: false,
    mode: "creation",
    shift: null,
});
export function ShiftsStep() {
    const { taskId } = taskFormRoute.useParams();
    const { create } = shiftsStepRoute.useSearch();
    const [modalState, setModalState] = useAtom(modalShiftsStateAtom);
    const setDisabledSubmit = useSetAtom(disabledSubmitAtom);
    const { data: task, isLoading: isTaskLoading } = useQuery(queries.task.detail(taskId));
    const modalRef = useRef<HTMLDialogElement>(null);
    const shiftsLength = task?.shifts?.length;

    useEffect(() => {
        setDisabledSubmit(!shiftsLength);
    }, [shiftsLength]);

    useEffect(() => {
        if (modalState.open) {
            modalRef.current?.showModal();
        }
    }, [modalState.open]);

    useEffect(() => {
        // Open modal for creation from planning type selection
        if (create) {
            setModalState({ open: true, mode: "creation", shift: null });
        }
    }, [create]);

    const editFlagEnabled = useFlag("team-posting-shift-recurrency-edition");

    return (
        <div className={clsx(styles.shiftsStep, !task?.shifts.length && styles.blank)}>
            {!shiftsLength && isTaskLoading && <Loader />}
            {shiftsLength ? (
                <>
                    <div className='flex flex-col pb-8'>
                        <div className='flex items-center gap-4'>
                            <div className='grid h-8 w-8 shrink-0 place-items-center rounded-full bg-blue-50 text-blue-500'>
                                <CalendarDays className='h-5 w-5' />
                            </div>
                            <p className='text-gray-900 typography-heading-m-semibold'>
                                {i18n.task_shifts_title()} <span>({task?.shifts.length})</span>
                            </p>
                        </div>
                    </div>
                    <ShiftsTable
                        shifts={task?.shifts}
                        openModal={(mode: "edition" | "deletion", shift) => {
                            setModalState({ open: true, mode, shift });
                            modalRef.current?.showModal();
                        }}
                    />
                </>
            ) : (
                <Blank
                    openModal={() => {
                        setModalState({ open: true, mode: "creation", shift: null });
                        modalRef.current?.showModal();
                    }}
                />
            )}
            {editFlagEnabled ? (
                <ShiftModalEditFlag
                    key={Number(modalState.open)}
                    ref={modalRef}
                    motiveReason={task?.motive?.reason}
                />
            ) : (
                <ShiftModalOld
                    key={Number(modalState.open)}
                    ref={modalRef}
                    motiveReason={task?.motive?.reason}
                />
            )}
        </div>
    );
}
