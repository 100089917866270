import { Button, CTAButton } from "side-ui";

import { i18n } from "@lib/i18n";

const TimesheetsTableActionBar = ({
    selectedAttendances,
    setSiderDidntWork,
    validateAttendances,
    navigationMode,
    readOnly,
}) => (
    <footer
        className={`timesheets-table__action-bar ${selectedAttendances.length ? "timesheets-table__action-bar--open" : ""} ${
            navigationMode && // update left given navigationMode
            `timesheets-table__action-bar--${navigationMode}`
        } `}
    >
        {selectedAttendances.length ? (
            <>
                <div className='timesheets-table__action-bar__mobile-cta'>
                    <CTAButton
                        label={
                            selectedAttendances.length === 1
                                ? i18n.ts_footer_tovalidate_attendance()
                                : i18n.ts_footer_tovalidate_attendances({
                                      count: selectedAttendances.length,
                                  })
                        }
                        action={readOnly ? () => null : validateAttendances}
                    />
                </div>
                <div className='timesheets-table__action-bar__wrapper'>
                    <span className='timesheets-table__action-bar__sider-count'>
                        {selectedAttendances.length === 1
                            ? i18n.ts_footer_selected_sider()
                            : i18n.ts_footer_selected_siders({
                                  count: selectedAttendances.length,
                              })}
                    </span>

                    <span className='timesheets-table__action-bar__submit__wrapper'>
                        <Button
                            color='transparent-red'
                            iconBefore='Cancel'
                            action={readOnly ? () => null : setSiderDidntWork}
                            disabled={readOnly}
                        >
                            {selectedAttendances.length === 1
                                ? i18n.ts_footer_sider_didnt_work()
                                : i18n.ts_footer_siders_didnt_work({
                                      count: selectedAttendances.length,
                                  })}
                        </Button>

                        <Button
                            iconBefore='Checkmark'
                            color='blue'
                            action={readOnly ? () => null : validateAttendances}
                            disabled={readOnly}
                        >
                            {i18n.ts_footer_validate_hours()}
                        </Button>
                    </span>
                </div>
            </>
        ) : null}
    </footer>
);

export default TimesheetsTableActionBar;
