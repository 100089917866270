import { useEffect, useRef, useState } from "react";
import { useAtomValue } from "jotai";
import { pickBy } from "lodash";
import satismeter from "satismeter-loader";
import { Pagination } from "side-ui";
import { Button, Tooltip, TooltipContent, TooltipTrigger } from "sui";

import { queryClient } from "@App";
import { navigationModeAtom } from "@components/Navigation/Navigation";
import { i18n } from "@lib/i18n";
import { queries } from "@lib/queries";
import { useQuery } from "@tanstack/react-query";
import { useNavigate } from "@tanstack/react-router";

import { timesheetsRoute } from "../route";

import { TimesheetsFileImportModal } from "./Timesheets/TimesheetsFileImportModal/TimesheetsFileImportModal";
import TimeSheetsFilterbar from "./Timesheets/TimeSheetsFilterbar";
import TimesheetsTable from "./Timesheets/TimesheetsTable/TimesheetsTable";

import "./Timesheets.scss";

const Timesheets = () => {
    const navigationMode = useAtomValue(navigationModeAtom);
    const filters = timesheetsRoute.useSearch();

    const companyIdRef = useRef(localStorage.getItem(`side_team_activeOrganisationId`) ?? "");
    const loggedAsRef = useRef(!!localStorage.getItem("side_team_logas_email"));
    const navigate = useNavigate({ from: "/timesheets" });

    const { data: company } = useQuery(queries.company.detail());
    const { data: user } = useQuery(queries.user.detail());
    const [pageCount, setPageCount] = useState(0);

    useEffect(() => {
        const satismeterWriteKey = process.env.REACT_APP_SATISMETER_WRITE_KEY;

        if (!satismeterWriteKey) {
            // If the user has no timesheets we are not really interested in his
            // NPS because he didn't live an almost full experience
            // OR
            // If there is no satismeter write key
            return;
        }

        const userId = localStorage.getItem(`Meteor.userId`);

        if (userId && user) {
            satismeter({
                writeKey: satismeterWriteKey,
                userId,
                traits: {
                    email: user.email || `undefined`,
                    name: `${user.firstName || `undefined`} ${user.lastName || `undefined`}`,
                    companyName:
                        user.organisation && user.organisation.name ? user.organisation.name : ``,
                    companyId: companyIdRef.current,
                    label: `company`,
                },
            });
        }
    });

    const updateURL = (filtersToUpdate) => {
        Object.keys(filtersToUpdate).forEach((key) => {
            if (filtersToUpdate[key] === null) {
                delete filtersToUpdate[key];
            }
            // convert date to iso string
            if (filtersToUpdate[key] instanceof Date) {
                filtersToUpdate[key] = filtersToUpdate[key].toISOString();
            }
        });
        navigate({ to: timesheetsRoute.fullPath, search: filtersToUpdate });
    };

    // function responsible for transforming the URL params
    // and trigger the request
    const updateFilters = (filter) => {
        const updatedFilters = { ...filters, ...filter };

        // updating filters and URL only if valuable (truthy values) change
        if (Object.keys(pickBy(updatedFilters, (v) => v !== undefined)).length > 0) {
            updateURL(updatedFilters);
        }
    };
    const modalRef = useRef<HTMLDialogElement>(null);

    const hasAttendanceConfig = company?.organisation?.attendanceConfigType === "timesheet";
    const hasAttendanceConfigPrivilege =
        ["admin", "user"].includes(user?.role ?? "") && !loggedAsRef.current;
    return (
        <section className='timesheets'>
            <TimeSheetsFilterbar
                updateFilters={updateFilters}
                setPage={(number) => {
                    navigate({
                        search: (search) => ({
                            ...search,
                            pageNumber: number,
                        }),
                    }).then(() => {
                        queryClient.invalidateQueries(queries.attendance.list(filters));
                    });
                }}
                // for trackers purpose
                companyId={companyIdRef.current}
                logAs={loggedAsRef.current}
            />
            <TimesheetsTable
                // for trackers purpose
                companyId={companyIdRef.current}
                navigationMode={navigationMode}
                logAs={loggedAsRef.current}
                setPageCount={setPageCount}
            />
            {pageCount > 1 || hasAttendanceConfig ? (
                <div className={`timesheets-footer timesheets-footer--${navigationMode}`}>
                    <div className='spacer'></div>
                    <div className='column'>
                        {pageCount > 1 && (
                            <Pagination
                                page={filters?.pageNumber - 1}
                                max={pageCount}
                                setPage={(pageNumber) => {
                                    navigate({
                                        search: (search) => ({
                                            ...search,
                                            pageNumber: pageNumber + 1,
                                        }),
                                    }).then(() => {
                                        queryClient.invalidateQueries(
                                            queries.attendance.list(filters),
                                        );
                                    });
                                }}
                            />
                        )}
                    </div>
                    <div className='column'>
                        {hasAttendanceConfig && (
                            <>
                                <Tooltip
                                    placement='top-start'
                                    enabled={!hasAttendanceConfigPrivilege}
                                >
                                    <TooltipTrigger>
                                        <Button
                                            onClick={() => modalRef.current?.showModal()}
                                            disabled={!hasAttendanceConfigPrivilege}
                                        >
                                            {i18n.ts_file_import_modal_import()}
                                        </Button>
                                    </TooltipTrigger>
                                    <TooltipContent
                                        type='info'
                                        intention='warning'
                                        label={i18n.action_unauthorized()}
                                    >
                                        {loggedAsRef.current
                                            ? i18n.action_unauthorized_extended_logas()
                                            : i18n.action_unauthorized_extended()}
                                    </TooltipContent>
                                </Tooltip>
                                <TimesheetsFileImportModal ref={modalRef} />
                            </>
                        )}
                    </div>
                </div>
            ) : null}
        </section>
    );
};
export default Timesheets;
