import { useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { Dialog, toast } from "saphir";
import { Button, Select } from "sui";
import { z } from "zod";

import { zodResolver } from "@hookform/resolvers/zod";
import { i18n } from "@lib/i18n";
import { usePatchTask } from "@lib/mutations/usePatchTask";
import { queries } from "@lib/queries";
import { useQueryClient, useSuspenseQuery } from "@tanstack/react-query";

interface Props {
    taskId: string;
    trigger: React.ReactNode;
    tracker: () => void;
}

export function EditTaskManagerDialog(props: Props) {
    const [open, setOpen] = useState(false);

    return (
        <Dialog.Root open={open} onOpenChange={setOpen}>
            <Dialog.Trigger asChild>{props.trigger}</Dialog.Trigger>
            <Dialog.Content>
                <EditTaskManagerDialogContent {...props} setOpen={setOpen} />
            </Dialog.Content>
        </Dialog.Root>
    );
}

function EditTaskManagerDialogContent(props: Props & { setOpen: (open: boolean) => void }) {
    const { data: task } = useSuspenseQuery(queries.task.detail(props.taskId));
    const { data: companyInfoData } = useSuspenseQuery(queries.company.detail());
    const companyMembers = companyInfoData?.members || [];
    const currentManager = companyMembers?.find((member) => member._id === task.manager?.id);

    const options = companyMembers.map(({ _id, firstName, lastName }) => ({
        id: _id,
        value: _id,
        label: `${firstName} ${lastName}`,
    }));
    const schema = z.object({
        manager: z.object({
            label: z.string(),
            value: z.string(),
        }),
    });

    type Inputs = z.infer<typeof schema>;

    const {
        control,
        handleSubmit,
        formState: { isDirty },
        reset,
    } = useForm<Inputs>({
        mode: "onBlur",
        resolver: zodResolver(schema),
        defaultValues: {
            manager: {
                label: currentManager
                    ? `${currentManager.firstName} ${currentManager.lastName}`
                    : "",
                value: currentManager?._id,
            },
        },
    });

    const queryClient = useQueryClient();

    const { patchTask, isPending } = usePatchTask({
        onSuccess: (data) => {
            queryClient.setQueryData(queries.task.detail(props.taskId).queryKey, data);
            props.tracker();
            reset({
                manager: {
                    label: `${data?.manager?.firstName} ${data?.manager?.lastName}`,
                    value: data?.manager?.id,
                },
            });
            props.setOpen(false);
            toast.success(i18n.task_edit_success_toast());
        },
        onSettled: () => {
            queryClient.invalidateQueries({ queryKey: ["task"] });
        },
    });

    const onSubmit: SubmitHandler<Inputs> = async (data) => {
        patchTask({
            id: props.taskId,
            managerId: data.manager.value,
        });
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)} className='contents'>
            <Dialog.ScrollArea>
                <Dialog.Header>
                    <Dialog.Title>{i18n.order_summary_manager_title()}</Dialog.Title>
                    <Dialog.Description>{i18n.manager_form_subtitle()}</Dialog.Description>
                </Dialog.Header>
                <Dialog.Main>
                    <Controller
                        name='manager'
                        control={control}
                        rules={{ required: true }}
                        render={({ field }) => (
                            <Select
                                aria-label={i18n.modal_update_manager_title()}
                                options={options}
                                selection={field.value}
                                onChange={(value) => {
                                    field.onChange(value);
                                }}
                                size='small'
                            />
                        )}
                    />
                </Dialog.Main>
            </Dialog.ScrollArea>
            <Dialog.Footer className='justify-end'>
                <div className='flex gap-2'>
                    <Dialog.Close asChild>
                        <Button type='button' shape='invisible' intention='secondary'>
                            {i18n.action_cancel()}
                        </Button>
                    </Dialog.Close>
                    <Button loading={isPending} disabled={!isDirty}>
                        {i18n.save()}
                    </Button>
                </div>
            </Dialog.Footer>
        </form>
    );
}
