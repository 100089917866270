import { useState } from "react";
import { toast } from "saphir";
import { Dialog, ModalsService } from "side-ui";

import { i18n } from "@lib/i18n";
import trackEvent from "@lib/trackers";

import ModalForm from "../../../components/ModalForm";
import getJobPostingUrl from "../../../lib/utils/getJobPostingUrl";

import FeedbackForm from "./FeedbackForm";

import "./FeedbackModal.scss";

type FeedbackModalProps = {
    sider: any;
    task: any;
    feedbackId: string;
    workAgainValue: number;
};

const FeedbackModal = (props: FeedbackModalProps) => {
    const [workAgainValue, setWorkAgainValue] = useState(props.workAgainValue);
    const [isDialogDisplayed, setIsDialogDisplayed] = useState(false);

    const getHeader = () => {
        const { task, sider } = props;

        return (
            <div className='feedback-modal-header'>
                <div>
                    <h2>{i18n.feedback_modal_title()}</h2>
                    <span className='sider-name'>
                        {sider.firstName} {sider.lastName}
                    </span>
                    <a
                        href={getJobPostingUrl(`edit`, task.id, task.jobTypeId)}
                        target='_blank'
                        rel='noopener noreferrer'
                        className='link'
                    >
                        {task.name}
                    </a>
                </div>
                <div>
                    <div className='sider-with-feedback'>
                        <img
                            src={
                                sider.pictureUrl ||
                                "https://s3.eu-west-3.amazonaws.com/in.side-assets/placeholder-sider.png"
                            }
                            alt={`${sider.firstName} ${sider.lastName}`}
                            className='sider-with-feedback__picture'
                        />
                        <div className='sider-with-feedback__action' onKeyDown={() => null}>
                            {workAgainValue && workAgainValue === 1 && (
                                <div className='sider-with-feedback__feedback sider-with-feedback__feedback--liked'>
                                    <i className='icon icon-smiley-happy' />
                                </div>
                            )}
                            {workAgainValue && workAgainValue <= 0 && (
                                <div className='sider-with-feedback__feedback'>
                                    <i className='icon icon-smiley-sad' />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const showDialog = () => {
        setIsDialogDisplayed(true);
    };

    const hideDialog = () => {
        setIsDialogDisplayed(false);
    };

    const closeModalWithoutSaving = (sider, task) => {
        trackEvent({
            name: `feedback - stopped rating`,
            params: {
                taskId: task.id,
                siderId: sider.id,
            },
        });
        ModalsService.closeModal(`FEEDBACK`);
        toast.warning(i18n.feedback_toast_not_saved({ name: sider.firstName }));
    };

    return (
        <>
            <ModalForm
                header={getHeader()}
                shouldCloseOnOverlayClick={false}
                onClose={() => showDialog()}
                hideModal={() => ModalsService.closeModal(`FEEDBACK`)}
            >
                <div className='feedback-modal' id='feedback-modal'>
                    <FeedbackForm
                        sider={props.sider}
                        task={props.task}
                        wrapperName='feedback-modal'
                        workAgainValue={workAgainValue}
                        feedbackId={props.feedbackId}
                        updateWorkAgainValue={(value) => {
                            setWorkAgainValue(value);
                        }}
                    />
                </div>
            </ModalForm>
            {isDialogDisplayed && (
                <Dialog
                    title={i18n.feedback_dialog_title()}
                    validateLabel={i18n.confirm()}
                    validateAction={() => closeModalWithoutSaving(props.sider, props.task)}
                    cancelLabel={i18n.cancel()}
                    cancelAction={() => hideDialog()}
                >
                    {i18n.feedback_dialog_content()}
                </Dialog>
            )}
        </>
    );
};

export default FeedbackModal;
