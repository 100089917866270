import { useState } from "react";
import { Button, IconRocket, ModalSimple } from "side-ui";

import { i18n } from "@lib/i18n";

import "./OnboardingModal.scss";

const OnboardingModal = ({ hideModal }) => {
    const [step, setStep] = useState(0);

    return (
        <ModalSimple customClass='onboarding-modal' hideModal={hideModal}>
            <div className='onboarding-modal__icon-container'>
                <IconRocket height={40} width={40} />
            </div>
            <div className='onboarding-modal__title'>{i18n.onboarding_modal_title()}</div>
            {step === 0 && (
                <>
                    <p className='onboarding-modal__intro'>{i18n.onboarding_modal_intro()}</p>
                    <Button color='blue' action={() => setStep(1)}>
                        {i18n.label_continue()}
                    </Button>
                </>
            )}

            {step === 1 && (
                <div className='onboarding-modal__content'>
                    <div className='onboarding-modal__content__block'>
                        <div className='onboarding-modal__content__block__header'>
                            <div className='onboarding-modal__content__block__header__step-number'>
                                1
                            </div>
                            <div className='onboarding-modal__content__block__header__title'>
                                {i18n.onboarding_modal_steps_1_title()}
                            </div>
                        </div>
                        <p className='onboarding-modal__content__block__content'>
                            {i18n.onboarding_modal_steps_1_content()}
                        </p>
                    </div>
                    <div className='onboarding-modal__content__block'>
                        <div className='onboarding-modal__content__block__header'>
                            <div className='onboarding-modal__content__block__header__step-number'>
                                2
                            </div>
                            <div className='onboarding-modal__content__block__header__title'>
                                {i18n.onboarding_modal_steps_2_title()}
                            </div>
                        </div>
                        <p className='onboarding-modal__content__block__content'>
                            {i18n.onboarding_modal_steps_2_content()}
                        </p>
                    </div>
                    <div className='onboarding-modal__content__block'>
                        <div className='onboarding-modal__content__block__header'>
                            <div className='onboarding-modal__content__block__header__step-number'>
                                3
                            </div>
                            <div className='onboarding-modal__content__block__header__title'>
                                {i18n.onboarding_modal_steps_3_title()}
                            </div>
                        </div>
                        <p className='onboarding-modal__content__block__content'>
                            {i18n.onboarding_modal_steps_3_content()}
                        </p>
                    </div>

                    <Button color='blue' action={hideModal}>
                        {i18n.onboarding_modal_understood()}
                    </Button>
                </div>
            )}
        </ModalSimple>
    );
};

export default OnboardingModal;
