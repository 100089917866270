import "./BlankTab.scss";

const BlankTab = (props) => {
    const { title, subtitle } = props;

    return (
        <div className='blank-tab'>
            <div className='blank-tab__inner'>
                {title && (
                    <h1 className='mb-6 text-gray-900 typography-heading-l-semibold'>{title}</h1>
                )}
                {subtitle && (
                    <p
                        className='typography-body-m-regular'
                        dangerouslySetInnerHTML={{ __html: subtitle }}
                    />
                )}
            </div>
        </div>
    );
};

export default BlankTab;
