import { z } from "zod";

import { i18n } from "@lib/i18n";
import { globalLayoutRoute } from "@routes";
import { allowAdminAndUser, allowCompany } from "@routes/authorization";
import { createRoute, redirect } from "@tanstack/react-router";

import ContractsBatches from "./containers/ContractsBatches";
import ContractsBatchesArchives from "./containers/ContractsBatchesArchives";
import ContractsSignature from "./containers/ContractsSignature";
import Contracts from "./index";

export const contractsRoute = createRoute({
    getParentRoute: () => globalLayoutRoute,
    path: `contracts`,
    component: Contracts,
    beforeLoad: async () => {
        const allowedAdminAndUser = await allowAdminAndUser();
        if (!(allowCompany() && allowedAdminAndUser)) {
            throw redirect({ to: `/` });
        }

        return { getTitle: () => i18n.doc_title_contracts() };
    },
});
export const contractsIndexRoute = createRoute({
    getParentRoute: () => contractsRoute,
    path: `/`,
    component: ContractsBatches,
});
const contractsBatchesArchivesSearchSchema = z
    .object({
        page: z.number().catch(1),
    })
    .partial();
type ContractsBatchesArchivesSearch = z.infer<typeof contractsBatchesArchivesSearchSchema>;
export const contractsBatchesArchivesRoute = createRoute({
    getParentRoute: () => contractsRoute,
    path: `archives`,
    validateSearch: (search: ContractsBatchesArchivesSearch) =>
        contractsBatchesArchivesSearchSchema.parse(search),
    component: ContractsBatchesArchives,
});
export const contractsSignatureRoute = createRoute({
    getParentRoute: () => globalLayoutRoute,
    path: `contracts/$id/signature`,
    component: ContractsSignature,
    beforeLoad: async () => {
        const allowedAdminAndUser = await allowAdminAndUser();
        if (!(allowCompany() && allowedAdminAndUser)) {
            throw redirect({ to: `/` });
        }
    },
});
