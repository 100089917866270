import { useState } from "react";
import { InputText, ModalSimple } from "side-ui";

import { i18n } from "@lib/i18n";

export function CustomTool({ hideModal, submitTools }) {
    const [state, setState] = useState({
        customTool: "",
        dirty: false,
    });

    function handleTextChange(event) {
        const { value } = event.target;

        if (value?.length > 50) {
            return;
        }

        setState((prevState) => ({
            ...prevState,
            customTool: value,
            dirty: true,
        }));
    }

    return (
        <ModalSimple
            title={i18n.job_descriptions_creation_skills_tools_add_custom_button()}
            validateLabel={i18n.add()}
            cancelLabel={i18n.cancel()}
            hideModal={hideModal}
            disableButton={!state.customTool.length || state.customTool?.length < 2 || !state.dirty}
            action={() => {
                submitTools({
                    name: state.customTool,
                    custom: true,
                    id: `to_custom${Date.now()}`,
                });
                hideModal();
            }}
        >
            <div className='job-description__form__tools__custom'>
                <p className='job-description__form__tools__custom__disclaimer'>
                    {i18n.job_descriptions_creation_skills_tools_custom_modal_tip()}
                </p>

                <InputText
                    label={i18n.job_descriptions_creation_skills_tools_custom_modal_label()}
                    name='customTool'
                    id='customTool'
                    value={state.customTool}
                    placeholder={i18n.job_descriptions_creation_skills_tools_custom_modal_placeholder()}
                    smallInput={true}
                    onChange={handleTextChange}
                />
            </div>
        </ModalSimple>
    );
}
