import { Plus } from "lucide-react";
import { Controller, useFormContext } from "react-hook-form";
import { Dialog, Input, toast } from "saphir";
import { Button, Select, Tip } from "sui";

import { useEffectSkipMount } from "@lib/hooks/useEffectSkipMount";
import { i18n } from "@lib/i18n";
import { usePatchTask } from "@lib/mutations/usePatchTask";
import { queries } from "@lib/queries";
import { useQueryClient, useSuspenseQuery } from "@tanstack/react-query";

interface InitialStepProps {
    taskId: string;
    setStep: React.Dispatch<
        React.SetStateAction<
            | "initial"
            | "waitingRecruitment"
            | "replacement"
            | "activityIncrease"
            | "temporaryContract"
            | "seasonalActivity"
        >
    >;
    tracker: () => void;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    motivesOptions: { label: string; value: string }[];
}

export function InitialStep(props: InitialStepProps) {
    const { data: motives } = useSuspenseQuery(queries.company.companyMotives());

    const queryClient = useQueryClient();

    const {
        control,
        handleSubmit,
        formState: { isDirty, isValid },
        reset,
        watch,
        setValue,
    } = useFormContext();

    const formValues = watch();

    const justificationsOptions = motives
        .filter((motive) => motive.reason === formValues.selectedMotive?.value)
        .map((motive) => ({
            label: motive.justification,
            value: motive.id,
        }));

    const { patchTask, isPending } = usePatchTask({
        onSuccess: (data) => {
            queryClient.setQueryData(queries.task.detail(props.taskId).queryKey, data);
            props.tracker();
            const newMotive = motives.find((motive) => motive.id === data?.motiveId);
            reset({
                selectedMotive: {
                    label: props.motivesOptions.find((motive) => motive.value === newMotive?.reason)
                        ?.label,
                    value: newMotive?.reason,
                },
                selectedJustification: {
                    label: newMotive?.justification,
                    value: newMotive?.id,
                },
            });
            props.setOpen(false);
            toast.success(i18n.task_edit_success_toast());
        },
        onSettled: () => {
            queryClient.invalidateQueries({ queryKey: ["task"] });
        },
    });

    const onSubmit = async (data) => {
        patchTask({
            id: props.taskId,
            motiveId: data.selectedJustification?.value,
            motive: motives.find((motive) => motive.id === data.selectedJustification?.value),
        });
    };

    // clear the selected justification when the selected motive changes
    useEffectSkipMount(() => {
        setValue("selectedJustification", null, { shouldValidate: true });
    }, [formValues.selectedMotive?.value]);

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Dialog.ScrollArea>
                <Dialog.Header>
                    <Dialog.Title>{i18n.order_motives_title()}</Dialog.Title>
                    <Dialog.Description>{i18n.order_motives_subtitle()}</Dialog.Description>
                </Dialog.Header>
                <Dialog.Main className='flex flex-col gap-4'>
                    <Controller
                        name='selectedMotive'
                        control={control}
                        rules={{ required: true }}
                        render={({ field }) => (
                            <Input.Root>
                                <Input.Label className='required'>
                                    {i18n.order_motives_label()}
                                </Input.Label>
                                <Input.Element>
                                    <Select
                                        aria-label={i18n.order_motives_label()}
                                        options={props.motivesOptions}
                                        selection={field.value}
                                        onChange={(value) => {
                                            field.onChange(value);
                                        }}
                                        size='small'
                                    />
                                </Input.Element>
                            </Input.Root>
                        )}
                    />
                    <Controller
                        name='selectedJustification'
                        control={control}
                        rules={{ required: true }}
                        render={({ field }) => (
                            <Input.Root>
                                <Input.Label className='required'>
                                    {i18n.order_justifications_label()}
                                </Input.Label>
                                <div className='flex flex-col gap-2'>
                                    <Input.Element>
                                        <Select
                                            aria-label={i18n.order_justifications_label()}
                                            options={[...justificationsOptions]}
                                            selection={field.value}
                                            onChange={(value) => {
                                                field.onChange(value);
                                            }}
                                            size='small'
                                            dropdownFooterComponent={
                                                <button
                                                    className='flex w-full cursor-pointer items-center gap-2 p-4 text-blue-500 hover:bg-blue-20'
                                                    onClick={() =>
                                                        props.setStep(
                                                            formValues.selectedMotive?.value,
                                                        )
                                                    }
                                                    type='button'
                                                >
                                                    <Plus className='h-5 w-5' />
                                                    <span className='typography-body-m-regular'>
                                                        {i18n.order_justifications_add()}
                                                    </span>
                                                </button>
                                            }
                                        />
                                    </Input.Element>
                                    <Tip>{i18n.order_justifications_tip()}</Tip>
                                </div>
                            </Input.Root>
                        )}
                    />
                </Dialog.Main>
            </Dialog.ScrollArea>
            <Dialog.Footer className='justify-end'>
                <div className='flex gap-2'>
                    <Dialog.Close asChild>
                        <Button type='button' shape='invisible' intention='secondary'>
                            {i18n.action_cancel()}
                        </Button>
                    </Dialog.Close>
                    <Button loading={isPending} disabled={!isDirty || !isValid}>
                        {i18n.save()}
                    </Button>
                </div>
            </Dialog.Footer>
        </form>
    );
}
