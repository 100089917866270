import { useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { Checkbox, Dialog, Input, toast } from "saphir";
import { Button } from "sui";
import { z } from "zod";

import { zodResolver } from "@hookform/resolvers/zod";
import { LicenceSchema } from "@lib/api/getTask";
import { i18n, i18nDK } from "@lib/i18n";
import { usePatchTask } from "@lib/mutations/usePatchTask";
import { queries } from "@lib/queries";
import { useQueryClient, useSuspenseQuery } from "@tanstack/react-query";

interface Props {
    taskId: string;
    trigger: React.ReactNode;
    tracker: () => void;
}

const schema = z.object({
    licences: z.array(LicenceSchema),
});

type Inputs = z.infer<typeof schema>;

export function EditTaskLicencesDialog(props: Props) {
    const [open, setOpen] = useState(false);
    const queryClient = useQueryClient();

    return (
        <Dialog.Root open={open} onOpenChange={setOpen}>
            <Dialog.Trigger
                asChild
                onMouseEnter={() => {
                    // prefetch jobDescriptionsOptions data
                    queryClient.ensureQueryData(queries.jobDescriptionOptions.list());
                }}
            >
                {props.trigger}
            </Dialog.Trigger>
            <Dialog.Content>
                <EditTaskLicencesDialogContent {...props} setOpen={setOpen} />
            </Dialog.Content>
        </Dialog.Root>
    );
}

function EditTaskLicencesDialogContent(props: Props & { setOpen: (open: boolean) => void }) {
    const { data: task } = useSuspenseQuery(queries.task.detail(props.taskId));
    const { data: jobDescriptionOptions } = useSuspenseQuery(queries.jobDescriptionOptions.list());

    const queryClient = useQueryClient();

    const licenceOptions =
        jobDescriptionOptions?.licences.sort(
            // licence.name === LI_DRIVING_LICENSE_B is always first
            (a, b) =>
                a.name === "LI_DRIVING_LICENSE_B" ? -1 : b.name === "LI_DRIVING_LICENSE_B" ? 1 : 0,
        ) ?? [];

    const {
        handleSubmit,
        reset,
        formState: { isDirty },
        control,
        watch,
    } = useForm<Inputs>({
        mode: "onChange",
        resolver: zodResolver(schema),
        defaultValues: {
            licences: task.licences ?? [],
        },
    });

    const { patchTask, isPending } = usePatchTask({
        onSuccess: (data) => {
            queryClient.setQueryData(queries.task.detail(props.taskId).queryKey, data);
            props.tracker();

            reset({
                licences: data?.licences ?? [],
            });

            props.setOpen(false);
            toast.success(i18n.task_edit_success_toast());
        },
        onSettled: () => {
            queryClient.invalidateQueries({ queryKey: ["task"] });
        },
    });

    const onSubmit: SubmitHandler<Inputs> = async (data) => {
        patchTask({ id: props.taskId, licenceIds: data.licences.map((licence) => licence.id) });
    };

    const { licences } = watch();

    return (
        <form onSubmit={handleSubmit(onSubmit)} className='contents'>
            <Dialog.ScrollArea>
                <Dialog.Header>
                    <Dialog.Title>
                        {i18n.job_descriptions_creation_skills_licence_label()}
                    </Dialog.Title>
                    <Dialog.Description>
                        {i18n.job_descriptions_creation_skills_licence_subtitle()}
                    </Dialog.Description>
                </Dialog.Header>
                <Dialog.Main className='flex flex-col gap-2'>
                    {licenceOptions.map((licenceOption) => {
                        const checked = licences.some((licence) => licence.id === licenceOption.id);

                        return (
                            <div
                                key={licenceOption.id}
                                className='group flex flex-col rounded border border-gray-200 hover:border-blue-500'
                            >
                                <Input.Root>
                                    <Input.Label className='flex w-full flex-row items-center gap-4 p-4 !typography-body-m-semibold'>
                                        <Controller
                                            control={control}
                                            name='licences'
                                            render={({ field }) => {
                                                return (
                                                    <Input.Element>
                                                        <Checkbox
                                                            className='group-hover:border-blue-500'
                                                            checked={checked}
                                                            onCheckedChange={(checked) => {
                                                                if (checked) {
                                                                    field.onChange([
                                                                        ...field.value,
                                                                        licenceOption,
                                                                    ]);
                                                                } else {
                                                                    field.onChange(
                                                                        field.value.filter(
                                                                            (licence) =>
                                                                                licence.id !==
                                                                                licenceOption.id,
                                                                        ),
                                                                    );
                                                                }
                                                            }}
                                                        />
                                                    </Input.Element>
                                                );
                                            }}
                                        />
                                        {i18nDK(licenceOption.name)}
                                    </Input.Label>
                                </Input.Root>
                            </div>
                        );
                    })}
                </Dialog.Main>
            </Dialog.ScrollArea>
            <Dialog.Footer className='justify-end'>
                <div className='flex gap-2'>
                    <Dialog.Close asChild>
                        <Button type='button' shape='invisible' intention='secondary'>
                            {i18n.action_cancel()}
                        </Button>
                    </Dialog.Close>
                    <Button loading={isPending} disabled={!isDirty}>
                        {i18n.save()}
                    </Button>
                </div>
            </Dialog.Footer>
        </form>
    );
}
