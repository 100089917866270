import { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { Dialog, TextArea, toast } from "saphir";
import { Button } from "sui";
import { z } from "zod";

import { zodResolver } from "@hookform/resolvers/zod";
import { i18n } from "@lib/i18n";
import { usePatchTask } from "@lib/mutations/usePatchTask";
import { queries } from "@lib/queries";
import { useQueryClient, useSuspenseQuery } from "@tanstack/react-query";

interface Props {
    taskId: string;
    trigger: React.ReactNode;
    tracker: () => void;
}

export function EditTaskMissionInformationDialog(props: Props) {
    const [open, setOpen] = useState(false);

    return (
        <Dialog.Root open={open} onOpenChange={setOpen}>
            <Dialog.Trigger asChild>{props.trigger}</Dialog.Trigger>
            <Dialog.Content>
                <EditTaskMissionInformationDialogContent {...props} setOpen={setOpen} />
            </Dialog.Content>
        </Dialog.Root>
    );
}

function EditTaskMissionInformationDialogContent(
    props: Props & { setOpen: (value: boolean) => void },
) {
    const { data: task } = useSuspenseQuery(queries.task.detail(props.taskId));

    const schema = z.object({
        missionInformation: z.string().optional(),
    });

    type Inputs = z.infer<typeof schema>;

    const {
        register,
        handleSubmit,
        formState: { isDirty },
        reset,
    } = useForm<Inputs>({
        mode: "onBlur",
        resolver: zodResolver(schema),
        defaultValues: {
            missionInformation: task.missionInformation ?? "",
        },
    });

    const queryClient = useQueryClient();

    const { patchTask, isPending } = usePatchTask({
        onSuccess: (data) => {
            queryClient.setQueryData(queries.task.detail(props.taskId).queryKey, data);
            props.tracker();
            reset({
                missionInformation: data?.missionInformation,
            });
            props.setOpen(false);
            toast.success(i18n.task_edit_success_toast());
        },
        onSettled: () => {
            queryClient.invalidateQueries({ queryKey: ["task"] });
        },
    });

    const onSubmit: SubmitHandler<Inputs> = async (data) => {
        patchTask({
            id: props.taskId,
            missionInformation: data.missionInformation?.trim(),
        });
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)} className='contents'>
            <Dialog.ScrollArea>
                <Dialog.Header>
                    <Dialog.Title>{i18n.order_summary_info_title()}</Dialog.Title>
                    <Dialog.Description>{i18n.order_details_subtitle()}</Dialog.Description>
                </Dialog.Header>
                <Dialog.Main>
                    <TextArea
                        aria-label={i18n.order_details_label()}
                        placeholder={i18n.order_details_placeholder()}
                        {...register("missionInformation")}
                    />
                </Dialog.Main>
            </Dialog.ScrollArea>
            <Dialog.Footer className='justify-end'>
                <div className='flex gap-2'>
                    <Dialog.Close asChild>
                        <Button type='button' shape='invisible' intention='secondary'>
                            {i18n.action_cancel()}
                        </Button>
                    </Dialog.Close>
                    <Button loading={isPending} disabled={!isDirty}>
                        {i18n.save()}
                    </Button>
                </div>
            </Dialog.Footer>
        </form>
    );
}
