import { Loader, RadioButtons, Searchbar, SectionHeader } from "side-ui";

import { useFormInput } from "@lib/hooks/form";
import { i18n } from "@lib/i18n";
import { queries } from "@lib/queries";
import { useQuery } from "@tanstack/react-query";

import styles from "./JobTitleForm.module.css";

export const JobTitleForm = ({ openSubtasks, setSelectedJobTitle, numberOfTasks, job }) => {
    const { data: allJobTitles = [], isLoading: isLoadingJobTitles } = useQuery(
        queries.jobTitles.list(),
    );
    const { formInput: searchJobTitleValue, handleChange: setSearchJobTitleValue } = useFormInput(
        "",
        () => null,
    );
    const { data: searchedJobTitles = [], isLoading: iSsearchedJobTitlesLoading } = useQuery(
        queries.jobTitles.search(searchJobTitleValue.value),
    );
    const jobTitles = searchedJobTitles?.length ? searchedJobTitles : allJobTitles;
    const selectedJobTitle = job?.jobTitle ?? null;

    function handleChange({ currentTarget: { value } }) {
        const newSelectedJobTitle = jobTitles.find(({ id }) => id === value);
        setSelectedJobTitle(newSelectedJobTitle);
        openSubtasks();
    }

    const tasksLink = (
        <div className={styles.item} onClick={() => openSubtasks()}>
            {i18n.job_descriptions_creation_job_jobtitle_subtasks({ numberOfTasks })}
        </div>
    );

    return (
        <div className={styles.jobTitle}>
            <SectionHeader
                title={i18n.job_descriptions_creation_job_jobtitle_title()}
                icon='Briefcase'
                subtitle={
                    <div>
                        <p>{i18n.job_descriptions_creation_job_jobtitle_subtitle()}</p>
                        <Searchbar
                            value={searchJobTitleValue.value}
                            id='searchJobTitle'
                            onFocus={() => null}
                            onChange={(event) => setSearchJobTitleValue(event?.target?.value)}
                            placeholder={i18n.search()}
                            iconPosition='right'
                            clearOnFocus={false}
                        />
                    </div>
                }
            />

            {isLoadingJobTitles || iSsearchedJobTitlesLoading ? (
                <Loader />
            ) : (
                <div className={styles.list}>
                    <RadioButtons
                        options={
                            jobTitles && jobTitles.length > 0
                                ? jobTitles
                                      .sort((a, b) =>
                                          a.id === selectedJobTitle?.id
                                              ? -1
                                              : b.id === selectedJobTitle?.id
                                                ? 1
                                                : 0,
                                      )
                                      .map((jobTitle) => ({
                                          name: jobTitle.name,
                                          value: jobTitle.id,
                                          label: jobTitle.name,
                                          children:
                                              jobTitle.id === selectedJobTitle?.id &&
                                              numberOfTasks > 0
                                                  ? tasksLink
                                                  : null,
                                      }))
                                : []
                        }
                        value={{ [selectedJobTitle?.id]: true }}
                        onChange={handleChange}
                        secondaryStyle={true}
                    />
                </div>
            )}
        </div>
    );
};
