import fetch from "isomorphic-fetch";
import { z } from "zod";

import { handleError } from "@lib/utils/error";
import { debug } from "@side.co/client-debug";

import { withAuthHeaders } from "./apiHelpers";
import { apiConf } from "./index";

const PlanningSchema = z.object({
    startDate: z
        .date()
        .transform((val) =>
            new Date(
                Date.UTC(val.getFullYear(), val.getMonth(), val.getDate(), 0, 0, 0, 0),
            ).toISOString(),
        ),
    endDate: z
        .date()
        .transform((val) =>
            new Date(
                Date.UTC(val.getFullYear(), val.getMonth(), val.getDate(), 0, 0, 0, 0),
            ).toISOString(),
        ),
    weeklyVolume: z.string().transform((val) => Number(val)),
    siders: z.number(),
    amplitudeStart: z.string(),
    amplitudeEnd: z.string(),
    include: z
        .object({
            holidays: z.boolean(),
            weekDays: z.boolean(),
            saturday: z.boolean(),
            sunday: z.boolean(),
        })
        .partial(),
});
export const PostFlexiblePlanningSchema = z.object({
    taskId: z.string(),
    config: PlanningSchema,
});
export type PostFlexiblePlanningParams = z.infer<typeof PostFlexiblePlanningSchema>;

const message = "Post FlexiblePlanning";
export async function postFlexiblePlanning(params: PostFlexiblePlanningParams): Promise<void> {
    debug.addBreadcrumb({
        message,
        category: `action`,
        data: params,
    });

    try {
        const resp = await fetch(
            withAuthHeaders(`${apiConf.BACK_URL}/tasks/${params.taskId}/flexible-planning`, {
                method: `POST`,
                body: JSON.stringify(PlanningSchema.parse(params.config)),
            }),
        );

        if (resp.status >= 400) {
            return Promise.reject(new Error(message));
        }

        return Promise.resolve();
    } catch (e) {
        handleError(e, 500);
        return Promise.reject(e);
    }
}
