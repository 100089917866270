import { memo } from "react";

import { i18n } from "@lib/i18n";

import { padZeroBeforeMinutes } from "../../../../utils";
import { convertMinutesToHours } from "../../../../utils/dates";

type BreakCellProps = {
    breakSum: number;
    conflict: any;
    siderDidntWork: boolean;
};

// named function to prevent "anonymous" displayed in devTools
const BreakCell = memo(function BreakCell({
    // eslint-disable-line
    breakSum,
    conflict,
    siderDidntWork,
}: BreakCellProps) {
    return (
        <span
            className={`timesheets-table__cell timesheets-table__cell--break ${siderDidntWork ? "--sider-didnt-work" : ""} `}
        >
            {breakSum > 0 ? (
                <span
                    className={`timesheets-table__cell__slot__break ${
                        conflict && !siderDidntWork
                            ? "timesheets-table__cell__hoverable__wrapper conflicting"
                            : ""
                    } `}
                >
                    <div className='timesheets-table__cell__slot__break__duration'>
                        {padZeroBeforeMinutes(
                            convertMinutesToHours(breakSum).replace(
                                " minutes",
                                breakSum > 60 ? "" : "min",
                            ),
                        )}
                    </div>

                    {conflict && !siderDidntWork ? (
                        <div className='timesheets-table__cell__hoverable__tooltip'>
                            <span className='timesheets-table__cell__hoverable__tooltip__title timesheets-table__cell__hoverable__tooltip__title--red'>
                                {i18n.ts_table_diff_break_label({
                                    delta: convertMinutesToHours(conflict),
                                })}
                            </span>

                            <div className='timesheets-table__cell__hoverable__tooltip__content timesheets-table__cell__hoverable__tooltip__content--smaller'>
                                {i18n.ts_table_diff_break_description({
                                    delta: convertMinutesToHours(conflict),
                                })}
                            </div>
                        </div>
                    ) : null}
                </span>
            ) : (
                "-"
            )}
        </span>
    );
});

export default BreakCell;
