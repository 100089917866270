import { ModalSimple, ModalsService } from "side-ui";

import { i18n } from "@lib/i18n";

const DeleteModal = (props) => (
    <ModalSimple
        action={props.deleteTask}
        validateLabel={i18n.label_delete()}
        cancelLabel={i18n.cancel()}
        title={i18n.modal_delete_title()}
        hideModal={() => ModalsService.closeModal(`DELETE_TASK`)}
    >
        <p>{i18n.modal_delete_description()}</p>
    </ModalSimple>
);

export default DeleteModal;
