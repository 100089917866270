import { i18n } from "@lib/i18n";
import { rootRoute } from "@routes";
import { createRoute } from "@tanstack/react-router";

import SignIn from "./SignIn";

export const signInRoute = createRoute({
    getParentRoute: () => rootRoute,
    path: `signin`,
    component: SignIn,
    beforeLoad: () => {
        localStorage.clear();
        return { getTitle: () => i18n.sign_in_title() };
    },
});
