import fetch from "isomorphic-fetch";
import { z } from "zod";

import { handleError } from "@lib/utils/error";
import { debug } from "@side.co/client-debug";

import { withAuthHeaders } from "./apiHelpers";
import {
    ExecutiveStatusSchema,
    HoursPricingSchema,
    LanguageSchema,
    LicenceSchema,
    RiskSchema,
    ToolSchema,
} from "./getTask";
import { apiConf } from "./index";

export const JobTitleSchema = z.object({
    id: z.string(),
    name: z.string(),
});
export const SubtasksSchema = z.array(
    z.object({
        id: z.string(),
        name: z.string(),
        custom: z.boolean(),
    }),
);

export const LocationSchema = z.object({
    id: z.string(),
    organisationId: z.string(),
    postalCode: z.string(),
    city: z.string(),
    lat: z.number(),
    lng: z.number(),
    country: z.string(),
    confirmed: z.boolean(),
    address: z.string(),
    description: z.string(),
    favorite: z.boolean(),
    options: z.string(),
    vehicle: z.boolean(),
});

export const PricingSchema = z.object({
    id: z.string(),
    gestion: z.array(HoursPricingSchema),
    delegation: z.array(HoursPricingSchema),
});

export const EquipmentSchema = z.object({
    id: z.string(),
    group: z.enum(["Safety", "Other"]),
    name: z.string(),
    custom: z.boolean(),
    providedBy: z.enum(["company", "sider"]),
});

export const JobDescriptionSchema = z.object({
    id: z.string(),
    organisationId: z.string(),
    jobTitle: JobTitleSchema.optional(),
    enabled: z.boolean(),
    hourlyRate: z.number().nullable(),
    origin: z.string(),
    managerId: z.string().optional(),
    suffix: z.string().optional(),
    subtasks: SubtasksSchema.optional(),
    tools: z.array(ToolSchema).optional(),
    risks: z.array(RiskSchema).optional(),
    pricing: PricingSchema.optional(),
    pricingOrigin: z.string(),
    createdAt: z.string(),
    updatedAt: z.string(),
    languages: z.array(LanguageSchema).optional(),
    subtaskIds: z.array(z.string()).optional(),
    equipment: z.array(EquipmentSchema).optional(),
    riskIds: z.array(z.string()).optional(),
    languageIds: z.array(z.string()).optional(),
    toolIds: z.array(z.string()).optional(),
    licenceIds: z.array(z.string()).optional(),
    location: LocationSchema.optional(),
    licences: z.array(LicenceSchema).optional(),
    purpose: z.string().optional(),
    dressCode: z.string().optional(),
    workConditions: z.string().optional(),
    experiences: z.string().optional(),
    executiveStatus: ExecutiveStatusSchema.optional(),
    preSelection: z.boolean().optional(),
});

const JobDescriptionsSchema = z.array(JobDescriptionSchema);
export type GetJobDescriptionsResponse = z.infer<typeof JobDescriptionsSchema>;

const message = "Get job descriptions";
export async function getJobDescriptions() {
    debug.addBreadcrumb({
        message,
        category: `action`,
    });

    try {
        const resp = await fetch(
            withAuthHeaders(`${apiConf.BACK_URL}/job-descriptions`, {
                method: `GET`,
            }),
        );

        if (resp.status >= 400) {
            handleError(await resp.json(), resp.status);
            return Promise.reject(new Error(message));
        }

        return JobDescriptionsSchema.parse(await resp.json());
    } catch (e) {
        handleError(e, 500);
        return Promise.reject(new Error(message));
    }
}
