import fetch from "isomorphic-fetch";

import { withAuthHeaders, withURLParam } from "./apiHelpers";
import { GetFlexiblePlanningResponse } from "./getFlexiblePlanning";
import { apiConf } from "./index";

type GetPlanningParams = { week: number | string; year: number | string; locale: string };

export type GetPlanningResponse = {
    tasks: Task[] | null;
};

type Task = {
    id: string;
    name: string;
    siders: Sider[];
    category: string;
    flexiblePlanning: GetFlexiblePlanningResponse;
};

type Sider = {
    id: string;
    firstName: string;
    lastName: string;
    pictureUrl: string;
    workedHoursInWeek: number;
    slots: Slot[];
    email: string;
    phoneNumber: string;
};

type Slot = {
    startDate: string;
    endDate: string;
    breakDuration: number;
};
export function getPlanning(params: GetPlanningParams): Promise<Response> {
    return fetch(
        withAuthHeaders(
            withURLParam(`${apiConf.BACK_URL}/v1/planning`, {
                week: params.week,
                year: params.year,
                locale: params.locale,
            }),
        ),
    );
}
