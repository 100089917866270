import * as i18n from "@paraglide/messages";

// It is meant to be used as a helper function to get safely the i18n translations from a dynamic key.
export function i18nDK(key: string, params?: Record<string, string>) {
    if (key in i18n) {
        if (params) {
            return i18n[key](params);
        }
        return i18n[key]();
    }
    return key;
}

// Otherwise use default functions from paraglide/messages to get the type safety.
export * as i18n from "@paraglide/messages";
