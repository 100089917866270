import { useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { Checkbox, Dialog, Input, toast } from "saphir";
import { Button, Select } from "sui";
import { z } from "zod";

import { zodResolver } from "@hookform/resolvers/zod";
import { getJobDescriptionOptions } from "@lib/api";
import { i18n, i18nDK } from "@lib/i18n";
import { usePatchTask } from "@lib/mutations/usePatchTask";
import { queries } from "@lib/queries";
import { useQueryClient, useSuspenseQuery } from "@tanstack/react-query";

interface Props {
    taskId: string;
    trigger: React.ReactNode;
    tracker: () => void;
}

export function EditTaskRisksDialog(props: Props) {
    const [open, setOpen] = useState(false);

    return (
        <Dialog.Root open={open} onOpenChange={setOpen}>
            <Dialog.Trigger asChild>{props.trigger}</Dialog.Trigger>
            <Dialog.Content>
                <EditTaskRisksDialogContent {...props} setOpen={setOpen} />
            </Dialog.Content>
        </Dialog.Root>
    );
}

function EditTaskRisksDialogContent(props: Props & { setOpen: (value: boolean) => void }) {
    const { data: task } = useSuspenseQuery(queries.task.detail(props.taskId));
    const { data: risks = [] } = useSuspenseQuery({
        ...queries.jobDescriptionOptions.list(),
        select: (data) => {
            return (data?.risks?.reduce((acc, val) => {
                if (!acc[val.name]) {
                    acc[val.name] = [val];
                    return acc;
                }
                acc[val.name].push(val);
                return acc;
            }, {}) || {}) as Record<
                string,
                Awaited<ReturnType<typeof getJobDescriptionOptions>>["risks"][number][]
            >;
        },
    });

    const schema = z.object({
        riskIds: z.array(z.string()),
    });

    type Inputs = z.infer<typeof schema>;

    const {
        control,
        handleSubmit,
        formState: { isDirty },
        reset,
        watch,
    } = useForm<Inputs>({
        mode: "onBlur",
        resolver: zodResolver(schema),
        defaultValues: {
            riskIds: task.risks?.map((risk) => risk.id) ?? [],
        },
    });

    const queryClient = useQueryClient();

    const { patchTask, isPending } = usePatchTask({
        onSuccess: (data) => {
            queryClient.setQueryData(queries.task.detail(props.taskId).queryKey, data);
            props.tracker();
            reset({
                riskIds: data?.risks?.map((risk) => risk.id) ?? [],
            });
            props.setOpen(false);
            toast.success(i18n.task_edit_success_toast());
        },
        onSettled: () => {
            queryClient.invalidateQueries({ queryKey: ["task"] });
        },
    });

    const onSubmit: SubmitHandler<Inputs> = async (data) => {
        patchTask({
            id: props.taskId,
            riskIds: data.riskIds,
        });
    };

    const { riskIds } = watch();

    return (
        <form onSubmit={handleSubmit(onSubmit)} className='contents'>
            <Dialog.ScrollArea>
                <Dialog.Header>
                    <Dialog.Title>{i18n.job_descriptions_summary_risks_title()}</Dialog.Title>
                    <Dialog.Description>
                        {i18n.job_descriptions_creation_conditions_equipments_risks_subtitle()}
                    </Dialog.Description>
                </Dialog.Header>
                <Dialog.Main className='flex flex-col gap-2'>
                    {risks &&
                        Object.entries(risks).map(([name, options]) => {
                            const isChecked = options.some(({ id }) => riskIds.includes(id));
                            const selectOptions = options.map(({ id, value: label }) => ({
                                value: id,
                                label: i18nDK(label ?? ""),
                            }));
                            return (
                                <div
                                    key={name}
                                    className='group flex flex-col rounded border border-gray-200 hover:border-blue-500'
                                >
                                    <Input.Root>
                                        <Input.Label className='flex w-full flex-row items-center gap-4 p-4 !typography-body-m-semibold'>
                                            <Controller
                                                control={control}
                                                name='riskIds'
                                                render={({ field }) => {
                                                    return (
                                                        <Input.Element>
                                                            <Checkbox
                                                                className='group-hover:border-blue-500'
                                                                checked={isChecked}
                                                                onCheckedChange={(checked) => {
                                                                    if (checked) {
                                                                        field.onChange([
                                                                            ...field.value,
                                                                            options[0].id,
                                                                        ]);
                                                                    } else {
                                                                        field.onChange(
                                                                            field.value.filter(
                                                                                (value) =>
                                                                                    !options
                                                                                        .map(
                                                                                            ({
                                                                                                id,
                                                                                            }) =>
                                                                                                id,
                                                                                        )
                                                                                        .includes(
                                                                                            value,
                                                                                        ),
                                                                            ),
                                                                        );
                                                                    }
                                                                }}
                                                            />
                                                        </Input.Element>
                                                    );
                                                }}
                                            />
                                            {i18nDK(name)}
                                        </Input.Label>
                                    </Input.Root>
                                    {options.length > 1 && isChecked ? (
                                        <div className='p-4 pt-0'>
                                            <Controller
                                                control={control}
                                                name='riskIds'
                                                render={({ field }) => {
                                                    return (
                                                        <Select
                                                            size='small'
                                                            placeholder={i18n.job_descriptions_creation_conditions_equipments_risks_options()}
                                                            aria-label={i18n.job_descriptions_creation_conditions_equipments_risks_options()}
                                                            options={selectOptions}
                                                            selection={
                                                                selectOptions.find((option) => {
                                                                    return (
                                                                        option.value ===
                                                                        field.value.find((id) => {
                                                                            return (
                                                                                id === option.value
                                                                            );
                                                                        })
                                                                    );
                                                                }) ?? null
                                                            }
                                                            onChange={(option) => {
                                                                const optionId = option?.value;
                                                                if (optionId) {
                                                                    field.onChange([
                                                                        ...field.value.filter(
                                                                            (value) => {
                                                                                return !options
                                                                                    .map(
                                                                                        ({ id }) =>
                                                                                            id,
                                                                                    )
                                                                                    .includes(
                                                                                        value,
                                                                                    );
                                                                            },
                                                                        ),
                                                                        optionId,
                                                                    ]);
                                                                }
                                                            }}
                                                        />
                                                    );
                                                }}
                                            />
                                        </div>
                                    ) : null}
                                </div>
                            );
                        })}
                </Dialog.Main>
            </Dialog.ScrollArea>
            <Dialog.Footer className='justify-end'>
                <div className='flex gap-2'>
                    <Dialog.Close asChild>
                        <Button type='button' shape='invisible' intention='secondary'>
                            {i18n.action_cancel()}
                        </Button>
                    </Dialog.Close>
                    <Button loading={isPending} disabled={!isDirty}>
                        {i18n.save()}
                    </Button>
                </div>
            </Dialog.Footer>
        </form>
    );
}
