import { forwardRef, useState } from "react";
import { Button, Modal, ModalContent, ModalFooter, ModalHeader, TextField } from "sui";

import { postCompanyMotive } from "@lib/api/postCompanyMotive";
import { i18n } from "@lib/i18n";
import { queries } from "@lib/queries";
import trackEvent from "@lib/trackers";
import { useMutation, useQueryClient, useSuspenseQuery } from "@tanstack/react-query";

import { TEMPORARY_CONTRACT } from "./MotiveStep";

import styles from "./motiveModals.module.css";

type Props = {
    setSelectedJustification: React.Dispatch<
        React.SetStateAction<{
            label: string;
            value: string;
        } | null>
    >;
};
export const TemporaryContractModal = forwardRef<HTMLDialogElement, Props>(
    function TemporaryContractModal({ setSelectedJustification }, ref) {
        const { data: company } = useSuspenseQuery(queries.company.detail());
        const [justification, setJustification] = useState("");

        const queryClient = useQueryClient();

        const { mutate: postCompanyMotiveMutation, isPending } = useMutation({
            mutationFn: (args: Parameters<typeof postCompanyMotive>[0]) => {
                return postCompanyMotive(args);
            },
            onSuccess: (res, req) => {
                setSelectedJustification({
                    label: req.justification,
                    value: res.id,
                });
                setJustification("");
                trackEvent({
                    name: `companymotives - created justification`,
                    params: {
                        orgId: company.organisation.id,
                        reason: TEMPORARY_CONTRACT,
                    },
                });
                (ref as React.MutableRefObject<HTMLDialogElement | null>).current?.close();
            },
            onSettled: () => {
                queryClient.invalidateQueries(queries.company.companyMotives());
            },
        });

        return (
            <Modal
                ref={ref as React.MutableRefObject<HTMLDialogElement | null>}
                onSubmit={() => {
                    postCompanyMotiveMutation({
                        reason: TEMPORARY_CONTRACT,
                        justification: justification.trim(),
                    });
                }}
                onClose={() => {
                    setJustification("");
                }}
            >
                <ModalHeader
                    title={i18n.motive_temporary_contract_title()}
                    description={
                        <p>
                            {i18n.motive_temporary_contract_subtitle()}
                            &nbsp;
                            <a
                                href={i18n.motive_temporary_contract_explaination_link()}
                                target='_blank'
                                rel='noopener noreferrer external'
                                className={styles.link}
                            >
                                {i18n.motive_temporary_contract_explaination_link_label()}
                            </a>
                        </p>
                    }
                />
                <ModalContent>
                    <TextField
                        label={i18n.motive_temporary_contract_label()}
                        placeholder={i18n.motive_temporary_contract_placeholder()}
                        value={justification}
                        onChange={(e) => setJustification(e.target.value)}
                        required
                        className={styles.requiredField}
                    />
                </ModalContent>
                <ModalFooter
                    mainButton={
                        <Button loading={isPending} disabled={justification === ""}>
                            {i18n.order_justifications_create_action()}
                        </Button>
                    }
                    cancelButtonLabel={i18n.cancel()}
                />
            </Modal>
        );
    },
);
