import { useEffect } from "react";
import { Button, SelectableSearch } from "side-ui";
import { Logo } from "sui";

import { queryClient } from "@App";
import { i18n } from "@lib/i18n";
import { queries } from "@lib/queries";
import { router } from "@routes";

import "./LogAs.scss";

function LogAs({
    selectedUserToImpersonate,
    possibleUsersToImpersonate,
    searchUser,
    setSelectedUserToImpersonate,
}) {
    useEffect(() => {
        localStorage.removeItem(`side_team_groupId`);
    }, []);

    const handleDelog = () => {
        localStorage.clear();
        window.location.assign("/signin");
    };

    const logAs = () => {
        localStorage.setItem(`side_team_logasId`, selectedUserToImpersonate.id);
        localStorage.setItem(`side_team_logas_email`, selectedUserToImpersonate.label);
        if (selectedUserToImpersonate.isParent === true) {
            localStorage.setItem(`side_team_groupId`, selectedUserToImpersonate.complementarylabel);
            router.navigate({ to: `/home` });
        } else {
            localStorage.setItem(
                `side_team_activeOrganisationId`,
                selectedUserToImpersonate.complementarylabel,
            );
            router.navigate({ to: `/planning` });
        }
        queryClient.invalidateQueries(queries.user.detail());
    };

    return (
        <div className='logas'>
            <Logo className='logas__logo' />

            <div className='logas--input'>
                <SelectableSearch
                    label={i18n.logas_input_label()}
                    id='log_as_input'
                    options={possibleUsersToImpersonate}
                    placeholder={i18n.logas_input_label()}
                    value={selectedUserToImpersonate.label}
                    onChange={(val) => searchUser(val)}
                    onOptionClick={(option) => setSelectedUserToImpersonate(option)}
                />
            </div>
            <div className='logas--actions'>
                <Button
                    color='blue'
                    iconAfter='ArrowRight'
                    disabled={
                        !(
                            selectedUserToImpersonate.sublabel &&
                            selectedUserToImpersonate.complementarylabel
                        )
                    }
                    action={logAs}
                >
                    {i18n.logas_impersonate_button()}
                    {selectedUserToImpersonate && selectedUserToImpersonate.sublabel}
                </Button>
                <Button color='red' disabled={false} action={handleDelog}>
                    {i18n.logas_logout_button()}
                </Button>
            </div>
        </div>
    );
}

export default LogAs;
