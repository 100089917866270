import { useEffect, useMemo, useState } from "react";
import {
    add,
    endOfDay,
    formatDuration,
    getYear,
    intervalToDuration,
    startOfDay,
    sub,
} from "date-fns";
import { useSetAtom } from "jotai";
import { CircleAlert, Info } from "lucide-react";
import { Controller, useForm } from "react-hook-form";
import { ComboBox, DatePicker, DocumentLines, Label, Select, TextField, Tip } from "sui";
import { ListItem } from "sui/dist/components/ComboBox/types";
import { z } from "zod";

import { zodResolver } from "@hookform/resolvers/zod";
import { i18n } from "@lib/i18n";
import { queries } from "@lib/queries";
import { getLocale } from "@lib/utils/getLocale";
import { parseStringWithFontStyle } from "@lib/utils/parseStringWithFontStyle";
import { useQuery, useSuspenseQuery } from "@tanstack/react-query";

import { taskFormRoute } from "../route";
import { disabledSubmitAtom, orderDataToSendAtom } from "../TaskFormFooter";

import { TimeField } from "./TimeField";

const customFormatDuration = (start, end) => {
    const locale = getLocale();
    const { years, months, days } = intervalToDuration({
        start,
        end: add(end, { seconds: 1 }),
    });
    return formatDuration(
        {
            years,
            months,
            weeks: days ? Math.floor(days / 7) : undefined,
            days: days ? days % 7 : undefined,
        },
        { locale },
    );
};

function getSchema() {
    return z.object({
        startDate: z.date().optional(),
        endDate: z.date().optional(),
        weeklyVolume: z.string(),
        siders: z.number().min(1).max(50),
        include: z
            .object({
                weekDays: z.boolean().optional(),
                holidays: z.boolean().optional(),
                saturday: z.boolean().optional(),
                sunday: z.boolean().optional(),
            })
            .partial(),
        amplitudeStart: z.string().regex(/^([01]\d|2[0-3]):([0-5]\d)\$/),
        amplitudeEnd: z.string().regex(/^([01]\d|2[0-3]):([0-5]\d)\$/),
    });
}

type Inputs = z.infer<ReturnType<typeof getSchema>>;

export function FlexiblePlanningStep() {
    const { taskId } = taskFormRoute.useParams();
    const { data: task } = useSuspenseQuery(queries.task.detail(taskId));
    const { data: planning } = useSuspenseQuery({
        ...queries.task.planning(taskId),
        queryKey: [...queries.task.planning(taskId).queryKey, task.flexiblePlanningId],
        queryFn: () => (task.flexiblePlanningId ? queries.task.planning(taskId).queryFn() : null),
    });
    const setOrderDataToSend = useSetAtom(orderDataToSendAtom);
    const setDisabledSubmit = useSetAtom(disabledSubmitAtom);
    const [dayTypeErrors, setDayTypeErrors] = useState({
        empty: false,
        holidaysAlone: false,
    });
    const [dateError, setDateError] = useState("");
    const [weeklyVolumeAmplitudeError, setWeeklyVolumeAmplitudeError] = useState("");
    const [weeklyVolumeError, setWeeklyVolumeError] = useState("");
    const [weeklyVolumeDayTypeError, setweeklyVolumeDayTypeError] = useState("");
    const { data: holidaysN } = useQuery(queries.holiday.list(getYear(new Date())));
    const { data: holidaysN1 } = useQuery(queries.holiday.list(getYear(new Date()) + 1));
    const holidays = useMemo(
        () => [...(holidaysN || []), ...(holidaysN1 || [])].map(({ startDate }) => startDate),
        [holidaysN, holidaysN1],
    );
    const schema = useMemo(() => getSchema(), []);
    const optionsValues = {
        weekDays: { value: "weekDays", label: i18n.planning_day_type_week() },
        saturday: { value: "saturday", label: i18n.planning_day_type_saturday() },
        sunday: { value: "sunday", label: i18n.planning_day_type_sunday() },
        holidays: { value: "holidays", label: i18n.planning_day_type_holidays() },
    };
    const optionList = Object.values(optionsValues);

    const weeklyVolumeOptions = [7, 14, 21, 28, 35, 42, 48].map((value) => ({
        value,
        label: value.toString(),
    }));

    const { watch, control } = useForm<Inputs>({
        mode: "onBlur",
        resolver: zodResolver(schema),
        defaultValues: planning
            ? {
                  ...planning,
                  startDate: startOfDay(planning.startDate),
                  endDate: endOfDay(planning.endDate),
              }
            : {
                  startDate: startOfDay(new Date()),
                  endDate: endOfDay(add(new Date(), { days: 6 })),
                  weeklyVolume: "35",
                  siders: 1,
                  include: { weekDays: true, holidays: true },
                  amplitudeStart: "",
                  amplitudeEnd: "",
              },
    });

    const { startDate, endDate, include, siders, weeklyVolume, amplitudeStart, amplitudeEnd } =
        watch();

    useEffect(() => {
        setOrderDataToSend({
            startDate,
            endDate,
            include,
            siders,
            weeklyVolume,
            amplitudeStart,
            amplitudeEnd,
        });
        setDisabledSubmit(
            [
                ...Object.values(dayTypeErrors),
                !!dateError,
                !!weeklyVolumeError,
                !!weeklyVolumeDayTypeError,
                !!weeklyVolumeAmplitudeError,
                !amplitudeStart,
                !amplitudeEnd,
            ].includes(true) ||
                !startDate ||
                !endDate ||
                Number.isNaN(siders) ||
                siders < 1 ||
                siders > 50,
        );
    }, [
        startDate,
        endDate,
        include,
        siders,
        weeklyVolume,
        dayTypeErrors,
        dateError,
        weeklyVolumeError,
        weeklyVolumeDayTypeError,
        weeklyVolumeAmplitudeError,
        amplitudeStart,
        amplitudeEnd,
    ]);

    useEffect(() => {
        // Check if at least one day type is selected (except holidays)
        setDayTypeErrors({
            empty: !Object.values(include).some((value) => value),
            holidaysAlone:
                !!include.holidays && Object.values(include).filter((value) => value).length === 1,
        });
    }, [startDate, endDate, include]);

    useEffect(() => {
        if (startDate && endDate) {
            if (endDate < startDate) {
                setDateError(i18n.planning_dates_reversed_error());
                return;
            }
            const minEndDate = sub(add(startDate, { days: 7 }), { seconds: 1 });
            const maxEndDate = add(startDate, { months: 18 });
            setDateError(
                endDate < minEndDate || endDate > maxEndDate
                    ? i18n.planning_dates_length_error()
                    : "",
            );
        }
    }, [startDate, endDate]);

    useEffect(() => {
        // Check if the weekly volume corresponds to the selected days limit
        const { weekDays, saturday, sunday } = include;
        if (!Number.isNaN(Number(weeklyVolume))) {
            if (!weekDays && saturday && sunday && Number(weeklyVolume) > 20) {
                setweeklyVolumeDayTypeError(i18n.planning_hourly_volume_error({ hours: 20 }));
            } else if (!weekDays && (saturday || sunday) && Number(weeklyVolume) > 10) {
                setweeklyVolumeDayTypeError(i18n.planning_hourly_volume_error({ hours: 10 }));
            } else {
                setweeklyVolumeDayTypeError("");
            }
        }
    }, [include, weeklyVolume]);

    useEffect(() => {
        // Check if the amplitude is enough to cover the weekly volume
        if (amplitudeStart && amplitudeEnd && weeklyVolume) {
            const [startHour, startMinute] = amplitudeStart.split(":").map(Number);
            const [endHour, endMinute] = amplitudeEnd.split(":").map(Number);
            const start = startHour * 60 + startMinute;
            const end = endHour * 60 + endMinute;
            let volumePerDay;
            if (include.weekDays && include.saturday && include.sunday) {
                volumePerDay = (Number(weeklyVolume) / 7) * 60;
            } else if (include.weekDays && (include.saturday || include.sunday)) {
                volumePerDay = (Number(weeklyVolume) / 6) * 60;
            } else {
                volumePerDay = (Number(weeklyVolume) / 5) * 60;
            }
            if (end - start < volumePerDay) {
                setWeeklyVolumeAmplitudeError(i18n.planning_hourly_volume_amplitude_error());
            } else {
                setWeeklyVolumeAmplitudeError("");
            }
        }
    }, [amplitudeStart, amplitudeEnd, weeklyVolume, include]);

    return (
        <div className='max-w-[642px] px-12 py-8'>
            <div className='mb-8 flex items-center gap-4 text-gray-900'>
                <div className='grid h-[32px] w-[32px] place-items-center rounded-full bg-blue-50'>
                    <DocumentLines className='fill-blue-500' />
                </div>
                <h2 className='typography-heading-m-semibold'>Planning</h2>
            </div>
            <div className='flex flex-col gap-6'>
                <div className='flex flex-col gap-3'>
                    <Label>{i18n.planning_dates_label()}</Label>
                    <div className='flex items-center gap-1 [&>[class*="TextField"]]:w-1/2'>
                        <Controller
                            control={control}
                            name='startDate'
                            render={({ field }) => (
                                <DatePicker
                                    selected={field.value || new Date()}
                                    onSelect={(date) => {
                                        field.onChange(date ? startOfDay(date) : undefined);
                                    }}
                                    size='small'
                                    portal={false}
                                    required
                                    modifiers={{
                                        holidays,
                                        weekEnd: { dayOfWeek: [0, 6] },
                                    }}
                                    modifiersClassNames={{
                                        holidays: "text-red-500",
                                        weekEnd:
                                            'bg-blue-20 [&:not([class*="Selected"])]:rounded-none',
                                    }}
                                    fixedWeeks={true}
                                    fromDate={new Date()}
                                    error={!!dateError || !startDate}
                                />
                            )}
                        />
                        -
                        <Controller
                            control={control}
                            name='endDate'
                            render={({ field }) => (
                                <DatePicker
                                    selected={field.value || new Date()}
                                    onSelect={(date) => {
                                        field.onChange(date ? endOfDay(date) : undefined);
                                    }}
                                    size='small'
                                    portal={false}
                                    required
                                    modifiers={{
                                        holidays,
                                        weekEnd: { dayOfWeek: [0, 6] },
                                    }}
                                    modifiersClassNames={{
                                        holidays: "text-red-500",
                                        weekEnd:
                                            'bg-blue-20 [&:not([class*="Selected"])]:rounded-none',
                                    }}
                                    fixedWeeks={true}
                                    error={!!dateError || !endDate}
                                />
                            )}
                        />
                    </div>
                    {dateError && <ErrorComponent>{dateError}</ErrorComponent>}
                    {!dateError && startDate && endDate && (
                        <div className='flex items-center text-gray-300 typography-label-m-medium'>
                            <Info className='mr-1 h-5 w-5' />
                            {i18n.planning_dates_info()}
                            <span className='ml-1 text-green-500'>
                                {customFormatDuration(startDate, endDate)}
                            </span>
                        </div>
                    )}
                    <Tip>{parseStringWithFontStyle(i18n.planning_dates_tip())}</Tip>
                </div>
                <div className='flex flex-col gap-2'>
                    <div className='flex items-end gap-2'>
                        <Controller
                            control={control}
                            name='weeklyVolume'
                            render={({ field }) => (
                                <ComboBox
                                    className='w-1/2'
                                    placeholder=''
                                    size='small'
                                    label={i18n.planning_hourly_volume_label()}
                                    selection={
                                        weeklyVolumeOptions.find(
                                            (option) => option.label === weeklyVolume,
                                        ) || { label: "", value: "" }
                                    }
                                    options={weeklyVolumeOptions}
                                    inputValue={field.value}
                                    onInputChange={(value) => {
                                        setWeeklyVolumeError("");
                                        field.onChange(value);
                                    }}
                                    onChange={(value: ListItem) => {
                                        field.onChange(String(value?.value));
                                    }}
                                    onBlur={(event: React.FocusEvent<HTMLInputElement>) => {
                                        const value = event.target.value;
                                        if (
                                            Number.isNaN(Number(value)) ||
                                            Number(value) < 7 ||
                                            Number(value) > 48
                                        ) {
                                            setWeeklyVolumeError(i18n.planning_volume_error());
                                        }
                                    }}
                                    allowCustomEntry={true}
                                    filterMethod='none'
                                    openOnInitialClick={true}
                                    error={!!weeklyVolumeError || !!weeklyVolumeDayTypeError}
                                />
                            )}
                        />
                        <Controller
                            control={control}
                            name='siders'
                            render={({ field }) => (
                                <TextField
                                    className='w-1/2'
                                    label={i18n.planning_siders_label()}
                                    size='small'
                                    min={1}
                                    max={50}
                                    type='number'
                                    value={field.value}
                                    onChange={(event) => {
                                        const value = Number(event.target.value);
                                        if (!Number.isNaN(value)) {
                                            field.onChange(value);
                                        }
                                    }}
                                    error={Number.isNaN(siders) || siders < 1 || siders > 50}
                                />
                            )}
                        />
                    </div>
                    {weeklyVolumeDayTypeError && (
                        <ErrorComponent>{weeklyVolumeDayTypeError}</ErrorComponent>
                    )}
                    {weeklyVolumeError && <ErrorComponent>{weeklyVolumeError}</ErrorComponent>}
                    {Number.isNaN(siders) ||
                        siders < 1 ||
                        (siders > 50 && (
                            <ErrorComponent>{i18n.planning_siders_error()}</ErrorComponent>
                        ))}
                </div>
                <div className='flex flex-col gap-2'>
                    <Controller
                        control={control}
                        name='include'
                        render={({ field }) => (
                            <Select
                                label={i18n.planning_day_type_label()}
                                size='small'
                                options={optionList}
                                selection={Object.entries(field.value).reduce(
                                    (acc, [key, value]) => {
                                        if (value) {
                                            acc.push(optionsValues[key]);
                                        }
                                        return acc;
                                    },
                                    [] as typeof optionList,
                                )}
                                onChange={(values) => {
                                    field.onChange(
                                        values.reduce(
                                            (acc, value) => {
                                                acc[value.value] = true;
                                                return acc;
                                            },
                                            {} as Inputs["include"],
                                        ),
                                    );
                                }}
                                error={dayTypeErrors.empty || dayTypeErrors.holidaysAlone}
                            />
                        )}
                    />
                    {dayTypeErrors.empty && (
                        <ErrorComponent>{i18n.planning_day_type_error_empty()}</ErrorComponent>
                    )}
                    {dayTypeErrors.holidaysAlone && (
                        <ErrorComponent>{i18n.planning_day_type_error_more()}</ErrorComponent>
                    )}
                </div>
                <div className='flex flex-col gap-2'>
                    <Label>{i18n.planning_amplitude_label()}</Label>
                    <div className='flex w-full gap-1'>
                        <Controller
                            control={control}
                            name='amplitudeStart'
                            render={({ field }) => (
                                <TimeField
                                    className='w-1/2'
                                    placeholder={i18n.planning_amplitude_min_placeholder()}
                                    value={field.value}
                                    onChange={(value) => field.onChange(value)}
                                    error={!!weeklyVolumeAmplitudeError}
                                />
                            )}
                        />
                        <span className='mt-[10px]'>-</span>
                        <Controller
                            control={control}
                            name='amplitudeEnd'
                            render={({ field }) => (
                                <TimeField
                                    className='w-1/2'
                                    placeholder={i18n.planning_amplitude_max_placeholder()}
                                    value={field.value}
                                    onChange={(value) => field.onChange(value)}
                                    error={!!weeklyVolumeAmplitudeError}
                                />
                            )}
                        />
                    </div>
                    {weeklyVolumeAmplitudeError && (
                        <ErrorComponent>{weeklyVolumeAmplitudeError}</ErrorComponent>
                    )}
                </div>
                <Tip>{i18n.planning_amplitude_tip()}</Tip>
                <Tip>
                    {i18n.planning_legal_tip_1()}
                    <br />
                    <a
                        href='https://support.side.co/b2b/fr/articles/9113080-quelles-sont-les-durees-legales-de-travail-et-repos'
                        target='_blank'
                        rel='noreferrer'
                        className='underline'
                    >
                        {i18n.planning_legal_tip_2()}
                    </a>
                </Tip>
            </div>
        </div>
    );
}

function ErrorComponent({ children }) {
    return (
        <div className='text-red-500 typography-label-m-medium'>
            <p className='flex'>
                <CircleAlert className='mr-1 h-5 w-5 shrink-0' />
                {children}
            </p>
        </div>
    );
}
