import { memo } from "react";
import { IconButton, ModalsService } from "side-ui";

import { i18n } from "@lib/i18n";
import FeedbackModal from "@routes/Timesheets/components/FeedbackModal";

type FeedbackCellProps = {
    sider: any;
    task: any;
    feedback: any;
};

const FeedbackCell = memo(function FeedbackCell({
    // eslint-disable-line
    sider,
    task,
    feedback,
}: FeedbackCellProps) {
    const showFeedbackModal = () => {
        ModalsService.openModal({
            id: `FEEDBACK`,
            content: (
                <FeedbackModal
                    sider={sider}
                    task={task}
                    feedbackId={feedback?.id}
                    workAgainValue={feedback?.workAgain}
                />
            ),
        });
    };
    const hasFeedback = feedback && feedback?.id;
    const workAgainStatus = feedback?.workAgain > 0 ? "SmileyHappy" : "SmileySad";

    return (
        <span
            title={`${hasFeedback ? i18n.ts_table_edit_comment() : ""}`}
            // color icon fill based on workAgain value
            className={`timesheets-table__cell timesheets-table__cell--feedback ${
                hasFeedback
                    ? feedback?.workAgain > 0 // eslint-disable-line
                        ? "timesheets-table__cell--feedback--satisfied"
                        : "timesheets-table__cell--feedback--unsatisfied"
                    : ""
            } `}
        >
            <IconButton
                icon={`${!hasFeedback ? "SmileyAddReaction" : workAgainStatus}`}
                color='grey'
                action={showFeedbackModal}
            />
        </span>
    );
});

export default FeedbackCell;
