import { Button, Done } from "sui";

import { i18n } from "@lib/i18n";

import ActionBar from "../../../../components/ActionBar";

const SaveBar = ({ resetAction, saveAction, canSave, loading }) => (
    <ActionBar maxWidth='800px'>
        <div className='settings__action'>
            <div className='settings__action__content'>{i18n.settings_actions_content()}</div>
            <div className='settings__action__buttons'>
                {resetAction && (
                    <button
                        type='button'
                        className='settings__action__discard'
                        onClick={resetAction}
                    >
                        {i18n.settings_actions_discard()}
                    </button>
                )}
                <Button
                    intention='secondary'
                    icon={
                        <div className='flex-shrink-0'>
                            <Done />
                        </div>
                    }
                    onClick={saveAction}
                    disabled={!canSave}
                    loading={loading}
                >
                    {i18n.settings_actions_save()}
                </Button>
            </div>
        </div>
    </ActionBar>
);

export default SaveBar;
