import { PureComponent } from "react";
import { Field } from "formik";
import { InputNumber, ToggleSwitch } from "side-ui";

import { i18n } from "@lib/i18n";

import { normalizeNumber } from "../../../../lib/utils/inputsNormalizers";

import "./IndemnityForm.scss";

class HoursSeniorityIncrease extends PureComponent {
    render() {
        return (
            <Field
                name='seniority'
                component={({ field, form }) => {
                    const { values, errors, touched, handleBlur, setFieldValue } = form;

                    return (
                        <>
                            <div className={field.value.checked ? "indemnity__field" : ""}>
                                <ToggleSwitch
                                    id='seniority.checked'
                                    checked={field.value.checked}
                                    label={i18n.settings_remuneration_indemnities_seniority_switch()}
                                    onChange={(e) =>
                                        onFieldChange(
                                            "checked",
                                            e.target.checked,
                                            values.seniority,
                                            setFieldValue,
                                        )
                                    }
                                />
                            </div>
                            {values.seniority.checked && (
                                <>
                                    <div className='indemnity__field'>
                                        <InputNumber
                                            id='seniority.daysThreshold'
                                            label={i18n.settings_remuneration_indemnities_seniority_threshold()}
                                            onChange={(e) =>
                                                onFieldChange(
                                                    "daysThreshold",
                                                    e.target.value,
                                                    values.seniority,
                                                    setFieldValue,
                                                )
                                            }
                                            onBlur={handleBlur}
                                            placeholder={50}
                                            smallInput={true}
                                            value={field.value.daysThreshold}
                                            error={
                                                touched.seniority &&
                                                touched.seniority.daysThreshold &&
                                                errors.seniority &&
                                                errors.seniority.daysThreshold
                                            }
                                            hint={i18n.settings_remuneration_indemnities_seniority_unit()}
                                        />
                                    </div>
                                    <InputNumber
                                        id='seniority.increase'
                                        label={i18n.settings_remuneration_indemnities_seniority_rate()}
                                        onChange={(e) =>
                                            onFieldChange(
                                                "increase",
                                                e.target.value,
                                                values.seniority,
                                                setFieldValue,
                                            )
                                        }
                                        onBlur={handleBlur}
                                        placeholder={100}
                                        unit='%'
                                        smallInput={true}
                                        value={field.value.increase}
                                        error={
                                            touched.seniority &&
                                            touched.seniority.increase &&
                                            errors.seniority &&
                                            errors.seniority.increase
                                        }
                                        customClass='indemnity__small-input'
                                    />
                                </>
                            )}
                        </>
                    );
                }}
            />
        );
    }
}

const onFieldChange = (fieldName, value, seniority, setFieldValue) => {
    let newValue = value;

    switch (fieldName) {
        case "daysThreshold":
        case "increase":
            newValue = normalizeNumber(value);
            break;
        default:
            break;
    }

    const newSeniority = {
        ...seniority,
        [fieldName]: newValue,
    };
    setFieldValue("seniority", newSeniority);
};

export default HoursSeniorityIncrease;
