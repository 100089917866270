import { Suspense, useState } from "react";
import { isEqual } from "lodash";
import { AlertCircle, ArrowLeft, Plus, Search } from "lucide-react";
import { Controller, FormProvider, SubmitHandler, useForm, useFormContext } from "react-hook-form";
import { Checkbox, Dialog, Input, TextField, toast, VisuallyHidden } from "saphir";
import { Button, Loader } from "sui";
import { z } from "zod";

import { zodResolver } from "@hookform/resolvers/zod";
import { i18n } from "@lib/i18n";
import { usePatchTask } from "@lib/mutations/usePatchTask";
import { queries } from "@lib/queries";
import normalizeString from "@lib/utils/normalizeString";
import { useQueryClient, useSuspenseQuery } from "@tanstack/react-query";

interface Props {
    taskId: string;
    trigger: React.ReactNode;
    tracker: () => void;
}

const steps = ["initial", "addCustomSubtask"] as const;
type Steps = (typeof steps)[number];

const schema = z.object({
    subtasks: z.array(
        z.object({
            id: z.string(),
            name: z.string(),
            issuedByFrontEnd: z.boolean().optional(),
            custom: z.boolean().optional(),
        }),
    ),
});

type Inputs = z.infer<typeof schema>;

export function EditTaskSubtasksDialog(props: Props) {
    const [open, setOpen] = useState(false);
    const queryClient = useQueryClient();

    return (
        <Dialog.Root open={open} onOpenChange={setOpen}>
            <Dialog.Trigger
                asChild
                onMouseEnter={() => {
                    // prefetch jobDescriptionsOptions data
                    queryClient.ensureQueryData(queries.jobDescriptionOptions.list());
                }}
            >
                {props.trigger}
            </Dialog.Trigger>
            <Dialog.Content>
                <Suspense
                    fallback={
                        <div className='flex w-full items-center justify-center p-4 text-blue-500'>
                            <VisuallyHidden>
                                <Dialog.Title>
                                    {i18n.job_descriptions_summary_subtasks_title()}
                                </Dialog.Title>
                            </VisuallyHidden>
                            <Loader />
                        </div>
                    }
                >
                    <EditTaskSubtasksDialogContent {...props} setOpen={setOpen} />
                </Suspense>
            </Dialog.Content>
        </Dialog.Root>
    );
}

function EditTaskSubtasksDialogContent(props: Props & { setOpen: (open: boolean) => void }) {
    const { data: task } = useSuspenseQuery(queries.task.detail(props.taskId));
    const { data: jobTitles } = useSuspenseQuery(queries.jobTitles.search(task.jobTitle ?? ""));
    const jobTitle = jobTitles.find((jobTitle) => jobTitle.name === task.jobTitle);
    const { data: subtasksFromBackend = [] } = useSuspenseQuery(
        queries.jobDescriptionSubtasks.list(jobTitle?.id ?? ""),
    );
    const subtasksFromTask = task.subtasks ?? [];

    const [step, setStep] = useState<Steps>("initial");

    const [subtasksOptions, setSubtasksOptions] = useState<
        {
            custom?: boolean;
            id: string;
            name: string;
        }[]
    >(() => {
        const mergedSubtasks = [...subtasksFromTask, ...subtasksFromBackend];
        // remove duplicates
        return mergedSubtasks.filter(
            (subtask, index, self) => self.findIndex((s) => s.id === subtask.id) === index,
        );
    });

    const form = useForm<Inputs>({
        mode: "onChange",
        resolver: zodResolver(schema),
        defaultValues: {
            subtasks: task.subtasks ?? [],
        },
    });

    return (
        <FormProvider {...form}>
            {step === "initial" ? (
                <InitialStep
                    setOpen={props.setOpen}
                    setStep={setStep}
                    taskId={props.taskId}
                    tracker={props.tracker}
                    subtasksOptions={subtasksOptions}
                    setSubtasksOptions={setSubtasksOptions}
                />
            ) : step === "addCustomSubtask" ? (
                <AddCustomSubtaskStep setStep={setStep} setSubtasksOptions={setSubtasksOptions} />
            ) : null}
        </FormProvider>
    );
}

interface InitialStepProps {
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    setStep: React.Dispatch<React.SetStateAction<"initial" | "addCustomSubtask">>;
    tracker: () => void;
    taskId: string;
    subtasksOptions: {
        custom?: boolean;
        id: string;
        name: string;
    }[];
    setSubtasksOptions: React.Dispatch<
        React.SetStateAction<
            {
                custom: boolean;
                id: string;
                name: string;
            }[]
        >
    >;
}

function InitialStep(props: InitialStepProps) {
    const [search, setSearch] = useState("");
    const queryClient = useQueryClient();

    const {
        control,
        handleSubmit,
        formState: { defaultValues, isValid },
        reset,
        watch,
    } = useFormContext<Inputs>();

    const isDirty = !isEqual(defaultValues, watch());

    const { patchTask, isPending } = usePatchTask({
        onSuccess: (data) => {
            queryClient.setQueryData(queries.task.detail(props.taskId).queryKey, data);
            props.tracker();

            reset({
                subtasks: data?.subtasks ?? [],
            });

            props.setOpen(false);
            toast.success(i18n.task_edit_success_toast());
        },
        onSettled: () => {
            queryClient.invalidateQueries({ queryKey: ["task"] });
        },
    });

    const onSubmit: SubmitHandler<Inputs> = async (data) => {
        //remove ids from subtasks that are issued by frontend
        const subtasksToSubmit = data.subtasks.map((subtask) => {
            if (subtask.issuedByFrontEnd) {
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                const { id, ...rest } = subtask;
                return rest;
            }
            return subtask;
        });
        patchTask({ id: props.taskId, subtasks: subtasksToSubmit });
    };

    const { subtasks } = watch();

    const subtasksOptions = props.subtasksOptions.filter((subtask) =>
        normalizeString(subtask.name).includes(normalizeString(search)),
    );

    return (
        <form onSubmit={handleSubmit(onSubmit)} className='contents'>
            <Dialog.ScrollArea>
                <Dialog.Header>
                    <Dialog.Title>{i18n.job_descriptions_summary_subtasks_title()}</Dialog.Title>
                    <Dialog.Description>
                        {i18n.job_descriptions_edit_subtasks_subtitle()}
                    </Dialog.Description>
                </Dialog.Header>
                <Dialog.Main>
                    <TextField.Root
                        placeholder={i18n.search()}
                        className='mb-6'
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                    >
                        <TextField.Slot>
                            <Search />
                        </TextField.Slot>
                    </TextField.Root>
                    {subtasksOptions.length === 0 ? (
                        <div className='rounded border border-gray-50 p-6'>
                            <div className='flex flex-col items-center justify-center gap-2'>
                                <div className='grid h-10 w-10 place-items-center rounded-full bg-blue-50'>
                                    <AlertCircle className='h-5 w-5 text-blue-500' />
                                </div>
                                <div className='text-center text-gray-700 typography-body-m-regular'>
                                    <p>{i18n.no_result()}</p>
                                </div>
                            </div>
                        </div>
                    ) : null}
                    <div className='flex flex-col gap-2'>
                        {subtasksOptions.map((subtask) => {
                            const checked = subtasks.some(
                                (subtaskOption) => subtaskOption.name === subtask.name,
                            );

                            return (
                                <div
                                    key={subtask.id}
                                    className='group flex flex-col rounded border border-gray-200 hover:border-blue-500'
                                >
                                    <Input.Root>
                                        <Input.Label className='flex w-full flex-row items-center gap-4 p-4 !typography-body-m-semibold'>
                                            <Controller
                                                control={control}
                                                name='subtasks'
                                                render={({ field }) => {
                                                    return (
                                                        <Input.Element>
                                                            <Checkbox
                                                                className='group-hover:border-blue-500'
                                                                checked={checked}
                                                                onCheckedChange={(checked) => {
                                                                    if (checked) {
                                                                        field.onChange([
                                                                            ...subtasks,
                                                                            subtask,
                                                                        ]);
                                                                    } else {
                                                                        field.onChange(
                                                                            subtasks.filter(
                                                                                (subtaskOption) =>
                                                                                    subtaskOption.id !==
                                                                                    subtask.id,
                                                                            ),
                                                                        );
                                                                    }
                                                                }}
                                                            />
                                                        </Input.Element>
                                                    );
                                                }}
                                            />
                                            {subtask.name}
                                        </Input.Label>
                                    </Input.Root>
                                </div>
                            );
                        })}
                    </div>
                </Dialog.Main>
            </Dialog.ScrollArea>
            <Dialog.Footer className='justify-between'>
                <Button
                    type='button'
                    shape='outlined'
                    icon={<Plus />}
                    iconDisposition='left'
                    onClick={() => {
                        props.setStep("addCustomSubtask");
                    }}
                >
                    {i18n.job_descriptions_creation_job_subtasks_custom_add_button()}
                </Button>
                <div className='flex gap-2'>
                    <Dialog.Close asChild>
                        <Button type='button' shape='invisible' intention='secondary'>
                            {i18n.action_cancel()}
                        </Button>
                    </Dialog.Close>
                    <Button loading={isPending} disabled={!isDirty || !isValid}>
                        {i18n.save()}
                    </Button>
                </div>
            </Dialog.Footer>
        </form>
    );
}

interface addCustomSubtaskStepProps {
    setStep: React.Dispatch<React.SetStateAction<"initial" | "addCustomSubtask">>;
    setSubtasksOptions: React.Dispatch<
        React.SetStateAction<
            {
                custom: boolean;
                id: string;
                name: string;
            }[]
        >
    >;
}

function AddCustomSubtaskStep(props: addCustomSubtaskStepProps) {
    const addCustomSubtaskFormSchema = z.object({
        name: z.string().max(50),
    });
    type addCustomSubtaskInputs = z.infer<typeof addCustomSubtaskFormSchema>;

    const {
        register,
        handleSubmit,
        formState: { isDirty, isValid },
        reset,
    } = useForm<addCustomSubtaskInputs>({
        mode: "onChange",
        resolver: zodResolver(addCustomSubtaskFormSchema),
        defaultValues: {
            name: "",
        },
    });

    // initial form
    const { setValue: setInitialFormValue, watch: initialFormWatch } = useFormContext<Inputs>();

    const onSubmit: SubmitHandler<addCustomSubtaskInputs> = async (data) => {
        const newSubtask = {
            id: crypto.randomUUID(),
            issuedByFrontEnd: true,
            custom: true,
            ...data,
        } as const;
        props.setSubtasksOptions((prev) => [newSubtask, ...prev]);
        setInitialFormValue("subtasks", [newSubtask, ...initialFormWatch("subtasks")], {
            shouldDirty: true,
            shouldValidate: true,
        });
        reset();
        props.setStep("initial");
    };

    return (
        <form className='contents' onSubmit={handleSubmit(onSubmit)}>
            <Dialog.Header>
                <Dialog.Title className='flex items-center gap-2'>
                    <Button
                        shape='outlined'
                        intention='secondary'
                        onClick={() => props.setStep("initial")}
                        iconDisposition='icon-only'
                        icon={<ArrowLeft />}
                    />
                    {i18n.task_edit_custom_equipment_title()}
                </Dialog.Title>
                <Dialog.Description>
                    {i18n.job_descriptions_creation_job_subtasks_custom_modal_subtitle()}
                </Dialog.Description>
            </Dialog.Header>
            <Dialog.Main className='flex flex-col gap-4'>
                <Input.Root>
                    <Input.Label>
                        {i18n.job_descriptions_creation_job_subtasks_custom_modal_label()}
                    </Input.Label>
                    <Input.Element>
                        <TextField.Root
                            placeholder={i18n.job_descriptions_creation_job_subtasks_custom_modal_placeholder()}
                            required
                            autoComplete='off'
                            maxLength={50}
                            {...register("name")}
                        />
                    </Input.Element>
                </Input.Root>
            </Dialog.Main>
            <Dialog.Footer className='justify-end'>
                <div className='flex gap-2'>
                    <Dialog.Close asChild>
                        <Button type='button' shape='invisible' intention='secondary'>
                            {i18n.action_cancel()}
                        </Button>
                    </Dialog.Close>
                    <Button disabled={!isDirty || !isValid}>{i18n.add()}</Button>
                </div>
            </Dialog.Footer>
        </form>
    );
}
