import { useEffect } from "react";

import { useLocation } from "@lib/hooks/useLocation";
import { i18n } from "@lib/i18n";

import "./NotFound.scss";

const NotFound = () => {
    useEffect(() => {
        document.title = i18n.doc_title_not_found();
    }, []);
    const { pathname } = useLocation();

    return (
        <div className='not-found'>
            <h1>Not found</h1>
            <h3>
                No match for
                <code>{pathname}</code>
            </h3>
        </div>
    );
};

export default NotFound;
