import { useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { Dialog, Input, Switch, toast } from "saphir";
import { Button } from "sui";
import { z } from "zod";

import { zodResolver } from "@hookform/resolvers/zod";
import { i18n } from "@lib/i18n";
import { usePatchTask } from "@lib/mutations/usePatchTask";
import { queries } from "@lib/queries";
import { useQueryClient, useSuspenseQuery } from "@tanstack/react-query";

interface Props {
    taskId: string;
    trigger: React.ReactNode;
    tracker: () => void;
}

export function EditTaskRemoteDialog(props: Props) {
    const [open, setOpen] = useState(false);

    return (
        <Dialog.Root open={open} onOpenChange={setOpen}>
            <Dialog.Trigger asChild>{props.trigger}</Dialog.Trigger>
            <Dialog.Content>
                <EditTaskRemoteDialogContent {...props} setOpen={setOpen} />
            </Dialog.Content>
        </Dialog.Root>
    );
}

function EditTaskRemoteDialogContent(props: Props & { setOpen: (value: boolean) => void }) {
    const { data: task } = useSuspenseQuery(queries.task.detail(props.taskId));

    const schema = z.object({
        remote: z.boolean(),
    });

    type Inputs = z.infer<typeof schema>;

    const {
        control,
        handleSubmit,
        formState: { isDirty },
        reset,
    } = useForm<Inputs>({
        mode: "onBlur",
        resolver: zodResolver(schema),
        defaultValues: {
            remote: task.location?.remote,
        },
    });
    const queryClient = useQueryClient();

    const { patchTask, isPending } = usePatchTask({
        onSuccess: (data) => {
            queryClient.setQueryData(queries.task.detail(props.taskId).queryKey, data);
            props.tracker();
            reset({
                remote: data?.location?.remote,
            });
            props.setOpen(false);
            toast.success(i18n.task_edit_success_toast());
        },
        onSettled: () => {
            queryClient.invalidateQueries({ queryKey: ["task"] });
        },
    });

    const onSubmit: SubmitHandler<Inputs> = async (data) => {
        patchTask({
            id: props.taskId,
            location: {
                ...task.location!,
                remote: data.remote,
            },
        });
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)} className='contents'>
            <Dialog.ScrollArea>
                <Dialog.Header>
                    <Dialog.Title>{i18n.order_summary_remote_title()}</Dialog.Title>
                    <Dialog.Description>{i18n.task_edit_remote_desc()}</Dialog.Description>
                </Dialog.Header>
                <Dialog.Main>
                    <Input.Root className='flex-row items-center gap-4'>
                        <Controller
                            control={control}
                            name='remote'
                            render={({ field }) => (
                                <Input.Element>
                                    <Switch
                                        checked={field.value}
                                        onCheckedChange={field.onChange}
                                    />
                                </Input.Element>
                            )}
                        />
                        <Input.Label className='!typography-body-m-semibold'>
                            {i18n.location_form_workplace_option1()}
                        </Input.Label>
                    </Input.Root>
                </Dialog.Main>
            </Dialog.ScrollArea>
            <Dialog.Footer className='justify-end'>
                <div className='flex gap-2'>
                    <Dialog.Close asChild>
                        <Button type='button' shape='invisible' intention='secondary'>
                            {i18n.action_cancel()}
                        </Button>
                    </Dialog.Close>
                    <Button loading={isPending} disabled={!isDirty}>
                        {i18n.save()}
                    </Button>
                </div>
            </Dialog.Footer>
        </form>
    );
}
