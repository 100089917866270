import { useCallback, useEffect, useRef, useState } from "react";
import { ComboBox } from "sui";

import { i18n } from "@lib/i18n";

const queryOption = {
    componentRestrictions: { country: "fr" },
};

export function LocationInput({ setLocation }) {
    const placesRef = useRef<HTMLDivElement | null>(null);
    const [searchValue, setSearchValue] = useState("");

    const [googlePlaces, setGooglePlaces] = useState<any[]>([]);

    const options = googlePlaces.map((place) => ({
        label: place.value,
        value: place.placeId,
    }));

    function handleChange(value: string) {
        setSearchValue(value);

        if (!value) {
            setGooglePlaces([]);
            return;
        }

        setGooglePlacesFromSearchValue(value);
    }

    const setGooglePlacesFromSearchValue = useCallback(function setGooglePlacesFromSearchValue(
        searchValue: string,
    ) {
        const query = { ...queryOption, input: searchValue };
        const service = new window.google.maps.places.AutocompleteService();

        service.getPlacePredictions(query, (predictions, status: string) => {
            if (status === window.google.maps.places.PlacesServiceStatus.OK) {
                const result = predictions?.reduce((places, prediction) => {
                    // exclude "France" because it missleads the user as it's only the coordinates of the center of France
                    if (
                        prediction.place_id === "ChIJMVd4MymgVA0R99lHx5Y__Ws"
                        // !prediction.types.some((t) => placeTypes.includes(t))
                    )
                        return places;

                    return [
                        ...places,
                        { value: prediction.description, placeId: prediction.place_id },
                    ];
                }, [] as GooglePlace[]);

                setGooglePlaces(result || []);
            }
        });
    }, []);

    useEffect(() => {
        const place = googlePlaces.find((place) => place.value === searchValue);

        if (place) {
            setCoords(place);
        }
    }, [searchValue, setGooglePlacesFromSearchValue]);

    const setCoords = useCallback(function setCoords(place: GooglePlace) {
        try {
            const PlacesService = new window.google.maps.places.PlacesService(placesRef.current!);

            PlacesService.getDetails(
                { placeId: place.placeId, fields: ["geometry", "address_component"] },
                (result) => {
                    if (result?.geometry) {
                        setLocation({
                            address: place.value,
                            postalCode: result?.address_components!.find((c) =>
                                c.types.includes("postal_code"),
                            )?.long_name,
                            city: result?.address_components!.find((c) =>
                                c.types.includes("locality"),
                            )?.long_name,
                            country: result?.address_components!.find((c) =>
                                c.types.includes("country"),
                            )?.long_name,
                            lat: result?.geometry?.location?.lat(),
                            lon: result?.geometry?.location?.lng(),
                        });
                    }
                },
            );
        } catch (e) {
            console.error(e);
            return;
        }
    }, []);

    return (
        <>
            {/* GooglePlace needs a DOM element to be called */}
            <div ref={placesRef} style={{ display: "none" }} />
            <ComboBox
                label={i18n.horizon_modal_address_label()}
                placeholder='Paris, France'
                size='small'
                inputValue={searchValue}
                onInputChange={handleChange}
                selection={{ label: "", value: "" }}
                onChange={() => {}}
                options={options}
                portal={false}
            />
        </>
    );
}

export type GooglePlace = { value: string; placeId: string };
export type PlacePrediction = {
    description: string;
    place_id: string;
};
export type PlacesServiceResult = {
    address_components: [];
    geometry: {
        location: {
            lat: () => number;
            lng: () => number;
        };
    };
};
