/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable max-len */
/* eslint-disable react/jsx-indent */

import { useMemo } from "react";
import { differenceInMinutes, format, getWeek, isWithinInterval } from "date-fns";
import { enUS, fr } from "date-fns/locale";

import { i18n } from "@lib/i18n";

import "./ShiftsTable.scss";

const locale = localStorage.getItem("side_team_locale") || "fr";

function Row({ shift, holidays = [] }) {
    // eslint-disable-next-line max-len
    const start = new Date(shift.startDate);
    const end = new Date(shift.endDate);
    const durationInMinutes = differenceInMinutes(end, start) - shift.breakDuration;
    const totalInMinutes = durationInMinutes * shift.slots;
    const totalHours = Math.floor(totalInMinutes / 60);
    const totalMinutes = Math.floor(totalInMinutes % 60);
    const breakHours = Math.floor(shift.breakDuration / 60)
        .toString()
        .padStart(2, "0");
    const breakMinutes = Math.floor(shift.breakDuration % 60)
        .toString()
        .padStart(2, "0");

    const formattedDuration = `${totalHours}h${totalMinutes !== 0 ? `${totalMinutes}` : ""}`;
    const formattedBreak = `${breakHours}:${breakMinutes !== 0 ? `${breakMinutes}` : ""}`;

    const isWithinHoliday = holidays.find(
        (holiday) =>
            isWithinInterval(new Date(shift.startDate), {
                start: new Date(holiday.startDate),
                end: new Date(holiday.endDate),
            }) && !holiday.solidarity,
    );

    return (
        <div className='shiftstable__row' key={shift.id}>
            <div className='shiftstable__row__date'>
                <span
                    className={`shiftstable__row__day ${
                        isWithinHoliday && "shiftstable__row__date--holiday"
                    }`}
                >
                    {`${format(start, "eee d LLL yyyy", {
                        locale: locale === "fr" ? fr : enUS,
                    })}, `}
                </span>
                <span className='shiftstable__row__hours'>
                    {`${format(start, "HH:mm")} ${i18n.shifts_summary_time_to()} ${format(
                        end,
                        "kk:mm",
                    )}`}
                </span>
            </div>
            <div className='shiftstable__row__break'>
                {shift.breakDuration ? formattedBreak : "-"}
            </div>
            <div className='shiftstable__row__slots'>{shift.slots}</div>
            <div className='shiftstable__row__total'>{formattedDuration}</div>
        </div>
    );
}

export default function ShiftsTable({ shifts, holidays }) {
    const [total, shiftsWithTotal] = useMemo(() => {
        const groupedByWeek = shifts.reduce(
            (acc, shift) => {
                const start = new Date(shift.startDate);
                const end = new Date(shift.endDate);
                const week = getWeek(start, { weekStartsOn: 1 });
                if (!acc.shifts[week]) {
                    acc.shifts[week] = { total: 0, shifts: [] };
                }

                const durationInMinutes = differenceInMinutes(end, start) - shift.breakDuration;
                const totalInMinutes = durationInMinutes * shift.slots;
                acc.shifts[week].shifts = [...acc.shifts[week].shifts, shift];
                acc.shifts[week].total += totalInMinutes;
                acc.total += totalInMinutes;
                return acc;
            },
            { total: 0, shifts: {} },
        );
        return [
            groupedByWeek.total,
            Object.entries(groupedByWeek.shifts).sort(
                (a, b) => new Date(a[1].shifts[0].startDate) - new Date(b[1].shifts[0].startDate),
            ),
        ];
    }, [shifts]);

    return (
        <div className='shiftstable__wrapper'>
            <div className='shiftstable__head'>
                <div>{i18n.shifts_summary_column_date()}</div>
                <div>{i18n.shifts_summary_column_break()}</div>
                <div className='shiftstable__head__slots'>
                    {i18n.shifts_summary_column_total_number()}
                </div>
                <div className='shiftstable__head__hours'>
                    {i18n.shifts_summary_column_total_hours()}
                </div>
            </div>
            <div className='shiftstable__body'>
                <div className='flex flex-col gap-2'>
                    {shiftsWithTotal.map(([weekNumber, weekValue]) => (
                        <div key={weekNumber} className='flex flex-col gap-2'>
                            {weekValue.shifts.map((shift) => Row({ shift, holidays }))}
                            <div className='grid grid-cols-[minmax(228px,3fr)_minmax(112px,1fr)] gap-[48px] text-[var(--sui-grey-900)]'>
                                <div>
                                    {i18n.order_summary_total_week()} {weekNumber}
                                </div>
                                <div>{minutesToHours(weekValue.total)}</div>
                            </div>
                        </div>
                    ))}

                    <div className='grid grid-cols-[minmax(228px,3fr)_minmax(112px,1fr)] gap-[48px] text-[var(--sui-grey-900)]'>
                        <div>Total</div>
                        <div>{minutesToHours(total)}</div>
                    </div>
                </div>
            </div>
        </div>
    );
}

const minutesToHours = (minutes) =>
    `${Math.floor(minutes / 60)}h${minutes % 60 ? minutes % 60 : ""}`;
