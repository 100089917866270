import { useRef, useState } from "react";
import { toast } from "saphir";
import { InputText, ModalSimple } from "side-ui";

import { i18n } from "@lib/i18n";

import useOnClickOutside from "../../../lib/hooks/useOnClickOutside";
import { addressAdapter, addressComponentsAdapter } from "../../../routes/JobDescriptions/utils";

import "./FavoriteLocationForm.scss";

const FavoriteLocationForm = ({
    hideModal,
    placesRef,
    submitAddress,
    location = null,
    modalTitle = i18n.location_form_workplace_favorite_title(),
    deleteLabel = "",
    validateLabel = i18n.save(),
    deleteAction = null,
    cancelAction = null,
}) => {
    const [state, setState] = useState({
        id: location?.id || null,
        title: location?.title || "",
        description: location?.description || "",
        address: {
            placesSearchText: location ? addressAdapter(location) : "",
            formattedAddress: location ? addressAdapter(location) : "",
        },
        suggestionsOpen: false,
        errors: null,
        googlePlaces: [],
    });
    const addressListRef = useRef();

    function handleInputTextChange({ currentTarget }) {
        setState((prevState) => ({
            ...prevState,
            [currentTarget.name]: currentTarget.value,
        }));
    }

    function handleAddressChange(event) {
        const { value } = event.target;

        setState((prevState) => ({
            ...prevState,
            address: {
                ...prevState.address,
                placesSearchText: value,
                formattedAddress: "", // disable button when user is typing
            },
        }));

        if (!window.google || !window.google.maps) {
            // set gmaps errors
            setState((prevState) => ({
                ...prevState,
                errors: {
                    ...prevState.errors,
                    gmaps: {
                        valid: false,
                        notLoaded: true,
                    },
                },
            }));

            return;
        }

        const query = {
            componentRestrictions: {
                country: ["fr"],
            },
            input: value,
        };
        const service = new window.google.maps.places.AutocompleteService();

        service.getPlacePredictions(query, (predictions, status) => {
            if (status === window.google.maps.places.PlacesServiceStatus.OK) {
                const result = predictions.map((prediction) => ({
                    value: prediction.description,
                    placeId: prediction.place_id,
                }));

                setState((prevState) => ({
                    ...prevState,
                    address: {
                        ...prevState.address,
                    },
                    googlePlaces: result,
                    suggestionsOpen: true,
                }));
            }
        });
    }

    function selectAddress(place) {
        // eslint-disable-line consistent-return
        try {
            const PlacesService = new window.google.maps.places.PlacesService(placesRef.current);

            PlacesService.getDetails(
                {
                    placeId: place.placeId,
                    fields: ["geometry", "address_component"],
                },
                (result) => {
                    const addressData = addressComponentsAdapter(result.address_components);
                    const formattedAddress = addressAdapter(addressData) || place.value;

                    setState((prevState) => ({
                        ...prevState,
                        address: {
                            ...prevState.address,
                            ...addressData,
                            placesSearchText: formattedAddress,
                            formattedAddress,
                            lat: result.geometry.location.lat(),
                            lng: result.geometry.location.lng(),
                        },
                        googlePlaces: [],
                        suggestionsOpen: false,
                    }));
                },
            );
        } catch (e) {
            toast.error(i18n.siderprofile_update_error_gmaps_place_not_found());
        }
    }

    function handleKeyPress(event, place) {
        if (event.key === "Enter") {
            selectAddress(place);
        }
    }

    function handleDescriptionChange(event) {
        const { value } = event.target;

        setState((prevState) => ({
            ...prevState,
            description: value,
        }));
    }

    useOnClickOutside(addressListRef, () =>
        setState((prevState) => ({
            ...prevState,
            suggestionsOpen: false,
        })),
    );

    return (
        <section className='add-favorite__wrapper'>
            <ModalSimple
                title={modalTitle}
                subtitle={i18n.location_form_workplace_favorite_subtitle()}
                withCloseButton={false}
                loading={false}
                hideModal={hideModal}
                deleteLabel={location && deleteLabel}
                cancelAction={cancelAction || hideModal}
                cancelLabel={i18n.cancel()}
                validateLabel={validateLabel}
                disableButton={!state.title.length || !state.address.formattedAddress} // input at least a title and an address
                deleteAction={() =>
                    deleteAction({
                        ...state.address,
                        address: state.address.formattedAddress,
                        lat: state.address.lat,
                        lng: state.address.lng,
                        description: state.description,
                        title: state.title,
                        favorite: false,
                        id: state.id,
                    })
                }
                action={() =>
                    submitAddress({
                        ...state.address,
                        address: state.address.formattedAddress,
                        lat: state.address.lat,
                        lng: state.address.lng,
                        description: state.description,
                        title: state.title,
                        id: state.id,
                        favorite: true,
                    })
                }
            >
                <div className='add-favorite__form-group'>
                    <InputText
                        label={i18n.location_form_workplace_favorite_input_title_label()}
                        onChange={handleInputTextChange}
                        name='title'
                        id='add-favorite-title'
                        placeholder={i18n.location_form_workplace_favorite_input_title_placeholder()}
                        smallInput={true}
                        value={state.title}
                    />
                </div>

                <div className='add-favorite__form-group' ref={addressListRef}>
                    <InputText
                        label={i18n.location_form_workplace_favorite_input_label()}
                        onChange={handleAddressChange}
                        name='address'
                        id='add-favorite-address'
                        placeholder={i18n.location_form_workplace_favorite_placeholder()}
                        smallInput={true}
                        value={state.address.placesSearchText}
                        error={state?.errors?.input}
                    />

                    {state.suggestionsOpen ? (
                        <div className='location-form__address__places'>
                            {state.googlePlaces.length > 0 ? (
                                <>
                                    <ul
                                        className={`location-form__address__places__list ${
                                            state.suggestionsOpen
                                                ? "location-form__address__places__list--visible"
                                                : "location-form__address__places__list--hidden"
                                        } `}
                                    >
                                        {state.googlePlaces.map((place) => (
                                            <li
                                                key={place.placeId}
                                                className='location-form__address__places__list__item'
                                                role='button' // eslint-disable-line
                                                onClick={() => selectAddress(place)}
                                                onKeyDown={(event) => handleKeyPress(event, place)}
                                                tabIndex='0'
                                            >
                                                {place.value}
                                            </li>
                                        ))}
                                    </ul>
                                </>
                            ) : null}
                        </div>
                    ) : null}
                </div>

                <div className='add-favorite__form-group'>
                    <label
                        className='input-label add-favorite__informations__label'
                        htmlFor='add-favorite-description'
                    >
                        {i18n.location_form_workplace_label2()}
                    </label>

                    <textarea
                        onChange={handleDescriptionChange}
                        name='descriptions'
                        id='add-favorite-description'
                        placeholder={i18n.location_form_workplace_placeholder2()}
                        value={state.description}
                        className='sui-textarea__input add-favorite__informations__input'
                    />
                </div>
            </ModalSimple>
        </section>
    );
};

export default FavoriteLocationForm;
