import { forwardRef, useEffect, useState } from "react";
import clsx from "clsx";
import { toast } from "saphir";
import {
    Button,
    FileDropZone,
    FileInput,
    FileItem,
    FilesShelf,
    FileUpload,
    fonts,
    Modal,
    ModalContent,
    ModalFooter,
    ModalHeader,
    Tip,
} from "sui";

import { queryClient } from "@App";
import { GetOrganisationResponse } from "@lib/api";
import {
    postAttendances,
    PostAttendancesError,
    PostAttendancesParams,
} from "@lib/api/postAttendances";
import { i18n, i18nDK } from "@lib/i18n";
import { queries } from "@lib/queries";
import trackEvent from "@lib/trackers";
import { timesheetsRoute } from "@routes/Timesheets/route";
import { useMutation, useQuery } from "@tanstack/react-query";

import { requiredColumns, useCsvToJson } from "./useCsvToJson";

import styles from "./TimesheetsFileImportModal.module.css";
export type FileImportErrors = {
    column?: (typeof requiredColumns)[number][];
    empty?: number[];
    format?: number[];
    siderId?: number[];
    endBeforeStart?: number[];
    fileAttDuplicate?: number[];
    dbAttDuplicate?: number[];
    shiftsNotFound?: number[];
};
export const TimesheetsFileImportModal = forwardRef<HTMLDialogElement, any>(function (props, ref) {
    const filters = timesheetsRoute.useSearch();
    const { data: company } = useQuery(queries.company.detail());
    const { file, setFile, reset, json } = useCsvToJson();
    const [errors, setErrors] = useState<FileImportErrors | null>(null);
    const [isSubmited, setIsSubmited] = useState(false);
    const [resetFile, setResetFile] = useState(0);
    const uniqueLinesErrors = errors
        ? Object.values(errors)
              .reduce((acc, value) => [...acc, ...value], [] as number[])
              .filter((value, index, self) => self.indexOf(value) === index).length
        : 0;
    const secondStepErrorType =
        uniqueLinesErrors === json.length || errors?.column?.length ? "all" : "partial";

    const secondStep = errors && isSubmited;

    const maxSize = 1024 * 1024 * 1; // 1MB
    const formatedErrors = formatErrors(errors, maxSize, file?.[0]?.size || 0);
    const trackEventFn = getTrackEventFn(company!.organisation!, maxSize);

    const { mutate: sendAttendances, isPending } = useMutation({
        mutationFn: (payload: PostAttendancesParams) => postAttendances(payload),
        onSuccess: () => {
            toast.success(
                i18n.ts_file_import_modal_import_success({
                    count: json.length - uniqueLinesErrors,
                }),
            );
            queryClient.invalidateQueries(queries.attendance.list(filters));
            trackEventFn(errors, file?.[0]?.size || 0, json);
            onClose();
        },
        onError: (err: PostAttendancesError) => {
            const apiErrors = Object.entries(err.metadata).reduce((acc, [key, value]) => {
                if (!value) return acc;
                return {
                    ...acc,
                    [key]: value
                        .split(",")
                        .map(
                            (v) =>
                                isNaN(Number(v))
                                    ? (v as string)
                                    : ((parseInt(v, 10) + 2) as number), // +2 because the first line is the header and the backend returns abolute indexes
                        )
                        .toSorted((a, b) =>
                            typeof a === "number" && typeof b === "number" ? a - b : a > b ? -1 : 1,
                        ),
                };
            }, {} as FileImportErrors);

            setErrors(apiErrors);
            trackEventFn(apiErrors, file?.[0]?.size || 0, json);
        },
    });

    useEffect(() => {
        if (!file || file.length === 0) {
            setResetFile((prev) => prev + 1);
            setIsSubmited(false);
        }
    }, [file]);

    function onClose() {
        reset();
        setErrors(null);
        if (ref && "current" in ref) {
            ref?.current?.close();
        }
    }

    return (
        <Modal ref={ref} onClose={onClose}>
            {secondStep ? (
                <ModalHeader
                    className='text-[var(--sui-grey-900)]'
                    title={i18nDK(`ts_file_import_modal_${secondStepErrorType}_error_title`)}
                />
            ) : (
                <ModalHeader
                    className='text-[var(--sui-grey-900)]'
                    title={i18n.ts_file_import_modal_title()}
                    description={
                        <>
                            <p>{i18n.ts_file_import_modal_description()}</p>
                            <p>
                                <a
                                    href='https://docs.google.com/spreadsheets/d/1wGfDSltwgYvro3eo8Ml_1mmE1WFSVMH_U_ow6g_thoM'
                                    target='_blank'
                                    className={clsx(fonts.sans18Medium, styles.link)}
                                    rel='noreferrer'
                                >
                                    {i18n.ts_file_import_modal_template_link()}
                                </a>
                            </p>
                        </>
                    }
                />
            )}
            <ModalContent className='text-[var(--sui-grey-700)]'>
                {secondStep ? (
                    <div className={styles.errors}>
                        {formatedErrors.map((error, index) => (
                            <Tip intention='error' highlight key={index}>
                                {error}
                            </Tip>
                        ))}
                        {secondStepErrorType === "all" ? (
                            <Tip intention='error' highlight>
                                {i18n.ts_file_import_modal_error_all_lines()}
                            </Tip>
                        ) : (
                            <p>{i18n.ts_file_import_modal_rest_of_lines()}</p>
                        )}
                    </div>
                ) : (
                    <FileUpload key={resetFile}>
                        <FileDropZone
                            text={i18n.ts_file_import_dropzone_text()}
                            hint={i18n.ts_file_import_dropzone_hint()}
                        >
                            <FileInput onChange={setFile} accept='text/csv'>
                                {i18n.ts_file_import_dropzone_button()}
                            </FileInput>
                        </FileDropZone>
                        {!!(file?.length && file[0]) && (
                            <FilesShelf>
                                <FileItem file={file[0]} />
                            </FilesShelf>
                        )}
                    </FileUpload>
                )}
            </ModalContent>
            {secondStep ? (
                <ModalFooter
                    leftButtons={
                        secondStepErrorType === "partial" ? (
                            <Button intention='secondary' shape='outlined' onClick={onClose}>
                                {i18n.ts_file_import_modal_cancel()}
                            </Button>
                        ) : null
                    }
                    mainButton={
                        secondStepErrorType === "partial" ? (
                            <>
                                <Button intention='primary' shape='outlined' onClick={reset}>
                                    {i18n.ts_file_import_modal_with_error_secondary_button()}
                                </Button>
                                <Button
                                    disabled={isPending || !json.length}
                                    loading={isPending}
                                    onClick={() => {
                                        trackEventFn(errors, file?.[0]?.size || 0, json);
                                        setIsSubmited(true);
                                        sendAttendances({ attendances: json, partial: true });
                                    }}
                                >
                                    {i18n.ts_file_import_modal_with_error_main_button()}
                                </Button>
                            </>
                        ) : (
                            <>
                                <Button intention='secondary' shape='contained' onClick={onClose}>
                                    {i18n.ts_file_import_modal_cancel()}
                                </Button>
                                <Button onClick={reset}>
                                    {i18n.ts_file_import_modal_with_error_secondary_button()}
                                </Button>
                            </>
                        )
                    }
                />
            ) : (
                <ModalFooter
                    mainButton={
                        <>
                            <Button intention='primary' shape='outlined' onClick={onClose}>
                                {i18n.ts_file_import_modal_cancel()}
                            </Button>
                            <Button
                                disabled={
                                    isPending ||
                                    !json.length ||
                                    !!(formatedErrors.length && isSubmited)
                                }
                                loading={isPending}
                                onClick={() => {
                                    trackEventFn(errors, file?.[0]?.size || 0, json);
                                    setIsSubmited(true);
                                    sendAttendances({ attendances: json, partial: false });
                                }}
                            >
                                {i18n.ts_file_import_modal_import()}
                            </Button>
                        </>
                    }
                />
            )}
        </Modal>
    );
});

function getTrackEventFn(company: GetOrganisationResponse["organisation"], maxSize: number) {
    return function (errors: FileImportErrors | null, fileSize = 0, json: any[]) {
        const trackingErrors = Object.entries(errors || {}).reduce(
            (acc, [key, value]) => {
                if (!value) return acc;
                return {
                    ...acc,
                    [`${key}ErrorCount`]: value.length,
                };
            },
            { ...(fileSize > maxSize ? { maxSizeError: 1 } : {}) },
        );
        const failedLineCount =
            [...new Set(Object.values(errors || {}).reduce((acc, value) => [...acc, ...value], []))]
                .length || undefined;

        const uniqueSiderCount = json.length
            ? [...new Set(json.reduce((acc, { siderId }) => [...acc, siderId], []))].length
            : undefined;

        trackEvent({
            name: "timesheet - user import attendances",
            params: {
                organisationId: company?.id ?? "",
                companyName: company?.name ?? "",
                industry: company?.industry ?? "",
                success: !errors,
                importedAttendanceCount: !errors && json.length,
                uniqueSiderCount,
                failedLineCount,
                ...trackingErrors,
            },
        });
    };
}

function formatErrors(errors: FileImportErrors | null, maxSize: number, fileSize = 0) {
    const errorArray: string[] = [];
    if (fileSize > maxSize) {
        errorArray.push(i18n.error_file_size({ maxSize: maxSize / 1024 / 1024 }));
    }

    [
        "column",
        "empty",
        "format",
        "siderId",
        "endBeforeStart",
        "fileAttDuplicate",
        "dbAttDuplicate",
        "shiftsNotFound",
    ].forEach((key) => {
        if (errors?.[key]?.length) {
            const translationKey = key
                .split(/(?=[A-Z])/)
                .join("_")
                .toLowerCase();
            errorArray.push(
                `${i18nDK(`ts_file_import_modal_${translationKey}_error`)} ${errors?.[key]?.join(
                    ", ",
                )}`,
            );
        }
    });

    return errorArray;
}
