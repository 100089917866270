import { Suspense, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { Dialog, VisuallyHidden } from "saphir";
import { Loader } from "sui";
import { z } from "zod";

import { zodResolver } from "@hookform/resolvers/zod";
import { i18n } from "@lib/i18n";
import { queries } from "@lib/queries";
import {
    ACTIVITY_INCREASE,
    RECRUITMENT,
    REPLACEMENT,
    SEASONAL_ACTIVITY,
    TEMPORARY_CONTRACT,
} from "@routes/TaskPosting/MotiveStep/MotiveStep";
import { useQueryClient, useSuspenseQuery } from "@tanstack/react-query";

import { ActivityIncreaseStep } from "./ActivityIncreaseStep";
import { InitialStep } from "./InitialStep";
import { RecruitmentStep } from "./RecruitmentStep";
import { ReplacementStep } from "./ReplacementStep";
import { SeasonalJobStep } from "./SeasonalJobStep";
import { TemporaryContractStep } from "./TemporaryContractStep";

export const stepsSchema = z.enum([
    "initial",
    RECRUITMENT,
    REPLACEMENT,
    ACTIVITY_INCREASE,
    TEMPORARY_CONTRACT,
    SEASONAL_ACTIVITY,
]);

type Steps = z.infer<typeof stepsSchema>;

const stepsComponents = {
    initial: InitialStep,
    [RECRUITMENT]: RecruitmentStep,
    [REPLACEMENT]: ReplacementStep,
    [ACTIVITY_INCREASE]: ActivityIncreaseStep,
    [TEMPORARY_CONTRACT]: TemporaryContractStep,
    [SEASONAL_ACTIVITY]: SeasonalJobStep,
};

interface Props {
    taskId: string;
    trigger: React.ReactNode;
    tracker: () => void;
}

export interface StepContentProps {
    setStep: React.Dispatch<
        React.SetStateAction<
            | "initial"
            | "waitingRecruitment"
            | "replacement"
            | "activityIncrease"
            | "temporaryContract"
            | "seasonalActivity"
        >
    >;
    tracker: () => void;
}

export function EditTaskMotiveDialog(props: Props) {
    const [open, setOpen] = useState(false);
    const queryClient = useQueryClient();

    return (
        <Dialog.Root open={open} onOpenChange={setOpen}>
            <Dialog.Trigger
                asChild
                onMouseEnter={() => {
                    // prefetch motive data
                    queryClient.ensureQueryData(queries.company.companyMotives());
                }}
            >
                {props.trigger}
            </Dialog.Trigger>
            <Dialog.Content>
                <Suspense
                    fallback={
                        <div className='flex w-full items-center justify-center p-4 text-blue-500'>
                            <VisuallyHidden>
                                <Dialog.Title>Loading motive dialog</Dialog.Title>
                            </VisuallyHidden>
                            <Loader />
                        </div>
                    }
                >
                    <EditTaskMotiveDialogContent {...props} setOpen={setOpen} />
                </Suspense>
            </Dialog.Content>
        </Dialog.Root>
    );
}

function EditTaskMotiveDialogContent(props: Props & { setOpen: (value: boolean) => void }) {
    const { data: task } = useSuspenseQuery(queries.task.detail(props.taskId));
    const { data: motives } = useSuspenseQuery(queries.company.companyMotives());
    const [step, setStep] = useState<Steps>("initial");

    const StepComponent = stepsComponents[step] as React.FC<StepContentProps>;

    const motivesOptions = [
        {
            label: i18n.order_motives_label_replacement(),
            value: REPLACEMENT,
        },
        {
            label: i18n.order_motives_label_waiting_recruitment(),
            value: RECRUITMENT,
        },
        {
            label: i18n.order_motives_label_temporary_contract(),
            value: TEMPORARY_CONTRACT,
        },
        {
            label: i18n.order_motives_label_seasonal_activity(),
            value: SEASONAL_ACTIVITY,
        },
        {
            label: i18n.order_motives_label_activity_increase(),
            value: ACTIVITY_INCREASE,
        },
    ] as const;

    const schema = z
        .object({
            selectedMotive: z
                .object({
                    label: z.string(),
                    value: stepsSchema,
                })
                .nullable(),
            selectedJustification: z
                .object({
                    label: z.string(),
                    value: z.string(),
                })
                .nullable(),
        })
        .refine((data) => {
            // make sure both fields are filled
            return data.selectedMotive !== null && data.selectedJustification !== null;
        });

    type Inputs = z.infer<typeof schema>;

    const form = useForm<Inputs>({
        mode: "onChange",
        resolver: zodResolver(schema),
        defaultValues: {
            selectedMotive: (() => {
                const taskMotive = motives.find((motive) => motive.id === task.motiveId);
                if (taskMotive) {
                    return (
                        motivesOptions.find((option) => option.value === taskMotive.reason) ?? null
                    );
                }
                return null;
            })(),
            selectedJustification: (() => {
                const motive = motives.find((motive) => motive.id === task.motiveId);
                if (!motive) return null;
                return {
                    label: motive.justification,
                    value: motive.id,
                };
            })(),
        },
    });

    return (
        <FormProvider {...form}>
            {step === "initial" ? (
                <InitialStep
                    taskId={props.taskId}
                    setStep={setStep}
                    tracker={props.tracker}
                    setOpen={props.setOpen}
                    motivesOptions={[...motivesOptions]}
                />
            ) : (
                <StepComponent tracker={props.tracker} setStep={setStep} />
            )}
        </FormProvider>
    );
}
