import { Accordion, ModalInfo, ModalsService } from "side-ui";

import { i18n } from "@lib/i18n";

const ConfirmationModal = () => {
    const content = {
        subtitle: i18n.modal_confirmation_subtitle(),
        question1: i18n.modal_confirmation_question_1(),
        answer1: i18n.modal_confirmation_answer_1(),
        question2: i18n.modal_confirmation_question_2(),
        answer2: i18n.modal_confirmation_answer_2(),
        question3: i18n.modal_confirmation_question_3(),
        answer3: i18n.modal_confirmation_answer_3(),
        question4: i18n.modal_confirmation_question_4(),
        answer4: i18n.modal_confirmation_answer_4(),
        tip4: i18n.modal_confirmation_tip_4(),
        support: i18n.modal_confirmation_faq(),
    };

    const accordionItems = [
        {
            title: content.question1,
            content: content.answer1,
        },
        {
            title: content.question2,
            content: content.answer2,
        },
        {
            title: content.question3,
            content: content.answer3,
        },
        {
            title: content.question4,
            content: content.answer4,
            tip: content.tip4,
        },
    ];

    return (
        <ModalInfo
            subtitle={content.subtitle}
            title={i18n.modal_confirmation_title()}
            description={i18n.modal_confirmation_description()}
            backgroundColor='var(--sui-yellow-500)'
            hideModal={() => ModalsService.closeModal(`TASK_CREATION_CONFIRMATION`)}
        >
            <Accordion items={accordionItems} />
            <p dangerouslySetInnerHTML={{ __html: content.support }} />
        </ModalInfo>
    );
};

export default ConfirmationModal;
