import { useEffect, useRef, useState } from "react";
import { toast } from "saphir";
import {
    Button,
    IconPicture,
    IconPlusXL,
    InputRadioSwitch,
    InputText,
    Loader,
    Textarea,
} from "side-ui";

import { i18n } from "@lib/i18n";

import { getOrganisationProfile } from "../../../lib/api/getOrganisationProfile";
import { patchOrganisationProfile } from "../../../lib/api/patchOrganisationProfile";
import { uploadOrganisationPicture } from "../../../lib/api/uploadOrganisationPicture";
import { useFormInput } from "../../../lib/hooks/form";
import SaveBar from "../components/partials/SaveBar";

import "./Profile.scss";

const Profile = () => {
    const [loading, setLoading] = useState(false);
    const { formInput: name, handleChange: setName } = useFormInput("", (value) =>
        value === "" ? i18n.error_field_is_required() : null,
    );
    const { formInput: description, handleChange: setDescription } = useFormInput("", (value) =>
        value === "" ? i18n.error_field_is_required() : null,
    );
    const { formInput: website, handleChange: setWebsite } = useFormInput("", (value) => {
        if (value === "") {
            return i18n.error_field_is_required();
        }

        if (value.length > 8 && !value.startsWith("https://")) {
            return i18n.settings_team_profile_website_error();
        }

        return null;
    });

    const { formInput: logoURL, handleChange: setLogoURL } = useFormInput("", (value) =>
        value === "" ? i18n.error_field_is_required() : null,
    );
    const [logoFile, setLogoFile] = useState(null);

    const [mediaType, setMediaType] = useState("");
    const { formInput: youtubeURL, handleChange: setYoutubeURL } = useFormInput("", (value) => {
        if (
            mediaType === "youtube" &&
            !value.startsWith("https://youtube.com") &&
            !value.startsWith("https://www.youtube.com")
        ) {
            return i18n.settings_team_profile_youtube_url_error();
        }

        return null;
    });
    const { formInput: coverURL, handleChange: setCoverURL } = useFormInput("", () => null);
    const [coverFile, setCoverFile] = useState(null);

    useEffect(() => {
        setLoading(true);

        getOrganisationProfile()
            .then((res) => {
                if (res) {
                    setName(res.name || "", false);
                    setDescription(res.description || "", false);
                    setWebsite(res.website || "", false);
                    setLogoURL(res.logoURL || "", false);
                    setYoutubeURL(res.youtubeURL || "", false);
                    setCoverURL(res.coverURL || "", false);
                    setMediaType(res.youtubeURL ? "youtube" : "cover", false);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    }, []);

    const logoUploadRef = useRef(null);
    const coverUploadRef = useRef(null);

    function handleFileSelection(e, photoType) {
        if (!e) {
            return;
        }
        if (!e.target.files || e.target.files.length === 0 || !e.target.files[0]) {
            return;
        }

        switch (photoType) {
            case "cover":
                setCoverFile(e.target.files[0]);
                setCoverURL(URL.createObjectURL(e.target.files[0]));
                break;
            case "logo":
                setLogoFile(e.target.files[0]);
                setLogoURL(URL.createObjectURL(e.target.files[0]));
                break;
            default:
                return;
        }

        const data = new FormData();
        data.append("picture", e.target.files[0]);

        uploadOrganisationPicture(data, photoType).then((res) => {
            if (res) {
                switch (photoType) {
                    case "logo":
                        setLogoURL(res.URL);
                        break;
                    case "cover":
                        setCoverURL(res.URL);
                        break;
                    default:
                        break;
                }
            }
        });
    }

    async function save() {
        let newLogoURL;
        if (logoFile !== null) {
            const data = new FormData();
            data.append("picture", logoFile);
            const res = await uploadOrganisationPicture(data, "logo");
            if (res) {
                newLogoURL = res.URL;
                setLogoFile(null);
            }
        }
        let newCoverURL;
        if (coverFile !== null) {
            const data = new FormData();
            data.append("picture", coverFile);
            const res = await uploadOrganisationPicture(data, "cover");
            if (res) {
                newCoverURL = res.URL;
                setCoverFile(null);
            }
        }

        patchOrganisationProfile({
            name: name.value,
            description: description.value,
            website: website.value,
            logoURL: newLogoURL,
            ...(mediaType === "youtube" && youtubeURL.value !== ""
                ? { youtubeURL: youtubeURL.value }
                : {}),
            ...(mediaType === "cover" && newCoverURL !== undefined
                ? { coverURL: newCoverURL }
                : {}),
        }).then((res) => {
            if (res) {
                setName(res.name || "", false);
                setDescription(res.description || "", false);
                setWebsite(res.website || "", false);
                setLogoURL(res.logoURL || "", false);
                setYoutubeURL(res.youtubeURL || "", false);
                setCoverURL(res.coverURL || "", false);
                setMediaType(res.youtubeURL ? "youtube" : "cover", false);

                toast.success(i18n.settings_team_profile_saved());
            }
        });
    }

    if (loading) {
        return <Loader />;
    }

    return (
        <div className='team-settings-profile'>
            <h2 className='mb-2 typography-heading-xl-semibold'>{i18n.settings_profile_title()}</h2>
            <div className='team-settings-profile__label'>
                {i18n.settings_team_profile_upload_logo_label()}
            </div>
            <div className='team-settings-profile__line team-settings-profile__line--no-margin'>
                <div className='team-settings-profile__logo'>
                    {logoURL.value !== "" && <img src={logoURL.value} alt='logo' />}
                    <IconPicture />
                    <button
                        type='button'
                        className='team-settings-profile__logo__button'
                        onClick={() => {
                            if (!logoUploadRef.current) {
                                return;
                            }
                            logoUploadRef.current.click();
                        }}
                    >
                        <IconPlusXL />
                    </button>
                </div>

                <input
                    ref={logoUploadRef}
                    type='file'
                    id='upload-logo'
                    accept='image/png, image/jpeg'
                    className='team-settings-profile__upload'
                    onChange={(e) => handleFileSelection(e, "logo")}
                />
            </div>

            <div className='team-settings-profile__line'>
                <InputText
                    label={i18n.settings_team_profile_name()}
                    placeholder={i18n.settings_team_profile_name_placeholder()}
                    value={name.value}
                    name='name'
                    id='name'
                    error={name.dirty ? name.error : undefined}
                    smallInput={true}
                    required={true}
                    onChange={(event) => {
                        setName(event.target.value);
                    }}
                />
            </div>
            <div className='team-settings-profile__line'>
                <InputText
                    label={i18n.settings_team_profile_website()}
                    placeholder={i18n.settings_team_profile_website_placeholder()}
                    value={website.value}
                    name='website'
                    id='website'
                    error={website.dirty || website.value.length > 8 ? website.error : null}
                    smallInput={true}
                    required={true}
                    onChange={(event) => {
                        setWebsite(event.target.value);
                    }}
                />
            </div>
            <div className='team-settings-profile__line'>
                <Textarea
                    id='comment'
                    label={i18n.settings_team_profile_description()}
                    placeholder={i18n.settings_team_profile_description_placeholder()}
                    value={description.value}
                    error={description.dirty ? description.error : undefined}
                    onChange={(value) => setDescription(value)}
                />
            </div>

            <div className='team-settings-profile__block'>
                <InputRadioSwitch
                    id='media-type'
                    label={i18n.settings_team_profile_media()}
                    value={mediaType}
                    onChange={(value) => setMediaType(value)}
                    options={[
                        {
                            icon: "Picture",
                            iconSize: 48,
                            label: i18n.settings_team_profile_media_picture(),
                            value: "cover",
                        },
                        {
                            icon: "Play",
                            iconSize: 48,
                            label: i18n.settings_team_profile_media_video(),
                            value: "youtube",
                        },
                    ]}
                />

                {mediaType === "cover" && (
                    <>
                        <div className='team-settings-profile__upload-block'>
                            <Button
                                color='blue'
                                action={() => {
                                    if (!coverUploadRef.current) {
                                        return;
                                    }

                                    coverUploadRef.current.click();
                                }}
                            >
                                {i18n.settings_team_profile_media_upload()}
                            </Button>
                            <span className='team-settings-profile__upload-block__formats'>
                                {i18n.settings_team_profile_media_valid_formats()}
                            </span>
                        </div>

                        {coverURL.value.startsWith("https://") && (
                            <img
                                className='team-settings-profile__upload-block__cover'
                                src={coverURL.value}
                                alt='cover'
                            />
                        )}

                        <input
                            ref={coverUploadRef}
                            type='file'
                            id='upload-logo'
                            accept='image/png, image/jpeg'
                            className='team-settings-profile__upload'
                            onChange={(e) => handleFileSelection(e, "cover")}
                        />
                    </>
                )}

                {mediaType === "youtube" && (
                    <InputText
                        label={i18n.settings_team_profile_youtube_url()}
                        placeholder={i18n.settings_team_profile_youtube_url_placeholder()}
                        value={youtubeURL.value}
                        name='youtubeURL'
                        id='youtubeURL'
                        error={youtubeURL.dirty ? youtubeURL.error : undefined}
                        smallInput={true}
                        required={true}
                        onChange={(event) => {
                            setYoutubeURL(event.target.value);
                        }}
                    />
                )}
            </div>

            {(name.dirty ||
                description.dirty ||
                website.dirty ||
                logoURL.dirty ||
                youtubeURL.dirty ||
                coverURL.dirty) && (
                <SaveBar
                    saveAction={() => save()}
                    canSave={
                        !(
                            name.error ||
                            description.error ||
                            website.error ||
                            (logoURL.error && !logoFile === null) ||
                            (mediaType === "youtube" && youtubeURL.value !== "" && youtubeURL.error)
                        )
                    }
                />
            )}
        </div>
    );
};

export default Profile;
